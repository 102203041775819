import React, { FC, memo } from 'react'

import { ICCIDSProvider } from 'hooks/iccids'

import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import CreateChipForm from './components/CreateChipForm'
import { useCreateChips } from './context'
import CreateChipProps from './props'
import * as Styled from './styles'

const CreateChip: FC<CreateChipProps> = props => {
  const { stockId } = props

  const { modalActive, closeModal } = useCreateChips()

  return (
    <Dialog
      open={modalActive}
      onClose={closeModal}
      aria-labelledby="form-dialog-create-chips"
      data-cy="modal-create-chip__div"
      fullWidth
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Styled.Title variant="h6" color="textSecondary">
              Cadastrar chips
            </Styled.Title>
          </Grid>
        </Grid>
        <ICCIDSProvider>
          <CreateChipForm stockId={stockId} />
        </ICCIDSProvider>
      </Container>
    </Dialog>
  )
}

export default memo(CreateChip)
