import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    button {
      margin-left: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`
