import styled from 'styled-components'

import ContainerPros from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

export const Container = styled(ContainerPros)`
  margin-bottom: 20px;
`

export const Tittle = styled(Typography)`
  margin: 20px 0 10px 0;
  font-weight: 400;
  font-size: 24px;
`

export const SubTittle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
`

export const ListTittle = styled(Typography)`
  margin-top: 8px;
  font-size: 16px;
`
