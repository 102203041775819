import Axios from 'axios'

export const BASE_URL = process.env.REACT_APP_API_URL

export const api = Axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  timeout: 15000,
})

export const movelApi = Axios.create({
  baseURL: process.env.REACT_APP_BRISAMOVEL_URL,
  responseType: 'json',
  timeout: 15000,
  auth: {
    username: process.env.REACT_APP_BRISAMOVEL_USER || '',
    password: process.env.REACT_APP_BRISAMOVEL_PASS || '',
  },
})
