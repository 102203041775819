import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  ReportsState,
  ReportAltasReducerActionMap,
  ReportActions,
} from './props'

const initialState: ReportsState = {
  requestAltasStatus: {
    error: false,
    loading: false,
    success: false,
  },
  report: null,
  requestOfRechargesStatus: {
    error: false,
    loading: false,
    success: false,
  },
  reportOfRecharges: null,
  requestOfPackagesStatus: {
    error: false,
    loading: false,
    success: false,
  },
  reportOfPackages: null,
}

const reportDetailReducer: Reducer<
  ReportsState,
  ReportAltasReducerActionMap
> = (state, action) => {
  switch (action.type) {
    case ReportActions.ResetReportAltas:
      return initialState

    case ReportActions.RequestReportAltas:
      state.requestAltasStatus.loading = true
      state.requestAltasStatus.success = false
      state.requestAltasStatus.error = false
      return state

    case ReportActions.RequestErrorReportAltas:
      state.requestAltasStatus.loading = false
      state.requestAltasStatus.success = false
      state.requestAltasStatus.error = true
      state.report = null
      return state

    case ReportActions.RequestSuccessReportAltas:
      state.requestAltasStatus.loading = false
      state.requestAltasStatus.success = true
      state.requestAltasStatus.error = false
      state.report = action.payload.data
      return state

    case ReportActions.ResetReportOfRecharges:
      return initialState

    case ReportActions.RequestReportOfRecharges:
      state.requestOfRechargesStatus.loading = true
      state.requestOfRechargesStatus.success = false
      state.requestOfRechargesStatus.error = false
      return state

    case ReportActions.RequestErrorReportOfRecharges:
      state.requestOfRechargesStatus.loading = false
      state.requestOfRechargesStatus.success = false
      state.requestOfRechargesStatus.error = true
      state.reportOfRecharges = null
      return state

    case ReportActions.RequestSuccessReportOfRecharges:
      state.requestOfRechargesStatus.loading = false
      state.requestOfRechargesStatus.success = true
      state.requestOfRechargesStatus.error = false
      state.reportOfRecharges = action.payload.data
      return state

    case ReportActions.ResetReportOfPackage:
      return initialState

    case ReportActions.RequestReportOfPackage:
      state.requestOfPackagesStatus.loading = true
      state.requestOfPackagesStatus.success = false
      state.requestOfPackagesStatus.error = false
      return state

    case ReportActions.RequestErrorReportOfPackage:
      state.requestOfPackagesStatus.loading = false
      state.requestOfPackagesStatus.success = false
      state.requestOfPackagesStatus.error = true
      state.reportOfRecharges = null
      return state

    case ReportActions.RequestSuccessReportOfPackage:
      state.requestOfPackagesStatus.loading = false
      state.requestOfPackagesStatus.success = true
      state.requestOfPackagesStatus.error = false
      state.reportOfPackages = action.payload.data
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(reportDetailReducer)
