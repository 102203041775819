import React, { FC, useCallback, memo, useMemo, useState } from 'react'
import { useDebounce, useEffectOnce } from 'react-use'

import { useField } from 'formik'
import { useStates, State } from 'hooks/location/states'
import { useStockDetail } from 'hooks/stock/details'

import Autocomplete from '@material-ui/lab/Autocomplete'

import * as Styled from './styles'

const AutocompleteState: FC = () => {
  const { states, getStates, status: requestStatusListStates } = useStates()
  const [field, meta, helpers] = useField<string>('state_id')
  const [inputValue, setInputValue] = useState('')
  const { stock } = useStockDetail()

  useEffectOnce(() => {
    setInputValue(stock?.stock?.address.city.state.name || '')
    getStates()
  })

  useDebounce(
    () => {
      getStates(inputValue)
    },
    500,
    [inputValue],
  )

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }
    return {}
  }, [meta])

  const getStateOptionLabel = useCallback((option: State) => option.name, [])

  const onInputStateChange = useCallback((_, value: string) => {
    setInputValue(value)
  }, [])

  const onStateChange = useCallback(
    (event: React.ChangeEvent<{}>, value: State | null) => {
      if (value) {
        helpers.setValue(value.id, true)
      } else {
        field.onChange(event)
      }
    },
    [field, helpers],
  )

  return (
    <Autocomplete
      id="combo-box-states"
      options={states}
      getOptionLabel={getStateOptionLabel}
      noOptionsText="nenhum estado encontrado"
      loading={requestStatusListStates.loading}
      inputValue={inputValue}
      onInputChange={onInputStateChange}
      onChange={onStateChange}
      renderInput={params => {
        // Remove autoFill do Browser
        const originalInputProps = params.inputProps as any
        originalInputProps.autoComplete = 'no'
        params.inputProps = originalInputProps
        return (
          <Styled.TextField
            label="Estado"
            name="state_id"
            variant="outlined"
            onBlur={field.onBlur}
            autoComplete="no"
            {...params}
            {...errorProps}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )
      }}
    />
  )
}

export default memo(AutocompleteState)
