import ButtonStyles from 'components/Button'
import styled from 'styled-components'

export const Button = styled(ButtonStyles)`
  height: 36px;
  font-size: 12px;
  border: 1px solid #9cc4e4;
  width: 40px;
  &:hover {
    background-color: #3a89c9;
  }
`
export const Row = styled.div`
  font-size: 0.79rem;
`
