import React, {
  FC,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'
import Document from 'utils/document'

import {
  EditCompanyRepresentativeCredentials,
  EditCompanyRepresentativeContextData,
} from './props'

const initialValue = {} as EditCompanyRepresentativeContextData
const EditCompanyRepresentativeContext = createContext(initialValue)

export const EditCompanyRepresentativeProvider: FC = props => {
  const { children } = props
  const { push } = useHistory()

  const [status, setStatus] = useState<RequestStatus>({
    loading: false,
    error: false,
    success: false,
  })

  const { addToast } = useToast()

  const editCompanyRepresentative = useCallback(
    async (
      id: string,
      companyId: string,
      credentials: EditCompanyRepresentativeCredentials,
    ) => {
      try {
        if (!id) {
          throw new Error('ID cannot be empty')
        }

        credentials.phone = Document.removeMask(credentials.phone)

        setStatus({
          error: false,
          loading: true,
          success: false,
        })

        await api.put(`/users/${id}`, credentials)

        setStatus({
          error: false,
          loading: false,
          success: true,
        })
        addToast({
          type: 'success',
          title: 'Representante atualizado com sucesso',
        })

        push(
          RoutingService.getRouteString(
            RoutingService.COMPANY_DETAILS,
            'companyId',
            companyId,
          ),
        )
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        setStatus({
          error: true,
          loading: false,
          success: false,
        })
      }
    },
    [addToast, push],
  )

  return (
    <EditCompanyRepresentativeContext.Provider
      value={{
        status,
        editCompanyRepresentative,
      }}
    >
      {children}
    </EditCompanyRepresentativeContext.Provider>
  )
}

export const useEditCompanyRepresentative = () => {
  const context = useContext(EditCompanyRepresentativeContext)
  if (!context || context === initialValue) {
    throw new Error(
      'useEditCompanyRepresentative must be used within a EditCompanyRepresentativeProvider',
    )
  }
  return context
}
