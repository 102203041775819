import { ActionMapData } from 'hooks/props'

export type EditProfileBaseData = {
  name: string
  email: string
  phone: string
  photoAvatar?: File
}

export type EditProfileAndPasswordData = {
  name: string
  email: string
  phone: string
  password: string
  old_password: string
}

export type EditProfileData = EditProfileBaseData | EditProfileAndPasswordData

export type RequestStatus = {
  loading: boolean
  error: boolean
  success: boolean
}

export enum EditProfileActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type UserState = {
  requestStatus: RequestStatus
}

export type UserPayload = {
  [EditProfileActions.Request]: undefined
  [EditProfileActions.RequestSuccess]: undefined
  [EditProfileActions.RequestError]: undefined
  [EditProfileActions.Reset]: undefined
}

export type UserReducerActionMap = ActionMapData<UserPayload>

export interface UploadPhotoValidate {
  avatar: File | null
}

export type EditProfileContextData = {
  requestStatus: RequestStatus

  /**
   * Envia requisição para editar o usuário
   */
  editProfile(newData: EditProfileData): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void

  uploadPhoto(data: UploadPhotoValidate): Promise<void>
}
