import React, { FC, useEffect } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAuth } from 'hooks/auth'
import { ChipsCashFlowProvider } from 'hooks/ChipsCashFlow'
import { useChipsCashFlow } from 'hooks/ChipsCashFlow'
import { api } from 'services/api'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import { FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core'

import SaleType from './components/SaleType'
import Package from './components/SelectPackage'
import Recharge from './components/SelectRecharge'
import Value from './components/SelectValue'
import HasQrcode from './HasQrCode'
import {
  ActionButton,
  Root,
  SelectICCID,
  StepButton,
  ContainerButtons,
  MenuProps,
  CelPhoneNumber,
  Container,
} from './styles'

const NewSalesCashFlow: FC = () => {
  const { user } = useAuth()
  const {
    data,
    chipValue,
    packageRecharge,
    recharge,
    saleType,
    clearAll,
    getWalletICCID,
  } = useChipsCashFlow()

  const [IDS, setIds] = React.useState<string[]>([])
  const [CCid, setCCid] = React.useState<string>('')
  const [QrCode, setQrCode] = React.useState<string>()
  const [numberChip, setNumberChip] = React.useState<string>('')

  const fullAllInputs =
    CCid &&
    chipValue &&
    packageRecharge &&
    saleType &&
    numberChip.length > 12 &&
    recharge

  useEffect(() => {
    if (user) {
      getWalletICCID({ status_eq: 'available' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (data) {
      const iccids = data.map(chip => chip.iccid)
      setIds(iccids)
    }
  }, [data])

  const NewSale = async (CCid: string) => {
    const params = {
      iccid: CCid,
      value: Number(chipValue.replace(',', '.').slice(2)),
      recharge_value: Number(recharge.replace(',', '.').slice(2)),
      pack: packageRecharge,
      phone_number: numberChip,
      category: saleType,
      seller_user_id: user?.id,
      seller_role_name: user?.role.name,
    }
    const {
      data: { encrypted_pix_transaction },
    } = await api.post('/sales-to-customers/encrypt-pix-transaction', params)

    const HasCode = `${process.env.REACT_APP_FRONT_BRISAMOVEL}/payment-flow/${encrypted_pix_transaction}`

    setQrCode(HasCode)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCCiD = CCID => {
    setCCid(CCID)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleReset = () => {
    setCCid('')
    setNumberChip('')
    clearAll()
    setQrCode('')
  }

  return (
    <Route
      title="Nova Venda"
      access="auth"
      requireRoles={[
        'admin',
        'attendant',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'office_stockist',
        'sale_leader',
        'pap_leader',
        'pap_seller',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Nova venda',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-new-sales"
      >
        <Container>
          <PageTitle title={'Escolha um ICCID'} />
          <Grid container spacing={4}>
            <Grid container item xs={12} spacing={0}>
              <Value />
              <Package />
              <Recharge />
              <SaleType />
              <CelPhoneNumber
                mask="(99) 99999-9999"
                maskChar={null}
                placeholder="Número do chip"
                value={numberChip}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNumberChip(e.target.value)
                }}
              />
              <FormControl variant="outlined">
                <InputLabel>ICCID</InputLabel>
                <SelectICCID
                  variant="outlined"
                  MenuProps={MenuProps}
                  value={CCid ? CCid : ' '}
                >
                  {IDS.map(item => (
                    <MenuItem
                      onClick={() => handleCCiD(item)}
                      value={item}
                      key={`${item}`}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </SelectICCID>
              </FormControl>
            </Grid>
          </Grid>
          <ActionButton
            variant="contained"
            color="primary"
            disabled={fullAllInputs ? false : true}
            onClick={() => NewSale(CCid)}
          >
            Nova Venda
          </ActionButton>
          <Root>
            <HasQrcode QRCODE={QrCode} />
          </Root>
          <ContainerButtons>
            <StepButton
              color="primary"
              variant="outlined"
              disabled={CCid ? false : true}
              onClick={handleReset}
            >
              Resetar valores
            </StepButton>
          </ContainerButtons>
        </Container>
      </LayoutProduct>
    </Route>
  )
}
export default withContext(NewSalesCashFlow, ChipsCashFlowProvider)
