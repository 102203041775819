import React, { FC, memo } from 'react'

import TableRowItem from 'components/TableRowItem'
import customFormatDate, { DATE_FORMAT_DMY_HOUR_MINUTE } from 'utils/formatDate'

import TableCell from '@material-ui/core/TableCell'

import { UploadFileListItemProps } from './props'

const UploadFileListItem: FC<UploadFileListItemProps> = props => {
  const { file_name, created_at, referring_date } = props

  return (
    <TableRowItem>
      <TableCell>{file_name}</TableCell>
      <TableCell>
        {customFormatDate({
          format: 'dd/MM/yyyy',
          date: referring_date.toString(),
        })}
      </TableCell>
      <TableCell>
        {customFormatDate({
          format: DATE_FORMAT_DMY_HOUR_MINUTE,
          date: created_at.toString(),
        })}
      </TableCell>
    </TableRowItem>
  )
}

export default memo(UploadFileListItem)
