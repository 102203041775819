import QRCode from 'react-qr-code'

import styled from 'styled-components'

import { TextField } from '@material-ui/core'

export const ContainerQRCode = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const ContainerQrCodeDefault = styled.div`
  margin-bottom: 20px;
  width: 300px;
  height: 300px;
  background-color: #f0f0f4;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ICCCIDQRCode = styled(QRCode)`
  width: 256px;
  height: 256px;
  margin-top: 76px;
`
export const TextInput = styled(TextField)`
  margin-top: 22px;
  margin-bottom: 22px;
  width: 356px;
`
