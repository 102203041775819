import styled from 'styled-components'

import MUTableFooter from '@material-ui/core/TableFooter'

export const TableFooter = styled(MUTableFooter)`
  .MuiToolbar-root {
    justify-content: flex-end;
    padding: 0;
  }
  .TablePagination__lines-per-page {
    padding-right: ${({ theme }) => theme.spacing(1)}px;
  }
  .TablePagination__page-indicator {
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
`
