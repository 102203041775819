import Typography from 'components/Typography'
import styled from 'styled-components'

import GridUnstyled from '@material-ui/core/Grid'

export const TextDate = styled.span`
  font-weight: 500;
`
export const Grid = styled(GridUnstyled)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const BoldText = styled(Typography)`
  font-weight: 600;
`
