import React, { memo, useMemo } from 'react'

import HelpIcon from 'components/HelpIcon'
import TextField from 'components/Inputs/TextField'
import Typography from 'components/Typography'
import { useFormikContext } from 'formik'

import Grid from '@material-ui/core/Grid'

import { useNewSale, useQuantityChips } from '../../context'
import { CreateSaleData } from '../../context/props'
import * as Styled from './styles'

const IntervalIccids: React.FC<any> = props => {
  const { index } = props

  const { isValidQuantityChips } = useNewSale()

  const { values } = useFormikContext<CreateSaleData>()

  const { quantityChips } = useQuantityChips(
    values.iccids_intervals[index].first_iccid,
    values.iccids_intervals[index].last_iccid,
    index,
  )

  // eslint-disable-next-line consistent-return
  const renderErrorMultipleFive = useMemo(() => {
    if (!isValidQuantityChips) {
      return {
        helperText: 'Quantidade de chips deve ser múltiplo de cinco',
        error: true,
      }
    }
  }, [isValidQuantityChips])

  return (
    <Styled.Grid container spacing={2}>
      <Grid lg={4} md={4} sm={12} xs={12} item>
        <TextField
          label="ICCID Inicial"
          name={`iccids_intervals[${index}].first_iccid`}
          mask="99999999999999999999"
          {...renderErrorMultipleFive}
          InputProps={{
            endAdornment: <HelpIcon />,
          }}
        />
      </Grid>
      <Grid lg={4} md={4} sm={12} xs={12} item>
        <TextField
          label="ICCID Final"
          name={`iccids_intervals[${index}].last_iccid`}
          mask="99999999999999999999"
          {...renderErrorMultipleFive}
        />
      </Grid>
      <Grid lg={2} md={2} sm={6} xs={6} item>
        <Styled.QuantityChips color="initial" variant="subtitle1">
          Qtd. de chips
        </Styled.QuantityChips>
        <Typography
          color="primary"
          variant="h6"
          capitalize="uppercase"
          data-cy="quantity_chip__h6"
        >
          {quantityChips}
        </Typography>
      </Grid>
    </Styled.Grid>
  )
}

export default memo(IntervalIccids)
