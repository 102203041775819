import PureButton from 'components/Button'
import styled from 'styled-components'

export const Button = styled(PureButton)`
  background-color: ${({ theme }) => theme.palette.success.light};

  color: ${({ theme }) => theme.palette.success.contrastText};
  :hover {
    background-color: ${({ theme }) => theme.palette.success.light};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    min-width: 100%;
  }
`
