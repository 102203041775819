/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'

import ComboBox from 'components/SelectStores'
import { Options } from 'components/SelectStores/props'
import { Formik } from 'formik'
import { useAuth } from 'hooks/auth'
import { useChipsLeavesCashFlow } from 'hooks/ChipsLeavesCashFlow'
import { OfficeData } from 'hooks/salesCashFlow/props'
import { DataGenerateCommissionProps } from 'pages/GenerateCommission/context/props'
import { initialSeller, initialStore } from 'utils/initialStateForSelects'
import { GetOffices, GetSellers } from 'utils/officesAndSellers'
import { rolePermission } from 'utils/permissions'

import { DatePicker } from '@material-ui/pickers'

import * as Styled from './styles'
const HeaderTable = () => {
  const initialValues: DataGenerateCommissionProps = {
    month: '',
    year: '',
  }
  const {
    store,
    seller,
    startDate,
    endDate,
    category,
    setSeller,
    setStore,
    setCategory,
    setStartDate,
    setEndDate,
    getChipsLeaves,
  } = useChipsLeavesCashFlow()
  const { user } = useAuth()
  const [offices, setOffices] = useState<OfficeData[]>([])
  const [sellers, setSellers] = useState<Options[]>([])
  const [permission, setPermission] = useState<string>('')
  useEffect(() => {
    if (offices.length === 0) {
      (async () => {
        const dataOffices = await GetOffices()
        setOffices(dataOffices)
      })()
    }

    if (store) {
      (async () => {
        const dataSellers = await GetSellers(store.id)
        setSellers(dataSellers)
      })()
    }
    if (user) {
      const role = rolePermission(user?.role.name)
      setPermission(role)
    }
    return () => {
      setOffices([])
      setSellers([])
      setPermission('')
    }
  }, [seller?.name, store?.name, user])

  useEffect(() => {
    if (!store?.name) {
      setSellers([])
      setSeller(initialSeller)
      setStore(initialStore)
    }
    getChipsLeaves()
  }, [startDate, endDate, category, store, seller])

  useEffect(() => {
    if (
      store?.name == '' &&
      permission == 'midlePermission' &&
      offices.length > 0
    ) {
      setStore(offices[0])
    }
  }, [offices, permission])

  const icon = <img src={require('assets/images/icons/calendar.svg').default} />
  return (
    <Formik initialValues={initialValues} onSubmit={getChipsLeaves}>
      <Styled.FormControl fullWidth>
        <Styled.Container>
          <DatePicker
            variant="inline"
            label="Data de início"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={startDate}
            InputProps={{
              endAdornment: icon,
            }}
            onChange={value => setStartDate(value!)}
          />
          <DatePicker
            variant="inline"
            label="Data final"
            inputVariant="outlined"
            value={endDate}
            format="dd/MM/yyyy"
            InputProps={{
              endAdornment: icon,
            }}
            onChange={value => setEndDate(value!)}
          />

          {permission === 'higherPermission' && (
            <ComboBox
              width="250px"
              labelName="Lojas"
              options={offices}
              setOption={setStore}
              value={store}
            />
          )}
          {(permission === 'midlePermission' ||
            permission === 'higherPermission') && (
            <ComboBox
              width="250px"
              labelName="Vendedores"
              options={sellers}
              setOption={setSeller}
              value={seller}
            />
          )}
          <ComboBox
            width="250px"
            labelName="Categorias"
            options={[
              { name: 'Perca', id: 'Reports_Leaves_Perca', labelName: 'loss' },
              { name: 'Roubo', id: 'Reports_Leaves_Roubo', labelName: 'theft' },

              {
                name: 'Problema de rede',
                id: 'Reports_Leaves_Problema_de_rede',
                labelName: 'network_problem',
              },

              {
                name: 'Solicitado pela vivo',
                id: 'Reports_Leaves_Solicitado_pela_vivo',
                labelName: 'requested_by_vivo',
              },
              {
                name: 'Produto',
                id: 'Reports_Leaves_Produto',
                labelName: 'product',
              },
              {
                name: 'Setor Comercial',
                id: 'Reports_Leaves_Setor_Comercial',
                labelName: 'commercial_sector',
              },
              {
                name: 'Marketing',
                id: 'Reports_Leaves_Marketing',
                labelName: 'marketing',
              },
            ]}
            setOption={setCategory}
            value={category}
          />
        </Styled.Container>
      </Styled.FormControl>
    </Formik>
  )
}

export default HeaderTable
