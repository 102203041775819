export type ChipsPageLeavesProps =
  | 'loss'
  | 'theft'
  | 'network_problem'
  | 'requested_by_vivo'
  | 'product'
  | 'commercial_sector'
  | 'marketing'

export const TranslateCategorySell = (category: string | undefined): string => {
  switch (category) {
    case 'single':
      return 'Venda Avulsa'
    case 'activation':
      return 'Venda Ativação'
    case 'portability':
      return 'Portabilidade/Migração'
    default:
      return 'Desconhecido'
  }
}

export const TranslateToInglishCategorySell = (
  category: string | undefined,
): string => {
  switch (category) {
    case 'Venda avulsa':
      return 'single'
    case 'Venda Avulsa':
      return 'single'
    case 'Venda Ativação':
      return 'activation'
    case 'Portabilidade/Migração':
    case 'Portabilidade / Migração':
      return 'portability'
    default:
      return 'Desconhecido'
  }
}

export const TranslateStatusSell = (status: string | undefined): string => {
  switch (status) {
    case 'paid':
      return 'Pago'
    case 'canceled':
      return 'Cancelado'
    case 'in_progress':
      return 'Em andamento'
    default:
      return 'Desconhecido'
  }
}

export const TranslateStatusChip = (status: string | undefined): string => {
  switch (status) {
    case 'pending':
      return 'Venda em processamento'
    case 'with_customer':
      return 'Vendido'
    case 'available':
      return 'Disponivel para a venda'
    case 'output':
      return 'Saída efetivada'
    case 'exchange':
      return 'Troca efetivada'
    default:
      return 'Status Desconhecido'
  }
}

export const TranslateCategoryReports = (
  category: string | undefined,
): string => {
  switch (category) {
    case 'loss':
      return 'Perca'
    case 'theft':
      return 'Roubo'
    case 'network_problem':
      return 'Problema de rede'
    case 'requested_by_vivo':
      return 'Solicitado pela vivo'
    case 'product':
      return 'Produto'
    case 'commercial_sector':
      return 'Setor comercial'
    case 'marketing':
      return 'Marketing'
    default:
      return 'Desconhecido'
  }
}
