import React, { FC, memo, useMemo } from 'react'

import Formik from 'components/Formik'
import TextField from 'components/Inputs/TextField'

import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useAskReplacement } from './context'
import { DataFormAskReplacement } from './context/props'
import schema from './schema'
import * as Styled from './styles'

const AskReplacement: FC = () => {
  const {
    modalActive,
    closeModal,
    askForReplacement,
    status,
  } = useAskReplacement()

  const renderLabelButton = useMemo(() => {
    if (status.loading) {
      return 'Carregando...'
    }

    return 'Solicitar'
  }, [status])

  const initialValues: DataFormAskReplacement = {
    chip_quantity: undefined,
  }

  return (
    <Dialog
      open={modalActive}
      onClose={closeModal}
      aria-labelledby="form-dialog-ask-for-replacement"
      data-cy="modal_ask_replacement__div"
      fullWidth
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Styled.Title variant="h6" color="textSecondary">
              Solicitar reposição
            </Styled.Title>
            <Formik
              onSubmit={askForReplacement}
              initialValues={initialValues}
              validationSchema={schema}
            >
              <Grid item sm={6} xs={12}>
                <TextField
                  name="chip_quantity"
                  label="Quantidade (não obrigatório)"
                  type="number"
                  autoFocus
                />
              </Grid>
              <Styled.FooterModal item xs={12}>
                <Typography variant="caption">
                  * Você não precisa especificar a quantidade
                </Typography>
                <Styled.ButtonSubmit
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={status.loading}
                >
                  {renderLabelButton}
                </Styled.ButtonSubmit>
              </Styled.FooterModal>
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}

export default memo(AskReplacement)
