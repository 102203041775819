import React, { FC, memo } from 'react'

import { ButtonSidebarProps } from './props'
import * as Styled from './styles'

const ButtonSidebar: FC<ButtonSidebarProps> = props => {
  const { typeIcon, onClick, children } = props

  return (
    <Styled.Container>
      <Styled.Button onClick={onClick} typeIcon={typeIcon}>
        {children}
      </Styled.Button>
    </Styled.Container>
  )
}

export default memo(ButtonSidebar)
