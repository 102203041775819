import React, { FC, memo } from 'react'

import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import { useListIntervalIccids } from './context'
import * as Styled from './styles'

const ListIntervalIccids: FC = () => {
  const {
    modalActive,
    closeModal,
    intervalListIccids,
  } = useListIntervalIccids()

  return (
    <Dialog open={modalActive} onClose={closeModal} fullWidth>
      <Styled.Container>
        <Styled.Tittle variant="h6" color="textSecondary">
          Intervalo de iccids
        </Styled.Tittle>

        <Grid container justify="space-between">
          <Grid item lg={6}>
            <Styled.SubTittle color="primary">
              Intervalo inicial
            </Styled.SubTittle>
            {intervalListIccids.map(item => (
              <Styled.ListTittle key={item.id}>
                {item.first_iccid}
              </Styled.ListTittle>
            ))}
          </Grid>

          <Grid item lg={6}>
            <Styled.SubTittle color="primary">Intervalo final</Styled.SubTittle>
            {intervalListIccids.map(item => (
              <Styled.ListTittle key={item.id}>
                {item.last_iccid}
              </Styled.ListTittle>
            ))}
          </Grid>
        </Grid>
      </Styled.Container>
    </Dialog>
  )
}

export default memo(ListIntervalIccids)
