/* eslint-disable react/destructuring-assignment */
import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useReducer,
  memo,
} from 'react'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import {
  NewDistributorContextProps,
  NewDistributorActions,
  DataFormAddDistributorType,
} from './props'
import newUserReducer, { initialState } from './reducer'

const NewDistributorContext = createContext({} as NewDistributorContextProps)

const NewDistributorProvider: FC = props => {
  const [state, dispatch] = useReducer(newUserReducer, initialState)
  const { addToast } = useToast()

  /**
   *
   * @param value ['franchisee'] | ['partner'] | ['partner', 'franchise']
   * @return 'franchisee' | 'partner' | 'franchisee_and_partner'
   */
  const formatPatternCompanyWhoCanSell = useCallback((value: string[]) => {
    if (value.includes('partner') && value.includes('franchisee')) {
      return 'franchisee_and_partner'
    }
    return value.toString()
  }, [])

  const addDistributor = useCallback(
    async (data: DataFormAddDistributorType) => {
      try {
        dispatch({ type: NewDistributorActions.RequestNewDistributor })

        if (data.company_who_can_sell) {
          data.company_who_can_sell = formatPatternCompanyWhoCanSell(
            data.company_who_can_sell as string[],
          )
        }

        const response = await api.post('/distributors', data)

        addToast({
          type: 'success',
          title: 'Distribuidor adicionado!',
        })
        dispatch({
          type: NewDistributorActions.RequestSuccessNewDistributor,
          payload: response,
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        dispatch({
          type: NewDistributorActions.RequestErrorNewDistributor,
        })
      }
    },
    [addToast, formatPatternCompanyWhoCanSell],
  )

  const resetState = useCallback(
    () => dispatch({ type: NewDistributorActions.Reset }),
    [],
  )

  return (
    <NewDistributorContext.Provider
      value={{ ...state, addDistributor, resetState }}
    >
      {props.children}
    </NewDistributorContext.Provider>
  )
}

export const useAddDistributor = () => useContext(NewDistributorContext)

export default memo(NewDistributorProvider)
