import React, { FC, useMemo } from 'react'

import Card from 'components/Card'
import Typography from 'components/Typography'
import { useRecharge } from 'hooks/recharges'
import customFormatPrice from 'utils/formatPrice'

import { CircularProgress } from '@material-ui/core'

import * as Styled from './styles'

const RechargesReportCards: FC = () => {
  const { requestStatus, meta } = useRecharge()

  const price = useMemo(() => {
    if (typeof meta?.total_of_interval == undefined) return null
    return customFormatPrice({ price: meta?.total_of_interval })
  }, [meta])

  if (requestStatus.loading) {
    return (
      <Styled.Container>
        <Card>
          <Styled.Center>
            <CircularProgress />
          </Styled.Center>
        </Card>
      </Styled.Container>
    )
  }

  return (
    <Styled.Container>
      <Styled.Row>
        <Styled.Card>
          <Typography color="primary">Valor total</Typography>
          <Typography color="primary" variant="h3">
            {price}
          </Typography>
        </Styled.Card>

        <Styled.Card>
          <Typography color="primary">Usuarios diferentes</Typography>
          <Typography color="primary" variant="h3">
            {meta?.distinct_users}
          </Typography>
        </Styled.Card>
      </Styled.Row>
    </Styled.Container>
  )
}

export default RechargesReportCards
