import React, {
  FC,
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react'

import { FormStepperContextProps, FormStepperProviderProps } from './props'

const FormStepperContext = createContext({} as FormStepperContextProps)

export const FormStepperProvider: FC<FormStepperProviderProps> = props => {
  const { forms } = props
  const [step, setStep] = useState(0)

  const maxSteps = useMemo(() => Object.keys(forms).length, [forms])

  const nextStep = useCallback(() => {
    if (step < maxSteps) {
      setStep(step + 1)
    }
  }, [step, maxSteps])

  const previousStep = useCallback(() => {
    if (step > 0) {
      setStep(step - 1)
    }
  }, [step])

  const goToStep = useCallback(
    (newStep: number) => {
      if (newStep < maxSteps && newStep >= 0) {
        setStep(newStep)
      }
    },
    [maxSteps],
  )

  return (
    <FormStepperContext.Provider
      value={{ step, maxSteps, nextStep, previousStep, goToStep }}
    >
      {forms[step]}
    </FormStepperContext.Provider>
  )
}

export function useStepper(): FormStepperContextProps {
  const context = useContext(FormStepperContext)

  if (!context) {
    throw new Error('useStepper must be used within a FormStepperContext')
  }

  return context
}
