import React, { FC, memo, useMemo } from 'react'
import { Textfit } from 'react-textfit'

import { insertDots } from 'utils/formatInteger'

import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import { QuantityChipsCardProps } from './props'
import * as Styled from './styles'

const QuantityChipsCard: FC<QuantityChipsCardProps> = props => {
  const { title, value, tooltip = '', loading } = props

  const renderValue = useMemo(() => {
    if (typeof value === 'undefined') {
      return '0'
    }
    return insertDots(value) || '0'
  }, [value])

  const renderHelpIcon = useMemo(
    () => tooltip !== '' && <Styled.HelpIcon title={tooltip} />,
    [tooltip],
  )

  if (loading) {
    return (
      <Styled.Card>
        <CardContent>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Styled.CardHeader>
                <Styled.Title variant="subtitle2">{title}</Styled.Title>
                {renderHelpIcon}
              </Styled.CardHeader>
            </Grid>
            <Grid item>
              <Skeleton variant="rect" width={90} height={60} />
            </Grid>
          </Grid>
        </CardContent>
      </Styled.Card>
    )
  }

  return (
    <Styled.Card>
      <CardContent>
        <Grid container spacing={1} direction="column">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Styled.CardHeader>
              <Styled.Title variant="subtitle2">{title}</Styled.Title>
              {renderHelpIcon}
            </Styled.CardHeader>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Styled.TextValueItem variant="h3" color="primary">
              <Textfit>{renderValue}</Textfit>
            </Styled.TextValueItem>
          </Grid>
        </Grid>
      </CardContent>
    </Styled.Card>
  )
}

export default memo(QuantityChipsCard)
