import React, { FC } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import Route from 'components/Route'
import { ChipsCashFlowProvider } from 'hooks/ChipsCashFlow'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import ChangeChip from './ChangeChip'
import LeavesChip from './LeavesChip'

const Leaves: FC = () => {
  return (
    <Route
      title="Saídas"
      access="auth"
      requireRoles={[
        'admin',
        'attendant',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'office_stockist',
        'sale_leader',
        'pap_leader',
        'pap_seller',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Saídas',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-leaves"
      >
        <LeavesChip />
        <ChangeChip />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Leaves, ChipsCashFlowProvider)
