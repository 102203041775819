import React, { FC, memo, useMemo } from 'react'

import { PageTitleProps } from './props'
import * as Styled from './styles'

const PageTitle: FC<PageTitleProps> = props => {
  const { title, buttonText, showButton, buttonOnClick } = props

  const pageButton = useMemo(() => {
    if (!showButton) return null

    return (
      <Styled.Button
        data-cy="page-title__button"
        color="primary"
        variant="contained"
        size="large"
        disableElevation
        onClick={buttonOnClick}
      >
        {buttonText || 'Novo'}
      </Styled.Button>
    )
  }, [buttonOnClick, buttonText, showButton])

  return (
    <Styled.Header>
      <Styled.HeaderTitle variant="h3" color="textPrimary">
        {title}
      </Styled.HeaderTitle>

      {pageButton}
    </Styled.Header>
  )
}

export default memo(PageTitle)
