import { formatToPattern } from './formatToPattern'

export function removeSpaces(value: string) {
  const pattern = /\s/g
  return value.replace(pattern, '')
}

function extractNumbers(value: string) {
  const pattern = /\d+/g
  const onlyNumbers = value.match(pattern)?.join('') || ''

  return removeSpaces(onlyNumbers)
}

export function formatPhone(mask: string, value: string) {
  const phone = extractNumbers(value)
  return formatToPattern(phone, mask)
}
