import ButtonStyles from 'components/Button'
import styled from 'styled-components'

import TableRow from '@material-ui/core/TableRow'
export const Button = styled(ButtonStyles)`
  height: 36px;
`
export const TableLine = styled(TableRow)`
  .MuiTableCell-head {
    font-weight: 700;
    margin: 40;
  }
`
export const Box = styled.div`
  font-weight: 700;
`
