import { useCallback, useState } from 'react'

import { CreatedAndUpdatedDateType } from 'interfaces/common'
import { api } from 'services/api'

type Company = CreatedAndUpdatedDateType & {
  id: string
  fantasy_name: string
  representative: {
    user: {
      name: string
    }
  }
}

export const useCompany = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })
  const [company, setCompany] = useState<Company | null>(null)

  const getCompany = useCallback(async (id: string) => {
    try {
      setStatus({
        error: false,
        loading: true,
      })

      const response = await api.get(`/companies/${id}`)

      setCompany(response.data)

      setStatus({
        error: false,
        loading: false,
      })
    } catch (e) {
      setCompany({} as Company)

      setStatus({
        error: true,
        loading: false,
      })
    }
  }, [])

  return {
    status,
    company,
    getCompany,
  }
}
