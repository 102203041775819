/* eslint-disable react/destructuring-assignment */
import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useReducer,
  useState,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { useAuth } from 'hooks/auth'
import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ContextUser from 'services/contextUser'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'
import validateICCID from 'utils/validateICCID'

import {
  NewTransferContextProps,
  NewTransferActions,
  DataFormAddTransferType,
} from './props'
import newUserReducer, { initialState } from './reducer'

const NewTransferContext = createContext({} as NewTransferContextProps)

export const NewTransferProvider: FC = props => {
  const [state, dispatch] = useReducer(newUserReducer, initialState)
  const history = useHistory()
  const { addToast } = useToast()
  const { user, getProfile } = useAuth()
  const [senderUser, setSenderUser] = useState({ id: '', type: '' })

  useEffectOnce(() => {
    getProfile()
  })

  useEffect(() => {
    if (user) {
      setSenderUser({
        id: ContextUser.extractId(user),
        type: ContextUser.extractType(user),
      })
    }
  }, [user])

  const addTransfer = useCallback(
    async ({
      iccids_intervals,
      recipient,
      sender,
      quantityChips,
    }: DataFormAddTransferType) => {
      try {
        sender = senderUser
        if (!quantityChips) {
          dispatch({ type: NewTransferActions.RequestNewTransfer })
          const response = await api
            .post('/transfers', {
              iccids_intervals,
              recipient,
              sender,
            })
            .catch(error => {
              throw error
            })

          dispatch({
            type: NewTransferActions.RequestSuccessNewTransfer,
            payload: response,
          })
          addToast({
            type: 'success',
            title: 'Transferência adicionada!',
          })

          history.push(
            RoutingService.getRouteString(
              RoutingService.TRANSFER_DETAILS,
              'transferId',
              response.data.id,
            ),
          )
        } else {
          addToast({
            type: 'info',
            title: '',
            description:
              'Essa transferência contém muitos chips, a confirmação pode demorar até 2 minutos',
          })
          dispatch({ type: NewTransferActions.RequestNewTransfer })
          api.defaults.timeout = 200000
          const response = await api
            .post('/transfers', {
              iccids_intervals,
              recipient,
              sender,
            })
            .catch(error => {
              throw error
            })

          dispatch({
            type: NewTransferActions.RequestSuccessNewTransfer,
            payload: response,
          })
          addToast({
            type: 'success',
            title: 'Transferência adicionada!',
          })

          history.push(
            RoutingService.getRouteString(
              RoutingService.TRANSFER_DETAILS,
              'transferId',
              response.data.id,
            ),
          )
        }
      } catch (error) {
        dispatch({ type: NewTransferActions.RequestErrorNewTransfer })
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
      }
    },
    [addToast, history, senderUser],
  )

  const resetState = useCallback(
    () => dispatch({ type: NewTransferActions.Reset }),
    [],
  )

  const updateIntervals = useCallback(
    (index: number, value: number) =>
      dispatch({
        type: NewTransferActions.RequestSuccessContChips,
        payload: { index, value },
      }),
    [],
  )

  return (
    <NewTransferContext.Provider
      value={{ ...state, addTransfer, resetState, updateIntervals }}
    >
      {props.children}
    </NewTransferContext.Provider>
  )
}

export const useNewTransfer = () => useContext(NewTransferContext)

export function useQuantityChips(
  first_iccid: string,
  last_iccid: string,
  index: number,
) {
  const [status, setStatus] = useState({
    error: false,
    loading: false,
  })

  const [quantityChips, setQuantityChips] = useState(0)
  const { updateIntervals } = useNewTransfer()

  const countICCIDS = useCallback(
    async (first_iccid: string, last_iccid: string, index: number) => {
      try {
        setStatus({ error: false, loading: true })

        const response = await api.get(`/chips/iccids`, {
          params: {
            first_iccid,
            last_iccid,
          },
        })

        setStatus({ error: false, loading: false })
        updateIntervals(index, response.data.iccids.length)
        setQuantityChips(response.data.iccids.length)
      } catch (error) {
        setStatus({ error: true, loading: false })
      }
    },
    [updateIntervals],
  )

  useEffect(() => {
    if (validateICCID([first_iccid, last_iccid])) {
      countICCIDS(first_iccid, last_iccid, index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first_iccid, last_iccid])

  return { status, quantityChips }
}
