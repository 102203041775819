import React, { FC, useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import RequestError from 'components/DetailsPages/RequestError'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import ModalListIntervalIccids from 'components/Modals/ListIntervalIccids'
import ListIntervalIccidsProvider from 'components/Modals/ListIntervalIccids/context'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import { useAuth } from 'hooks/auth'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import DetailsComponent from './components/DetailsComponent'
import { useSaleDetail, SaleDetailProvider } from './context'

const SaleDetails: FC = () => {
  const { getSale, requestStatus } = useSaleDetail()

  const { saleId } = useParams<any>()
  const { push } = useHistory()
  const { hasRole, user } = useAuth()

  useEffectOnce(() => {
    getSale(saleId)
  })

  const redirectToNew = useCallback(() => push(RoutingService.NEW_SALE), [push])

  const renderPageTitle = useMemo(() => {
    if (hasRole(['distributor', 'dev'])) {
      return (
        <PageTitle
          title="Vendas"
          showButton
          buttonOnClick={redirectToNew}
          buttonText="Nova venda"
        />
      )
    }

    return (
      <PageTitle
        title={
          user?.role.name == 'company_representative' ? 'Compras' : 'Vendas'
        }
      />
    )
  }, [hasRole, redirectToNew, user?.role.name])

  const Content = useMemo(() => {
    if (requestStatus.success) return <DetailsComponent id={saleId} />
    if (requestStatus.error) return <RequestError />
    return <Loading />
  }, [requestStatus, saleId])

  return (
    <Route
      title="Venda"
      access="auth"
      requireRoles={[
        'dev',
        'distributor',
        'analyst',
        'admin',
        'stockist',
        'company_representative',
        'financial_collection',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title:
              user?.role.name === 'company_representative'
                ? 'Compras'
                : 'Vendas',
            link: RoutingService.SALES,
          },
          {
            title:
              user?.role.name === 'company_representative'
                ? 'Detalhes da Compra'
                : 'Detalhes da Venda',
          },
        ]}
        activeOption="sales"
      >
        {renderPageTitle}

        {Content}
        <ModalListIntervalIccids />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(
  SaleDetails,
  SaleDetailProvider,
  ListIntervalIccidsProvider,
)
