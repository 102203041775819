import React, { FC, useMemo, memo } from 'react'

import Typography from 'components/Typography'
import { useTransferDetail } from 'hooks/transfers/details'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

const TransferHeader: FC = () => {
  const { transfer } = useTransferDetail()

  const color = useMemo(() => {
    if (!transfer) return '#FFF'
    const { status } = transfer

    switch (status) {
      case 'canceled':
        return '#F2994A'
      case 'denied':
        return '#EB5757'
      case 'finished':
        return '#8CC83C'
      default:
        return '#F2C94C'
    }
  }, [transfer])

  return (
    <Styled.Header color={color}>
      <Styled.Grid container spacing={2}>
        <Grid sm={12} item>
          <Typography color="inherit" variant="h6">
            Transferência {transfer?.order_number}
          </Typography>
        </Grid>
      </Styled.Grid>
    </Styled.Header>
  )
}

export default memo(TransferHeader)
