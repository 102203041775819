import ButtonSubmitStyles from 'components/ButtonSubmit'
import DatePickerStyles from 'components/Inputs/DatePicker'
import styled from 'styled-components'

import MUFormControl from '@material-ui/core/FormControl'
import GridStyles from '@material-ui/core/Grid'

export const GridContainer = styled(GridStyles)`
  margin-bottom: 10px;
  padding-top: 10px;
  background-color: #f1f3f5;
`

export const GridItem = styled(GridStyles)``

export const ButtonSubmit = styled(ButtonSubmitStyles)`
  width: 80%;
  font-size: 16px;
  background-color: ${() => '#083D93'};
  color: ${({ theme }) => theme.palette.success.contrastText};

  :hover {
    background-color: ${({ theme }) => theme.palette.success.main};
    box-shadow: none;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.12);
  }
`
export const FormControl = styled(MUFormControl)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  .MuiOutlinedInput-notchedOutline {
    border-width: 1.5px !important;
  }
`

export const Container = styled.div`
  background-color: #f1f3f5;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: end;

  & > div {
    margin-right: 15px;
  }
`

export const DatePicker = styled(DatePickerStyles)``
