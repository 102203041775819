import { useCallback, useState } from 'react'

import { api } from 'services/api'

export type City = {
  id: string
  created_at: string | Date
  updated_at: string | Date
  ibge_id: number
  name: string
}

type Params = {
  state_id_eq: string
  name_contains?: string
}

export const useCities = () => {
  const [cities, setCities] = useState<City[]>([])
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })

  const getCities = useCallback(
    async (state_id_eq: string, name_contains?: string) => {
      try {
        setStatus({
          error: false,
          loading: true,
        })

        const params: Params = {
          state_id_eq,
          name_contains: undefined,
        }
        if (name_contains) {
          params.name_contains = name_contains
        }

        const response = await api.get('/cities', {
          params,
        })

        setCities(response.data.data)

        setStatus({
          error: false,
          loading: false,
        })
      } catch (e) {
        setCities([])
        setStatus({
          error: true,
          loading: false,
        })
      }
    },
    [],
  )

  return { cities, getCities, status }
}
