import ButtonSubmitStyles from 'components/ButtonSubmit'
import DatePickerStyles from 'components/Inputs/DatePicker'
import styled from 'styled-components'

import GridStyles from '@material-ui/core/Grid'

export const GridContainer = styled(GridStyles)`
  margin-bottom: 80px;
`

export const GridItem = styled(GridStyles)``

export const ButtonSubmit = styled(ButtonSubmitStyles)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.success.light};
  color: ${({ theme }) => theme.palette.success.contrastText};

  :hover {
    background-color: ${({ theme }) => theme.palette.success.dark};
    box-shadow: none;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.12);
  }
`

export const DatePicker = styled(DatePickerStyles)``
