import * as Yup from 'yup'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('Campo obrigatório'),
  password: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'Mínimo de 8 caracteres'),
})

export default schema
