import React, { FC, memo, useEffect, useMemo } from 'react'

import Button from 'components/Button'
import RequestError from 'components/DetailsPages/RequestError'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import customDateReport from 'utils/formateDateReport'

import { PDFDownloadLink } from '@react-pdf/renderer'

import { useReport } from '../../context'
import DetailsComponent from './components/DetailsComponent'
import PrintReportAltas from './components/PrintReportAltas'
import { ReportAltasProps } from './props'
import * as Styled from './styles'

const ReportAltas: FC<ReportAltasProps> = ({ data }) => {
  const { type, date_gt, date_lt, company_type_eq } = data
  const { getReportAltas, report, requestAltasStatus } = useReport()

  const dategt = customDateReport({ date: data?.date_gt })

  const datelt = customDateReport({ date: data?.date_lt })

  useEffect(() => {
    getReportAltas({ type, date_gt, date_lt, company_type_eq })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_gt, date_lt, type, company_type_eq])

  const component = useMemo(() => {
    if (requestAltasStatus.success) return <DetailsComponent data={report} />
    if (requestAltasStatus.error) return <RequestError />
    return <Loading />
  }, [report, requestAltasStatus])

  const buttonPrint = useMemo(() => {
    if (requestAltasStatus.success) {
      return (
        <PDFDownloadLink
          document={<PrintReportAltas data={{ ...report, ...data }} />}
          fileName={`relatorio-altas-${dategt}-${datelt}.pdf`}
        >
          {({ loading }) => (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              disableElevation
            >
              {loading ? 'Carregando relatório' : 'Baixar relatório'}
            </Button>
          )}
        </PDFDownloadLink>
      )
    }
    return <></>
  }, [report, requestAltasStatus, data, dategt, datelt])

  return (
    <>
      <Styled.Container>
        <PageTitle title="Relatório de Altas" />

        {buttonPrint}
      </Styled.Container>

      {component}
    </>
  )
}

export default memo(ReportAltas)
