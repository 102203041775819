import React, { FC, memo, useMemo } from 'react'

import Typography from 'components/Typography'
import version from 'utils/getVersion'

import * as Styled from './styles'

const LINK_SUPORT_FORM =
  'https://form.asana.com/?hash=611198de4ee147b28c4b36de8928d7bd9064f09b34429726c4fc431c78dab63c&id=1190765337663418'

const Footer: FC = () => {
  const actualYear = useMemo(() => new Date().getFullYear(), [])

  return (
    <Styled.Container>
      <Typography variant="caption" align="center">
        Teve algum problema?{' '}
        <Styled.LinkText href={LINK_SUPORT_FORM} target="_blank">
          Conte para nós{' '}
        </Styled.LinkText>
      </Typography>

      <Typography variant="caption" align="center">
        © {actualYear} Brisanet - CRM Versão {version}
      </Typography>
    </Styled.Container>
  )
}

export default memo(Footer)
