import { optionSelectType } from 'components/FormCompany/components/SelectDocument/props'

export const OPTIONS_STATUS_REPORT: optionSelectType[] = [
  {
    name: 'PAGO',
    value: 'paid',
  },
  {
    name: 'NÃO PAGO',
    value: 'unpaid',
  },
]
