export const OPTIONS_TYPE_FILE = [
  {
    name: 'Recarga e pacote',
    value: 'chips',
  },
  {
    name: 'Altas',
    value: 'discharges',
  },
  {
    name: 'Base',
    value: 'optin',
  },
]
