import { ActionMapData } from 'hooks/props'

export type RequestStatus = {
  loading: boolean
  error: boolean
}

export enum NewStockActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type NewStockState = {
  requestStatus: RequestStatus
}

export type StockPayload = {
  [NewStockActions.Request]: undefined
  [NewStockActions.RequestSuccess]: undefined
  [NewStockActions.RequestError]: undefined
  [NewStockActions.Reset]: undefined
}

export type StockReducerActionMap = ActionMapData<StockPayload>

export type CreateStockData = {
  name: string
  stockist: {
    name: string
    email: string
    phone: string
  }
  city_id: string
  state_id?: string
}

export type NewStockContextData = {
  requestStatus: RequestStatus

  /**
   * Recebe os dados da API
   */
  createStock(stock: CreateStockData): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}
