import React, { FC } from 'react'

import Card from 'components/Card'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import FormGenerateCommission from './components/FormGenerateCommission'
import GenerateCommissionProvider from './context'
import * as Styled from './styles'

const GenerateCommission: FC = () => {
  return (
    <Route title="Gerar comissão" access="auth" requireRoles={['admin', 'dev']}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Comissão', link: '#' },
        ]}
        activeOption="generate-commission"
      >
        <PageTitle title="Gerar comissão" />
        <Card>
          <Styled.Title>Informe o perído</Styled.Title>
          <FormGenerateCommission />
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(GenerateCommission, GenerateCommissionProvider)
