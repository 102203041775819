type RoutesParams =
  | 'saleId'
  | 'companyId'
  | 'stockId'
  | 'userId'
  | 'transferId'
  | 'distributorId'
  | 'date'

class RoutingService {
  static LOGIN = `/`

  static RECOVER_PASSWORD = '/recuperar-senha'

  static FIRST_ACCESS = '/primeiro-acesso'

  static SET_NEW_PASSWORD = '/nova-senha'

  static PRODUCTS = `/app`

  static UNAUTHORIZED_ACCESS = `${RoutingService.PRODUCTS}/nao-autorizado`

  static EDIT_PROFILE = `${RoutingService.PRODUCTS}/perfil`

  static BRISAMOVEL = `${RoutingService.PRODUCTS}/brisamovel`

  static SALES = `${RoutingService.BRISAMOVEL}/vendas`

  static SALE_DETAILS = `${RoutingService.BRISAMOVEL}/vendas/:saleId`

  static NEW_SALE = `${RoutingService.BRISAMOVEL}/vendas/novo`

  static COMPANIES = `${RoutingService.BRISAMOVEL}/revendedores`

  static CASH_FLOW = `${RoutingService.BRISAMOVEL}/fluxo-de-caixa`

  static WALLET = `${RoutingService.BRISAMOVEL}/carteira`

  static NEW_SALE_CASHFLOW = `${RoutingService.BRISAMOVEL}/nova-venda`

  static COMISSIONS_CASHFLOW = `${RoutingService.BRISAMOVEL}/comissoes`

  static LEAVES_CASHFLOW = `${RoutingService.BRISAMOVEL}/saidas`

  static REPORTS_CASHFLOW = `${RoutingService.BRISAMOVEL}/relatorios`

  static USERS_CASHFLOW = `${RoutingService.BRISAMOVEL}/controle-de-usuarios`

  static NEW_USER_CASHFLOW = `${RoutingService.BRISAMOVEL}/controle-de-usuarios/novo`

  static REPORTS_VALIDATION_CASHFLOW = `${RoutingService.BRISAMOVEL}/relatorios/validacoes`

  static REPORTS_CHIPLEAVES_CASHFLOW = `${RoutingService.BRISAMOVEL}/relatorios/saidas-de-chip`

  static REPORTS_CHANGES_CASHFLOW = `${RoutingService.BRISAMOVEL}/relatorios/changes_chip`

  static REPORTS_DETAILS_VALIDATION = `${RoutingService.BRISAMOVEL}/relatorios/details-validation`

  static EDIT_SELL = `${RoutingService.BRISAMOVEL}/relatorios/edit_sell`

  static COMPANY_DETAILS = `${RoutingService.BRISAMOVEL}/revendedores/:companyId`

  static COMPANY_UPDATE = `${RoutingService.BRISAMOVEL}/revendedores/:companyId/editar`

  static COMPANY_REPRESENTATIVE_EDIT = `${RoutingService.BRISAMOVEL}/representante/:userId/revendedor/:companyId/editar`

  static COMPANY_REPRESENTATIVE_REPORTS = `${RoutingService.BRISAMOVEL}/revendedores/relatorios`

  static REPORT_OF_COMMISSIONS = `${RoutingService.BRISAMOVEL}/revendedores/:companyId/relatorio-de-comissionamento`

  static BUILD_REPORT = `${RoutingService.BRISAMOVEL}/revendedores/:companyId/relatorios`

  static COMMISSIONS = `${RoutingService.BRISAMOVEL}/revendedores/:companyId/comissoes`

  static BONUS = `${RoutingService.BRISAMOVEL}/revendedores/:companyId/bonificacao`

  static COMMISSIONS_DETAILS = `${RoutingService.BRISAMOVEL}/revendedores/:companyId/comissao/:date`

  static COMMISSIONS_COMPANY_REPRESENTATIVE = `${RoutingService.BRISAMOVEL}/comissoes`

  static COMMISSIONS_COMPANY_REPRESENTATIVE_DETAILS = `${RoutingService.BRISAMOVEL}/comissao/:date`

  static GENERATE_COMMISSION = `${RoutingService.BRISAMOVEL}/gerar-comissao`

  static CONSOLIDATED_REPORTS = `${RoutingService.BRISAMOVEL}/relatorios/consolidados/revendedores`

  static NEW_COMPANY = `${RoutingService.BRISAMOVEL}/revendedores/novo`

  static USERS = `${RoutingService.BRISAMOVEL}/usuarios`

  static USER_DETAILS = `${RoutingService.BRISAMOVEL}/usuarios/:userId`

  static EDIT_USER = `${RoutingService.BRISAMOVEL}/usuarios/:userId/editar`

  static NEW_USER = `${RoutingService.BRISAMOVEL}/usuarios/novo`

  static STOCKS = `${RoutingService.BRISAMOVEL}/estoque`

  static STOCK_DETAILS = `${RoutingService.BRISAMOVEL}/estoque/:stockId`

  static EDIT_STOCK = `${RoutingService.BRISAMOVEL}/estoque/:stockId/editar`

  static NEW_STOCK = `${RoutingService.BRISAMOVEL}/estoque/novo`

  static MY_STOCK = `${RoutingService.BRISAMOVEL}/meu-estoque`

  static TRANSFERS = `${RoutingService.BRISAMOVEL}/transferencias`

  static TRANSFER_DETAILS = `${RoutingService.BRISAMOVEL}/transferencias/:transferId`

  static NEW_TRANSFER = `${RoutingService.BRISAMOVEL}/transferencias/novo`

  static DISTRIBUTORS = `${RoutingService.BRISAMOVEL}/distribuidores`

  static DISTRIBUTOR_DETAILS = `${RoutingService.BRISAMOVEL}/distribuidores/:distributorId`

  static NEW_DISTRIBUTOR = `${RoutingService.BRISAMOVEL}/distribuidores/novo`

  static EDIT_DISTRIBUTOR = `${RoutingService.BRISAMOVEL}/distribuidores/:distributorId/editar`

  static APP_RECHARGES = `${RoutingService.BRISAMOVEL}/recargas`

  static APP_RECHARGES_DATE_PERIOD_REPORT = `${RoutingService.BRISAMOVEL}/recargas/periodo`

  static UPLOAD_FILE_VIVO = `${RoutingService.BRISAMOVEL}/upload`

  /**
   * Constrói a URL substituindo parâmetros
   *
   * @param route deve ser um dos valores estáticos da classe `RoutingService`
   * @param paramId nome do parâmetro, apenas palavras (não precisa do prefixo `:`)
   * @param value valor que substituirá esse parâmetro
   *
   * @returns string com a rota
   */
  static getRouteString(
    route: string,
    paramId: RoutesParams | RoutesParams[],
    value: string | string[],
  ) {
    if (Array.isArray(paramId)) {
      let endRoute = route
      paramId.forEach((param, index) => {
        endRoute = endRoute.replace(`:${param}`, value[index])
      })
      return endRoute
    }
    if (Array.isArray(value)) value.join('')
    return route.replace(`:${paramId}`, value as string)
  }
}

export default RoutingService
