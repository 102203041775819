import React, { FC, memo, useCallback } from 'react'

import { useField } from 'formik'

import MuiCheckbox from '@material-ui/core/Checkbox'

import { CheckboxSimpleProps } from './props'
import * as Styled from './styles'

const CheckboxSimple: FC<CheckboxSimpleProps> = props => {
  const { label, name } = props

  const [field, , helpers] = useField<boolean>(name)

  const handleChange = useCallback(() => {
    helpers.setValue(!field.value)
  }, [field.value, helpers])

  return (
    <Styled.FormControlLabel
      control={
        <MuiCheckbox
          icon={<Styled.FilledOutline />}
          disableRipple
          disableFocusRipple
          checkedIcon={
            <Styled.FilledOutline>
              <Styled.FilledOutlineChecked />
            </Styled.FilledOutline>
          }
          onChange={handleChange}
          checked={field.value}
        />
      }
      name={name}
      label={label}
      labelPlacement="end"
    />
  )
}

export default memo(CheckboxSimple)
