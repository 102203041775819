import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState,
} from 'react'

import {
  Commission,
  CommissionsStatusType,
  CommissionsStatusParam,
} from 'interfaces/commission'
import { api } from 'services/api'
import {
  paginationReducer,
  PaginationActions,
  PaginationRouteParams,
} from 'services/pagination'

import { CommissionContextData } from './props'
import { initialState } from './reducer'

const initialValue = {} as CommissionContextData
export const CommissionContext = createContext(initialValue)

export const CommissionProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    paginationReducer<Commission>(),
    initialState,
  )

  const [status, setStatus] = useState<CommissionsStatusType | undefined>()

  const [preview, setPreview] = useState(null)
  const [totalCommissions, setTotalCommissions] = useState<string>('')

  const getCommissions = useCallback(
    async (company_id: string) => {
      try {
        const params: PaginationRouteParams & CommissionsStatusParam = {
          page: state.current_page,
          per_page: state.per_page,
          status_eq: status,
          company_id_eq: company_id,
        }

        const response = await api.get<any>('/commissions', {
          params,
        })

        const TotalValueComissions = await api.get<any>(
          `/commissions/${company_id}/total`,
        )

        setTotalCommissions(TotalValueComissions.data.total)

        dispatch({
          type: PaginationActions.RequestSuccess,
          payload: response,
        })

        setPreview(response.data.preview)
      } catch (e) {
        dispatch({ type: PaginationActions.RequestError })
      }
    },
    [state, status],
  )

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <CommissionContext.Provider
      value={{
        ...state,
        preview,
        totalCommissions,
        getCommissions,
        nextPage,
        previousPage,
        setLimit,
        status,
        setStatus,
        setPage,
      }}
    >
      {children}
    </CommissionContext.Provider>
  )
}

export function useCommission() {
  const context = useContext(CommissionContext)
  if (!context || context === initialValue) {
    throw new Error('useCommission must be within as CommissionContext')
  }

  return context
}
