import {
  FC,
  useCallback,
  useState,
  createContext,
  useMemo,
  useContext,
} from 'react'

import { endOfDay, startOfDay, subDays } from 'date-fns'
import { movelApi } from 'services/api'

import {
  ChipsStatusResponse,
  ProductContextProps,
  RequestEventStatus,
} from './props'

const AppRechargesPeriodContext = createContext<
  ProductContextProps | undefined
>(undefined)

const AppRechargesPeriodProvider: FC = props => {
  const { children } = props

  const [data, setData] = useState<ChipsStatusResponse[]>([])
  const [status, setStatus] = useState<RequestEventStatus>('idle')

  const [startDate, setStartDate] = useState<Date | null>(() =>
    subDays(new Date(), 30),
  )
  const [endDate, setEndDate] = useState<Date | null>(() => new Date())

  const getData = useCallback(async () => {
    try {
      setStatus('loading')
      setData([])

      let date_end: string | undefined = undefined
      let date_start: string | undefined = undefined

      if (startDate) date_start = startOfDay(startDate).toISOString()
      if (endDate) date_end = endOfDay(endDate).toISOString()

      const params = {
        date_start,
        date_end,
      }

      const response = await movelApi.get<ChipsStatusResponse[]>(
        '/crm/recharge-report/period',
        {
          params,
          headers: { secret: process.env.REACT_APP_BRISAMOVEL_ROUTES_SECRET },
        },
      )
      setData(response.data)
      setStatus('ok')
    } catch (error) {
      setData([])
      setStatus('error')
    }
  }, [endDate, startDate])

  const value = useMemo(
    () => ({
      data,
      getData,
      setStartDate,
      setEndDate,
      startDate,
      endDate,
      status,
    }),
    [data, endDate, getData, startDate, status],
  )

  return (
    <AppRechargesPeriodContext.Provider value={value}>
      {children}
    </AppRechargesPeriodContext.Provider>
  )
}

export function useAppRechargesPeriod() {
  const context = useContext(AppRechargesPeriodContext)

  if (!context) {
    throw new Error(
      'useAppRechargesPeriod must be used within a AppRechargesPeriodProvider',
    )
  }
  return context
}

export default AppRechargesPeriodProvider
