import styled from 'styled-components'

import { Select } from '@material-ui/core'

export const SelectType = styled(Select)`
  width: 350px;
  height: 42px;
  margin-top: 8px;
  margin-bottom: 15px;
  margin-right: 10px;
`
