interface FormatPriceProps {
  price: string | number
  showCurrencyPreffix?: boolean
}

function customFormatPrice(props: FormatPriceProps): string {
  const { price, showCurrencyPreffix: showCurrency = true } = props

  const priceValue = Number(price)

  const currencyText = priceValue.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })

  if (showCurrency) return currencyText

  return currencyText.replace(/R\$/gim, '')
}

export default customFormatPrice
