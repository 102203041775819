import { css } from 'styled-components'

export const smallShadow = css`
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
`

export const mediumShadow = css`
  box-shadow: 1px 2px 24px rgba(18, 18, 18, 0.08) !important;
`

export const largeShadow = css`
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`
