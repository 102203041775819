import React, { FC, useCallback } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { SalesCashFlowProvider, useSaleCashFlow } from 'hooks/salesCashFlow'
import { SaleCashFlow } from 'interfaces/SalesCashFlow'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import CashFlowListHeader from './components/CashFlowListHeader'
import CashFlowListItem from './components/CashFlowListItem'
import HeaderTableSalesCashFlow from './components/HeaderTableSalesCashFlow'
const CashFlow: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
    getSalesCashFlow,
  } = useSaleCashFlow()

  const renderItem = useCallback<TableRenderItem<SaleCashFlow>>(
    item => <CashFlowListItem key={item.id} {...item} />,
    [],
  )

  return (
    <Route
      title="Fluxo de Caixa"
      access="auth"
      requireRoles={[
        'admin',
        'attendant',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'office_stockist',
        'sale_leader',
        'pap_leader',
        'pap_seller',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Vendas',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-sales"
      >
        <PageTitle title={'Listagem de vendas de chip brisamóvel'} />
        <HeaderTableSalesCashFlow />
        <Table
          data={data}
          renderItem={renderItem}
          loadingRequest={requestStatus.loading}
          HeaderComponent={<CashFlowListHeader />}
          FooterComponent={
            <TablePagination
              getData={getSalesCashFlow}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(CashFlow, SalesCashFlowProvider)
