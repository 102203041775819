import React, { FC, memo, useState } from 'react'

import Formik from 'components/Formik'
import Select from 'components/Inputs/Select'

import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import { useValidateCommission } from './context'
import { CommissionIdProp, CommissionIValidate } from './context/props'
import { OPTIONS_STATUS_REPORT } from './options'
import schema from './schema'
import * as Styled from './styles'

const ValidateCommission: FC<CommissionIdProp> = ({ commissionId }) => {
  const {
    modalActive,
    closeModal,
    validateCommission,
  } = useValidateCommission()

  const [status, setStatus] = useState('')

  const initialValues: CommissionIValidate = {
    status,
    commissionId,
  }

  return (
    <Dialog
      open={modalActive}
      onClose={closeModal}
      aria-labelledby="form-dialog-validate-commission"
      data-cy="modal_validate_commission__div"
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Styled.Title variant="h6" color="textSecondary">
              Atualizar status de pagamento
            </Styled.Title>
            <Formik
              onSubmit={validateCommission}
              initialValues={initialValues}
              validationSchema={schema}
            >
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <Select
                    name="status"
                    label="Status"
                    value={status}
                    setValue={setStatus}
                    options={OPTIONS_STATUS_REPORT}
                  />
                </Grid>
              </Grid>
              <Styled.FooterModal item xs={12}>
                <Styled.ButtonSubmit
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Atualizar status
                </Styled.ButtonSubmit>
              </Styled.FooterModal>
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}

export default memo(ValidateCommission)
