import React, { FC, memo, useEffect, useMemo } from 'react'

import Button from 'components/Button'
import RequestError from 'components/DetailsPages/RequestError'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import customDateReport from 'utils/formateDateReport'

import { PDFDownloadLink } from '@react-pdf/renderer'

import { useReport } from '../../context'
import DetailsComponent from './components/DetailsComponent'
import PrintReportOfRecharges from './components/PrintReportOfRecharges'
import { ReportOfRecargesProps } from './props'
import * as Styled from './styles'

const ReportOfRecharges: FC<ReportOfRecargesProps> = ({ data }) => {
  const { type, date_gt, date_lt, company_type_eq } = data
  const {
    getReportOfRecharges,
    reportOfRecharges,
    requestOfRechargesStatus,
  } = useReport()

  const dategt = customDateReport({ date: data?.date_gt })

  const datelt = customDateReport({ date: data?.date_lt })

  useEffect(() => {
    getReportOfRecharges({ type, date_gt, date_lt, company_type_eq })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_gt, date_lt, type, company_type_eq])

  const component = useMemo(() => {
    if (requestOfRechargesStatus.success)
      return <DetailsComponent data={reportOfRecharges} />
    if (requestOfRechargesStatus.error) return <RequestError />
    return <Loading />
  }, [reportOfRecharges, requestOfRechargesStatus])

  const buttonPrint = useMemo(() => {
    if (requestOfRechargesStatus.success) {
      return (
        <PDFDownloadLink
          document={
            <PrintReportOfRecharges data={{ ...reportOfRecharges, ...data }} />
          }
          fileName={`relatorio-recargas-${dategt}-${datelt}.pdf`}
        >
          {({ loading }) => (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              disableElevation
            >
              {loading ? 'Carregando relatório' : 'Baixar relatório'}
            </Button>
          )}
        </PDFDownloadLink>
      )
    }
    return <></>
  }, [reportOfRecharges, requestOfRechargesStatus, data, dategt, datelt])

  return (
    <>
      <Styled.Container>
        <PageTitle title="Relatório de Recargas" />
        {buttonPrint}
      </Styled.Container>
      {component}
    </>
  )
}

export default memo(ReportOfRecharges)
