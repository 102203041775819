import ReactInputMask from 'react-input-mask'

import styled from 'styled-components'

import {
  Typography,
  Container as MUContainer,
  FormControl,
} from '@material-ui/core'
import { TextField, Button } from '@material-ui/core'
export const Container = styled(MUContainer)``

export const Option = styled.div`
  margin-left: 20px;
`

export const HeaderTitle = styled(Typography)``

export const TextInput = styled(TextField)`
  width: 256px;
  min-width: 100px;
`

export const Form = styled(FormControl)`
  width: 356px;
  & > label {
    margin-left: 15px;
  }
`
export const ContainerInputsOut = styled.div`
  width: 1100px;
  display: flex;
  justify-content: start;
  gap: 10px;
  font-size: 12px;
  @media (max-width: 1380px) {
    font-size: 10px;
    width: 1000px;
    gap: 2px;
  }
  @media (max-width: 1280px) {
    width: 900px;
    gap: 2px;
  }
  @media (max-width: 1180px) {
    font-size: 9px;
    width: 780px;
    gap: 1px;
  }
`

export const SendButton = styled(Button)`
  width: 111px;
  margin-left: 8px;
  height: 55px;
`
export const Input = styled(ReactInputMask)`
  height: 54px;
  border-radius: 5px;
  border-style: none;
  border: 1px solid #999;
  font-size: 14px;
  color: #333;
  padding: 12px;
  margin-right: 3px;
  margin-left: 3px;

  margin-bottom: 10px;
  width: 130px;
  @media (max-width: 1380px) {
    width: 115px;
  }
`
