import React, { FC, useCallback, memo } from 'react'

import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import { Transfer } from 'hooks/transfers/props'

import TransferListHeader from './components/TransferListHeader'
import TransferListItem from './components/TransferListItem'
import { TablesTransfersProps } from './props'
import * as Styled from './styles'

const TablesTransfers: FC<TablesTransfersProps> = props => {
  const { dataTransfersReceived, dataTransfersSent, loading, page } = props

  const renderItem = useCallback<TableRenderItem<Transfer>>(
    item => <TransferListItem key={item.id} page={page} {...item} />,
    [page],
  )

  return (
    <Styled.Container>
      <Table
        data={dataTransfersSent}
        loadingRequest={loading}
        emptyMessage="Nenhuma transferência encontrada"
        renderItem={renderItem}
        HeaderComponent={
          <TransferListHeader title="Transferências realizadas" type="sent" />
        }
      />
      <Table
        data={dataTransfersReceived}
        loadingRequest={loading}
        emptyMessage="Nenhuma transferência encontrada"
        renderItem={renderItem}
        HeaderComponent={
          <TransferListHeader
            title="Transferências recebidas"
            type="received"
          />
        }
      />
    </Styled.Container>
  )
}

export default memo(TablesTransfers)
