import { OfficeData, SellersData } from 'hooks/salesCashFlow/props'
import { api } from 'services/api'

type Offices = () => Promise<OfficeData[]>

type Sellers = (office_id: string) => Promise<SellersData[]>

export const GetOffices: Offices = async () => {
  const { data } = await api.get<OfficeData[]>('/offices')

  return data
}

export const GetSellers: Sellers = async office_id => {
  const { data } = await api.get<SellersData[]>(
    `/offices/list-sellers/${office_id}`,
  )

  return data
}
