import React, { FC, memo } from 'react'

import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import StepperItem from '../StepperItem'
import * as Styled from './styles'

const Stepper: FC = () => {
  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Hidden smDown>
        <Styled.Main container justify="space-between">
          <StepperItem
            item
            text="Informações Principais"
            step={0}
            data-cy="step1__button"
          />
          <StepperItem item separator step={0} />
          <StepperItem
            item
            text="Contato e Endereço"
            step={1}
            data-cy="step2__button"
          />
          <StepperItem item separator step={1} />
          <StepperItem item text="Finalizar" step={2} data-cy="step3__button" />
        </Styled.Main>
      </Hidden>
    </Grid>
  )
}

export default memo(Stepper)
