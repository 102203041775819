export const OPTIONS_SELECT_PERIOD = [
  {
    name: 'Anual',
    value: 'yearly',
  },
  {
    name: 'Mensal',
    value: 'monthly',
  },
]

export const OPTIONS_MENU_DEALER = [
  {
    name: 'Todos',
    value: 'all',
  },
  {
    name: 'Parceiro',
    value: 'partner',
  },
  {
    name: 'Franquia',
    value: 'franchisee',
  },
]
