import React, {
  FC,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'
import Document from 'utils/document'

import { EditUserCredentials, EditUserContextData } from './props'

const initialValue = {} as EditUserContextData
const EditUserContext = createContext(initialValue)

export const EditUserProvider: FC = props => {
  const { children } = props

  const [status, setStatus] = useState<RequestStatus>({
    loading: false,
    error: false,
    success: false,
  })

  const { addToast } = useToast()
  const history = useHistory()

  const editUser = useCallback(
    async (id: string, credentials: EditUserCredentials) => {
      try {
        if (!id) {
          throw new Error('ID cannot be empty')
        }

        setStatus({
          error: false,
          loading: true,
          success: false,
        })

        credentials.phone = Document.removeMask(credentials.phone)

        await api.put(`/users/${id}`, credentials)

        setStatus({
          error: false,
          loading: false,
          success: true,
        })
        addToast({
          type: 'success',
          title: 'Usuário atualizado com sucesso',
        })
        history.push(
          RoutingService.getRouteString(
            RoutingService.USER_DETAILS,
            'userId',
            id,
          ),
        )
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        setStatus({
          error: true,
          loading: false,
          success: false,
        })
      }
    },
    [addToast, history],
  )

  return (
    <EditUserContext.Provider value={{ status, editUser }}>
      {children}
    </EditUserContext.Provider>
  )
}

export const useEditUser = () => {
  const context = useContext(EditUserContext)
  if (!context || context === initialValue) {
    throw new Error('useEditUser must be used within a EditUserProvider')
  }
  return context
}
