import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'

export type NewDistributor = {
  requestStatus: RequestStatus
  distributor: DataFormAddDistributorType
}

export enum NewDistributorActions {
  RequestNewDistributor = 'REQUEST_NEW_DISTRIBUTOR',
  RequestSuccessNewDistributor = 'REQUEST_SUCCESS_NEW_DISTRIBUTOR',
  RequestErrorNewDistributor = 'REQUEST_ERROR_NEW_DISTRIBUTOR',
  Reset = 'RESET',
}

export type NewDistributorPayload = {
  [NewDistributorActions.RequestNewDistributor]: undefined
  [NewDistributorActions.RequestSuccessNewDistributor]: AxiosResponse<any>
  [NewDistributorActions.RequestErrorNewDistributor]: undefined

  [NewDistributorActions.Reset]: undefined
}

export interface DataFormAddDistributorType {
  name: string
  email: string
  phone: string
  company_who_can_sell: string[] | string
}

export type NewDistributorContextProps = {
  requestStatus: RequestStatus

  /**
   * Adiciona Distribuidor na Base
   */
  addDistributor(data: any): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}

export type NewDistributorReducerActionMap = ActionMapData<NewDistributorPayload>
