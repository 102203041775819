import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'
import { CompanyWhoCanSellType } from 'interfaces/distributor'

export type EditDistributor = {
  requestStatus: RequestStatus
  distributor: DataFormEditDistributor
}

export enum EditDistributorActions {
  RequestEditDistributor = 'REQUEST_NEW_DISTRIBUTOR',
  RequestSuccessEditDistributor = 'REQUEST_SUCCESS_NEW_DISTRIBUTOR',
  RequestErrorEditDistributor = 'REQUEST_ERROR_NEW_DISTRIBUTOR',
  Reset = 'RESET',
}

export type EditDistributorPayload = {
  [EditDistributorActions.RequestEditDistributor]: undefined
  [EditDistributorActions.RequestSuccessEditDistributor]: AxiosResponse<any>
  [EditDistributorActions.RequestErrorEditDistributor]: undefined

  [EditDistributorActions.Reset]: undefined
}

export type FormatPatternCompanyWhoCanSell = (
  values: string[],
) => CompanyWhoCanSellType

export interface DataFormEditDistributor {
  company_who_can_sell: CompanyWhoCanSellType[]
}

export type EditDistributorContextProps = {
  requestStatus: RequestStatus

  /**
   * Adiciona Distribuidor na Base
   */
  editDistributor(id: string, data: DataFormEditDistributor): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}

export type EditDistributorReducerActionMap = ActionMapData<EditDistributorPayload>
