import ButtonStyles from 'components/ButtonSubmit'
import styled from 'styled-components'

import { DatePicker as MuiDatePicker } from '@material-ui/pickers'

export const ButtonBuildReport = styled(ButtonStyles)`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  :hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  text-transform: none;
`

export const DatePicker = styled(MuiDatePicker)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;

  input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
