import React, { FC, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import Card from 'components/Card'
import FormAddCompany from 'components/FormCompany'
import {
  FormCompanyProvider,
  useFormCompany,
} from 'components/FormCompany/context'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import { useCompany } from './context'

const UpdateCompany: FC = () => {
  const { companyId } = useParams<any>()
  const { getCompany, company, status } = useCompany()

  const {
    setActualStepForm,
    setDataFormCompany,
    setCityAndState,
    setTypeForm,
  } = useFormCompany()

  useEffectOnce(() => {
    getCompany(companyId)
    setTypeForm('update')
  })

  useEffect(() => {
    if (company) {
      setDataFormCompany({
        active: company.active,
        new_representative: false,
        document: company.document,
        commission_model: company.commission_model,
        email: company.email,
        fantasy_name: company.fantasy_name,
        latitude: company.address.latitude,
        longitude: company.address.longitude,
        phone: company.phone,
        state_id: company.address.city.state.id,
        city_id: company.address.city.id,
        representative_id: company.representative_id,
        street: company.address.street || '',
        district: company.address.district || '',
        number: company.address.number || 0,
        representative: {
          email: company.representative.user.email,
          name: company.representative.user.name,
          phone: company.representative.user.phone,
        },
        type: company.type,
        corporate_name: company.corporate_name
          ? company.corporate_name
          : undefined,
      })

      setCityAndState({
        city: company.address.city.name,
        state: company.address.city.state.name,
      })
    }
  }, [company, setActualStepForm, setCityAndState, setDataFormCompany])

  const renderTitle = useMemo(() => {
    if (status.loading) return 'Carregando ...'
    return `Atualizar revendedor ${company?.fantasy_name}`
  }, [company, status.loading])

  const renderForm = useMemo(() => {
    if (status.loading) return <></>
    return <FormAddCompany />
  }, [status.loading])

  return (
    <Route
      title="empresa"
      access="auth"
      requireRoles={['distributor', 'admin', 'dev', 'financial']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          { title: 'Atualizar Revendedor', link: '#' },
        ]}
        activeOption="companies"
      >
        <PageTitle title={renderTitle} />
        <Card shadow>{renderForm}</Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(UpdateCompany, FormCompanyProvider)
