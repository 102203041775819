import React, { FC, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import RoutingService from 'services/routing'

import TableCell from '@material-ui/core/TableCell'

import { TransfersListItemProps } from './props'
import * as Styled from './styles'

const TransfersListItem: FC<TransfersListItemProps> = props => {
  const { id, status, order_number, wallet_sender, wallet_recipient } = props

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.TRANSFER_DETAILS,
        'transferId',
        id,
      ),
    [id],
  )

  const statusText = useMemo(() => {
    switch (status) {
      case 'canceled':
        return 'Cancelado'
      case 'denied':
        return 'Negado'
      case 'finished':
        return 'Finalizado'

      default:
        return 'Pendente'
    }
  }, [status])

  const from = useMemo(() => {
    switch (wallet_sender.owner_type) {
      case 'company':
        return `Empresa: ${wallet_sender.company?.fantasy_name}`
      case 'distributor':
        return `Distribuidor: ${wallet_sender.distributor?.user.name}`
      case 'stock':
        return `Estoque: ${wallet_sender.stock?.name}`
      default:
        return '-'
    }
  }, [wallet_sender])

  const recipient = useMemo(() => {
    switch (wallet_recipient.owner_type) {
      case 'company':
        return `Empresa: ${wallet_recipient.company?.fantasy_name}`
      case 'distributor':
        return `Distribuidor: ${wallet_recipient.distributor?.user.name}`
      case 'stock':
        return `Estoque: ${wallet_recipient.stock?.name}`
      default:
        return '-'
    }
  }, [wallet_recipient])

  return (
    <TableRowItem>
      <TableCell>{order_number}</TableCell>
      <TableCell>{from}</TableCell>
      <TableCell>{recipient}</TableCell>
      <TableCell>{statusText}</TableCell>

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.Button color="primary" variant="outlined">
            Detalhes
          </Styled.Button>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(TransfersListItem)
