import React, { FC, memo, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import ButtonSubmit from 'components/ButtonSubmit'
import Card from 'components/Card'
import Formik from 'components/Formik'
import Typography from 'components/Typography'
import { useDistributorDetail } from 'hooks/distributors/details'
import {
  DataFormEditDistributor,
  useEditDistributor,
} from 'hooks/distributors/edit'

import Grid from '@material-ui/core/Grid'

import Checkbox from '../../../components/Checkbox'
import schema from './schema'
import * as Styled from './styles'

const EditDistributorForm: FC = () => {
  const detail = useDistributorDetail()
  const { editDistributor, requestStatus } = useEditDistributor()
  const { distributorId } = useParams<any>()

  const distributor = useMemo(() => detail.distributor!, [detail.distributor])

  const handleSubmit = useCallback(
    (values: DataFormEditDistributor) => editDistributor(distributorId, values),
    [distributorId, editDistributor],
  )

  const initialValues = useMemo<DataFormEditDistributor>(
    () => ({
      company_who_can_sell: [distributor.company_who_can_sell],
    }),
    [distributor.company_who_can_sell],
  )

  return (
    <Card>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        <Styled.Grid container spacing={2}>
          <Grid lg={6} md={6} sm={12} xs={12} item>
            <Typography color="primary" variant="h6" capitalize="uppercase">
              Detalhes do distribuidor
            </Typography>
          </Grid>
        </Styled.Grid>

        <Styled.Grid container spacing={2}>
          <Grid lg={6} md={6} sm={12} xs={12} item>
            <Typography variant="subtitle1" color="primary">
              Pode vender para revendedores:
            </Typography>

            <Styled.CheckboxHorizontalList>
              <Checkbox name="company_who_can_sell" value="franchisee">
                franqueadas
              </Checkbox>
              <Checkbox name="company_who_can_sell" value="partner">
                parceiras
              </Checkbox>
            </Styled.CheckboxHorizontalList>
          </Grid>
        </Styled.Grid>

        <Styled.ButtonsContainer>
          <ButtonSubmit
            variant="outlined"
            color="primary"
            disabled={requestStatus.loading}
          >
            Salvar
          </ButtonSubmit>
        </Styled.ButtonsContainer>
      </Formik>
    </Card>
  )
}

export default memo(EditDistributorForm)
