import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Textfit } from 'react-textfit'

import Button from 'components/Button'
import Card from 'components/Card'
import LayoutProduct from 'components/LayoutProduct'
import RenderWithRoles from 'components/RenderWithRoles'
import Route from 'components/Route'
import Typography from 'components/Typography'
import format from 'date-fns/format'
import { SalesCashFlowProvider } from 'hooks/salesCashFlow'
import RoutingService from 'services/routing'
import {
  TranslateCategorySell,
  TranslateStatusChip,
  TranslateStatusSell,
} from 'utils/Translate'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import { ValidationProps } from './props'
import * as Styled from './styles'

const ValidationDetails: FC = () => {
  const [sellDetails, setSellDetails] = useState<ValidationProps>()
  useEffect(() => {
    const detalhes = sessionStorage.getItem('detalhes')
    if (detalhes) {
      const json = JSON.parse(detalhes)
      setSellDetails(json)
    }
  }, [])

  return (
    <Route
      title="Usuário"
      access="auth"
      requireRoles={[
        'admin',
        'attendant',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'office_stockist',
        'sale_leader',
        'pap_leader',
        'pap_seller',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Detalhes',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-reports"
      >
        <Card>
          <Styled.Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Typography color="initial" variant="subtitle1">
                Nome do comprador
              </Typography>

              <Typography color="primary" variant="h6" capitalize="uppercase">
                {sellDetails?.customer_name}
              </Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={12} xs={12}>
              <Typography color="initial" variant="subtitle1">
                Pacote escolhido na venda
              </Typography>

              <Typography color="primary" variant="h6">
                <Textfit>{sellDetails?.pack}</Textfit>
              </Typography>
            </Grid>

            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Typography color="initial" variant="subtitle1">
                Telefone
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {sellDetails?.phone_number}
              </Typography>
            </Grid>

            <Grid item lg={3} md={2} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Status da venda
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {TranslateStatusSell(sellDetails?.status)}
              </Typography>
            </Grid>
          </Styled.Grid>

          <Styled.Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Valor da venda
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {`R$ ${sellDetails?.price}`}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Valor da Recarga
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {`R$ ${sellDetails?.recharge_value}`}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Status do Chip
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {TranslateStatusChip(sellDetails?.chip.status)}
              </Typography>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Categoria da venda
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {TranslateCategorySell(sellDetails?.category)}
              </Typography>
            </Grid>
          </Styled.Grid>

          <Styled.Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                ICCID
              </Typography>
              <Typography
                color="primary"
                variant="subtitle1"
                capitalize="uppercase"
              >
                {sellDetails?.chip.iccid}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Data de criação da venda
              </Typography>
              {sellDetails?.created_at && (
                <Typography color="primary" variant="h6" capitalize="uppercase">
                  {format(new Date(sellDetails!.created_at), 'yyyy-MM-dd')}
                </Typography>
              )}
            </Grid>
          </Styled.Grid>

          <RenderWithRoles
            roles={[
              'dev',
              'admin',
              'attendant',
              'commercial_coordinator',
              'general_coordinator',
              'office_leader',
              'office_stockist',
              'sale_leader',
              'pap_leader',
              'pap_seller',
              'supervisor',
              'general_manager',
            ]}
          >
            <Styled.ButtonContainer>
              {sellDetails?.status !== 'canceled' && (
                <Link to={`${RoutingService.EDIT_SELL}`}>
                  <Button variant="outlined" color="primary" size="large">
                    Editar
                  </Button>
                </Link>
              )}
            </Styled.ButtonContainer>
          </RenderWithRoles>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(ValidationDetails, SalesCashFlowProvider)
