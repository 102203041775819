import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 265px;
  height: 121px;
  margin-right: 15px;
  padding: 22px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  transition: ease 0.8s;

  :hover {
    border: 1px solid ${({ theme }) => theme.palette.services.brisamovel};

    span {
      transition: ease 0.8s;
      color: ${({ theme }) => theme.palette.services.brisamovel};
    }

    h1 {
      transition: ease 0.8s;
      color: ${({ theme }) => theme.palette.services.brisamovel};
    }
  }
`

export const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(140, 200, 60, 0.2);
`

export const ContentText = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)}px;

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.grey[600]};
  }

  h1 {
    font-size: 20px;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 700;
  }
`
