import React, { FC, memo } from 'react'

import CheckboxSimple from 'components/Inputs/CheckboxSimple'

import { useFormCompany } from '../../context'
import * as Styled from './styles'

const CheckboxStatus: FC = () => {
  const { typeForm } = useFormCompany()

  if (typeForm !== 'update') return null

  return (
    <>
      <Styled.TitlePanel
        variant="subtitle1"
        color="primary"
        capitalize="uppercase"
      >
        status do revendedor
      </Styled.TitlePanel>
      <CheckboxSimple name="active" label="Ativo" />
    </>
  )
}

export default memo(CheckboxStatus)
