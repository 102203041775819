import * as Yup from 'yup'

const schema = Yup.object().shape({
  first_iccid: Yup.string()
    .required('Iccid inicial é obrigatório')
    .min(20)
    .max(20),
  last_iccid: Yup.string()
    .required('Iccid final é obrigatório')
    .min(20)
    .max(20),
})

export default schema
