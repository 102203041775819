import styled from 'styled-components'

import MUFormControl from '@material-ui/core/FormControl'
import { DatePicker as DatePickerMU } from '@material-ui/pickers'

export const DatePicker = styled(DatePickerMU)`
  border-radius: 6px;
  cursor: pointer !important;
  .MuiOutlinedInput-root {
    height: ${({ theme }) => theme.spacing(6)}px;
    border-radius: 6px;
  }
`

export const FormControl = styled(MUFormControl)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  .MuiOutlinedInput-notchedOutline {
    border-width: 1.5px !important;
  }
`
