/* eslint-disable react/destructuring-assignment */
import React, { FC, useState, createContext, useMemo, useContext } from 'react'

type ModalContextProps = {
  modalActive: boolean
  openModal(): void
  closeModal(): void
}

const ModalContext = createContext({} as ModalContextProps)

const ModalProvider: FC = props => {
  const [modalActive, setModalAtive] = useState(false)

  const value = useMemo(
    () => ({
      modalActive,
      openModal: () => setModalAtive(true),
      closeModal: () => setModalAtive(false),
    }),
    [modalActive],
  )

  return (
    <ModalContext.Provider value={value}>
      {props.children}
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)

export default ModalProvider
