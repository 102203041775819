import React, { FC, memo, useMemo } from 'react'
import { useEffectOnce } from 'react-use'

import CheckboxGroup from 'components/Inputs/CheckboxGroup'
import Typography from 'components/Typography'
import { useFormikContext } from 'formik'
import { EditUserCredentials } from 'hooks/users/edit/props'

import * as Styled from './styles'
import { useProducts } from './useProducts'

const CheckboxesProducts: FC = () => {
  const { getProducts, products } = useProducts()

  const { errors } = useFormikContext<EditUserCredentials>()

  useEffectOnce(() => {
    getProducts()
  })

  const renderOtions = useMemo(() => {
    if (!products) return null

    return (
      <CheckboxGroup
        data={products}
        name="products_ids"
        checkboxKeys={{
          key: 'id',
          checked: 'id',
          value: 'id',
          label: 'name',
        }}
      />
    )
  }, [products])

  const renderError = useMemo(() => {
    if (!errors.products_ids) return null

    return (
      <Typography variant="caption" color="error">
        {errors.products_ids}
      </Typography>
    )
  }, [errors.products_ids])

  return (
    <>
      <Styled.ContainerOptions>{renderOtions}</Styled.ContainerOptions>

      {renderError}
    </>
  )
}

export default memo(CheckboxesProducts)
