import styled from 'styled-components'

import {
  Typography,
  Container as MUContainer,
  FormControl,
} from '@material-ui/core'
import { TextField, Button } from '@material-ui/core'
import PureDrawer from '@material-ui/core/Drawer'

export const Container = styled(MUContainer)``

export const HeaderTitle = styled(Typography)``

export const Drawer = styled(PureDrawer)`
  .MuiDrawer-paper {
    width: 202px;
    max-height: 100vh;
    overflow-y: auto;
  }
`

export const TextInput = styled(TextField)`
  width: 356px;
`

export const Form = styled(FormControl)`
  width: 356px;
  & > label {
    margin-left: 15px;
  }
`

export const ContainerInputs = styled.div`
  width: 900px;
  display: flex;
  margin: 30px 0;
  justify-content: space-between;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)}px 0;

  & ${HeaderTitle} {
    display: inline-block;
    flex: 1;
  }
`

export const SendButton = styled(Button)`
  width: 151px;
`

export const TitleSection = styled.div`
  color: #083d93;
  font-weight: bold;
  font-size: 24px;
`
export const ExportButton = styled(Button)`
  width: 188px;
  height: 42px;
`
