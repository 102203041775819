import styled from 'styled-components'

import MUFormControl from '@material-ui/core/FormControl'
import MUTextField from '@material-ui/core/TextField'

export const TextField = styled(MUTextField)`
  border-radius: 6px;
  .MuiOutlinedInput-root {
    border-radius: 6px;
  }
`
export const FormControl = styled(MUFormControl)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  .MuiOutlinedInput-notchedOutline {
    border-width: 1.5px !important;
  }
`
