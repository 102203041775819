import React, { FC, memo, useMemo } from 'react'

import Typography from 'components/Typography'
import { Plan } from 'pages/ReportPages/BuildReport/context/props'
import Table from 'pages/ReportPages/components/Table'
import TableCellBody from 'pages/ReportPages/components/TableCellBody'
import TableCellHead from 'pages/ReportPages/components/TableCellHead'
import { ReportDetailsProps } from 'pages/ReportPages/ConsolidatedReport/components/ReportAltas/props'
import formatPrice from 'utils/formatPrice'

import TableRow from '@material-ui/core/TableRow'

import * as Styled from './styles'

const TableChipsActivated: FC<ReportDetailsProps> = ({ data }) => {
  const tableHead = useMemo(() => {
    return (
      <>
        <TableCellHead> Produto </TableCellHead>
        <TableCellHead align="right"> Quantidade </TableCellHead>
        <TableCellHead align="right"> Valor total </TableCellHead>
      </>
    )
  }, [])

  const tableBody = useMemo(() => {
    if (data?.plans.length == 0) {
      return (
        <TableRow>
          <TableCellBody
            capitalizeText="uppercase"
            component="th"
            scope="row"
            colSpan={3}
            align="center"
          >
            <Typography variant="subtitle1"> Sem dados </Typography>
          </TableCellBody>
        </TableRow>
      )
    }

    return data?.plans.map((plan: Plan) => (
      <TableRow key={plan.description}>
        <TableCellBody capitalizeText="uppercase" component="th" scope="row">
          {plan.description}
        </TableCellBody>
        <Styled.TableCellBody align="right">
          {plan.quantity}
        </Styled.TableCellBody>
        <Styled.TableCellBody align="right">
          {formatPrice({ price: plan.total_value })}
        </Styled.TableCellBody>
      </TableRow>
    ))
  }, [data])

  return <Table tableHead={tableHead} tableBody={tableBody} />
}

export default memo(TableChipsActivated)
