import React, { FC, useMemo, useRef, memo } from 'react'

import MenuItem from '@material-ui/core/MenuItem'

import { SelectProps, OptionSelectType } from './props'
import * as Styled from './styles'

const Select: FC<SelectProps> = props => {
  const { name, label, options, value, ...rest } = props
  const inputRef = useRef(null)

  const optionsMap = useMemo(
    () =>
      options.map((item: OptionSelectType) => (
        <MenuItem value={item.value} key={item.value}>
          {item.name}
        </MenuItem>
      )),
    [options],
  )

  return (
    <Styled.Select
      fullWidth
      select
      label={label}
      variant="outlined"
      inputRef={inputRef}
      id={name}
      name={name}
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    >
      {optionsMap}
    </Styled.Select>
  )
}

export default memo(Select)
