import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState,
} from 'react'

import { format } from 'date-fns'
import { api } from 'services/api'

import ReportAltas from '../components/ReportAltas'
import ReportOfPackages from '../components/ReportOfPackage/ReportOfPackages'
import ReportOfRecharges from '../components/ReportOfRecharges'
import {
  ReportContextData,
  ReportData,
  ReportActions,
  ResponseReportAltas,
  ResponseReportOfRecharges,
  ResponseReportOfPackage,
} from './props'
import reportDetailReducer, { initialState } from './reducer'

const initialValue = {} as ReportContextData

const ReportContext = createContext(initialValue)

export const ReportProvider: React.FC = ({ children }) => {
  const [Element, setElement] = useState(undefined)

  const [state, dispatch] = useReducer(reportDetailReducer, initialState)

  const renderReport = useCallback(async (data: ReportData) => {
    setElement(undefined)
    const reports = {
      discharges: <ReportAltas data={data} />,
      recharges: <ReportOfRecharges data={data} />,
      packages: <ReportOfPackages data={data} />,
    }

    setElement(reports[data.type])
  }, [])

  const getReportAltas = useCallback(async (data: ReportData) => {
    try {
      const params = {
        date_gt: format(new Date(data.date_gt), 'yyyy-MM-dd'),
        date_lt: format(new Date(data.date_lt), 'yyyy-MM-dd'),
      }

      if (data.company_type_eq !== 'all') {
        Object.assign(params, { company_type_eq: data.company_type_eq })
      }

      dispatch({ type: ReportActions.RequestReportAltas })

      const response = await api.get<ResponseReportAltas>(
        '/reports/discharges',
        { params },
      )

      dispatch({
        type: ReportActions.RequestSuccessReportAltas,
        payload: response,
      })
    } catch (error) {
      dispatch({ type: ReportActions.RequestErrorReportAltas })
    }
  }, [])

  const getReportOfPackages = useCallback(async (data: ReportData) => {
    try {
      const params = {
        date_gt: format(new Date(data.date_gt), 'yyyy-MM-dd'),
        date_lt: format(new Date(data.date_lt), 'yyyy-MM-dd'),
      }

      if (data.company_type_eq !== 'all') {
        Object.assign(params, { company_type_eq: data.company_type_eq })
      }

      dispatch({ type: ReportActions.RequestReportOfPackage })

      const response = await api.get<ResponseReportOfPackage>(
        '/reports/most-consumed-plans',
        { params },
      )

      dispatch({
        type: ReportActions.RequestSuccessReportOfPackage,
        payload: response,
      })
    } catch (error) {
      dispatch({ type: ReportActions.RequestErrorReportOfPackage })
    }
  }, [])

  const getReportOfRecharges = useCallback(async (data: ReportData) => {
    try {
      const params = {
        date_gt: format(new Date(data.date_gt), 'yyyy-MM-dd'),
        date_lt: format(new Date(data.date_lt), 'yyyy-MM-dd'),
      }

      if (data.company_type_eq !== 'all') {
        Object.assign(params, { company_type_eq: data.company_type_eq })
      }

      dispatch({ type: ReportActions.RequestReportOfRecharges })

      const response = await api.get<ResponseReportOfRecharges>(
        '/reports/recharges',
        { params },
      )

      dispatch({
        type: ReportActions.RequestSuccessReportOfRecharges,
        payload: response,
      })
    } catch (error) {
      dispatch({ type: ReportActions.RequestErrorReportOfRecharges })
    }
  }, [])

  return (
    <ReportContext.Provider
      value={{
        ...state,
        Element,
        renderReport,
        getReportAltas,
        getReportOfPackages,
        getReportOfRecharges,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

export function useReport() {
  const context = useContext(ReportContext)
  if (!context || context === initialValue) {
    throw new Error('useReport must be within as ReportContext')
  }

  return context
}
