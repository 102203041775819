import styled from 'styled-components'

import MUBreadcrumbs from '@material-ui/core/Breadcrumbs'
import MUContainer from '@material-ui/core/Container'

export const Container = styled(MUContainer)`
  display: flex;
  flex-direction: column;
  height: 90px;
  flex: 2;
  position: sticky;
  top: 0;
  z-index: 1001;
  min-width: auto;
  background-color: ${({ theme }) => theme.palette.common.white};

  @media (min-width: 960px) {
    position: initial;
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`

export const Hr = styled.hr`
  border: none;
  display: initial;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
`

export const Breadcrumbs = styled(MUBreadcrumbs)`
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
  li:first-child a {
    color: ${({ theme }) => theme.palette.grey[500]};
  }
`

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

export const ContainerBreadcrumbsMobile = styled(MUContainer)`
  display: flex;
  flex: 0.4;
  margin-top: 10px;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  font-size: 18px;
`

export const LogoMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;

  img {
    outline: none;
    cursor: pointer;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
