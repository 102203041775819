import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { useAuth } from 'hooks/auth'
import { UserProvider, useUser } from 'hooks/users'
import { User } from 'hooks/users/props'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import UsersListHeader from './components/UsersListHeader'
import UsersListItem from './components/UsersListItem'

const Users: FC = () => {
  const { push } = useHistory()

  const { hasRole } = useAuth()

  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    getUsers,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
  } = useUser()

  const renderItem = useCallback<TableRenderItem<User>>(
    item => <UsersListItem key={item.id} {...item} />,
    [],
  )

  const redirectToNew = useCallback(() => push(RoutingService.NEW_USER), [push])

  const renderPageTitle = useMemo(() => {
    if (hasRole(['dev', 'admin'])) {
      return (
        <PageTitle
          title="Usuários"
          showButton
          buttonText="Novo Usuário"
          buttonOnClick={redirectToNew}
        />
      )
    }

    return <PageTitle title="Usuários" />
  }, [hasRole, redirectToNew])

  return (
    <Route title="Usuários" access="auth" requireRoles={['dev', 'admin']}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Usuários', link: '#' },
        ]}
        activeOption="users"
      >
        {renderPageTitle}

        <Table<User>
          data={data}
          renderItem={renderItem}
          loadingRequest={requestStatus.loading}
          HeaderComponent={<UsersListHeader />}
          FooterComponent={
            <TablePagination
              getData={getUsers}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Users, UserProvider)
