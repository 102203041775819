import styled from 'styled-components'

export const Container = styled.div`
  h1 {
    padding-top: ${({ theme }) => theme.spacing(5)}px;
  }

  h2 {
    padding-bottom: 15px;
  }
`
