export const categoryChangeOptions = [
  { name: '', id: '_defaultcategoryChange' },
  { name: 'Perca', id: 'Changes_PERCA', labelName: 'loss' },
  { name: 'Roubo', id: 'Changes_Roubo', labelName: 'theft' },
  {
    name: 'Problema de Rede',
    id: 'Changes_Problema_de_Rede',
    labelName: 'network_problem',
  },
  {
    name: 'Solicitado pela Vivo',
    id: 'Changes_Solicitado_Pela_Vivo',
    labelName: 'requested_by_vivo',
  },
  { name: 'Ação', id: 'Leaves_Action' },
]

export const actionChangeOptions = [
  { name: '', id: '_defaultActioncategory' },
  { name: 'Produto', id: 'Changes_Produto', labelName: 'product' },
  {
    name: 'Setor Comercial',
    id: 'Changes_Setor_Comercial',
    labelName: 'commercial_sector',
  },
  {
    name: 'Marketing',
    id: 'Changes_Marketing',
    labelName: 'marketing',
  },
]
