import React, { FC, memo } from 'react'

import Button from 'components/Button'

import { CircularProgress } from '@material-ui/core'

import { useAcceptTransfer } from './useAcceptTransfer'

const AcceptButton: FC = () => {
  const { acceptTransfer, status } = useAcceptTransfer()

  return (
    <Button
      variant="outlined"
      color="primary"
      size="large"
      onClick={acceptTransfer}
      disabled={status.loading}
      startIcon={status.loading && <CircularProgress color="primary" />}
    >
      {!status.loading && 'Aceitar'}
    </Button>
  )
}

export default memo(AcceptButton)
