import styled from 'styled-components'

import MUFormControlLabel from '@material-ui/core/FormControlLabel'

export const FilledOutline = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  height: 20px;
  width: 20px;
  border-radius: 3px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FilledOutlineChecked = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 2px;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  padding: 2px;
  justify-content: center;
`
export const FormControlLabel = styled(MUFormControlLabel)`
  color: ${({ theme }) => theme.palette.primary.main};
  .MuiTypography-root {
    text-transform: capitalize;
  }
`
