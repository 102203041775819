import MUButton from 'components/Button'
import styled, { css } from 'styled-components'

import { ButtonSidebarProps } from './props'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Button = styled(MUButton)<ButtonSidebarProps>`
  cursor: pointer;
  justify-content: flex-start;
  width: 170px;
  padding-left: 6px;
  transition: ease 0.3s;

  ${props =>
    props.typeIcon === 'arrow' &&
    css`
      background: none;
    `}

  ${props =>
    props.typeIcon === 'all-products' &&
    css`
      background: rgba(93, 144, 227, 0.4);
    `}

  ${props =>
    props.typeIcon === 'cashflow' &&
    css`
      background: rgba(0, 151, 30, 0.3);
    `}

  ${props =>
    props.typeIcon === 'return' &&
    css`
      background: rgba(100, 100, 100, 0.3);
    `}

    ${props =>
    props.typeIcon === 'logout' &&
    css`
      background: rgba(244, 68, 68, 0.2);
    `}

    :hover {
    ${props =>
      props.typeIcon === 'arrow' &&
      css`
        svg path {
          stroke: ${({ theme }) => theme.palette.primary.main};
        }
      `}

    ${props =>
      props.typeIcon === 'all-products' &&
      css`
        svg path {
          transition: ease 0.3s;
          stroke: ${({ theme }) => theme.palette.common.white};
        }
      `}

    ${props =>
      props.typeIcon === 'cashflow' &&
      css`
        svg path {
          transition: ease 0.3s;
          color: ${({ theme }) => theme.palette.common.white};
        }
      `}

      ${props =>
      props.typeIcon === 'return' &&
      css`
        svg path {
          transition: ease 0.3s;
          color: ${({ theme }) => theme.palette.common.white};
        }
      `}

    ${props =>
      props.typeIcon === 'logout' &&
      css`
        svg path {
          transition: ease 0.3s;
          stroke: ${({ theme }) => theme.palette.common.white};
        }
      `}

    ${props =>
      props.typeIcon === 'arrow' &&
      css`
        background: #f0f0f4;
        h4 {
          transition: ease 0.3s;
          color: none;
        }
      `}

    ${props =>
      props.typeIcon === 'all-products' &&
      css`
        background: ${({ theme }) => theme.palette.primary.main};
        h4 {
          transition: ease 0.3s;
          color: ${({ theme }) => theme.palette.common.white};
        }
      `}


    ${props =>
      props.typeIcon === 'cashflow' &&
      css`
        background: rgba(0, 151, 30);
        h4 {
          transition: ease 0.3s;
          color: ${({ theme }) => theme.palette.common.white};
        }
      `}

    ${props =>
      props.typeIcon === 'return' &&
      css`
        background: rgba(100, 100, 100);
        h4 {
          transition: ease 0.3s;
          color: ${({ theme }) => theme.palette.common.white};
        }
      `}

      ${props =>
      props.typeIcon === 'logout' &&
      css`
        background: ${({ theme }) => theme.palette.error.light};
        h4 {
          transition: ease 0.3s;
          color: ${({ theme }) => theme.palette.common.white};
        }
      `}
  }
`
