import React, { FC, memo, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

import Button from 'components/Button'
import Card from 'components/Card'
import RenderWithRoles from 'components/RenderWithRoles'
import Typography from 'components/Typography'
import { CompanyDetail } from 'interfaces/company'
import RoutingService from 'services/routing'
import { formatPhone } from 'utils/formatPhone'

import { Grid } from '@material-ui/core'

import * as Styled from './styles'

const RepresentativeCard: FC<Pick<CompanyDetail, 'representative'>> = props => {
  const { representative } = props
  const { companyId } = useParams<any>()

  const phoneRepresentative = useMemo(() => {
    if (!representative) return ''
    return formatPhone('(XX) X XXXX XXXX', representative.user.phone)
  }, [representative])

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.COMPANY_REPRESENTATIVE_EDIT,
        ['userId', 'companyId'],
        [representative?.user.id as string, companyId],
      ),
    [companyId, representative?.user.id],
  )

  return (
    <Card shadow>
      <Styled.Grid container spacing={2}>
        <Grid sm={6} item>
          <Typography color="primary" variant="h6" capitalize="uppercase">
            Dados do Representante
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color="initial" variant="subtitle1">
            Nome
          </Typography>
          <Typography color="primary" variant="h6">
            {representative?.user.name}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color="initial" variant="subtitle1">
            E-mail
          </Typography>
          <Typography color="primary" variant="h6">
            {representative?.user.email}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color="initial" variant="subtitle1">
            Telefone
          </Typography>
          <Typography color="primary" variant="h6">
            {phoneRepresentative}
          </Typography>
        </Grid>
      </Styled.Grid>
      <Styled.ContenButton>
        <RenderWithRoles roles={['admin', 'dev', 'distributor']}>
          <Link to={detailUrl}>
            <Button variant="outlined" color="primary">
              Atualizar
            </Button>
          </Link>
        </RenderWithRoles>
      </Styled.ContenButton>
    </Card>
  )
}

export default memo(RepresentativeCard)
