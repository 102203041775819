import React, { FC, memo } from 'react'

import { format } from 'date-fns'
import { ChangeChipsCashFlow } from 'hooks/ChangeChipsCashFlow/props'
import { TranslateCategoryReports } from 'utils/Translate'

import { Page, Document, View, Text } from '@react-pdf/renderer'

import { ModalReportsProps } from '../../props'
import styles from './styles'

const PdfChangeReport: FC<ModalReportsProps> = props => {
  const { data } = props

  return (
    <Document>
      <Page size="A4">
        <View style={styles.container}>
          <Text style={styles.title}>Relatorio de Trocas</Text>
        </View>

        <View style={styles.containerTable}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeaderSmall}>
                <Text style={styles.tableCellHeader}>Data da troca</Text>
                {data.map((item: ChangeChipsCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {format(new Date(item.created_at), 'dd-MM-yyyy')}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeaderSmall}>
                <Text style={styles.tableCellHeader}>Categoria</Text>
                {data.map((item: ChangeChipsCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {TranslateCategoryReports(item.category)}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeaderLarge}>
                <Text style={styles.tableCellHeader}>ICCID antigo</Text>
                {data.map((item: ChangeChipsCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItemSell}>
                    {item.old_chip.iccid}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeaderLarge}>
                <Text style={styles.tableCellHeader}>ICCID novo</Text>
                {data.map((item: ChangeChipsCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.new_chip.iccid}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeaderLarge}>
                <Text style={styles.tableCellHeader}>Nome</Text>
                {data.map((item: ChangeChipsCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.name}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeaderSmall}>
                <Text style={styles.tableCellHeader}>CPF</Text>
                {data.map((item: ChangeChipsCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.document}
                  </Text>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default memo(PdfChangeReport)
