import React, { FC, memo } from 'react'

import Button from 'components/Button'

import { useCancelTransfer } from './useCancelTransfer'

const CancelButton: FC = () => {
  const { cancelTransfer, status } = useCancelTransfer()

  return (
    <Button
      variant="outlined"
      color="primary"
      size="large"
      onClick={cancelTransfer}
      disabled={status.loading}
    >
      Cancelar
    </Button>
  )
}

export default memo(CancelButton)
