import * as Yup from 'yup'

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  stockist: Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
    phone: Yup.string().required('Campo obrigatório'),
  }),
  city_id: Yup.string().required('Campo obrigatório'),
  state_id: Yup.string().required('Campo obrigatório'),
})

export default schema
