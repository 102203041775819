import styled from 'styled-components'

import { Select, Button } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'

export const SelectValue = styled(Select)`
  width: 410px;
  height: 42px;
  margin-top: 8px;
  margin-bottom: 15px;
  margin-right: 10px;
`

export const ActionButton = styled(Button)`
  width: 188px;
  height: 42px;
  margin-bottom: 8px;
  margin-left: 24px;
`
export const TextInput = styled.input`
  width: 150px;
  height: 45px;
  border-radius: 3px;
  border-style: none;
  border: 1px solid #ccc;
  margin-left: 20px;
  padding: 10px;

  &:hover {
    border: 1px solid black;
  }
`
export const Label = styled(InputLabel)`
  font-size: 20px;
`
