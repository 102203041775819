import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import Typography from 'components/Typography'
import { useFirstAccess } from 'hooks/auth/password/FirstAccess'
import RoutingService from 'services/routing'

import * as Styled from './styles'

const FormCompleted: FC = () => {
  const { status, resendEmail } = useFirstAccess()

  return (
    <div>
      <Typography color="primary" variant="h5">
        Verifique seu e-mail
      </Typography>

      <Typography color="primary" variant="subtitle1">
        Você receberá um link para criar sua nova senha
      </Typography>

      <Styled.ButtonsContainer>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={resendEmail}
          disabled={status.loading}
          size="large"
        >
          Enviar novamente
        </Button>

        <Link to={RoutingService.LOGIN}>
          <Button variant="outlined" color="secondary" fullWidth size="large">
            Voltar para login
          </Button>
        </Link>
      </Styled.ButtonsContainer>
    </div>
  )
}

export default memo(FormCompleted)
