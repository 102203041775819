/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC,
  useCallback,
  useState,
  createContext,
  useMemo,
  useContext,
  useEffect,
} from 'react'

import { subDays, format } from 'date-fns'
import { useAuth } from 'hooks/auth'
import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'

import {
  ChipCountResponse,
  ChipsStatusResponse,
  CommissionStatusResponse,
  ProductContextProps,
  ChartDataStatusResponse,
  ChartRequest,
  DataParamsProps,
  TypesStatusChips,
} from './props'

const ProductContext = createContext<ProductContextProps>(undefined as any)

const ProductProvider: FC = props => {
  const { children } = props
  const { user, hasRole } = useAuth()

  const [statusChipCount, setStatusChipCount] = useState<RequestStatus>({
    error: false,
    loading: false,
    success: false,
  })

  const [status, setStatus] = useState<RequestStatus>({
    error: false,
    loading: false,
    success: false,
  })

  const [statusCommission, setStatusCommission] = useState<RequestStatus>({
    error: false,
    loading: false,
    success: false,
  })

  const [statusChart, setStatusChart] = useState<RequestStatus>({
    error: false,
    loading: false,
    success: false,
  })

  const [data, setData] = useState<ChipsStatusResponse[]>([])

  const [dataCommission, setDataCommission] = useState<
    CommissionStatusResponse[]
  >([])

  const [dataChart, setDataChart] = useState<ChartDataStatusResponse[] | null>(
    null,
  )

  const [chipCount, setChipCount] = useState<ChipCountResponse | null>(null)

  const [startDatePanel, setStartDatePanel] = useState<Date | null>(() =>
    subDays(new Date(), 30),
  )
  const [startDateCommission, setStartDateCommission] = useState<Date | null>(
    null,
  )

  const [endDatePanel, setEndDatePanel] = useState<Date | null>(
    () => new Date(),
  )
  const [endDateCommission, setEndDateCommission] = useState<Date | null>(null)

  const [dataChartParams, setDataChartParams] = useState<ChartRequest>({})

  const [dataParams, setDataParams] = useState<DataParamsProps>({})

  const getData = useCallback(
    async (company_type_eq?: string) => {
      try {
        setStatus({ loading: true, success: false, error: true })
        setData([])

        const dateFormat = dataParams.date
          ? format(new Date(dataParams.date), 'MM/yyyy')
          : undefined

        const params: DataParamsProps = {
          company_id_eq: undefined,
          company_type_eq: dataParams.dealer,
          date: dateFormat,
        }

        if (dataParams.period === 'yearly') {
          delete params.date
        }

        if (params.company_type_eq === 'all') {
          delete params.company_type_eq
        }

        if (hasRole('company_representative')) {
          if (user?.company_representative?.company?.id) {
            params.company_id_eq = user?.company_representative?.company?.id
          }
        }

        const response = await api.get<ChipsStatusResponse[]>(
          '/reports/chips-status',
          {
            params,
          },
        )
        setStatus(prev => ({ ...prev, loading: false, success: true }))
        setData(response.data)
      } catch (error) {
        setStatus(prev => ({ ...prev, loading: false, error: true }))
      }
    },
    [
      dataParams.date,
      dataParams.dealer,
      dataParams.period,
      hasRole,
      user?.company_representative?.company?.id,
    ],
  )

  const getDataCommission = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (company_type_eq?: string) => {
      try {
        setStatusCommission({ loading: true, success: false, error: true })
        setDataCommission([])

        const dateGt = startDateCommission
          ? format(new Date(startDateCommission), 'yyyy-MM')
          : undefined

        const dateLt = endDateCommission
          ? format(new Date(endDateCommission), 'yyyy-MM')
          : undefined

        const params: Record<string, any> = {
          company_id_eq: undefined,
          date_gt: dateGt,
          date_lt: dateLt,
        }

        if (hasRole('company_representative')) {
          if (user?.company_representative?.company?.id) {
            params.company_id_eq = user?.company_representative?.company?.id
          }
        }

        if (startDateCommission && endDateCommission !== null) {
          const response = await api.get<CommissionStatusResponse[]>(
            '/reports/plans-commission-graphic',
            { params },
          )

          setStatusCommission(prev => ({
            ...prev,
            loading: false,
            success: true,
          }))
          setDataCommission(response.data)
        } else {
          const response = await api.get<CommissionStatusResponse[]>(
            '/reports/plans-commission-graphic',
          )

          setStatusCommission(prev => ({
            ...prev,
            loading: false,
            success: true,
          }))
          setDataCommission(response.data)
        }
      } catch (error) {
        setStatusCommission(prev => ({
          ...prev,
          loading: false,
          error: true,
        }))
      }
    },
    [
      endDateCommission,
      hasRole,
      startDateCommission,
      user?.company_representative?.company?.id,
    ],
  )

  const getChart = useCallback(async () => {
    try {
      setStatusChart({ loading: true, success: false, error: true })
      setDataChart(null)

      const dateFormat = dataChartParams.date
        ? format(new Date(dataChartParams.date), 'MM/yyyy')
        : undefined

      const params: ChartRequest = {
        company_id_eq: dataChartParams.company_id_eq,
        date: dateFormat,
        company_type_eq: dataChartParams.company_type_eq,
      }

      if (dataChartParams.period === 'yearly') {
        delete params.date
      }

      if (
        params.company_type_eq === 'all' ||
        params.company_type_eq === 'name'
      ) {
        delete params.company_type_eq
      }

      if (hasRole('company_representative')) {
        if (user?.company_representative?.company?.id) {
          params.company_id_eq = user?.company_representative?.company?.id
        }
      }

      if (dataChartParams.report_type === 'discharges') {
        const response = await api.get<ChartDataStatusResponse[]>(
          '/reports/discharges-new',
          {
            params,
          },
        )
        setStatusChart(prev => ({ ...prev, loading: false, success: true }))
        setDataChart(response.data)
      } else if (dataChartParams.report_type === 'recharges') {
        const response = await api.get<ChartDataStatusResponse[]>(
          '/reports/recharges-new',
          {
            params,
          },
        )
        setStatusChart(prev => ({ ...prev, loading: false, success: true }))
        setDataChart(response.data)
      } else {
        const response = await api.get<ChartDataStatusResponse[]>(
          '/reports/plans-new',
          {
            params,
          },
        )
        setStatusChart(prev => ({ ...prev, loading: false, success: true }))
        setDataChart(response.data)
      }
    } catch (error) {
      setStatusChart(prev => ({ ...prev, loading: false, error: true }))
    }
  }, [dataChartParams, hasRole, user?.company_representative?.company?.id])

  useEffect(() => {
    async function getChipCount() {
      setStatusChipCount({ loading: true, success: false, error: false })
      setChipCount(null)
      const response = await api.get<ChipCountResponse>('/reports/chips-sold')
      setChipCount(response.data)
      setStatusChipCount({ loading: false, success: false, error: false })
    }
    if (hasRole(['admin', 'dev'])) {
      getChipCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const translateKey = useCallback((key: string) => {
    const keys: Record<keyof TypesStatusChips, string> = {
      active: 'Chips ativos',
      restricted: 'Chips restritos',
      barred: 'Chips barrados',
      canceled: 'Chips cancelados',
      finished: 'Finalizado',
    }
    return keys[key]
  }, [])

  const getColor = useCallback(
    (key: string): string | undefined => {
      if (!data) return undefined

      const keyMap = {
        active: '#65C763',
        restricted: '#F56E28',
        barred: '#F8AF43',
        canceled: '#FD4E4E',
        finished: '#C30303',
      }

      return keyMap[key]
    },
    [data],
  )

  const value = useMemo(
    () => ({
      status,
      data,
      getData,
      translateKey,
      getColor,
      chipCount,
      statusChipCount,
      getDataCommission,
      dataCommission,
      statusCommission,
      endDatePanel,
      endDateCommission,
      setEndDatePanel,
      setEndDateCommission,
      startDatePanel,
      startDateCommission,
      setStartDatePanel,
      setStartDateCommission,
      statusChart,
      getChart,
      dataChart,
      setDataChartParams,
      dataChartParams,
      dataParams,
      setDataParams,
    }),
    [
      status,
      data,
      getData,
      translateKey,
      getColor,
      chipCount,
      statusChipCount,
      getDataCommission,
      dataCommission,
      statusCommission,
      endDatePanel,
      endDateCommission,
      startDatePanel,
      startDateCommission,
      statusChart,
      getChart,
      dataChart,
      setDataChartParams,
      dataChartParams,
      dataParams,
      setDataParams,
    ],
  )

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  )
}

export function useProduct() {
  const context = useContext(ProductContext)

  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider')
  }
  return context
}

export default ProductProvider
