import React, { FC, memo, useState } from 'react'

import TableHead from 'components/TableHead'
import { useGetDistributors } from 'hooks/distributors'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const DistributorListHeader: FC = () => {
  const [search, setSearch] = useState('')
  const { getDistributors } = useGetDistributors()

  return (
    <TableHead
      title="Todos os distribuidores"
      filter={[
        {
          type: 'text',
          value: search,
          onChange: setSearch,
          label: 'Encontre um distribuidor',
        },
      ]}
      onClickFilter={() => getDistributors(search)}
    >
      <TableRow>
        <TableCell>Nome</TableCell>
        <TableCell>E-mail</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default memo(DistributorListHeader)
