import React, { FC, memo } from 'react'

import { CardTotalChipsProps } from './props'
import * as Styled from './styles'

const CardTotalChips: FC<CardTotalChipsProps> = props => {
  const { value } = props

  return (
    <Styled.Container>
      <Styled.ContentButton>
        <img
          src={require('assets/images/images-product/chip.svg').default}
          alt="Imagem chip"
        />
      </Styled.ContentButton>

      <Styled.ContentText>
        <span>Total de chips</span>
        <h1>{value}</h1>
      </Styled.ContentText>
    </Styled.Container>
  )
}

export default memo(CardTotalChips)
