import React, { FC } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import EditProfileCard from './components/EditProfileCard'
import { EditProfileProvider } from './context'

const UserDetails: FC = () => {
  return (
    <Route title="Editar Perfil" access="auth" requireRoles={[]}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Editar Perfil', link: '#' },
        ]}
        activeOption="home"
      >
        <PageTitle title="Editar Perfil" />

        <EditProfileCard />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(UserDetails, EditProfileProvider)
