import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useState,
} from 'react'

import ToastContainer from 'components/ToastContainer'

import { ToastContextData, ToastMessage } from './props'

const initialValue = {} as ToastContextData
const ToastContext = createContext(initialValue)

export const ToastProvider: FC = props => {
  const { children } = props

  const [messages, setMessages] = useState<ToastMessage[]>([])

  const addToast = useCallback(
    ({ type, title, description }: Omit<ToastMessage, 'id'>) => {
      const id = new Date().getTime().toString()
      const toast = {
        id,
        type,
        title,
        description,
      }
      setMessages(state => [...state, toast])
    },
    [],
  )

  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  )
}

export function useToast(): ToastContextData {
  const context = useContext(ToastContext)

  if (!context || context === initialValue) {
    throw new Error('useToast must be used within an ToastProvider')
  }

  return context
}
