import React, { FC, useEffect, useMemo, memo } from 'react'
import { useParams } from 'react-router-dom'

import Card from 'components/Card'
import RequestError from 'components/DetailsPages/RequestError'
import Loading from 'components/Loading'
import ValidateCommission from 'components/Modals/ValidateCommission'
import { useCommissionDetail } from 'hooks/commissions/details'
import SectionCalc from 'pages/ReportPages/components/SectionCalc'
import { DataSectionCalc } from 'pages/ReportPages/components/SectionCalc/props'

import CommissionHeader from '../CommissionHeader'
import SectionBasicData from '../SectionBasicData'
import * as Styled from './styles'

const DetailsComponent: FC = () => {
  const { getCommission, commission, requestStatus } = useCommissionDetail()

  const { date } = useParams<any>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data: DataSectionCalc[] = [
    {
      label: 'Total de pacotes',
      value: `R$ ${commission?.total_plans_value}`,
    },
    {
      label: 'Valor líquido',
      value: `R$ ${commission?.net_value}`,
    },
    {
      label: 'Valor da comissão',
      value: `R$ ${commission?.total_value}`,
    },
  ]

  const { companyId } = useParams<any>()

  useEffect(() => {
    getCommission(companyId, date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  const Content = useMemo(() => {
    if (requestStatus.success)
      return (
        <Styled.Root>
          <CommissionHeader />
          <SectionBasicData />

          <Styled.Divider />

          <SectionCalc title="Cálculo de repasse" data={data} />
        </Styled.Root>
      )
    if (requestStatus.error) return <RequestError />
    return (
      <Styled.Root>
        <Loading />
      </Styled.Root>
    )
  }, [data, requestStatus.error, requestStatus.success])

  const commissionId = useMemo(() => {
    if (!commission) return ''
    return commission?.id
  }, [commission])

  return (
    <Card shadow>
      {Content}

      <ValidateCommission commissionId={commissionId} />
    </Card>
  )
}

export default memo(DetailsComponent)
