import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  FC,
} from 'react'

import { ProductsProps, ProductProps, ProductContextData } from './props'

const ProductContext = createContext<ProductContextData>(
  {} as ProductContextData,
)

export const ProductProvider: FC = props => {
  const { children } = props

  const [products] = useState<ProductsProps[]>([
    {
      id: '91c8ef57-e2ec-4bdb-910c-eb8fa84e792d',
      name: 'Brisamovel',
      tags: ['android', 'ios', 'web'],
    },
  ])

  const getProductById = useCallback((id: string): ProductProps => {
    // just a mock, we will get this information from an API route after
    const product: ProductProps = {
      id,
      name: 'Brisamovel',
      created_at: new Date(),
      updated_at: new Date(),
    }

    return product
  }, [])

  return (
    <ProductContext.Provider value={{ products, getProductById }}>
      {children}
    </ProductContext.Provider>
  )
}

export function useProduct(): ProductContextData {
  const context = useContext(ProductContext)

  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider')
  }

  return context
}
