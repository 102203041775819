type Func = (...args: any) => any

export function debounce(callback: Func, time = 200) {
  let timeout: null | NodeJS.Timeout = null
  // @ts-ignore
  function debounced(this: any, ...args) {
    if (timeout) {
      clearTimeout(timeout)
    }
    callback.apply(this, args)
    timeout = setTimeout(callback, time)
  }

  return debounced
}
