import React, { FC, memo, useMemo } from 'react'

import { OptionCardProps } from './props'
import * as Styled from './styles'

const Option: FC<OptionCardProps> = props => {
  const { name, iconActive, iconInactive, page, active } = props

  const icon = useMemo(() => (active ? iconActive : iconInactive), [
    active,
    iconActive,
    iconInactive,
  ])

  const pageLink = useMemo(() => page || '#', [page])

  return (
    <Styled.Option to={pageLink}>
      <div>
        <Styled.ActiveLabel active={active}>
          <Styled.ContainerLabel>
            <Styled.Icon src={icon} />
            <Styled.OptionLabel active={active}>{name}</Styled.OptionLabel>
          </Styled.ContainerLabel>
        </Styled.ActiveLabel>
      </div>
    </Styled.Option>
  )
}

export default memo(Option)
