import React, { FC, memo } from 'react'

const Info: FC = () => (
  <div>
    <h1>
      CRM Produtos.
      <br />
      Nossa plataforma de dados
    </h1>

    <h2>Obtenha todos os dados e informações necessárias.</h2>
  </div>
)

export default memo(Info)
