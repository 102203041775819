import React, { FC } from 'react'

import TableHead from 'components/TableHead'
import { useRecharge } from 'hooks/recharges'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const SalesListHeader: FC = () => {
  const { setStatus, status } = useRecharge()

  return (
    <TableHead
      title="Todas as vendas"
      filter={[
        {
          type: 'select',
          value: status,
          // @ts-ignore
          onChange: setStatus,
          label: 'Status',
          options: [
            {
              label: 'Sucesso',
              value: 'success',
            },
            {
              label: 'Erro',
              value: 'error',
            },
          ],
        },
      ]}
    >
      <TableRow>
        <TableCell>Data</TableCell>
        <TableCell>Quantidade de recargas</TableCell>
        <TableCell>Quantidade de usuários diferentes</TableCell>
        <TableCell>Valor Total</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default SalesListHeader
