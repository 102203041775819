import isMultiple from 'utils/isMultiple'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  chip_quantity: Yup.number()
    .test(
      'Quantidade múltipla de cinco',
      'Necessário ser múltiplo de cinco',
      value => {
        if (typeof value === 'number') {
          return isMultiple.five(value as number)
        }

        return true
      },
    )
    .min(0, 'Valor precisa ser um número positivo')
    .integer('Valor precisa ser inteiro'),
})

export default schema
