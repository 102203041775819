class isMultiple {
  /**
   * Verifica se o numero é multiplo de cinco
   * @param number Exemplos de entradas válidas: 5, 10, 15, 20, 25, 30 , 35, 40, 45, …
   *
   *
   */
  static five(value: number) {
    if (typeof value === 'number') {
      return Math.round(value / 5) / 0.2 === value
    }
    return false
  }
}

export default isMultiple
