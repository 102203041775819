import React, { FC, memo } from 'react'

import { TextFilterProps } from '../../props'
import * as Styled from './styles'

const TextFilter: FC<TextFilterProps> = props => {
  const { onChange, value, name, label } = props

  return (
    <Styled.TextField
      value={value}
      variant="outlined"
      name={name}
      onChange={evt => onChange(evt.target.value)}
      label={label}
      type={'text'}
    />
  )
}

export default memo(TextFilter)
