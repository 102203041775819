import validateICCID from 'utils/validateICCID'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  iccids_intervals: Yup.array().of(
    Yup.object().shape({
      first_iccid: Yup.string()
        .required('ICCID inicial é obrigatório')
        .test('valid pattern ICCID', 'ICCID inválido', value =>
          validateICCID(value as string),
        )
        .notOneOf([Yup.ref('last_iccid')], 'ICCIDS devem ser diferentes'),

      last_iccid: Yup.string()
        .required('ICCID final é obrigatório')
        .test('valid pattern ICCID', 'ICCID inválido', value =>
          validateICCID(value as string),
        )
        .notOneOf([Yup.ref('first_iccid')], 'ICCIDS devem ser diferentes')
        .test(
          'Minor ICCID',
          'ICCID final menor que o ICCID inicial',
          function (LAST_ICCID_STRING) {
            const FIRST_ICCID_STRING: string = this.parent.first_iccid

            const compare = FIRST_ICCID_STRING.localeCompare(
              LAST_ICCID_STRING as string,
            )

            if (compare === -1) {
              return true
            }
            return false
          },
        ),
    }),
  ),

  recipient: Yup.object().shape({
    id: Yup.string().required('Campo obrigatório'),
    type: Yup.string().required('Campo obrigatório'),
  }),
})

export default schema
