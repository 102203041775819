import { useCallback, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { Role } from 'hooks/users/props'
import { api } from 'services/api'

type ListRolesResponse = Role[]

export const useRoles = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })
  const [roles, setRoles] = useState<Role[]>([])

  const getRoles = useCallback(async () => {
    try {
      setStatus({
        error: false,
        loading: true,
      })

      const response = await api.get<ListRolesResponse>('/roles')
      setStatus({
        error: false,
        loading: false,
      })
      setRoles(response.data)
    } catch (e) {
      setStatus({
        error: true,
        loading: false,
      })
      setRoles([])
    }
  }, [])

  useEffectOnce(() => {
    getRoles()
  })

  return {
    status,
    roles,
    getRoles,
  }
}
