import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'
import { CompanyType, Company } from 'interfaces/company'

export type TypeFormCompany = 'update' | 'register'

export enum FormCompanyActions {
  RequestAddCompany = 'REQUEST_ADD_COMPANY',
  RequestSuccessAddCompany = 'REQUEST_SUCCESS_ADD_COMPANY',
  RequestErrorAddCompany = 'REQUEST_ERROR_ADD_COMPANY',

  RequestUpdateCompany = 'REQUEST_UPDATE_COMPANY',
  RequestSuccessUpdateCompany = 'REQUEST_SUCCESS_UPDATE_COMPANY',
  RequestErrorUpdateCompany = 'REQUEST_ERROR_UPDATE_COMPANY',

  Reset = 'RESET',
}

export type FormCompanyPayload = {
  [FormCompanyActions.RequestAddCompany]: undefined
  [FormCompanyActions.RequestSuccessAddCompany]: AxiosResponse<any>
  [FormCompanyActions.RequestErrorAddCompany]: undefined

  [FormCompanyActions.RequestUpdateCompany]: undefined
  [FormCompanyActions.RequestSuccessUpdateCompany]: undefined
  [FormCompanyActions.RequestErrorUpdateCompany]: undefined

  [FormCompanyActions.Reset]: undefined
}

export type FormCompanyReducerActionMap = ActionMapData<FormCompanyPayload>

export type FormCompanyState = {
  requestStatusAddCompany: RequestStatus
  requestStatusUpdateCompany: RequestStatus
  company: Company | null
}

export interface DataFormCompanyType {
  active?: boolean
  new_representative?: boolean
  type: CompanyType
  document: string
  fantasy_name: string
  corporate_name?: string
  phone: string
  commission_model: any
  email: string
  latitude: string
  longitude: string
  representative: Representative
  representative_id?: string
  city_id: string
  state_id?: string
  street: string
  district: string
  number: number | undefined
}

export interface Representative {
  name: string
  phone: string
  email: string
}

interface CityAndState {
  city: string
  state: string
}

export type FormCompanyContextData = FormCompanyState & {
  /**
   * Estado que armazena dados da empresa no formulário para ser cadastrada
   */
  dataFormCompany: DataFormCompanyType

  /**
   * Modifica o estado da empresa à ser cadastrada
   */
  setDataFormCompany: React.Dispatch<React.SetStateAction<DataFormCompanyType>>

  /**
   * Memoriza o passo atual do formulário de cadastro
   */
  actualStepForm: number

  /**
   * Modifica o passo atual memorizado do formulário de cadastro
   */
  setActualStepForm: React.Dispatch<React.SetStateAction<number>>

  /**
   * Função para adicionar empresa
   */
  addCompany(): Promise<void>

  /**
   * Função para atualizar empresa
   */
  updateCompany(id: string): Promise<void>

  /**
   * Define o tipo de formulário de envio do formulário
   */
  typeForm: TypeFormCompany

  /**
   * Altera o tipo de envio do formulário
   */
  setTypeForm: React.Dispatch<React.SetStateAction<TypeFormCompany>>

  /**
   * Possui o valor de texto dos campos de cidade e estado
   */
  cityAndState: CityAndState

  /**
   * Atribui um valor de texto para os campos de cidade e estado
   */
  setCityAndState: React.Dispatch<React.SetStateAction<CityAndState>>
}
