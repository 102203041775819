import styled, { css } from 'styled-components'

import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'

import { AvatarPictureProps } from './props'

export const Root = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(3)}px;

  padding: 0 ${({ theme }) => theme.spacing(1)}px;

  border-radius: 6px;
  transition: ease 0.6s;
  :hover {
    background-color: rgba(214, 229, 253, 1);
  }
`

export const AvatarPicture = styled.img<AvatarPictureProps>`
  height: 31px;
  width: 31px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};

  ${props =>
    props.visibleBackground
      ? css`
          background-color: ${({ theme }) => theme.palette.primary.main};
          padding: 4px;
        `
      : css`
          background-color: transparent;
        `}

  cursor: pointer;
`

export const NameUser = styled(Typography)`
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const ContainerOptions = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 5%;
  padding: 5px;
`

export const ContainerOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OptionEditPerfil = styled(Typography)`
  padding: 10px;
  cursor: pointer;
  font-weight: 500;

  color: ${({ theme }) => theme.palette.grey[600]};

  a {
    text-decoration: none;
  }
`

export const OptionLogout = styled(Typography)`
  padding: 10px;
  cursor: pointer;
  font-weight: 500;

  color: ${({ theme }) => theme.palette.error.light};
`

export const Icon = styled.img`
  margin-left: 6px;
`
