import { optionSelectType } from 'components/FormCompany/components/SelectDocument/props'

export const OPTIONS_TYPE_REPORT: optionSelectType[] = [
  {
    name: 'Altas',
    value: 'discharges',
  },
  {
    name: 'Recargas',
    value: 'recharges',
  },
  {
    name: 'Pacotes',
    value: 'packages',
  },
]

export const OPTIONS_TYPE_COMPANY: optionSelectType[] = [
  {
    name: 'Todos',
    value: 'all',
  },
  {
    name: 'Parceira',
    value: 'partner',
  },
  {
    name: 'Franqueada',
    value: 'franchisee',
  },
]
