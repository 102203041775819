import PureButton from 'components/Button'
import TypographyStyles from 'components/Typography'
import styled from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

export const Grid = styled(MUGrid)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Button = styled(PureButton)`
  background-color: transparent;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    min-width: 100%;
  }

  span {
    color: ${({ theme }) => theme.palette.primary.main};
    text-transform: uppercase;
  }

  svg {
    margin-right: 5px;
  }
`

export const QuantityChips = styled(TypographyStyles)`
  line-height: 1;
`
