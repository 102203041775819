import ButtonUnstyled from 'components/Button'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'

export const HeaderTitle = styled(Typography)`
  margin-right: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin: 0px;
  }
  font-size: 28px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  align-items: baseline;

  & ${HeaderTitle} {
    display: inline-block;
    flex: 1;
  }

  & button {
    margin-top: ${({ theme }) => theme.spacing(3)}px;
    margin-bottom: 0px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: row;
    padding-top: ${({ theme }) => theme.spacing(1)}px;
    padding-bottom: ${({ theme }) => theme.spacing(4)}px;

    button {
      margin-top: 0px;
    }
  }
`

export const Button = styled(ButtonUnstyled)`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`
