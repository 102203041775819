import React, { FC, useEffect, useCallback, useMemo } from 'react'

import { useToast } from 'hooks/toast'

import ToastProps from './props'
import * as Styled from './styles'

const Toast: FC<ToastProps> = props => {
  const { message, style } = props

  const { removeToast } = useToast()

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, 4000)

    return () => {
      clearTimeout(timer)
    }
  }, [removeToast, message.id])

  const remove = useCallback(() => {
    removeToast(message.id)
  }, [message, removeToast])

  const Icon = useMemo(() => {
    const icons = {
      info: (
        <Styled.Icon
          src={require('assets/images/icons/info.svg').default}
          alt="Icone info"
        />
      ),
      error: (
        <Styled.Icon
          src={require('assets/images/icons/danger.svg').default}
          alt="Icone danger"
        />
      ),
      success: (
        <Styled.Icon
          src={require('assets/images/icons/success.svg').default}
          alt="Icone success"
        />
      ),
    }
    if (!message.type || !icons[message.type]) return icons.info
    return icons[message.type]
  }, [message.type])

  const Description = useMemo(() => {
    if (!message.description) return null
    return <p>{message.description}</p>
  }, [message])

  return (
    <Styled.Container type={message.type} style={style}>
      {Icon}

      <div>
        <strong>{message.title}</strong>
        {Description}
      </div>

      <button onClick={remove} type="button">
        <Styled.Icon
          src={require('assets/images/icons/close-toast.svg').default}
          alt="Icone close"
        />
      </button>
    </Styled.Container>
  )
}

export default Toast
