import MUTypography from 'components/Typography'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import { ItemBreadcrumpProps } from './props'

export const Main = styled(Grid)`
  display: flex;
  flex-direction: row;
`

export const Typography = styled(MUTypography)<ItemBreadcrumpProps>`
  font-weight: 500;
  color: ${({ theme, enable }) =>
    enable ? theme.palette.primary.main : theme.palette.grey['500']};
  cursor: ${({ enable }) => (enable ? `pointer` : `default`)};
`
