import HelpIconUnstyled from 'components/HelpIcon'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Card = styled.div`
  background-color: transparent;
  width: 200px;
  height: 100%;
  flex: 0 0 auto;
  border-radius: 6px;
  margin-right: 20px;
  border: 1px solid ${({ theme }) => theme.palette.border.main};
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
`

export const TextValueItem = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`

export const HelpIcon = styled(HelpIconUnstyled)`
  width: 20px;
  height: 20px;
`
