import React, { FC, memo } from 'react'

import TableHead from 'components/TableHead'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const UploadFileListHeader: FC = () => {
  return (
    <TableHead title="Histórico de arquivos">
      <TableRow>
        <TableCell>Arquivo</TableCell>
        <TableCell>Data de referência</TableCell>
        <TableCell>Data de Upload</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default memo(UploadFileListHeader)
