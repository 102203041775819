import React, { FC, memo } from 'react'

import Typography from 'components/Typography'

import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'

import { CardTableProps } from './props'
import * as Styled from './styles'

import {} from 'utils/formatInteger'

const CardTable: FC<CardTableProps> = props => {
  const { title, value } = props
  return (
    <Styled.Card>
      <CardContent>
        <Grid container spacing={1} direction="column">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Styled.CardHeader>
              <Typography variant="subtitle2">{title}</Typography>
            </Styled.CardHeader>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Styled.TextValueItem variant="h3" color="primary">
              {value}
            </Styled.TextValueItem>
          </Grid>
        </Grid>
      </CardContent>
    </Styled.Card>
  )
}

export default memo(CardTable)
