export const categoryOptions = [
  { name: '', id: '_defaultcategory' },
  { name: 'Perca', id: 'Leaves_PERCA', labelName: 'loss' },
  { name: 'Roubo', id: 'Leaves_Roubo', labelName: 'theft' },
  {
    name: 'Problema de Rede',
    id: 'Leaves_Problema_de_Rede',
    labelName: 'network_problem',
  },
  {
    name: 'Solicitado pela Vivo',
    id: 'Leaves_Solicitado_Pela_Vivo',
    labelName: 'requested_by_vivo',
  },
  {
    name: 'Ação',
    id: 'Leaves_Action',
    labelName: '_actions',
  },
]

export const actionOptions = [
  { name: '', id: '_defaultAction' },
  { name: 'Produto', id: 'Leaves_Produto', labelName: 'product' },
  {
    name: 'Setor Comercial',
    id: 'Leaves_Setor_Comercial',
    labelName: 'commercial_sector',
  },
  {
    name: 'Marketing',
    id: 'Leaves_Marketing',
    labelName: 'marketing',
  },
]
