import BaseCard from 'components/Card'
import styled from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

export const Card = styled(BaseCard)`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const Header = styled.header`
  background-color: ${({ theme }) => theme.palette.success.light};
  color: ${({ theme }) => theme.palette.common.white};
`

export const Grid = styled(MUGrid)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const ViewList = styled.a`
  text-decoration: underline;
`
