import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'

import { FieldProps, useFormikContext } from 'formik'

import { DataFormAddTransferType } from '../../context/props'
import * as Styled from './styles'
import { useOptions } from './useOptions'

const AutocompleteRecipient: FC<FieldProps> = props => {
  const { field, form } = props
  const { name } = field
  const { setFieldValue, getFieldMeta } = form

  const { values } = useFormikContext<DataFormAddTransferType>()

  const { getOptions, options, status } = useOptions()

  const [inputValue, setInputValue] = useState('')

  useDebounce(
    () => {
      getOptions(values.recipient.type, inputValue)
    },
    1000,
    [inputValue, values.recipient.type],
  )

  useEffect(() => {
    setFieldValue(name, '')
    setInputValue('')
  }, [name, setFieldValue, values.recipient.type])

  const handleChoosedOption = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      if (value !== null) {
        setFieldValue(name, value.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name],
  )

  const getOptionLabel = useCallback(
    (option: any) => {
      switch (values.recipient.type) {
        case 'stock':
          return option.name ? option.name : ''
        case 'distributor':
          return option.user?.name ? option.user.name : ''
        case 'company':
          return option.fantasy_name ? option.fantasy_name : ''
        default:
          return ''
      }
    },
    [values.recipient.type],
  )

  const getOptionSelected = useCallback((option: any) => option.id, [])

  const onInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue)
  }, [])

  const meta = useMemo(() => getFieldMeta(name), [getFieldMeta, name])

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }

    return {}
  }, [meta.error, meta.initialError, meta.touched])

  const LoadingIcon = useMemo(() => status.loading && <Styled.LoadingIcon />, [
    status.loading,
  ])

  return (
    <Styled.Autocomplete
      debug
      id="combo-box-recipient"
      options={options}
      getOptionLabel={getOptionLabel}
      noOptionsText="não encontrado"
      onChange={handleChoosedOption}
      loading={status.loading}
      onInputChange={onInputChange}
      inputValue={inputValue}
      clearOnEscape
      autoComplete
      getOptionSelected={getOptionSelected}
      renderInput={params => (
        <Styled.TextField
          {...params}
          {...errorProps}
          label="Destino"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: LoadingIcon,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  )
}

export default AutocompleteRecipient
