import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'
import isMultiple from 'utils/isMultiple'
import validateICCID from 'utils/validateICCID'

import { NewSaleActions, NewSaleContextData, CreateSaleData } from './props'
import stocksReducer, { initialState } from './reducer'

const initialValue = {} as NewSaleContextData

export const NewSaleContext = createContext(initialValue)

export const NewSaleProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(stocksReducer, initialState)
  const { addToast } = useToast()
  const history = useHistory()

  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [totalDiscountPrice, setTotalDiscountPrice] = useState<number>(0)
  const [finalTotalPrice, setFinalTotalPrice] = useState<number>(0)

  const [representativeFieldLoading, setRepresentativeFieldLoading] = useState(
    false,
  )
  const [representativeFieldText, setRepresentativeFieldText] = useState('')

  const representativeField = {
    field: representativeFieldText,
    setField: setRepresentativeFieldText,
    loading: representativeFieldLoading,
    setLoading: setRepresentativeFieldLoading,
  }

  const isValidQuantityChips = useMemo(() => {
    return isMultiple.five(
      state.intervalsChips.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
      ),
    )
  }, [state.intervalsChips])

  const createSale = useCallback(
    async (sale: CreateSaleData) => {
      try {
        if (!sale) {
          throw Error('Sale data cannot be empty')
        }

        const bought_chips = state.intervalsChips.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0,
        )

        if (state.bonus == 0) {
          delete sale.discount_quantity
        } else if (state.bonus > bought_chips) {
          sale.discount_quantity = state.bonus - (state.bonus - bought_chips)
        } else {
          sale.discount_quantity = state.bonus
        }

        dispatch({ type: NewSaleActions.Request })
        api.defaults.timeout = 100000
        const response = await api.post(`/sales`, sale).catch(error => {
          throw error
        })

        dispatch({
          type: NewSaleActions.RequestSuccess,
          payload: response,
        })

        history.push(
          RoutingService.getRouteString(
            RoutingService.SALE_DETAILS,
            'saleId',
            response.data.id,
          ),
        )

        addToast({
          type: 'success',
          title: 'Venda adicionada!',
        })
      } catch (error) {
        dispatch({ type: NewSaleActions.RequestError })
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
      }
    },

    [addToast, history, state.bonus, state.intervalsChips],
  )

  const checkBonus = useCallback(async (id_company: string) => {
    try {
      dispatch({ type: NewSaleActions.RequestBonus })

      const response = await api.get(`/companies/${id_company}/bonus`)

      dispatch({
        type: NewSaleActions.RequestSuccessBonus,
        payload: response,
      })
    } catch (e) {
      dispatch({ type: NewSaleActions.RequestErrorBonus })
    }
  }, [])

  const calculateTotalPrice = useCallback(
    async (pricePerChip: number) => {
      const quantityChips = state.intervalsChips.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
      )
      let priceDiscount = pricePerChip * state.bonus
      const price = pricePerChip * quantityChips

      // Regra para a qtd de desconto não ser maior do que o total de chips
      if (quantityChips < state.bonus) {
        priceDiscount -= priceDiscount - price
      }

      setTotalPrice(price)
      setFinalTotalPrice(price - priceDiscount)
      setTotalDiscountPrice(pricePerChip * state.bonus)
    },
    [state.bonus, state.intervalsChips],
  )

  const resetState = useCallback(
    () => dispatch({ type: NewSaleActions.Reset }),
    [],
  )

  const updateIntervals = useCallback(
    (index: number, value: number) =>
      dispatch({
        type: NewSaleActions.RequestSuccessContChips,
        payload: { index, value },
      }),
    [],
  )

  return (
    <NewSaleContext.Provider
      value={{
        ...state,
        isValidQuantityChips,
        totalPrice,
        finalTotalPrice,
        totalDiscountPrice,
        representativeField,
        createSale,
        calculateTotalPrice,
        checkBonus,
        resetState,
        updateIntervals,
      }}
    >
      {children}
    </NewSaleContext.Provider>
  )
}

export function useNewSale(): NewSaleContextData {
  const context = useContext(NewSaleContext)

  if (!context || context === initialValue) {
    throw new Error('useNewSale must be used within a NewSaleProvider')
  }

  return context
}

export function useQuantityChips(
  first_iccid: string,
  last_iccid: string,
  index: number,
) {
  const [status, setStatus] = useState({
    error: false,
    loading: false,
  })

  const [quantityChips, setQuantityChips] = useState(0)
  const { updateIntervals } = useNewSale()

  const countICCIDS = useCallback(
    async (first_iccid: string, last_iccid: string, index: number) => {
      try {
        setStatus({ error: false, loading: true })

        const response = await api.get(`/chips/iccids`, {
          params: {
            first_iccid,
            last_iccid,
          },
        })

        setStatus({ error: false, loading: false })
        updateIntervals(index, response.data.iccids.length)
        setQuantityChips(response.data.iccids.length)
      } catch (error) {
        setStatus({ error: true, loading: false })
      }
    },
    [updateIntervals],
  )

  useEffect(() => {
    if (validateICCID([first_iccid, last_iccid])) {
      countICCIDS(first_iccid, last_iccid, index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first_iccid, last_iccid])

  return { status, quantityChips }
}
