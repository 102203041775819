import React, { FC } from 'react'

import Typography from 'components/Typography'

import BadgeProps from './props'
import Container from './styles'

const Badge: FC<BadgeProps> = props => {
  const { colorVariation, children } = props

  return (
    <Container colorVariation={colorVariation}>
      <Typography>{children}</Typography>
    </Container>
  )
}

export default Badge
