import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { CreatedAndUpdatedDateType } from 'interfaces/common'

export type RequestStatus = {
  loading: boolean
  error: boolean
}

interface RequestResponseBonus {
  total_bonus: number
}

interface RequestResponseCountChips {
  index: number
  value: number
}

export interface RequestResponseSaleCreated extends CreatedAndUpdatedDateType {
  chips: string[]
  company: any
  company_id: string
  discount_quantity: number
  distributor: any
  distributor_id: string
  id: string
  order_number: number
  status: string
  total_price: number
  unit_chip_price: number | undefined
}

export enum NewSaleActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',

  RequestCountChips = 'REQUEST_COUNT_CHIPS',
  RequestSuccessContChips = 'REQUEST_SUCCESS_COUNT_CHIPS',
  RequestErrorCountChips = 'REQUEST_ERROR_COUNT_CHIPS',

  RequestBonus = 'REQUEST_BONUS',
  RequestSuccessBonus = 'REQUEST_SUCCESS_BONUS',
  RequestErrorBonus = 'REQUEST_ERROR_BONUS',

  Reset = 'RESET',
  ResetQuantityChips = 'RESET_QUANTITY_CHIPS',
}

export type NewSaleState = {
  requestStatus: RequestStatus
  requestCountChipsStatus: RequestStatus
  requestBonusStatus: RequestStatus
  sale: RequestResponseSaleCreated
  bonus: number
  intervalsChips: number[]
}

export type NewSalePayload = {
  [NewSaleActions.Request]: undefined
  [NewSaleActions.RequestSuccess]: AxiosResponse<RequestResponseSaleCreated>
  [NewSaleActions.RequestError]: undefined

  [NewSaleActions.RequestCountChips]: undefined
  [NewSaleActions.RequestSuccessContChips]: RequestResponseCountChips

  [NewSaleActions.RequestErrorCountChips]: undefined

  [NewSaleActions.RequestBonus]: undefined
  [NewSaleActions.RequestSuccessBonus]: AxiosResponse<RequestResponseBonus>
  [NewSaleActions.RequestErrorBonus]: undefined

  [NewSaleActions.Reset]: undefined
  [NewSaleActions.ResetQuantityChips]: undefined
}

export type NewSaleReducerActionMap = ActionMapData<NewSalePayload>

export type CreateSaleData = {
  iccids_intervals: Array<{ first_iccid: string; last_iccid: string }>
  company_id: string
  unit_chip_price: number | undefined
  discount_quantity?: number
  comments: string
}

interface RepresentativeFieldType {
  field: string
  setField: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export type NewSaleContextData = NewSaleState & {
  /**
   * Valor total da venda.
   */
  totalPrice: number

  /**
   * Valor final da venda apos aplicar a bonificação caso tenha.
   */
  finalTotalPrice: number

  /**
   * Valor total do desconto.
   */
  totalDiscountPrice: number

  /**
   * Define Quantidade de chips é válida ou seja multiplo de cinco.
   */
  isValidQuantityChips: boolean

  /**
   * Define estado de carregamento do Input de representante de empresa no formulário
   */
  representativeField: RepresentativeFieldType

  /**
   * Cria uma venda com os dados do formulário
   */
  createSale(sale: CreateSaleData): Promise<void>

  /**
   * @param id_company
   * Checa se existe valor de bonus para a empresa
   */
  checkBonus(id_company): Promise<void>

  /**
   * @param first_iccid
   * @param final_iccid
   * Conta quantos chips existe entre o intervalo inicial e final
   */

  /**
   * @param pricePerChip
   * Calcula o valor total a ser pago na venda
   */
  calculateTotalPrice(price: number): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void

  updateIntervals(index: number, value: number): void
}
