import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import { UserState, UserReducerActionMap, EditProfileActions } from './props'

const initialState: UserState = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
}

const editProfileReducer: Reducer<UserState, UserReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case EditProfileActions.Reset:
      return initialState

    case EditProfileActions.Request:
      state.requestStatus.loading = true
      state.requestStatus.error = false
      state.requestStatus.success = false
      return state

    case EditProfileActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.requestStatus.success = false
      return state

    case EditProfileActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.requestStatus.success = true
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(editProfileReducer)
