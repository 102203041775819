import React, { FC } from 'react'

import TableHead from 'components/TableHead'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
const ChangeChipsHeader: FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Vendedor</TableCell>
        <TableCell>Data da troca</TableCell>
        <TableCell>Categoria</TableCell>
        <TableCell>ICCID Antigo</TableCell>
        <TableCell>ICCID Novo</TableCell>
        <TableCell>Nome</TableCell>
        <TableCell>CPF</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default ChangeChipsHeader
