import React, { FC, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import RoutingService from 'services/routing'

import TableCell from '@material-ui/core/TableCell'

import { UsersListItemProps } from './props'
import * as Styled from './styles'

const UsersListItem: FC<UsersListItemProps> = props => {
  const { email, name, id, active, products } = props

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(RoutingService.USER_DETAILS, 'userId', id),
    [id],
  )

  const statusText = useMemo(() => (active ? 'Ativo' : 'Inativo'), [active])

  const product = useMemo(() => products?.map(e => e.name).join(','), [
    products,
  ])

  return (
    <TableRowItem>
      <TableCell>{name}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{product}</TableCell>
      <TableCell>{statusText}</TableCell>

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.Button color="primary" variant="outlined">
            Detalhes
          </Styled.Button>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(UsersListItem)
