import styled from 'styled-components'

import { InputLabel, Select } from '@material-ui/core'

export const SelectValue = styled(Select)`
  width: 350px;
  height: 42px;
  margin-top: 8px;
  margin-bottom: 15px;
  margin-right: 10px;
`
export const Label = styled(InputLabel)`
  font-size: 17px;
`
