import React, { FC, memo } from 'react'

import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'

import * as Styled from './styles'

const HelpIcon: FC<Partial<TooltipProps>> = props => {
  const { title = '', placement = 'bottom', ...rest } = props

  return (
    <Tooltip title={title} placement={placement} {...rest}>
      <Styled.HelpIcon color="disabled" />
    </Tooltip>
  )
}

export default memo(HelpIcon)
