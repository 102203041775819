import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import Route from 'components/Route'
import Typography from 'components/Typography'
import { useAuth } from 'hooks/auth'
import routes from 'services/routing'

import { Container } from './styles'

const NotFound: FC = () => {
  const { isAuthenticated } = useAuth()

  const route = useMemo(
    () => (isAuthenticated ? routes.PRODUCTS : routes.LOGIN),
    [isAuthenticated],
  )

  return (
    <Route title="Página não encontrada" access="none">
      <Container>
        <Typography
          color="primary"
          variant="h2"
          data-testid="message-not-found"
        >
          Página não encontrada
        </Typography>

        <Link to={route}>
          <Button
            color="primary"
            variant="outlined"
            data-testid="button-back-app"
            size="large"
          >
            Voltar ao Início
          </Button>
        </Link>
      </Container>
    </Route>
  )
}

export default NotFound
