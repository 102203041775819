import React, { useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import { Commission } from 'interfaces/commission'
import RoutingService from 'services/routing'
import customFormatPrice from 'utils/formatPrice'
import { getMonth } from 'utils/getMonth'

import { TableCell } from '@material-ui/core'

import * as Styled from './styles'

const CommissionListItem: React.FC<Commission> = props => {
  const { month, year, total_value, company_id, attachments } = props

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.COMMISSIONS_DETAILS,
        ['companyId', 'date'],
        [company_id, `${year}-${month}`],
      ),
    [company_id, month, year],
  )

  const formatAmount = useMemo(
    () => customFormatPrice({ price: total_value }),
    [total_value],
  )

  const dateFormatted = useMemo(() => {
    const monthCommission = getMonth(month)

    return `${monthCommission} / ${year}`
  }, [month, year])

  const openFileOne = useCallback(() => {
    window.open(attachments[0])
  }, [attachments])

  const openFileTwo = useCallback(() => {
    window.open(attachments[1])
  }, [attachments])

  return (
    <TableRowItem>
      <TableCell>{dateFormatted}</TableCell>
      <TableCell>{formatAmount}</TableCell>

      {attachments?.length >= 2 ? (
        <>
          <TableCell align="right">
            <Styled.ButtonOpenAnnex
              color="primary"
              variant="outlined"
              type="button"
              onClick={openFileOne}
            >
              Exibir anexo 1
            </Styled.ButtonOpenAnnex>
          </TableCell>

          <TableCell>
            <Styled.ButtonOpenAnnex
              color="primary"
              variant="outlined"
              type="button"
              onClick={openFileTwo}
            >
              Exibir anexo 2
            </Styled.ButtonOpenAnnex>
          </TableCell>
        </>
      ) : (
        <></>
      )}

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.ButtonDetails
            color="primary"
            variant="outlined"
            data-cy="detail-company__button"
          >
            Detalhes
          </Styled.ButtonDetails>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default CommissionListItem
