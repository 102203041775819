import styled from 'styled-components'

import MUButton from '@material-ui/core/Button'

export const Container = styled(MUButton)`
  border-radius: 6px;
  padding-right: 32px;
  padding-left: 32px;
  text-transform: none;
  letter-spacing: 0.5px;
  height: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`
