import React, { FC, memo } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import AppRechargesPeriod from 'pages/AppRechargesPeriod'
import FirstAccess from 'pages/AuthPages/FirstAccess'
import Login from 'pages/AuthPages/Login'
import RecoverPassword from 'pages/AuthPages/RecoverPassword'
import SetNewPassword from 'pages/AuthPages/SetNewPassword'
import UnauthorizedRole from 'pages/AuthPages/UnauthorizedRole'
import CashFlow from 'pages/CashFlow'
import ChangeChips from 'pages/ChangesChipCashFlow'
import ChipLeaves from 'pages/ChipLeavesCashflow'
import Bonus from 'pages/CommissionPages/Bonus'
import CommissionDetails from 'pages/CommissionPages/CommissionDetails/CommissionDetails'
import Commissions from 'pages/CommissionPages/Commissions'
import Companies from 'pages/CompanyPages/Companies'
import CompanyDetails from 'pages/CompanyPages/CompanyDetails'
import EditCompanyRepresentative from 'pages/CompanyPages/EditCompanyRepresentative'
import NewCompany from 'pages/CompanyPages/NewCompany'
import ReportOfCommissions from 'pages/CompanyPages/ReportOfCommissions'
import UpdateCompany from 'pages/CompanyPages/UpdateCompany'
import DistributorDetail from 'pages/DistributorsPages/DistributorDetails'
import Distributors from 'pages/DistributorsPages/Distributors'
import EditDistributor from 'pages/DistributorsPages/EditDistributor'
import NewDistributor from 'pages/DistributorsPages/NewDistributor'
import EditProfile from 'pages/EditProfile'
import GenerateCommission from 'pages/GenerateCommission'
import Leaves from 'pages/LeavesCashflow'
import NewSalesCashFlow from 'pages/NewSalesCashFlow'
import NotFound from 'pages/NotFound'
import Product from 'pages/Product'
import Products from 'pages/Products'
import Recharges from 'pages/Recharges'
import BuildReport from 'pages/ReportPages/BuildReport'
import ConsolidatedReport from 'pages/ReportPages/ConsolidatedReport'
import Reports from 'pages/ReportsCashFlow'
import NewSale from 'pages/SalesPages/NewSale'
import SaleDetails from 'pages/SalesPages/SaleDetails'
import Sales from 'pages/SalesPages/Sales'
import EditStock from 'pages/StockPages/EditStock'
import MyStock from 'pages/StockPages/MyStock'
import NewStock from 'pages/StockPages/NewStock'
import StockDetails from 'pages/StockPages/StockDetails'
import Stocks from 'pages/StockPages/Stocks'
import NewTransfer from 'pages/TransferPages/NewTransfer'
import TransferDetails from 'pages/TransferPages/TransferDetails'
import Transfers from 'pages/TransferPages/Transfers'
import UploadFileVivo from 'pages/UploadFileVivo'
import EditUser from 'pages/UserPages/EditUser'
import NewUser from 'pages/UserPages/NewUser'
import UserDetails from 'pages/UserPages/UserDetails'
import Users from 'pages/UserPages/Users'
import NewUsersCashflow from 'pages/UsersCashFlow/pages/NewUserCashFlow'
import Validation from 'pages/ValidationReports'
import ValidationDetails from 'pages/ValidationReports/components/Details'
import EditSell from 'pages/ValidationReports/components/EditSale'
import Wallet from 'pages/Wallet'
import RoutingService from 'services/routing'

const Routes: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={RoutingService.LOGIN} exact component={Login} />
        <Route
          path={RoutingService.RECOVER_PASSWORD}
          exact
          component={RecoverPassword}
        />
        <Route
          path={RoutingService.FIRST_ACCESS}
          exact
          component={FirstAccess}
        />
        <Route
          path={RoutingService.UNAUTHORIZED_ACCESS}
          exact
          component={UnauthorizedRole}
        />
        <Route
          path={RoutingService.SET_NEW_PASSWORD}
          exact
          component={SetNewPassword}
        />
        <Route path={RoutingService.EDIT_PROFILE} component={EditProfile} />
        <Route path={RoutingService.PRODUCTS} exact component={Products} />
        <Route path={RoutingService.BRISAMOVEL} exact component={Product} />
        <Route path={RoutingService.SALES} exact component={Sales} />
        <Route path={RoutingService.NEW_SALE} component={NewSale} />
        <Route path={RoutingService.CASH_FLOW} component={CashFlow} />
        <Route path={RoutingService.WALLET} component={Wallet} />

        <Route
          path={RoutingService.NEW_USER_CASHFLOW}
          component={NewUsersCashflow}
        />
        <Route path={RoutingService.LEAVES_CASHFLOW} component={Leaves} />
        <Route
          path={RoutingService.REPORTS_CHANGES_CASHFLOW}
          component={ChangeChips}
        />
        <Route
          path={RoutingService.REPORTS_CASHFLOW}
          exact
          component={Reports}
        />
        <Route
          path={RoutingService.REPORTS_CHIPLEAVES_CASHFLOW}
          component={ChipLeaves}
        />
        <Route
          path={RoutingService.REPORTS_VALIDATION_CASHFLOW}
          component={Validation}
        />
        <Route
          path={RoutingService.REPORTS_DETAILS_VALIDATION}
          component={ValidationDetails}
        />
        <Route path={RoutingService.EDIT_SELL} component={EditSell} />
        <Route
          path={RoutingService.NEW_SALE_CASHFLOW}
          exact
          component={NewSalesCashFlow}
        />
        <Route path={RoutingService.SALE_DETAILS} component={SaleDetails} />
        <Route path={RoutingService.COMPANIES} exact component={Companies} />
        <Route path={RoutingService.NEW_COMPANY} component={NewCompany} />
        <Route
          path={RoutingService.COMPANY_REPRESENTATIVE_EDIT}
          component={EditCompanyRepresentative}
        />

        <Route
          path={RoutingService.COMPANY_REPRESENTATIVE_REPORTS}
          component={BuildReport}
        />
        <Route
          path={RoutingService.REPORT_OF_COMMISSIONS}
          component={ReportOfCommissions}
        />
        <Route path={RoutingService.BUILD_REPORT} component={BuildReport} />
        <Route path={RoutingService.COMMISSIONS} component={Commissions} />
        <Route
          path={RoutingService.COMMISSIONS_DETAILS}
          component={CommissionDetails}
        />

        <Route path={RoutingService.BONUS} component={Bonus} />
        <Route
          path={RoutingService.CONSOLIDATED_REPORTS}
          component={ConsolidatedReport}
        />
        <Route path={RoutingService.COMPANY_UPDATE} component={UpdateCompany} />
        <Route
          path={RoutingService.COMPANY_DETAILS}
          component={CompanyDetails}
        />
        <Route
          path={RoutingService.COMMISSIONS_COMPANY_REPRESENTATIVE}
          component={Commissions}
        />
        <Route
          path={RoutingService.COMMISSIONS_COMPANY_REPRESENTATIVE_DETAILS}
          component={CommissionDetails}
        />
        <Route
          path={RoutingService.UPLOAD_FILE_VIVO}
          component={UploadFileVivo}
        />
        <Route
          path={RoutingService.GENERATE_COMMISSION}
          component={GenerateCommission}
        />
        <Route path={RoutingService.USERS} exact component={Users} />
        <Route path={RoutingService.NEW_USER} component={NewUser} />
        <Route path={RoutingService.EDIT_USER} component={EditUser} />
        <Route path={RoutingService.USER_DETAILS} component={UserDetails} />
        <Route path={RoutingService.STOCKS} exact component={Stocks} />
        <Route path={RoutingService.NEW_STOCK} component={NewStock} />
        <Route path={RoutingService.EDIT_STOCK} component={EditStock} />
        <Route path={RoutingService.STOCK_DETAILS} component={StockDetails} />
        <Route path={RoutingService.MY_STOCK} component={MyStock} />
        <Route path={RoutingService.TRANSFERS} exact component={Transfers} />
        <Route path={RoutingService.NEW_TRANSFER} component={NewTransfer} />
        <Route
          path={RoutingService.TRANSFER_DETAILS}
          component={TransferDetails}
        />
        <Route
          path={RoutingService.DISTRIBUTORS}
          exact
          component={Distributors}
        />
        <Route
          path={RoutingService.NEW_DISTRIBUTOR}
          component={NewDistributor}
        />
        <Route
          path={RoutingService.EDIT_DISTRIBUTOR}
          component={EditDistributor}
        />
        <Route
          path={RoutingService.DISTRIBUTOR_DETAILS}
          exact
          component={DistributorDetail}
        />
        <Route
          path={RoutingService.APP_RECHARGES}
          exact
          component={Recharges}
        />

        <Route
          path={RoutingService.APP_RECHARGES_DATE_PERIOD_REPORT}
          exact
          component={AppRechargesPeriod}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default memo(Routes)
