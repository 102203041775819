import React, { FC, memo, useMemo, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { ButtonBase } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'

import NotificationIndicator from '../NotificationIndicator'
import UserIndicator from '../UserIndicator'
import { HeaderProps } from './props'
import * as Styled from './styles'

const Header: FC<HeaderProps> = props => {
  const { toggleBar, crumbs } = props

  const crumbList = useMemo(
    () =>
      crumbs.map((crumb, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Link key={`crumb_${index}`} color="inherit" to={crumb.link || '#'}>
          {crumb.title}
        </Link>
      )),
    [crumbs],
  )

  const breadcrumbs = useMemo(
    () => (
      <Styled.Breadcrumbs separator="›" aria-label="breadcrumb">
        {crumbList}
      </Styled.Breadcrumbs>
    ),
    [crumbList],
  )

  return (
    <>
      <Styled.Container maxWidth={false}>
        <Styled.Main>
          <Hidden mdUp implementation="css">
            <Styled.LogoMobile>
              <ButtonBase onClick={toggleBar}>
                <img
                  src={require('assets/images/icons/toggle-bar.svg').default}
                  draggable={false}
                />
              </ButtonBase>

              <Styled.Title>CRM Produtos</Styled.Title>
            </Styled.LogoMobile>
          </Hidden>

          <Hidden smDown implementation="css">
            {breadcrumbs}
          </Hidden>

          <Styled.LeftContainer>
            <NotificationIndicator />
            <UserIndicator />
          </Styled.LeftContainer>
        </Styled.Main>
        <Styled.Hr />
      </Styled.Container>

      <Styled.ContainerBreadcrumbsMobile>
        <Hidden mdUp implementation="css">
          {breadcrumbs}
        </Hidden>
      </Styled.ContainerBreadcrumbsMobile>
    </>
  )
}

export default memo(Header)
