import React, { FC, useMemo, memo } from 'react'

import { useFormikContext } from 'formik'

import { ButtonSubmitProps } from './props'
import * as Styled from './styles'

const ButtonAddUser: FC<ButtonSubmitProps> = props => {
  const { loading } = props

  const { isValid } = useFormikContext()

  const renderContent = useMemo(() => (loading ? 'Carregando' : 'Atualizar'), [
    loading,
  ])

  return (
    <Styled.ButtonAddUser
      variant="contained"
      autoCapitalize=""
      type="submit"
      disabled={!isValid || loading}
    >
      {renderContent}
    </Styled.ButtonAddUser>
  )
}

export default memo(ButtonAddUser)
