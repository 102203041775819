import React, { useRef, useEffect } from 'react'

import {
  Formik as BaseFormik,
  Form as BaseForm,
  FormikProps,
  FormikConfig,
} from 'formik'

function Formik<Values>(props: FormikConfig<Values>) {
  const { children, ...rest } = props
  const formRef = useRef<FormikProps<Values> | null>(null)

  useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm()
    }
  }, [])

  return (
    <BaseFormik innerRef={formRef} {...rest}>
      <BaseForm>{children}</BaseForm>
    </BaseFormik>
  )
}

// Workaround for: https://github.com/formium/formik/issues/2652
export default Formik
