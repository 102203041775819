import React, { FC } from 'react'

import TableHead from 'components/TableHead'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
const ChipsLeavesHeader: FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Vendedor</TableCell>
        <TableCell>Data</TableCell>
        <TableCell>Categoria</TableCell>
        <TableCell>ICCID</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default ChipsLeavesHeader
