import React, { FC, memo } from 'react'

import { useModal } from '../Dialog/context'
import * as Styled from './styles'

const PaperComponent: FC = props => {
  const { children, ...rest } = props

  const { openModal } = useModal()

  return (
    <Styled.ContainerOptions {...rest}>
      {children}
      <Styled.ContainerNewCompanyOption onPointerDown={openModal}>
        <Styled.NewCompanyOption color="primary">
          Cadastre um novo revendedor
        </Styled.NewCompanyOption>
      </Styled.ContainerNewCompanyOption>
    </Styled.ContainerOptions>
  )
}

export default memo(PaperComponent)
