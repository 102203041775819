import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import { NewUserState, NewUserReducerActionMap, NewUserActions } from './props'

const initialState: NewUserState = {
  requestStatus: {
    error: false,
    loading: false,
  },
  user: null,
}

const newUserReducer: Reducer<NewUserState, NewUserReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case NewUserActions.Reset:
      return initialState

    case NewUserActions.RequestNewUser:
      state.requestStatus.loading = true
      state.requestStatus.error = false
      return state

    case NewUserActions.RequestSuccessNewUser:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.user = action.payload.data
      return state

    case NewUserActions.RequestErrorNewUser:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.user = null
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(newUserReducer)
