import React, { FC, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import RoutingService from 'services/routing'

import TableCell from '@material-ui/core/TableCell'

import { StockListItemProps } from './props'
import * as Styled from './styles'

const StockListItem: FC<StockListItemProps> = props => {
  const { id, name, active } = props

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.STOCK_DETAILS,
        'stockId',
        id,
      ),
    [id],
  )

  const status = useMemo(() => (active ? 'Ativo' : 'Inativo'), [active])

  return (
    <TableRowItem>
      <TableCell>{name}</TableCell>
      <TableCell>{status}</TableCell>

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.Button
            color="primary"
            variant="outlined"
            data-cy="detail-stock__button"
          >
            Detalhes
          </Styled.Button>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(StockListItem)
