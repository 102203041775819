import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useState,
  useReducer,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'
import Document from 'utils/document'

import {
  FormCompanyContextData,
  DataFormCompanyType,
  TypeFormCompany,
  FormCompanyActions,
} from './props'
import formAddCompanyReducer, { initialState } from './reducer'

const initialValue = {} as FormCompanyContextData

export const FormCompanyContext = createContext(initialValue)

export const FormCompanyProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(formAddCompanyReducer, initialState)
  const { push } = useHistory()
  const { addToast } = useToast()

  const { companyId } = useParams<any>()

  const [actualStepForm, setActualStepForm] = useState(0)
  const [typeForm, setTypeForm] = useState<TypeFormCompany>('register')
  const [cityAndState, setCityAndState] = useState({
    city: '',
    state: '',
  })

  const [dataFormCompany, setDataFormCompany] = useState<DataFormCompanyType>({
    type: 'partner',
    document: '',
    fantasy_name: '',
    corporate_name: undefined,
    commission_model: '',
    phone: '',
    email: '',
    latitude: '',
    longitude: '',
    new_representative: undefined,
    city_id: '',
    district: '',
    number: undefined,
    street: '',
    representative: {
      email: '',
      name: '',
      phone: '',
    },
  })

  const addCompany = useCallback(async () => {
    try {
      dispatch({ type: FormCompanyActions.RequestAddCompany })

      delete dataFormCompany.commission_model

      dataFormCompany.document = Document.removeMask(dataFormCompany.document)
      delete dataFormCompany.state_id

      dataFormCompany.phone = Document.removeMask(dataFormCompany.phone)

      dataFormCompany.representative.phone = Document.removeMask(
        dataFormCompany.representative.phone,
      )

      const response = await api
        .post('/companies', dataFormCompany)
        .catch(error => {
          throw error
        })

      dispatch({
        type: FormCompanyActions.RequestSuccessAddCompany,
        payload: response,
      })

      addToast({
        type: 'success',
        title: 'Revendedor adicionado!',
      })
    } catch (error) {
      dispatch({
        type: FormCompanyActions.RequestErrorAddCompany,
      })

      addToast({
        type: 'error',
        title: ErrorHandler.getMessage(error),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFormCompany])

  const updateCompany = useCallback(async () => {
    try {
      dispatch({
        type: FormCompanyActions.RequestUpdateCompany,
      })

      dataFormCompany.document = Document.removeMask(dataFormCompany.document)
      delete dataFormCompany.state_id

      dataFormCompany.phone = Document.removeMask(dataFormCompany.phone)

      dataFormCompany.representative.phone = Document.removeMask(
        dataFormCompany.representative.phone,
      )

      dataFormCompany.commission_model === true
        ? (dataFormCompany.commission_model = 'bonus')
        : (dataFormCompany.commission_model = 'commission')

      await api.put(`/companies/${companyId}`, dataFormCompany).catch(error => {
        throw error
      })

      dispatch({
        type: FormCompanyActions.RequestSuccessUpdateCompany,
      })

      addToast({
        type: 'success',
        title: 'Revendedor Atualizado!',
      })

      push(
        RoutingService.getRouteString(
          RoutingService.COMPANY_DETAILS,
          'companyId',
          companyId,
        ),
      )
    } catch (error) {
      dispatch({
        type: FormCompanyActions.RequestErrorUpdateCompany,
      })

      addToast({
        type: 'error',
        title: ErrorHandler.getMessage(error),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFormCompany])

  return (
    <FormCompanyContext.Provider
      value={{
        ...state,
        dataFormCompany,
        setDataFormCompany,
        actualStepForm,
        setActualStepForm,
        typeForm,
        setTypeForm,
        addCompany,
        updateCompany,
        cityAndState,
        setCityAndState,
      }}
    >
      {children}
    </FormCompanyContext.Provider>
  )
}

export function useFormCompany(): FormCompanyContextData {
  const context = useContext(FormCompanyContext)

  if (!context || context === initialValue) {
    throw new Error('useFormCompany must be used within a FormCompanyProvider')
  }

  return context
}
