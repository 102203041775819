import styled from 'styled-components'
import { mediumShadow } from 'styles/mixins/shadows'

import Typography from '@material-ui/core/Typography'

export const Card = styled.div`
  ${mediumShadow}

  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.common.white};
  flex: 0 0 auto;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(1)}px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: unset;
    flex: 1;
  }
`

export const LabelText = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  flex: 1;
`
