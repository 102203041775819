import React, { memo } from 'react'

// import { FormCompanyProvider } from 'components/FormCompany/context'

// import RoutingService from 'services/routing'
// import withContext from 'utils/withContext'

// import Grid from '@material-ui/core/Grid'

// import ChipCard from '../ChipCard'
import LineRechargePackageDischarges from '../Charts/LineRechargePackageDischarges'
import RechargePackageDischarges from '../Charts/RechargePackageDischarges'
import FormRechargePackageDischarges from './components/FormRechargePackageDischarges'
import FormTypesUsers from './components/FormTypesUsers'
import * as Styled from './styles'

const ReportRechargePackageDischarges: React.FC = () => {
  return (
    <>
      <Styled.Typography variant="h5" color="textPrimary">
        Relatórios por empresa
      </Styled.Typography>
      <Styled.Container container>
        <Styled.Container item xs={12} sm={12} md={6}>
          <FormTypesUsers />
        </Styled.Container>
        {/* <Grid container spacing={2}>
        <ChipCard title="Previsão de comissão" value={52.0} />
        <ChipCard title="Chips bonificados" value={123} />
      </Grid> */}
        {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
        <Styled.LinkCard
        label="Relatórios"
        to={RoutingService.CONSOLIDATED_REPORTS}
        />
        </Grid>
      </Grid> */}
        {/* <br /> <br /> */}

        <Styled.Container item xs={12} sm={12} md={6}>
          <FormRechargePackageDischarges />
        </Styled.Container>
      </Styled.Container>
      <LineRechargePackageDischarges />
      <RechargePackageDischarges />
      <Styled.Divider />
    </>
  )
}

export default memo(ReportRechargePackageDischarges)
