import styled from 'styled-components'

import { CardProps } from './props'

export const Card = styled.div<CardProps>`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background-color: transparent;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(3)}px
    ${({ theme }) => theme.spacing(2)}px;

  border: 1px solid ${({ theme }) => theme.palette.border.main};

  header {
    margin-top: -${({ theme }) => theme.spacing(5)}px;
    margin-left: -${({ theme }) => theme.spacing(2)}px;
    margin-right: -${({ theme }) => theme.spacing(2)}px;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
    padding-left: ${({ theme }) => theme.spacing(2)}px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: ${({ theme }) => theme.spacing(3)}px
      ${({ theme }) => theme.spacing(3)}px;

    header {
      margin-left: -${({ theme }) => theme.spacing(3)}px;
      margin-right: -${({ theme }) => theme.spacing(3)}px;
      padding-right: ${({ theme }) => theme.spacing(3)}px;
      padding-left: ${({ theme }) => theme.spacing(3)}px;
    }
  }
`
