import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import {
  DistributorDetailProvider,
  useDistributorDetail,
} from 'hooks/distributors/details'
import EditDistributorProvider from 'hooks/distributors/edit'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import EditDistributorForm from './components/EditDistributorForm'
import ErrorDetails from './components/ErrorDetails'
import * as Styled from './styles'

const EditDistributor: FC = () => {
  const { distributorId } = useParams<any>()
  const { getDistributor, requestStatus } = useDistributorDetail()

  useEffectOnce(() => {
    getDistributor(distributorId)
  })

  const Content = useCallback(() => {
    if (requestStatus.error) return <ErrorDetails />
    if (requestStatus.success) return <EditDistributorForm />
    return <Loading />
  }, [requestStatus])

  return (
    <Route
      title="Editar Distribuidor"
      access="auth"
      requireRoles={['admin', 'dev']}
    >
      <LayoutProduct
        activeOption="distributors"
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Distribuidores', link: RoutingService.DISTRIBUTORS },
          { title: 'Editar Distribuidor' },
        ]}
      >
        <PageTitle title="Editar distribuidor" />
        <Styled.Container>
          <Content />
        </Styled.Container>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(
  EditDistributor,
  DistributorDetailProvider,
  EditDistributorProvider,
)
