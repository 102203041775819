import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import ButtonSubmit from 'components/ButtonSubmit'
import Formik from 'components/Formik'
import TextField from 'components/Inputs/TextField'
import Typography from 'components/Typography'
import { useFirstAccess } from 'hooks/auth/password/FirstAccess'
import RoutingService from 'services/routing'

import schema from './schema'

const Form: FC = () => {
  const { status, firstAccess } = useFirstAccess()

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={firstAccess}
      validationSchema={schema}
    >
      <Typography color="primary" variant="h5">
        Primeiro acesso
      </Typography>

      <TextField
        data-testid="input-email"
        name="email"
        type="email"
        label="E-mail"
        placeholder="Digite seu e-mail"
      />

      <ButtonSubmit
        data-testid="button-enter"
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        disableElevation
        fullWidth
        disabled={status.loading}
      >
        Enviar email
      </ButtonSubmit>

      <Link to={RoutingService.LOGIN}>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          size="large"
          fullWidth
        >
          Voltar para login
        </Button>
      </Link>
    </Formik>
  )
}

export default memo(Form)
