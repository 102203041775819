import { Field } from 'formik'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Card = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(3)}px
    ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
`
export const TitlePanel = styled(Typography)`
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const FieldQuantity = styled(Field)`
  display: none;
`
