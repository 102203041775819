import React from 'react'

import LayoutProduct from 'components/LayoutProduct/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import { UserDetailProvider } from 'hooks/users/details'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import FormEditComapanyRepresentaive from './components/FormEditComapanyRepresentaive'
import { EditCompanyRepresentativeProvider } from './context'

const EditCompanyRepresentative: React.FC = () => {
  return (
    <Route
      title="representante"
      access="auth"
      requireRoles={['distributor', 'admin', 'dev']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          { title: 'Atualizar Representante', link: '#' },
        ]}
        activeOption="companies"
      >
        <PageTitle title="Editar representante" />

        <FormEditComapanyRepresentaive />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(
  EditCompanyRepresentative,
  EditCompanyRepresentativeProvider,
  UserDetailProvider,
)
