import React, { FC, memo, useMemo } from 'react'

import Badge from 'components/Badge'
import TableRowItem from 'components/TableRowItem'
import customFormatDate from 'utils/formatDate'
import customFormatPrice from 'utils/formatPrice'

import TableCell from '@material-ui/core/TableCell'

import { PurchasesListItemProps } from './props'

const PurchasesListItem: FC<PurchasesListItemProps> = props => {
  const { created_at, total_price, status } = props

  const statusSale = {
    paid: 'Pago',
    unpaid: 'Não pago',
    overdue: 'Vencido',
    canceled: 'Cancelado',
  }

  const colorsStatus = {
    paid: 'green',
    unpaid: 'orange',
    overdue: 'yellow',
    canceled: 'red',
  }

  const date = useMemo(() => {
    return customFormatDate({
      format: 'dd/MM/yyyy',
      date: created_at.toString(),
    })
  }, [created_at])

  const price = useMemo(() => {
    return customFormatPrice({ price: total_price })
  }, [total_price])

  return (
    <TableRowItem>
      <TableCell>{date}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>
        <Badge colorVariation={colorsStatus[status]}>
          {statusSale[status]}
        </Badge>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(PurchasesListItem)
