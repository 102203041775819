import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { api } from 'services/api'

import {
  StockDetailActions,
  StockDetailContextData,
  StockDetailResponse,
} from './props'
import stocksReducer, { initialState } from './reducer'

const initialValue = {} as StockDetailContextData

export const StockDetailContext = createContext(initialValue)

export const StockDetailProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(stocksReducer, initialState)

  const getStock = useCallback(async (id: string) => {
    try {
      if (!id) {
        throw Error('Stock ID cannot be empty')
      }

      dispatch({ type: StockDetailActions.Request })

      const response = await api.get<StockDetailResponse>(`/stocks/${id}`)

      return dispatch({
        type: StockDetailActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      return dispatch({ type: StockDetailActions.RequestError })
    }
  }, [])

  const resetState = useCallback(
    () => dispatch({ type: StockDetailActions.Reset }),
    [],
  )

  return (
    <StockDetailContext.Provider
      value={{
        ...state,
        getStock,
        resetState,
      }}
    >
      {children}
    </StockDetailContext.Provider>
  )
}

export function useStockDetail(): StockDetailContextData {
  const context = useContext(StockDetailContext)

  if (!context || context === initialValue) {
    throw new Error('useStockDetail must be used within a StockDetailProvider')
  }

  return context
}
