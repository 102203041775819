import React, { FC } from 'react'

import TableHead from 'components/TableHead'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { Box } from './styles'
const WalletListHeader: FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Box>ICCID</Box>
        </TableCell>
        <TableCell>
          <Box>Status</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default WalletListHeader
