import React, { FC } from 'react'
import ReactExport from 'react-data-export'

import { SaleCashFlow } from 'interfaces/SalesCashFlow'
import { TranslateCategorySell, TranslateStatusSell } from 'utils/Translate'

import { ModalReportsProps } from '../../props'
import { DowloadButton } from '../../styles'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export const ExcellValidation: FC<ModalReportsProps> = props => {
  const { data } = props

  const data1 = data.map((item: SaleCashFlow) => {
    return {
      ICCID: item.chip.iccid,
      price: item.price,
      typeSel: TranslateCategorySell(item.category),
      pack: item.pack,
      status: TranslateStatusSell(item.status),
      name: item.customer_name,
      cpf: item.customer_document,
      audited_pack: item.audited_pack,
    }
  })
  return (
    <ExcelFile
      element={
        <DowloadButton variant="contained" color="primary">
          Baixar
        </DowloadButton>
      }
    >
      <ExcelSheet data={data1} name="Employees">
        <ExcelColumn label="ICCID" value="ICCID" />
        <ExcelColumn label="Valor" value="price" />
        <ExcelColumn label="Tipo de venda" value="typeSel" />
        <ExcelColumn label="Pacote venda" value="pack" />
        <ExcelColumn label="Pacote auditado" value="audited_pack" />
        <ExcelColumn label="Status" value="status" />
        <ExcelColumn label="Nome" value="name" />
        <ExcelColumn label="CPF" value="cpf" />
      </ExcelSheet>
    </ExcelFile>
  )
}
