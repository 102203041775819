import styled from 'styled-components'

import MUTextField from '@material-ui/core/TextField'

import { OptionProps } from './props'

export const Container = styled.div`
  position: relative;
`

export const Loading = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 10px;
`

export const TextField = styled(MUTextField)`
  .MuiOutlinedInput-root {
    height: 40px;
    border-radius: 6px;
    height: ${({ theme }) => theme.spacing(6)}px;
  }
`
export const Options = styled.div`
  position: absolute;
  min-width: 100%;
  width: auto;
  z-index: 1000;
`
export const Icon = styled.img`
  margin-left: 5px;
  cursor: pointer;
`

export const Option = styled.div<OptionProps>`
  background-color: ${({ theme, active }) =>
    active ? theme.palette.services.brisamovel : theme.palette.common.white};
  padding: 10px;
  cursor: pointer;
`
