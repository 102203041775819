import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  CompanyState,
  CompanyReducerActionMap,
  CompanyDetailActions,
} from './props'

const initialState: CompanyState = {
  requestStatus: {
    success: false,
    error: false,
    loading: false,
  },
  company: null,
}

const CompanyDetailReducer: Reducer<CompanyState, CompanyReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case CompanyDetailActions.Reset:
      return initialState

    case CompanyDetailActions.Request:
      state.requestStatus.loading = true
      state.requestStatus.success = false
      state.requestStatus.error = false
      return state

    case CompanyDetailActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.success = false
      state.requestStatus.error = true
      state.company = null
      return state

    case CompanyDetailActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.success = true
      state.requestStatus.error = false
      state.company = action.payload.data
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(CompanyDetailReducer)
