import Typography from 'components/Typography'
import styled from 'styled-components'

import SkeletonStyles from '@material-ui/lab/Skeleton'

export const Skeleton = styled(SkeletonStyles)`
  width: 400px;
  height: 30px;

  @media (max-width: 500px) {
    width: 200px;
    height: 25px;
  }
`

export const Container = styled.div`
  background: transparent;
  border-radius: 6px;
  height: max-content;
  padding: 20px 36px 36px 20px;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.border.main};
`

export const TitleGraph = styled(Typography)`
  margin: 0px 0px 20px 20px;
`
export const Card = styled.div`
  height: max-content;
  border-radius: 6px;
  width: 100%;
  background-color: transparent;
  padding: 10px 36px 36px 20px;
  border: 1px solid ${({ theme }) => theme.palette.border.main};
`

export const ContainerTooltip = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.light};
`
export const Date = styled.p``

export const AverageValue = styled.p`
  color: #2a2e43;
  margin-top: 10px;
`
