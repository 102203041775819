import SelectProps from 'components/Inputs/Select'
import TypographyStyles from 'components/Typography'
import styled from 'styled-components'

import DividerStyles from '@material-ui/core/Divider'
import GridStyles from '@material-ui/core/Grid'

import LinkCardProps from '../LinkCard'

export const Select = styled(SelectProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const LinkCard = styled(LinkCardProps)`
  margin-left: 0px;
  margin-top: 20px;
`

export const Container = styled(GridStyles)``

export const Divider = styled(DividerStyles)`
  margin: ${({ theme }) => theme.spacing(3)}px 0
    ${({ theme }) => theme.spacing(2)}px 0;
`

export const Typography = styled(TypographyStyles)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`
