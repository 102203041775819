import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Card = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  background-color: transparent;

  border: 1px solid ${({ theme }) => theme.palette.border.main};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`

export const TextValueItem = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: 0px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.primary.main};
`
