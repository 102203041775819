import React, { FC, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Badge from 'components/Badge'
import TableRowItem from 'components/TableRowItem'
import RoutingService from 'services/routing'
import customFormatDate from 'utils/formatDate'
import customFormatPrice from 'utils/formatPrice'

import TableCell from '@material-ui/core/TableCell'

import { CompanyListItemProps } from './props'
import * as Styled from './styles'

const CompanyListItem: FC<CompanyListItemProps> = props => {
  const { id, status, total_price, created_at, wallet_recipient } = props

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(RoutingService.SALE_DETAILS, 'saleId', id),
    [id],
  )

  const translatedStatus = useMemo(() => {
    const statusSale = {
      paid: 'Pago',
      unpaid: 'Não pago',
      overdue: 'Vencido',
      canceled: 'Cancelado',
    }

    const colorsStatus = {
      paid: 'green',
      unpaid: 'orange',
      overdue: 'yellow',
      canceled: 'red',
    }
    return {
      color: colorsStatus[status],
      text: statusSale[status],
    }
  }, [status])

  const date = useMemo(
    () =>
      customFormatDate({ format: 'dd/MM/yyyy', date: created_at.toString() }),
    [created_at],
  )

  const price = useMemo(() => customFormatPrice({ price: total_price }), [
    total_price,
  ])

  const companyName = useMemo(() => {
    if (wallet_recipient.company.fantasy_name)
      return wallet_recipient.company.fantasy_name
    return '-'
  }, [wallet_recipient.company.fantasy_name])

  return (
    <TableRowItem>
      <TableCell>{date}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{companyName}</TableCell>
      <TableCell>
        <Badge colorVariation={translatedStatus.color}>
          {translatedStatus.text}
        </Badge>
      </TableCell>

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.Button color="primary" variant="outlined">
            Detalhes
          </Styled.Button>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(CompanyListItem)
