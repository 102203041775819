import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { CreatedAndUpdatedDateType, RequestStatus } from 'interfaces/common'

export interface LoginCredentials {
  email: string
  password: string
}

export type RoleName =
  | 'dev'
  | 'admin'
  | 'general_coordinator'
  | 'general_manager'
  | 'commercial_coordinator'
  | 'supervisor'
  | 'pap_leader'
  | 'pap_seller'
  | 'distributor'
  | 'stockist'
  | 'analyst'
  | 'office_stockist'
  | 'office_leader'
  | 'company_representative'
  | 'financial'
  | 'financial_collection'
  | 'attendant'
  | 'sale_leader'

export interface UserRole extends CreatedAndUpdatedDateType {
  id: string
  name: RoleName
  description: string
  translated_name: string
}

export interface User extends CreatedAndUpdatedDateType {
  id: string
  active: boolean
  name: string
  email: string
  avatar?: any
  avatar_url?: any
  role_id: string
  phone: string
  role: UserRole
  products: any[]

  // Dependem do tipo de usuário
  distributor?: Distributor
  company_representative?: CompanyRepresentative
  stockist?: Stockist
}

interface Distributor extends CreatedAndUpdatedDateType {
  id: string
  company_who_can_sell: 'partner' | 'franchisee' | 'franchisee_and_partner'
  user_id: string
}

interface CompanyRepresentative extends CreatedAndUpdatedDateType {
  id: string
  user_id: string
  company_id: string
  company: CreatedAndUpdatedDateType & {
    id: string
    active: boolean
    blocked: boolean
    type: 'partner' | 'franchisee' | 'franchisee_and_partner'
    document: string
    fantasy_name: string
    corporate_name: string
    phone: string
    email: string
    representative_id: string
  }
}

interface Stockist extends CreatedAndUpdatedDateType {
  id: string
  user_id: string
  stock_id: string
  stock: CreatedAndUpdatedDateType & {
    id: string
    active: boolean
    name: string
    stockist_id: string
  }
}

export type Token = {
  expiresAt: string
  token: string
}

export type ProfileResponse = User

export type AuthResponse = {
  expiresAt: string
  token: string
  user: User
}

export interface AuthState {
  token: Token | null
  user: User | null
  isAuthenticating: boolean
  requestProfileStatus: RequestStatus
}

export enum AuthActions {
  RequestUser = 'REQUEST_USER',
  RequestUserSuccess = 'REQUEST_USER_SUCCESS',
  RequestUserError = 'REQUEST_USER_ERROR',
  RequestProfile = 'REQUEST_PROFILE',
  RequestProfileSuccess = 'REQUEST_PROFILE_SUCCESS',
  RequestProfileError = 'REQUEST_PROFILE_ERROR',
  Reset = 'RESET',
  SignOut = 'SIGN_OUT',
}

export type AuthPayload = {
  [AuthActions.RequestUser]: undefined
  [AuthActions.RequestUserSuccess]: AxiosResponse<AuthResponse>
  [AuthActions.RequestUserError]: undefined
  [AuthActions.RequestProfile]: undefined
  [AuthActions.RequestProfileSuccess]: AxiosResponse<ProfileResponse>
  [AuthActions.RequestProfileError]: undefined
  [AuthActions.Reset]: undefined
  [AuthActions.SignOut]: undefined
}

export type AuthReducerActionMap = ActionMapData<AuthPayload>

export type AuthContextProviderProps = {
  initialProps?: AuthState
}

export type AuthContextData = AuthState & {
  isAuthenticated: boolean

  /**
   * Verifica se o usuário logado possui a(s) Role(s)
   * @param roleName nome da Role
   */
  hasRole(roleName: RoleName | RoleName[]): boolean
  signIn(credentials: LoginCredentials): Promise<void>
  signOut(): void
  getProfile(): Promise<void>
}
