import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import { ptBR } from '@material-ui/core/locale'

const materialTheme = createMuiTheme(
  {
    typography: {
      fontFamily: ['Barlow', 'Poppins', 'sans-serif'].join(','),
      h3: {
        fontWeight: 500,
      },
      h5: {
        fontWeight: 500,
      },
    },
    palette: {
      services: {
        brisamovel: '#8CC83C',
      },
      border: {
        main: '#adb5bd',
      },
      background: {
        default: '#FFFFFF',
      },
      primary: {
        main: '#083D93',
        contrastText: '#FFFFFF',
        light: '#083D93',
      },
      secondary: {
        contrastText: '#FFFFFF',
        main: '#F56E28',
        light: '#A8A8A8',
      },
      warning: {
        contrastText: '#FFFFFF',
        main: '#F2994A',
      },
      error: {
        contrastText: '#FFFFFF',
        main: '#EB5757',
        light: '#F44444',
        dark: '#CD3535',
      },
      success: {
        contrastText: '#FFFFFF',
        main: '#8CC83C',
        light: ' #46CE7C',
      },
      chart: {
        primary: {
          colorPrimary: '#0096D7',
          colorSecondary: '#1E2D69',
        },
        secondary: {
          colorPrimary: '#49D6B6',
          colorSecondary: '#FF7171',
        },
      },
    },
    overrides: {
      MuiDialog: {
        container: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      MuiButton: {
        outlined: {
          borderColor: '#FFFFFF',
          color: '#FFFFFF',
        },
      },
    },
  },
  ptBR,
)

export default responsiveFontSizes(materialTheme)
