import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import loginImage from 'assets/images/login.png'
import Button from 'components/Button'
import Route from 'components/Route'
import Typography from 'components/Typography'
import RoutingService from 'services/routing'

import Footer from '../components/Footer'
import Form from './components/Form'
import Info from './components/Info'
import * as Styled from './styles'

const Login: FC = () => {
  return (
    <Route title="Entrar" access="guest">
      <Styled.Container>
        <Styled.PresentationMd>
          <Info />
        </Styled.PresentationMd>

        <Styled.MainCard>
          <Styled.Presentation>
            <Info />

            <img src={loginImage} alt="Pessoa ao computador" />
          </Styled.Presentation>

          <hr />

          <Styled.FormContainer>
            <Form />

            <Link to={RoutingService.FIRST_ACCESS}>
              <Button
                data-testid="button-retrieve-password"
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                Primeiro Acesso
              </Button>
            </Link>

            <Link to={RoutingService.RECOVER_PASSWORD}>
              <Typography
                variant="body1"
                align="center"
                className="underline-text"
                color="primary"
              >
                Recuperar senha
              </Typography>
            </Link>

            <Footer />
          </Styled.FormContainer>
        </Styled.MainCard>
      </Styled.Container>
    </Route>
  )
}

export default Login
