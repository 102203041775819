import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import Formik from 'components/Formik'
import { useICCIDS } from 'hooks/iccids'

import { useCreateChips } from '../../context'
import ButtonSubmit from '../ButtonSubmit'
import ICCIDSFields from '../ICCIDSFields'
import FormProps, { CreateChipFormProps } from './props'
import schema from './schema'
import * as Styled from './styles'

const initialValues: FormProps = {
  first_iccid: '',
  last_iccid: '',
}

const CreateChipForm: FC<CreateChipFormProps> = props => {
  const { stockId } = props
  const { quantityChips } = useICCIDS()
  const [greaterThanAhundred, setGreaterThanAhundred] = useState<boolean>(false)

  const { addChips } = useCreateChips()

  useEffect(() => {
    if (quantityChips >= 99) {
      setGreaterThanAhundred(true)
    } else {
      setGreaterThanAhundred(false)
    }
  }, [quantityChips])

  const handleSubmit = useCallback(
    async (newDate: FormProps) => {
      addChips(newDate, stockId, greaterThanAhundred)
    },
    [addChips, stockId, greaterThanAhundred],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <ICCIDSFields />
      <Styled.ButtonContainer>
        <ButtonSubmit />
      </Styled.ButtonContainer>
    </Formik>
  )
}

export default memo(CreateChipForm)
