import React, {
  FC,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react'

import { useToast } from 'hooks/toast'
import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import { RecoverPasswordCredentials, RecoverPasswordContextData } from './props'

const initialValue = {} as RecoverPasswordContextData
const RecoverPasswordContext = createContext(initialValue)

export const RecoverPasswordProvider: FC = props => {
  const { children } = props

  const [status, setStatus] = useState<RequestStatus>({
    loading: false,
    error: false,
    success: false,
  })
  const [
    backupCredentials,
    setBackupCredentials,
  ] = useState<RecoverPasswordCredentials | null>(null)
  const { addToast } = useToast()

  const resendEmail = useCallback(async () => {
    try {
      if (!backupCredentials) {
        throw new Error('No Credentials previously saved')
      }
      setStatus({
        error: false,
        loading: true,
        success: false,
      })

      await api.post('/password/forgot', backupCredentials)

      setStatus({
        error: false,
        loading: false,
        success: true,
      })
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro de autenticação',
        description: 'Verifique suas credenciais',
      })
      setStatus({
        error: true,
        loading: false,
        success: false,
      })
    }
  }, [addToast, backupCredentials])

  const recoverPassword = useCallback(
    async (credentials: RecoverPasswordCredentials) => {
      try {
        setStatus({
          error: false,
          loading: true,
          success: false,
        })

        await api.post('/password/forgot', credentials)

        setStatus({
          error: false,
          loading: false,
          success: true,
        })
        setBackupCredentials(credentials)
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        setStatus({
          error: true,
          loading: false,
          success: false,
        })
      }
    },
    [addToast],
  )

  return (
    <RecoverPasswordContext.Provider
      value={{ status, recoverPassword, resendEmail }}
    >
      {children}
    </RecoverPasswordContext.Provider>
  )
}

export const useRecoverPassword = () => {
  const context = useContext(RecoverPasswordContext)
  if (!context || context === initialValue) {
    throw new Error(
      'useRecoverPassword must be used within a RecoverPasswordProvider',
    )
  }
  return context
}
