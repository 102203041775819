import React, {
  FC,
  useState,
  createContext,
  useMemo,
  useContext,
  useCallback,
} from 'react'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import { AskReplacementContextProps, DataFormAskReplacement } from './props'

const AskReplacementContext = createContext({} as AskReplacementContextProps)

const AskReplacementProvider: FC = props => {
  const { children } = props

  const [status, setStatus] = useState({
    loading: false,
    error: false,
    success: false,
  })
  const [modalActive, setModalAtive] = useState(false)
  const { addToast } = useToast()

  const askForReplacement = useCallback(
    async (data: DataFormAskReplacement) => {
      try {
        setStatus({
          error: false,
          loading: true,
          success: false,
        })

        let dataToSend: any = data

        if (!data.chip_quantity) {
          dataToSend = null
        }

        await api.post(`/chips/request-chips`, dataToSend).catch(error => {
          throw error
        })

        setStatus({
          error: false,
          loading: false,
          success: true,
        })
        addToast({
          title: 'Reposição solicitada!',
          type: 'success',
        })
      } catch (error) {
        setStatus({
          error: true,
          loading: false,
          success: false,
        })
        addToast({
          title: ErrorHandler.getMessage(error),
          type: 'error',
        })
      }
      setModalAtive(false)
    },
    [addToast],
  )

  const value = useMemo(
    () => ({
      status,
      modalActive,
      openModal: () => setModalAtive(true),
      closeModal: () => setModalAtive(false),
      askForReplacement,
    }),
    [askForReplacement, modalActive, status],
  )

  return (
    <AskReplacementContext.Provider value={value}>
      {children}
    </AskReplacementContext.Provider>
  )
}

export const useAskReplacement = () => useContext(AskReplacementContext)

export default AskReplacementProvider
