import React, { memo, FC, useMemo, useCallback, useEffect } from 'react'

import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { TablePaginationProps, HandleChangeRowsPerPage } from './props'
import * as Styled from './styles'

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]

const TablePagination: FC<TablePaginationProps> = props => {
  const {
    current_page,
    from,
    next_page,
    per_page,
    prev_page,
    to,
    total,
    onChangeRowsPerPage,
    nextPage,
    previousPage,
    getData,
    setPage,
  } = props

  const renderSelectItems = useMemo(
    () =>
      ROWS_PER_PAGE_OPTIONS.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      )),
    [],
  )
  const handleGetData = useCallback(() => {
    if (getData) {
      getData()
    }
  }, [getData])

  const handleChangeRowsPerPage = useCallback<HandleChangeRowsPerPage>(
    event => {
      if (onChangeRowsPerPage) {
        if (setPage) {
          setPage(1)
        }
        onChangeRowsPerPage(event.target.value as number)
      }
    },
    [onChangeRowsPerPage, setPage],
  )

  const isEmpty = useMemo(() => total == 0, [total])
  const canGoNext = useMemo(() => next_page != null, [next_page])
  const canGoPrev = useMemo(() => prev_page != null, [prev_page])

  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page, per_page])

  return (
    <Styled.TableFooter>
      <TableRow>
        <TableCell colSpan={1000}>
          <Toolbar>
            <Typography
              color="inherit"
              variant="body2"
              className="TablePagination__lines-per-page"
            >
              Linhas por página:
            </Typography>

            <Select
              input={<InputBase />}
              value={per_page}
              onChange={handleChangeRowsPerPage}
              disabled={isEmpty}
            >
              {renderSelectItems}
            </Select>

            <Typography
              color="inherit"
              variant="body2"
              className="TablePagination__page-indicator"
            >
              {from}-{to} de {total}
            </Typography>

            <IconButton
              onClick={previousPage}
              disabled={!canGoPrev}
              aria-label="Página anterior"
            >
              <KeyboardArrowLeft />
            </IconButton>

            <IconButton
              onClick={nextPage}
              disabled={!canGoNext}
              aria-label="Próxima página"
            >
              <KeyboardArrowRight />
            </IconButton>
          </Toolbar>
        </TableCell>
      </TableRow>
    </Styled.TableFooter>
  )
}

export default memo(TablePagination)
