import { FC, useEffect } from 'react'
import React from 'react'

import PageTitle from 'components/PageTitle'
import ComboBox from 'components/SelectStores'
import { Options } from 'components/SelectStores/props'
import { useChipsCashFlow } from 'hooks/ChipsCashFlow'
import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import { EnableChange } from 'utils/enablesForLeavePage'
import { initialCCID } from 'utils/initialStateForSelects'

import { AtualWallet } from '../Wallet'
import { actionChangeOptions, categoryChangeOptions } from './ChangeOptions'
import { ContainerInputs, Input, SendButton, TextInput } from './styles'

const ChangeChip: FC = () => {
  const { ICCIDChange, setICCIDChange } = useChipsCashFlow()

  const [documentChange, setDocumentChange] = React.useState<string>('')
  const [oldIccid, setOldIccid] = React.useState<string>('')
  const [categoryChange, setCategoryChange] = React.useState<Options>(
    categoryChangeOptions[0],
  )
  const [actionsChange, setActionChange] = React.useState<Options>(
    actionChangeOptions[0],
  )
  const [nameChange, setNameChange] = React.useState<string>('')
  const [enabled, setEnabled] = React.useState<boolean>()
  const { addToast } = useToast()

  useEffect(() => {
    const enable = EnableChange({
      actions: actionsChange?.name?.length,
      documentChange: documentChange?.length,
      iccidName: ICCIDChange?.name.length,
      category: categoryChange?.name.length,
      nameChange: nameChange.length,
      oldiccd: oldIccid.length,
    })
    setEnabled(enable)
  }, [
    documentChange,
    oldIccid,
    ICCIDChange?.name,
    categoryChange,
    actionsChange,
    nameChange,
  ])

  const SubmitChanges = async () => {
    try {
      setEnabled(false)
      let params = {
        category: categoryChange?.labelName,
        new_iccid: ICCIDChange.name,
        old_iccid: oldIccid,
        name: nameChange,
        document: documentChange,
      }
      if (categoryChange?.name === 'Ação') {
        params.category = actionsChange?.labelName
      }
      await api.post('chips/chips-exchange', params)
      addToast({
        type: 'success',
        title: 'Troca efetivada',
      })
      handleStates()
    } catch (e) {
      handleStates()
      addToast({
        type: 'error',
        title: 'Troca não efetuada',
      })
    }
  }
  const handleStates = () => {
    setDocumentChange('')
    setICCIDChange(initialCCID)
    setOldIccid('')
    setCategoryChange(categoryChangeOptions[0])
    setActionChange(actionChangeOptions[0])
    setNameChange('')
  }
  return (
    <div>
      <PageTitle title={'Trocar'} />
      <ContainerInputs>
        <Input
          mask="99999999999999999999"
          maskChar={null}
          placeholder="ICCID ANTIGO"
          value={oldIccid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setOldIccid(e.target.value)
          }}
        />
        <AtualWallet iccid={ICCIDChange} setOption={setICCIDChange} />
        <TextInput
          label="Nome"
          placeholder="Nome"
          variant="outlined"
          value={nameChange}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNameChange(e.target.value)
          }}
        />

        <Input
          mask="99999999999999"
          maskChar={null}
          placeholder="CPF/CNPJ"
          value={documentChange}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDocumentChange(e.target.value)
          }}
        />
        <ComboBox
          width={'230px'}
          options={categoryChangeOptions}
          labelName={'categorias'}
          setOption={setCategoryChange}
          value={categoryChange}
        />
        {categoryChange?.name === 'Ação' && (
          <ComboBox
            width={'230px'}
            options={actionChangeOptions}
            labelName={'Setor'}
            setOption={setActionChange}
            value={actionsChange}
          />
        )}
        <SendButton
          variant="contained"
          color="primary"
          size="small"
          onClick={SubmitChanges}
          disabled={!enabled}
        >
          Enviar
        </SendButton>
      </ContainerInputs>
    </div>
  )
}

export default ChangeChip
