import React, { FC, useCallback, useMemo } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import FormUploadFileBase from './components/Forms/FormUploadFileBase'
import FormUploadFileDischarges from './components/Forms/FormUploadFileDischarges'
import FormUploadFileRechargePackage from './components/Forms/FormUploadFileRechargePackage'
import UploadFileListHeader from './components/UploadFileListHeader'
import UploadFileListItem from './components/UploadFileListItem'
import { UploadFileListItemProps } from './components/UploadFileListItem/props'
import VisibleForm from './components/VisibleForm'
import UploadFileVivoProvider, { useUploadFileVivo } from './context'
import * as Styled from './styles'

const UploadFileVivo: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    listFiles,
    setLimit,
    nextPage,
    previousPage,
    requestStatus,
    setPage,
    valueFilter,
  } = useUploadFileVivo()

  const renderItem = useCallback<TableRenderItem<UploadFileListItemProps>>(
    item => <UploadFileListItem key={item.id} {...item} />,
    [],
  )

  const renderTypeForm = useMemo(() => {
    if (valueFilter === 'chips') {
      return <FormUploadFileRechargePackage />
    } else if (valueFilter === 'discharges') {
      return <FormUploadFileDischarges />
    } else if (valueFilter === 'optin') {
      return <FormUploadFileBase />
    }
    return <></>
  }, [valueFilter])

  return (
    <Route title="Upload diário" access="auth" requireRoles={[]}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Upload de arquivo Vivo', link: '#' },
        ]}
        activeOption="upload-file"
      >
        <PageTitle title="Upload diário" />

        <VisibleForm />
        <Styled.Container>{renderTypeForm}</Styled.Container>

        <Table
          data={data}
          loadingRequest={requestStatus.loading}
          renderItem={renderItem}
          HeaderComponent={<UploadFileListHeader />}
          FooterComponent={
            <TablePagination
              getData={listFiles}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(UploadFileVivo, UploadFileVivoProvider)
