import React, { FC, memo, useCallback, useMemo, useState } from 'react'

import Formik from 'components/Formik'
import { useProduct } from 'pages/Product/context'

import {
  DataParamsProps,
  PeriodType,
  DealerType,
} from '../../../../context/props'
import { OPTIONS_SELECT_PERIOD, OPTIONS_MENU_DEALER } from './options'
import schema from './schema'
import * as Styled from './styles'

const FormPanel: FC = () => {
  const { getData, dataParams, setDataParams } = useProduct()

  const [periodType, setPeriodType] = useState<PeriodType>('yearly')
  const [dealerType, setDealerType] = useState<DealerType>('all')

  const initialValues: DataParamsProps = {
    period: periodType,
    dealer: dealerType,
    date: '',
  }

  const textField = useMemo(() => {
    if (periodType === 'yearly') {
      return null
    }
    return (
      <Styled.GridItem item xs={12} sm={3} md={3}>
        <Styled.DatePicker
          label="Data"
          inputVariant="outlined"
          name="date"
          format="MM/yyyy"
          views={['year', 'month']}
          maxDate={new Date()}
        />
      </Styled.GridItem>
    )
  }, [periodType])

  const handleSubmit = useCallback(
    values => {
      dataParams.period = values.period
      dataParams.date = values.date
      dataParams.dealer = values.dealer

      setDataParams(dataParams)

      getData()
    },
    [dataParams, getData, setDataParams],
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Styled.GridContainer container spacing={2} justify="flex-end">
        <Styled.GridItem item xs={12} sm={3} md={3}>
          <Styled.Select
            name="dealer"
            label="Tipo de Revendedor"
            value={dealerType}
            setValue={() => setDealerType}
            options={OPTIONS_MENU_DEALER}
          />
        </Styled.GridItem>
        <Styled.GridItem item xs={12} sm={3} md={3}>
          <Styled.Select
            name="period"
            label="Período"
            value={periodType}
            setValue={(event: string) => setPeriodType(event as PeriodType)}
            options={OPTIONS_SELECT_PERIOD}
          />
        </Styled.GridItem>

        {textField}

        <Styled.GridItem item xs={12} sm={3} md={3}>
          <Styled.Button variant="contained" color="primary">
            Filtrar
          </Styled.Button>
        </Styled.GridItem>
      </Styled.GridContainer>
    </Formik>
  )
}

export default memo(FormPanel)
