import React, { FC, useMemo } from 'react'

import loginImage from 'assets/images/login.png'
import Route from 'components/Route'
import {
  FirstAccessProvider,
  useFirstAccess,
} from 'hooks/auth/password/FirstAccess'
import withContext from 'utils/withContext'

import Footer from '../components/Footer'
import Form from './components/Form'
import FormCompleted from './components/FormCompleted'
import Info from './components/Info'
import * as Styled from './styles'

const FirstAccess: FC = () => {
  const { status } = useFirstAccess()

  const content = useMemo(() => {
    if (status.success) return <FormCompleted />
    return <Form />
  }, [status.success])

  return (
    <Route title="Primeiro Acesso" access="guest">
      <Styled.Container>
        <Styled.PresentationMd>
          <Info />
        </Styled.PresentationMd>

        <Styled.MainCard>
          <Styled.Presentation>
            <Info />

            <img src={loginImage} alt="Pessoa ao computador" />
          </Styled.Presentation>

          <hr />

          <Styled.FormContainer>
            {content}

            <Footer />
          </Styled.FormContainer>
        </Styled.MainCard>
      </Styled.Container>
    </Route>
  )
}

export default withContext(FirstAccess, FirstAccessProvider)
