import React, { FC, useEffect, useState, useMemo, memo } from 'react'

import Typography from 'components/Typography'
import Document from 'utils/document'

import Grid from '@material-ui/core/Grid'

import { useFormCompany } from '../../context'
import ControlerSteps from '../ControllerSteps'
import Stepper from '../Stepper'
import { ItemSectionProps } from './props'
import * as Styled from './styles'

const ItemSection: FC<ItemSectionProps> = props => {
  const { label, value, ...rest } = props

  return (
    <Grid {...rest}>
      <Typography variant="overline">{label}</Typography>
      <Styled.ItemSectionDescription variant="subtitle1" color="textSecondary">
        {value}
      </Styled.ItemSectionDescription>
    </Grid>
  )
}

const Step3: FC = () => {
  const { dataFormCompany } = useFormCompany()
  const [addressCompany, setAddressCompany] = useState('')

  const typeCompany: {
    [key: string]: string
  } = useMemo(
    () => ({
      partner: 'PARCEIRA',
      franchisee: 'FRANQUEADA',
    }),
    [],
  )

  useEffect(() => {
    const Geocoder = new google.maps.Geocoder()

    Geocoder.geocode(
      {
        location: {
          lat: parseFloat(dataFormCompany.latitude),
          lng: parseFloat(dataFormCompany.longitude),
        },
      },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus,
      ) => {
        if (status === 'OK') {
          if (results[0]) {
            setAddressCompany(results[0].formatted_address)
          }
        }
      },
    )
  }, [dataFormCompany.latitude, dataFormCompany.longitude])

  const typeDocument = useMemo(() => Document.type(dataFormCompany.document), [
    dataFormCompany.document,
  ])

  const renderLabelName = useMemo(
    () => (typeDocument === 'CPF' ? 'Nome' : 'Nome fantasia'),
    [typeDocument],
  )

  const renderTypeDocument = useMemo(
    () => Document.format(dataFormCompany.document),
    [dataFormCompany.document],
  )

  const renderCorporateData = useMemo(
    () =>
      typeDocument === 'CNPJ' &&
      dataFormCompany.corporate_name && (
        <ItemSection
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          label="Razão social"
          value={dataFormCompany.corporate_name}
        />
      ),
    [dataFormCompany.corporate_name, typeDocument],
  )

  const formatedAddressContact = useMemo(() => {
    return `${dataFormCompany.street}, N° ${dataFormCompany.number}, Bairro ${dataFormCompany.district}`
  }, [dataFormCompany.district, dataFormCompany.number, dataFormCompany.street])

  return (
    <Grid container spacing={1}>
      <Stepper />

      <Styled.GridTitleSectionVariant1 item lg={12} md={12} sm={12} xs={12}>
        <Styled.TitleSection variant="overline" color="primary">
          Dados Principais
        </Styled.TitleSection>
      </Styled.GridTitleSectionVariant1>

      <ItemSection
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        label={typeDocument}
        value={renderTypeDocument}
      />
      <ItemSection
        item
        lg={5}
        md={5}
        sm={12}
        xs={12}
        label={renderLabelName}
        value={dataFormCompany.fantasy_name}
      />
      <ItemSection
        item
        lg={3}
        md={3}
        sm={12}
        xs={12}
        label="Tipo"
        value={typeCompany[dataFormCompany.type]}
      />
      {renderCorporateData}

      <Styled.GridTitleSectionVariant2 item lg={12} md={12} sm={12} xs={12}>
        <Styled.TitleSection variant="overline" color="primary">
          Dados de Contato
        </Styled.TitleSection>
      </Styled.GridTitleSectionVariant2>

      <ItemSection
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        label="Telefone"
        value={dataFormCompany.phone}
      />
      <ItemSection
        item
        lg={8}
        md={8}
        sm={12}
        xs={12}
        label="E-mail"
        value={dataFormCompany.email}
      />

      <ItemSection
        lg={8}
        md={8}
        sm={12}
        xs={12}
        label="Endereço de contato"
        value={formatedAddressContact}
      />

      <ItemSection
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        label="Endereço no mapa"
        value={addressCompany}
      />

      <Styled.GridTitleSectionVariant2 item lg={12} md={12} sm={12} xs={12}>
        <Styled.TitleSection variant="overline" color="primary">
          Dados do Representante
        </Styled.TitleSection>
      </Styled.GridTitleSectionVariant2>

      <ItemSection
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        label="Nome"
        value={dataFormCompany.representative.name}
      />
      <ItemSection
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        label="Telefone"
        value={dataFormCompany.representative.phone}
      />
      <ItemSection
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        label="E-mail"
        value={dataFormCompany.representative.email}
      />

      <ControlerSteps isLastStep />
    </Grid>
  )
}

export default memo(Step3)
