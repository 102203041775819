import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  NewSaleActions,
  NewSaleReducerActionMap,
  NewSaleState,
  RequestResponseSaleCreated,
} from './props'

const initialState: NewSaleState = {
  requestStatus: {
    error: false,
    loading: false,
  },
  requestCountChipsStatus: {
    error: false,
    loading: false,
  },
  requestBonusStatus: {
    error: false,
    loading: false,
  },
  intervalsChips: [],
  bonus: 0,
  sale: {} as RequestResponseSaleCreated,
}

const newSaleReducer: Reducer<NewSaleState, NewSaleReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case NewSaleActions.Reset:
      return initialState

    case NewSaleActions.ResetQuantityChips:
      state.intervalsChips = []
      return state

    /**
     * Request Cadastrar Nova Venda
     */
    case NewSaleActions.Request:
      state.requestStatus.loading = true
      return state

    case NewSaleActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      return state

    case NewSaleActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.sale = action.payload.data
      return state

    case NewSaleActions.RequestSuccessContChips:
      state.intervalsChips[action.payload.index] = action.payload.value
      return state

    /**
     * Request Verificar Bonus por empresa
     */
    case NewSaleActions.RequestBonus:
      state.requestBonusStatus.loading = true
      return state

    case NewSaleActions.RequestSuccessBonus:
      state.requestBonusStatus.loading = false
      state.requestBonusStatus.error = true
      state.bonus = action.payload.data.total_bonus
      return state

    case NewSaleActions.RequestErrorBonus:
      state.requestBonusStatus.loading = false
      state.requestBonusStatus.error = false
      state.bonus = 0
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(newSaleReducer)
