import styled from 'styled-components'

import MUDialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'

export const Title = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
`

export const Dialog = styled(MUDialog)`
  .MuiDialog-paperFullWidth {
    overflow-y: hidden;
    @media (max-width: 1000px) {
      overflow-y: auto;
    }
  }
  .MuiDialogTitle-root {
    max-width: 750px;
  }
`
