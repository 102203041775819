import React, { FC, useMemo, memo } from 'react'

import { useNewSale } from '../../context'
import * as Styled from './styles'

const InputRepresentative: FC = () => {
  const { representativeField } = useNewSale()

  const LoadingIconFieldRepresentative = useMemo(
    () => representativeField.loading && <Styled.LoadingIcon />,
    [representativeField.loading],
  )

  return (
    <Styled.FormControl variant="outlined" fullWidth>
      <Styled.TextField
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        label="Responsável"
        value={representativeField.field}
        InputProps={{
          endAdornment: LoadingIconFieldRepresentative,
        }}
        disabled
      />
    </Styled.FormControl>
  )
}

export default memo(InputRepresentative)
