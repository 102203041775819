import React from 'react'

import TableHead from 'components/TableHead'
import { useCommission } from 'hooks/commissions'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const CommissionList: React.FC = () => {
  const { setStatus, status } = useCommission()

  return (
    <TableHead
      title="Todas as comissões"
      filter={[
        {
          type: 'select',
          options: [
            {
              label: 'Pago',
              value: 'paid',
            },
            {
              label: 'Não pago',
              value: 'unpaid',
            },
            {
              label: 'Todos',
              value: undefined,
            },
          ],
          label: 'Status',
          value: status,
          // @ts-ignore
          onChange: setStatus,
        },
      ]}
    >
      <TableRow>
        <TableCell>Mês e ano</TableCell>
        <TableCell>Valor da comisão</TableCell>

        <TableCell colSpan={2}>Status</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default CommissionList
