import React, { FC, memo, useCallback, useEffect } from 'react'

import { useFormCompany } from 'components/FormCompany/context'
import { useField } from 'formik'

import MuiCheckbox from '@material-ui/core/Checkbox'

import { CheckboxSimpleProps } from './props'
import * as Styled from './styles'

const CheckboxSimple: FC<CheckboxSimpleProps> = props => {
  const { label, name, typeCompany } = props
  const { typeForm } = useFormCompany()

  const [field, , helpers] = useField<any>(name)
  useEffect(() => {
    if (field.value == 'commission') {
      helpers.setValue(false)
    }
    if (field.value == 'bonus') {
      helpers.setValue(true)
    }
  }, [field, helpers])

  const handleChange = useCallback(() => {
    helpers.setValue(!field.value)
  }, [field.value, helpers])

  if (typeForm !== 'update') return null

  return (
    <Styled.FormControlLabel
      control={
        <MuiCheckbox
          icon={<Styled.FilledOutline />}
          disableRipple
          disabled={typeCompany === 'partner' && true}
          disableFocusRipple
          checkedIcon={
            <Styled.FilledOutline>
              <Styled.FilledOutlineChecked />
            </Styled.FilledOutline>
          }
          onChange={handleChange}
          checked={field.value}
        />
      }
      name={name}
      label={label}
      labelPlacement="end"
    />
  )
}

export default memo(CheckboxSimple)
