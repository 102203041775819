import PureButton from 'components/Button'
import styled from 'styled-components'

export const Button = styled(PureButton)`
  background-color: transparent;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    min-width: 100%;
  }

  span {
    color: ${({ theme }) => theme.palette.primary.main};
    text-transform: uppercase;
  }

  svg {
    margin-right: 5px;
  }
`
