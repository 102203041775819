import ButtonStyles from 'components/Button'
import styled from 'styled-components'

export const Button = styled(ButtonStyles)`
  height: 36px;
`

export const Row = styled.div`
  font-size: 0.8rem;
`
