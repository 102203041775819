import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import { useAuth } from 'hooks/auth'
import { useSaleCashFlow } from 'hooks/salesCashFlow'
import { SaleCashFlow } from 'interfaces/SalesCashFlow'
import RoutingService from 'services/routing'
import { TranslateCategorySell, TranslateStatusSell } from 'utils/Translate'

import TableCell from '@material-ui/core/TableCell'

import * as Styled from './styles'

const ValidationListItem: FC<SaleCashFlow> = props => {
  const {
    chip: { iccid },
    price,
    category,
    pack,
    customer_document,
    customer_name,
    status,
    audited_pack,
    user,
  } = props

  const { push } = useHistory()
  const { user: userAuth } = useAuth()
  const { setDetails } = useSaleCashFlow()
  const notAllowed =
    userAuth?.role.name === 'office_stockist' ||
    userAuth?.role.name === 'pap_leader' ||
    userAuth?.role.name === 'pap_seller' ||
    userAuth?.role.name === 'attendant'
      ? true
      : false
  const Set = () => {
    setDetails(props)
    sessionStorage.setItem('detalhes', JSON.stringify(props))
    push(`${RoutingService.REPORTS_DETAILS_VALIDATION}`)
  }

  return (
    <TableRowItem>
      <TableCell>
        <Styled.Row>{user?.name}</Styled.Row>
      </TableCell>
      <TableCell>
        <Styled.Row>{iccid}</Styled.Row>
      </TableCell>
      <TableCell>
        <Styled.Row>{price}</Styled.Row>
      </TableCell>
      <TableCell>
        <Styled.Row>{TranslateCategorySell(category)}</Styled.Row>
      </TableCell>
      <TableCell>
        <Styled.Row>{pack}</Styled.Row>
      </TableCell>
      <TableCell>
        <Styled.Row>{audited_pack}</Styled.Row>
      </TableCell>
      <TableCell>
        <Styled.Row>{TranslateStatusSell(status)}</Styled.Row>
      </TableCell>
      <TableCell>
        <Styled.Row>{customer_name}</Styled.Row>
      </TableCell>
      <TableCell>
        <Styled.Row>{customer_document}</Styled.Row>
      </TableCell>
      {!notAllowed && (
        <TableCell>
          <Styled.Button onClick={() => Set()}>Detalhes</Styled.Button>
        </TableCell>
      )}
    </TableRowItem>
  )
}

export default ValidationListItem
