import React, { FC, useState, useMemo, useCallback, memo } from 'react'
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'

import Typography from 'components/Typography'

import CircularProgress from '@material-ui/core/CircularProgress'

import { AutocompletePlacesProps } from './props'
import * as Styled from './styles'

const AutocompletePlaces: FC<AutocompletePlacesProps> = props => {
  const [address, setAddress] = useState('')

  const { setMarker } = props

  const handleselect = async (value: string) => {
    const results = await geocodeByAddress(value)
    const latlng = await getLatLng(results[0])

    setMarker(latlng)
  }

  const clearAddress = useCallback(() => {
    setAddress('')
    setMarker({ lat: 0, lng: 0 })
  }, [setMarker])

  const IconInput = useMemo(() => {
    if (address === '')
      return (
        <Styled.Icon src={require('assets/images/icons/search.svg').default} />
      )

    return (
      <Styled.Icon
        src={require('assets/images/icons/close.svg').default}
        onClick={clearAddress}
      />
    )
  }, [address, clearAddress])

  return (
    <Autocomplete value={address} onChange={setAddress} onSelect={handleselect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Styled.Container>
          <Styled.TextField
            name="autocomplete_places"
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: IconInput,
              autoComplete: 'no',
            }}
            {...getInputProps({ placeholder: 'Encontre seu endereço' })}
          />
          <Styled.Options>
            {loading ? (
              <Styled.Loading>
                <CircularProgress size={25} />
              </Styled.Loading>
            ) : (
              suggestions.map((suggestion, index) => {
                return (
                  <Styled.Option
                    {...getSuggestionItemProps(suggestion)}
                    active={suggestion.active}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`suggestion_${index}`}
                  >
                    <Typography>{suggestion.description}</Typography>
                  </Styled.Option>
                )
              })
            )}
          </Styled.Options>
        </Styled.Container>
      )}
    </Autocomplete>
  )
}

export default memo(AutocompletePlaces)
