import styled from 'styled-components'

import MUTextField from '@material-ui/core/TextField'

export const Select = styled(MUTextField)`
  border-radius: 6px;
  margin-bottom: 20px;
  .MuiInputBase-root {
    border-radius: 6px;
    border-right-style: none;
    border-color: ${({ theme }) => theme.palette.grey[500]};
    height: ${({ theme }) => theme.spacing(6)}px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 1.5px;
  }
`
