import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import FormProps from '../components/CreateChipForm/props'
import {
  CreateChipsState,
  CreateChipsReducerActionMap,
  CreateChipsActions,
} from './props'

const initialState: CreateChipsState = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  chips: {} as FormProps,
}

const createChipsReducer: Reducer<
  CreateChipsState,
  CreateChipsReducerActionMap
> = (state, action) => {
  switch (action.type) {
    case CreateChipsActions.Reset:
      return initialState

    case CreateChipsActions.RequestCreateChips:
      state.requestStatus.loading = true
      state.requestStatus.error = false
      state.requestStatus.success = false
      return state

    case CreateChipsActions.RequestSuccessCreateChips:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.requestStatus.success = true
      state.chips = action.payload.data
      return state

    case CreateChipsActions.RequestErrorCreateChips:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.requestStatus.success = false
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(createChipsReducer)
