import React, {
  FC,
  useCallback,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDebounce } from 'react-use'

import { useFormCompany } from 'components/FormCompany/context'
import { FieldProps } from 'formik'

import { useNewSale } from '../../context'
import PaperComponent from './PaperComponent'
import * as Styled from './styles'
import { useCompanies } from './useCompanies'
import { useCompany } from './useCompany'

const AutoCompleteCompanies: FC<FieldProps> = props => {
  const { field, form } = props
  const { name } = field
  const { setFieldValue, getFieldMeta } = form
  const { representativeField, checkBonus } = useNewSale()

  const [inputValue, setInputValue] = useState('')

  const { company: companyAdded } = useFormCompany()

  const {
    companies,
    getCompanies,
    status: requestStatusListCompanies,
  } = useCompanies()

  const {
    getCompany,
    company,
    status: requestStatusCompanyDetail,
  } = useCompany()

  useDebounce(
    () => {
      getCompanies(inputValue)
    },
    1000,
    [inputValue, companyAdded],
  )

  useEffect(() => {
    if (companyAdded) {
      setInputValue(
        (companyAdded.corporate_name as string) ?? companyAdded.fantasy_name,
      )
      setFieldValue(name, companyAdded.id)
      representativeField.setField(
        companyAdded.representative?.user.name as string,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAdded])

  useEffect(() => {
    representativeField.setLoading(requestStatusCompanyDetail.loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatusCompanyDetail.loading])

  useEffect(() => {
    if (field.value && !requestStatusCompanyDetail.loading) {
      representativeField.setField(company?.representative?.user.name as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, requestStatusCompanyDetail.loading])

  const handleChoosedOption = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      if (value !== null) {
        getCompany(value.id as string)
        setFieldValue(name, value.id)
        checkBonus(value.id)
      } else {
        representativeField.setField('')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name],
  )

  const getOptionLabel = useCallback(
    (option: any) => option?.corporate_name ?? option.fantasy_name,
    [],
  )

  const getOptionSelected = useCallback((option: any) => option?.id, [])

  const onInputChange = useCallback((_, newInputValue) => {
    setInputValue(newInputValue)
  }, [])

  const meta = useMemo(() => getFieldMeta(name), [getFieldMeta, name])

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }

    return {}
  }, [meta.error, meta.initialError, meta.touched])

  return (
    <Styled.Autocomplete
      debug
      id="combo-box-companies"
      options={companies}
      getOptionLabel={getOptionLabel}
      noOptionsText="nenhum revendedor encontrado"
      onChange={handleChoosedOption}
      PaperComponent={PaperComponent}
      loading={requestStatusListCompanies.loading}
      onInputChange={onInputChange}
      inputValue={inputValue}
      clearOnEscape
      autoComplete
      getOptionSelected={getOptionSelected}
      renderInput={params => {
        // Remove autoFill do Browser
        const originalInputProps = params.inputProps as any
        originalInputProps.autoComplete = 'no'
        params.inputProps = originalInputProps
        return (
          <Styled.TextField
            {...params}
            label="Revendedor"
            variant="outlined"
            autoFocus
            {...errorProps}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )
      }}
    />
  )
}

export default memo(AutoCompleteCompanies)
