class UrlHandler {
  static removeHostname(url: string) {
    const pathname = url.replace(
      /^[a-z]{4,5}:\/{2}([a-z0-9]{1,}\.{0,1}){1,}\.:{0,1}[0-9]{0,4}.(.*)/,
      '$2',
    )
    if (pathname.startsWith('/')) return pathname
    return `/${pathname}`
  }
}

export default UrlHandler
