import React, { FC, memo, useMemo } from 'react'

import MenuItem from '@material-ui/core/MenuItem'

import { SelectFilterProps } from '../../props'
import * as Styled from './styles'

const Select: FC<SelectFilterProps> = props => {
  const { onChange, value, name, label, options } = props

  const renderOptions = useMemo(
    () =>
      options.map(item => (
        <MenuItem value={item.value} key={item.value}>
          {item.value ? item.label : <em>{item.label}</em>}
        </MenuItem>
      )),
    [options],
  )

  return (
    <Styled.Select
      select
      variant="outlined"
      name={name}
      value={value}
      label={label}
      onChange={event => onChange(event.target.value as string)}
    >
      {renderOptions}
    </Styled.Select>
  )
}

export default memo(Select)
