import React, { FC, useCallback, useState } from 'react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import Card from 'components/Card'
import Formik from 'components/Formik'
import CheckboxSimple from 'components/Inputs/CheckboxSimple'
import TextField from 'components/Inputs/TextField'
import Loading from 'components/Loading'
import { useUserDetail } from 'hooks/users/details'

import Grid from '@material-ui/core/Grid'

import { useEditCompanyRepresentative } from '../../context'
import { EditCompanyRepresentativeCredentials } from '../../context/props'
import ButtonSubmit from '../ButtonSubmit'
import CheckboxesProducts from '../CheckboxesProducts'
import SelectRoles from '../SelectRoles'
import schema from './schema'
import * as Styled from './styles'

const FormEditComapanyRepresentaive: FC = () => {
  const [userType, setUserType] = useState('')
  const { userId, companyId } = useParams<any>()
  const { user, requestStatus, getUser } = useUserDetail()
  const { editCompanyRepresentative, status } = useEditCompanyRepresentative()

  useEffectOnce(() => {
    getUser(userId)
  })

  const handleSubmit = useCallback(
    (values: EditCompanyRepresentativeCredentials) =>
      editCompanyRepresentative(userId, companyId, values),
    [companyId, editCompanyRepresentative, userId],
  )

  const initialValues = useMemo<EditCompanyRepresentativeCredentials>(
    () => ({
      active: user?.active,
      name: user?.name || '',
      email: user?.email || '',
      phone: user?.phone || '',
      role_name: user?.role.name || '',
      products_ids: user?.products.map(product => product.id) || [],
    }),
    [user],
  )

  if (requestStatus.loading) return <Loading />

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnMount
      enableReinitialize
    >
      <Card>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Styled.TitlePanel variant="body1">
              dados do representante
            </Styled.TitlePanel>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField name="name" label="Nome" />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField name="email" label="E-mail" type="email" />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <SelectRoles value={userType} setValue={setUserType} />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField
              name="phone"
              label="Telefone *"
              placeholder="DDD "
              mask="(99) 99999-9999"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Styled.TitlePanel variant="body1">
              status do usuário
            </Styled.TitlePanel>
            <CheckboxSimple name="active" label="Ativo" />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Styled.TitlePanel variant="body1">produtos</Styled.TitlePanel>
            <CheckboxesProducts />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Styled.ButtonContainer>
              <ButtonSubmit loading={status.loading} />
            </Styled.ButtonContainer>
          </Grid>
        </Grid>
      </Card>
    </Formik>
  )
}

export default FormEditComapanyRepresentaive
