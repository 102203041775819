import ButtonStyles from 'components/Button'
import styled, { css } from 'styled-components'

interface AvatarPictureProps {
  visibleBackground: boolean
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 428px;

  @media (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`

export const Span = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #868e96;
`

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 26px 0 31px 0;
`

export const AvatarPicture = styled.img<AvatarPictureProps>`
  width: 64px;
  height: 64px;
  border-radius: 50%;

  ${props =>
    props.visibleBackground
      ? css`
          background-color: ${({ theme }) => theme.palette.primary.main};
          padding: ${({ theme }) => theme.spacing(2)}px;
        `
      : css`
          background-color: transparent;
        `}
`

export const InputUpload = styled.input`
  display: none;
`
export const Label = styled.label``

export const ButtonUploadPhoto = styled(ButtonStyles)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: 0;
`

export const ButtonSubmit = styled(ButtonStyles)``

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`
