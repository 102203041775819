/* eslint-disable consistent-return */
import React, {
  FC,
  useMemo,
  useEffect,
  memo,
  useState,
  useCallback,
} from 'react'
import { FiPlus, FiMinus } from 'react-icons/fi'

import HelpIcon from 'components/HelpIcon'
import Textarea from 'components/Inputs/Textarea'
import TextField from 'components/Inputs/TextField'
import Typography from 'components/Typography'
import { useFormikContext } from 'formik'
import customFormatPrice from 'utils/formatPrice'
import isMultiple from 'utils/isMultiple'
import validateICCID from 'utils/validateICCID'

import Grid from '@material-ui/core/Grid'

import { useNewSale } from '../../context'
import { CreateSaleData } from '../../context/props'
import IntervalIccids from '../IntervalIccids'
import * as Styled from './styles'

const SectionDataSale: FC = () => {
  const { values, setFieldValue } = useFormikContext<CreateSaleData>()

  const {
    isValidQuantityChips,
    calculateTotalPrice,
    totalPrice,
    finalTotalPrice,
    totalDiscountPrice,
    bonus,
    requestBonusStatus,
    requestCountChipsStatus,
  } = useNewSale()

  const [intervals, setIntervalIccids] = useState(1)
  const [isDisableButton, setIsDisableButton] = useState(true)

  useEffect(() => {
    if (isMultiple.five(5)) {
      calculateTotalPrice(values.unit_chip_price as number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.unit_chip_price])

  const handleAddInterval = useCallback(() => {
    setFieldValue('iccids_intervals', [
      ...values.iccids_intervals,
      { first_iccid: '', last_iccid: '' },
    ])
    setIsDisableButton(false)
    setIntervalIccids(prev => prev + 1)
  }, [setFieldValue, values.iccids_intervals])

  const handleRemoveInterval = useCallback(() => {
    if (intervals === 1) return setIsDisableButton(true)

    setFieldValue(
      'iccids_intervals',
      values.iccids_intervals.splice(0, values.iccids_intervals.length - 1),
    )

    return setIntervalIccids(prev => prev - 1)
  }, [intervals, setFieldValue, values.iccids_intervals])

  const renderTotalPrice = useMemo(() => {
    if (requestCountChipsStatus.loading) {
      return `Carregando`
    }

    if (
      isValidQuantityChips &&
      validateICCID(
        values.iccids_intervals.map(item => [
          item.first_iccid,
          item.last_iccid,
        ]),
      )
    ) {
      const totalPriceFormatted = customFormatPrice({ price: totalPrice })
      return (
        <>
          <Typography color="initial" variant="subtitle1">
            Valor total da venda
          </Typography>
          <Typography
            color="primary"
            variant="h6"
            capitalize="uppercase"
            data-cy="total_price__h6"
          >
            {totalPriceFormatted}
          </Typography>
        </>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, values.unit_chip_price])

  const renderErrorMultipleFive = useMemo(() => {
    if (!isValidQuantityChips) {
      return {
        helperText: 'Quantidade de chips deve ser múltiplo de cinco',
        error: true,
      }
    }
  }, [isValidQuantityChips])

  const renderPriceTotalBonus = useMemo(() => {
    if (requestBonusStatus.loading) {
      return `Carregando`
    }

    if (
      isValidQuantityChips &&
      validateICCID(
        values.iccids_intervals.map(item => [
          item.first_iccid,
          item.last_iccid,
        ]),
      ) &&
      bonus !== 0
    ) {
      const totalDiscountPriceFormatted = customFormatPrice({
        price: totalDiscountPrice,
      })

      return (
        <>
          <Typography color="initial" variant="subtitle1">
            Valor total de Bonificação
          </Typography>
          <Typography
            color="error"
            variant="h6"
            data-cy="total_price_bonus__h6"
            capitalize="uppercase"
          >
            {totalDiscountPriceFormatted}
          </Typography>
        </>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDiscountPrice, values.unit_chip_price])

  const renderFinalTotalPrice = useMemo(() => {
    if (requestBonusStatus.loading) {
      return `Carregando`
    }

    if (
      isValidQuantityChips &&
      validateICCID(
        values.iccids_intervals.map(item => [
          item.first_iccid,
          item.last_iccid,
        ]),
      ) &&
      bonus !== 0
    ) {
      const finalTotalPriceFormatted = customFormatPrice({
        price: finalTotalPrice,
      })
      return (
        <>
          <Typography color="initial" variant="subtitle1">
            Valor final da venda
          </Typography>
          <Styled.TypographyCustom
            variant="h6"
            data-cy="final_price__h6"
            capitalize="uppercase"
          >
            {finalTotalPriceFormatted}
          </Styled.TypographyCustom>
        </>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDiscountPrice, values.unit_chip_price])

  return (
    <>
      <Typography color="primary" variant="h6" capitalize="uppercase">
        dados da venda
      </Typography>

      {Array.from({ length: intervals }, (value, indexArray) => indexArray).map(
        index => (
          <IntervalIccids key={index} index={index} />
        ),
      )}

      <Styled.Grid container spacing={0}>
        <Grid item>
          <Styled.Button
            onClick={handleAddInterval}
            variant="text"
            color="primary"
            size="large"
            type="button"
            isColorPrimary
          >
            <FiPlus size={20} /> Novo Intervalo
          </Styled.Button>
        </Grid>

        <Grid item>
          <Styled.Button
            onClick={handleRemoveInterval}
            variant="text"
            color="primary"
            size="large"
            type="button"
            isColorPrimary={false}
            disabled={isDisableButton}
          >
            <FiMinus size={20} /> Remover Intervalo
          </Styled.Button>
        </Grid>
      </Styled.Grid>

      <Styled.Grid container spacing={2}>
        <Grid lg={4} md={4} sm={6} xs={6} item>
          <TextField
            label="Preço por chip"
            name="unit_chip_price"
            type="number"
            {...renderErrorMultipleFive}
            InputProps={{
              endAdornment: <HelpIcon />,
            }}
          />
        </Grid>
      </Styled.Grid>

      <Styled.Grid container spacing={2}>
        <Grid lg={6} md={6} sm={12} xs={12} item>
          <Styled.Grid container spacing={2}>
            <Grid lg={12} md={12} sm={12} xs={12} item>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                observações
              </Typography>
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} item>
              <Textarea
                name="comments"
                rows={6}
                rowsMax={8}
                placeholder="Condição de pagamento negociada, etc."
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>
          </Styled.Grid>
        </Grid>

        <Grid lg={6} md={6} sm={12} xs={12} item>
          <Styled.Grid container spacing={2}>
            <Grid lg={12} md={12} sm={12} xs={12} item>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                valores e bonificações
              </Typography>
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} item>
              {renderTotalPrice}
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} item>
              {renderPriceTotalBonus}
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} item>
              {renderFinalTotalPrice}
            </Grid>
          </Styled.Grid>
        </Grid>
      </Styled.Grid>
    </>
  )
}

export default memo(SectionDataSale)
