import React, { FC, memo, useMemo } from 'react'

import { FormControl, Grid, MenuItem } from '@material-ui/core'

import { useUploadFileVivo } from '../../context'
import { OPTIONS_TYPE_FILE } from './options'
import * as Styled from './styles'

const VisibleForm: FC = () => {
  const { addValueFilter } = useUploadFileVivo()

  const showOptionsTypeFile = useMemo(
    () =>
      OPTIONS_TYPE_FILE.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.name}
        </MenuItem>
      )),
    [],
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3}>
        <FormControl fullWidth variant="outlined">
          <Styled.TextField
            select
            label="Tipo de arquivo"
            variant="outlined"
            color="primary"
            onChange={event => addValueFilter(event.target.value)}
          >
            {showOptionsTypeFile}
          </Styled.TextField>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default memo(VisibleForm)
