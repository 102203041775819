import SelectProps from 'components/Select'
import styled from 'styled-components'

import GridStyles from '@material-ui/core/Grid'

export const Select = styled(SelectProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const Container = styled(GridStyles)``

export const Span = styled.span`
  color: '#495057';
  font-size: 12px;
`
