import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus, CreatedAndUpdatedDateType } from 'interfaces/common'

export type NewTransferState = {
  requestStatus: RequestStatus
  transfer: Transfer | null
}

interface RequestResponseCountChips {
  index: number
  value: number
}

export enum NewTransferActions {
  RequestNewTransfer = 'REQUEST_NEW_TRANSFER',
  RequestSuccessNewTransfer = 'REQUEST_SUCCESS_NEW_TRANSFER',
  RequestErrorNewTransfer = 'REQUEST_ERROR_NEW_TRANSFER',

  RequestSuccessContChips = 'REQUEST_SUCCESS_COUNT_CHIPS',

  Reset = 'RESET',
}

export interface Transfer extends CreatedAndUpdatedDateType {
  chips: string[]
  company_id: string
  distributor_id: string
  id: string
  order_number: string
  recipient_type: number
  sender_type: string
  status: string
  stock_id: string
}

export type NewTransferPayload = {
  [NewTransferActions.RequestNewTransfer]: undefined
  [NewTransferActions.RequestSuccessNewTransfer]: AxiosResponse<Transfer>
  [NewTransferActions.RequestErrorNewTransfer]: undefined

  [NewTransferActions.RequestSuccessContChips]: RequestResponseCountChips

  [NewTransferActions.Reset]: undefined
}

export type RecipientTypeOptions = 'stock' | 'distributor' | 'company'

export interface DataFormAddTransferType {
  iccids_intervals: Array<{ first_iccid: string; last_iccid: string }>
  sender: {
    id: string
    type: string
  }
  recipient: {
    id: string
    type: RecipientTypeOptions
  }
  quantityChips: boolean
}

export type NewTransferContextProps = {
  requestStatus: RequestStatus

  /**
   * Adiciona trasnferencia na Base
   */
  addTransfer(dataTransfer: DataFormAddTransferType): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void

  updateIntervals(index: number, value: number): void
}

export type NewTransferReducerActionMap = ActionMapData<NewTransferPayload>
