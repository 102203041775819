import { useCallback, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { api } from 'services/api'

type Product = {
  id: string
  created_at: Date | string
  updated_at: Date | string
  name: string
}
type ListProductsResponse = Product[]

export const useProducts = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })
  const [products, setProducts] = useState<Product[]>([])

  const getProducts = useCallback(async () => {
    try {
      setStatus({
        error: false,
        loading: true,
      })

      const response = await api.get<ListProductsResponse>('/products')
      setStatus({
        error: false,
        loading: false,
      })
      setProducts(response.data)
    } catch (e) {
      setStatus({
        error: true,
        loading: false,
      })
      setProducts([])
    }
  }, [])

  useEffectOnce(() => {
    getProducts()
  })

  return {
    status,
    products,
    getProducts,
  }
}
