import React, { FC, useMemo, memo, useCallback } from 'react'

import { useField } from 'formik'

import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox'

import * as Styled from './styles'

type CheckboxProps = MuiCheckboxProps & {
  name: string
  value: string
  children: React.ReactNode
}

const Checkbox: FC<CheckboxProps> = props => {
  const { name, value, children, ...rest } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<string[]>(name)

  const checked = useMemo(() => field.value.includes(value), [
    field.value,
    value,
  ])

  const handleChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>) => {
      if (field.value.includes(value)) {
        const nextValue = field.value.filter(fieldValue => fieldValue !== value)
        helpers.setValue(nextValue, true)
      } else {
        const nextValue = [...field.value, value]
        helpers.setValue(nextValue, true)
      }
    },
    [field.value, helpers, value],
  )

  return (
    <Styled.FormControlLabel
      control={
        <MuiCheckbox
          {...rest}
          icon={<Styled.FilledOutline />}
          disableRipple
          disableFocusRipple
          checkedIcon={
            <Styled.FilledOutline>
              <Styled.FilledOutlineChecked />
            </Styled.FilledOutline>
          }
          onBlur={field.onBlur}
          checked={checked}
          onChange={handleChange}
        />
      }
      name={name}
      label={children}
      labelPlacement="end"
    />
  )
}

export default memo(Checkbox)
