import React, { FC, useEffect } from 'react'

import { useChipsCashFlow } from 'hooks/ChipsCashFlow'
import { valuesSaleTypes } from 'pages/NewSalesCashFlow/components/dataValues'

import { Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

import { EditSale } from '../props'
import { SelectType } from './styles'

const SaleType: FC<EditSale> = props => {
  const { sale_type_edit } = props
  const { saleType, setSaleType } = useChipsCashFlow()
  useEffect(() => {
    if (sale_type_edit) {
      setSaleType(sale_type_edit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sale_type_edit])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSaleType(event.target.value as string)
  }
  return (
    <FormControl variant="outlined">
      <Typography variant="subtitle1">Tipo de venda</Typography>
      <SelectType value={saleType} onChange={handleChange}>
        <MenuItem value={saleType}>{saleType}</MenuItem>
        {valuesSaleTypes.map(item => (
          <MenuItem value={item.name} key={`${item.labelName}`}>
            {item.name}
          </MenuItem>
        ))}
      </SelectType>
    </FormControl>
  )
}

export default SaleType
