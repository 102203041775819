import { AxiosResponse } from 'axios'
import { User } from 'hooks/auth/props'
import { ActionMapData } from 'hooks/props'

export type UserDetailResponse = User

export type RequestStatus = {
  loading: boolean
  error: boolean
}

export enum UserDetailActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type UserState = {
  requestStatus: RequestStatus
  user: User | null
}

export type UserPayload = {
  [UserDetailActions.Request]: undefined
  [UserDetailActions.RequestSuccess]: AxiosResponse<UserDetailResponse>
  [UserDetailActions.RequestError]: undefined
  [UserDetailActions.Reset]: undefined
}

export type UserReducerActionMap = ActionMapData<UserPayload>

export type UserDetailContextData = {
  user: User | null
  requestStatus: RequestStatus

  /**
   * Recebe os dados da API
   */
  getUser(id: string): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}
