import React, { FC, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import Card from 'components/Card'
import Formik from 'components/Formik'
import CheckboxSimple from 'components/Inputs/CheckboxSimple'
import TextField from 'components/Inputs/TextField'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Typography from 'components/Typography'
import { useStockDetail, StockDetailProvider } from 'hooks/stock/details'
import { EditStockData } from 'hooks/stock/edit/props'
import { useEditStock, EditStockProvider } from 'hooks/stock/edit/useEditStock'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import AutocompleteCity from './components/AutocompleteCity'
import AutocompleteState from './components/AutocompleteState'
import FieldsNewStockist from './components/FieldsNewStockist'
import schema from './schema'
import * as Styled from './styles'

const EditStock: FC = () => {
  const { stock, requestStatus, getStock } = useStockDetail()
  const { editStock, status } = useEditStock()

  const { stockId } = useParams<any>()

  useEffectOnce(() => {
    getStock(stockId)
  })

  const handleSubmit = useCallback(
    (values: EditStockData) => editStock(stockId, values),
    [editStock, stockId],
  )

  const initialValues = useMemo<EditStockData>(
    () => ({
      active: stock?.stock?.active,
      name: stock?.stock?.name || '',
      new_stockist: false,
      stockist_id: stock?.stock?.stockist_id || 'id',
      stockist: {
        name: '',
        email: '',
        phone: '',
      },
      city_id: stock?.stock?.address.city.id || 'id',
      state_id: stock?.stock?.address.city.state.id || 'id',
    }),
    [stock],
  )

  const labelButtonSubmit = useMemo(
    () => (status.loading ? 'Carregando' : 'Editar'),
    [status],
  )

  if (requestStatus.loading) return <Loading />

  return (
    <Route title="Editar Estoque" access="auth" requireRoles={['admin', 'dev']}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Estoques', link: RoutingService.STOCKS },
          { title: 'Editar Estoque', link: '#' },
        ]}
        activeOption="stocks"
      >
        <PageTitle title="Editar Estoque" />

        <Card shadow data-cy="edit-stock-form__div">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={schema}
            initialValues={initialValues}
          >
            <Typography color="primary" variant="h6" capitalize="uppercase">
              Dados do Estoque
            </Typography>

            <Styled.Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField label="Nome" name="name" autoFocus />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <AutocompleteState />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <AutocompleteCity />
              </Grid>
            </Styled.Grid>

            <Styled.Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography color="primary" variant="h6" capitalize="uppercase">
                  Status do Estoque
                </Typography>
                <CheckboxSimple name="active" label="Ativo" />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography color="primary" variant="h6" capitalize="uppercase">
                  Estoquista
                </Typography>
                <CheckboxSimple name="new_stockist" label="Novo Estoquista" />
              </Grid>
            </Styled.Grid>

            <FieldsNewStockist />

            <Styled.ButtonContainer>
              <Styled.Button
                variant="contained"
                color="primary"
                size="large"
                disabled={status.loading}
              >
                {labelButtonSubmit}
              </Styled.Button>
            </Styled.ButtonContainer>
          </Formik>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(EditStock, StockDetailProvider, EditStockProvider)
