import styled from 'styled-components'

import MUTextField from '@material-ui/core/TextField'

export const TextField = styled(MUTextField)`
  border-radius: 6px;
  min-width: 240px;

  .MuiOutlinedInput-root {
    height: ${({ theme }) => theme.spacing(6)}px;
    border-radius: 6px;
  }
`
