import styled from 'styled-components'

export const CardsQuantityChips = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    overflow-y: auto;
  }
`
