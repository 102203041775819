import { RoleName } from 'hooks/auth/props'

export const cashFlowRoles: string[] = [
  'attendant',
  'commercial_coordinator',
  'general_coordinator',
  'office_leader',
  'office_stockist',
  'sale_leader',
  'pap_leader',
  'pap_seller',
  'supervisor',
  'general_manager',
]

export const belongsToCashFlow = (user: string | undefined): boolean => {
  if (user) {
    return cashFlowRoles.includes(user)
  }
  return false
}

export const rolePermission = (
  role: RoleName | undefined,
): 'higherPermission' | 'midlePermission' | 'sellerPermission' => {
  switch (role) {
    case 'dev':
    case 'admin':
    case 'general_coordinator':
    case 'commercial_coordinator':
    case 'general_manager':
    case 'office_leader':
      return 'higherPermission'
    case 'supervisor':
    case 'sale_leader':
      return 'midlePermission'
    default:
      return 'sellerPermission'
  }
}
