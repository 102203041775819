/* eslint-disable consistent-return */
import React, { useMemo, FC, memo } from 'react'

import HelpIcon from 'components/HelpIcon'
import TextField from 'components/Inputs/TextField'
import Typography from 'components/Typography'
import { useFormikContext } from 'formik'
import { useICCIDS } from 'hooks/iccids'
import validateICCID from 'utils/validateICCID'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

import { useQuantityChips } from '../../context'
import { DataFormAddTransferType } from '../../context/props'
import { IntervalsIccidsProps } from '../SectionICCIDs/props'

const IntervalsIccids: FC<IntervalsIccidsProps> = props => {
  const { index } = props

  const { values } = useFormikContext<DataFormAddTransferType>()
  const { status, isValidQuantity } = useICCIDS()

  const { quantityChips } = useQuantityChips(
    values.iccids_intervals[index].first_iccid,
    values.iccids_intervals[index].last_iccid,
    index,
  )

  const renderErrorMultipleFive = useMemo(() => {
    if (!isValidQuantity) {
      return {
        helperText: 'Quantidade de chips deve ser múltiplo de cinco',
        error: true,
      }
    }
  }, [isValidQuantity])

  const renderQuantityChips = useMemo(() => {
    if (status.loading) {
      return <CircularProgress size={20} />
    }

    if (
      validateICCID(
        values.iccids_intervals.map(item => [
          item.first_iccid,
          item.last_iccid,
        ]),
      )
    ) {
      return (
        <>
          <Typography color="initial" variant="subtitle1">
            Qtd. de chips
          </Typography>
          <Typography color="primary" variant="h6" capitalize="uppercase">
            {quantityChips}
          </Typography>
        </>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityChips, status.loading])

  return (
    <>
      <Grid lg={5} md={5} sm={12} xs={12} item>
        <TextField
          label="ICCID Inicial"
          name={`iccids_intervals[${index}].first_iccid`}
          mask="99999999999999999999"
          {...renderErrorMultipleFive}
          InputProps={{
            endAdornment: <HelpIcon />,
          }}
        />
      </Grid>

      <Grid lg={5} md={5} sm={12} xs={12} item>
        <TextField
          label="ICCID Final"
          name={`iccids_intervals[${index}].last_iccid`}
          mask="99999999999999999999"
          {...renderErrorMultipleFive}
        />
      </Grid>

      <Grid item xl={2} md={2} sm={12} xs={12}>
        {renderQuantityChips}
      </Grid>
    </>
  )
}

export default memo(IntervalsIccids)
