import React, { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { useCommission, CommissionProvider } from 'hooks/commissions'
import RoutingService from 'services/routing'
import formatPrice from 'utils/formatPrice'
import withContext from 'utils/withContext'

import { Grid } from '@material-ui/core'

import CommissionListHeader from './components/CommissionListHeader'
import CommissionListItem from './components/CommissionListItem'
import * as Styled from './styles'

const Commissions: FC = () => {
  const {
    data,
    to,
    totalCommissions,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    getCommissions,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
    status,
    preview,
  } = useCommission()

  const { companyId } = useParams<any>()

  const renderItem = useCallback<TableRenderItem<any>>(
    item => <CommissionListItem key={item.id} {...item} />,
    [],
  )

  useEffect(() => {
    if (!requestStatus.loading) {
      if (companyId !== ':companyId') {
        getCommissions(companyId)
      } else {
        getCommissions()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, companyId])

  return (
    <Route
      title="Comissões e relatórios"
      access="auth"
      requireRoles={[
        'analyst',
        'admin',
        'distributor',
        'dev',
        'company_representative',
        'financial',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          { title: 'Relatórios e comissões' },
        ]}
        activeOption="commissions"
      >
        <PageTitle title="Comissões e relatórios" />

        <Styled.Card>
          <Grid item lg={12} sm={12} md={12}>
            <Styled.TypographyTitle variant="subtitle1" color="initial">
              Previsão de comissão mês corrente
            </Styled.TypographyTitle>
          </Grid>
          <Grid>
            <Styled.Typography variant="subtitle2" color="primary">
              {formatPrice({ price: preview?.total_value || 0 })}
            </Styled.Typography>
          </Grid>
        </Styled.Card>

        <Styled.Card>
          <Grid item lg={12} sm={12} md={12}>
            <Styled.TypographyTitle variant="subtitle1" color="initial">
              Valor a ser recebido
            </Styled.TypographyTitle>
          </Grid>
          <Grid>
            <Styled.Typography variant="subtitle2" color="primary">
              {formatPrice({ price: totalCommissions || 0 })}
            </Styled.Typography>
          </Grid>
        </Styled.Card>

        <Table
          data={data}
          loadingRequest={requestStatus.loading}
          renderItem={renderItem}
          HeaderComponent={<CommissionListHeader />}
          FooterComponent={
            <TablePagination
              getData={() => getCommissions(companyId)}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Commissions, CommissionProvider)
