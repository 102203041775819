import React, { FC, useCallback, memo, useEffect, useMemo } from 'react'

import { useField, useFormikContext } from 'formik'
import { useCities, City } from 'hooks/location/cities'
import { debounce } from 'utils/debounce'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { CreateStockData } from '../../context/props'
import * as Styled from './styles'

const AutocompleteLocation: FC = () => {
  const { cities, getCities, status: requestStatusListCities } = useCities()
  const [field, meta, helpers] = useField<string>('city_id')
  const { values } = useFormikContext<CreateStockData>()

  useEffect(() => {
    getCities(values.state_id || '', field.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.state_id])

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }
    return {}
  }, [meta])

  const getCitiesOptionLabel = useCallback((option: City) => option.name, [])

  const onInputCityChange = useCallback(
    (_, value: string) => getCities(values.state_id || '', value),
    [getCities, values.state_id],
  )

  const onCityChange = useCallback(
    (event: React.ChangeEvent<{}>, value: City | null) => {
      if (value) {
        helpers.setValue(value.id, true)
      } else {
        field.onChange(event)
      }
    },
    [field, helpers],
  )

  return (
    <Autocomplete
      id="combo-box-cities"
      options={cities}
      getOptionLabel={getCitiesOptionLabel}
      noOptionsText="nenhuma cidade encontrada"
      // PaperComponent={PaperComponent}
      loading={requestStatusListCities.loading}
      onInputChange={debounce(onInputCityChange, 500)}
      onChange={onCityChange}
      autoComplete
      renderInput={params => (
        <Styled.TextField
          label="Cidade"
          variant="outlined"
          name="city_id"
          {...errorProps}
          {...params}
          onBlur={field.onBlur}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  )
}

export default memo(AutocompleteLocation)
