import { StyleSheet } from '@react-pdf/renderer'

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  textTitle: {
    color: '#083D93',
    fontSize: 12,
    fontWeight: 'medium',
  },
  textValueItem: {
    marginTop: 10.2,
    borderStyle: BORDER_STYLE,
    borderBottomColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    marginLeft: 1,
    fontSize: 9,
    fontWeight: 'medium',
    color: '#000',
  },
  textValueItemSell: {
    marginTop: 11.3,
    borderStyle: BORDER_STYLE,
    borderBottomColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    marginLeft: 1,
    fontSize: 8,
    fontWeight: 'medium',
    color: '#000',
  },
  containerTable: {
    padding: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCellHeader: {
    color: '#083D93',
    margin: 2,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  tableColHeader: {
    width: '33%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
})

export default styles
