import React, { FC, useMemo, useCallback, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import {
  NotificationProvider,
  useNotification,
} from 'hooks/auth/notifications/useNotifications'
import UrlHandler from 'utils/urlHandler'
import withContext from 'utils/withContext'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Fade from '@material-ui/core/Fade'
import Popper from '@material-ui/core/Popper'

import * as Styled from './styles'

const NotificationIndicator: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const {
    notifications,
    getNotifications,
    readNotification,
    readNotificationsStatus,
  } = useNotification()

  useEffectOnce(() => {
    getNotifications()
  })

  useEffect(() => {
    if (readNotificationsStatus.success) {
      getNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readNotificationsStatus.success])

  const renderTick = useMemo(() => {
    if (notifications.length <= 0) return null
    return <circle cx="17.5" cy="4.5" r="2.5" fill="#F56E28" />
  }, [notifications])

  const id = useMemo(
    () => (open ? 'spring-popper-notification-indicator' : ''),
    [open],
  )

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      setAnchorEl(anchorEl ? null : event.currentTarget),
    [anchorEl],
  )

  const handleClose = useCallback(() => setAnchorEl(null), [])

  const renderNotifications = useMemo(() => {
    if (!notifications) return null
    if (notifications.length === 0) {
      return <Styled.Option>Nenhuma notificação</Styled.Option>
    }

    return notifications.map(notification => {
      const onClick = () => readNotification(notification.id)
      const content = (
        <Styled.Option key={notification.id} onClick={onClick}>
          {notification.content}
        </Styled.Option>
      )

      if (!notification.link) return content
      const link = UrlHandler.removeHostname(notification.link || '')

      return (
        <Link key={notification.id} to={link}>
          {content}
        </Link>
      )
    })
  }, [notifications, readNotification])

  return (
    <Styled.Root onClick={handleOpen} disableRipple>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.00078 13.682V13.4759C4.03101 12.8661 4.22645 12.2753 4.56692 11.7643C5.13365 11.1506 5.5216 10.3984 5.69008 9.58681C5.69008 8.95954 5.69008 8.3233 5.74487 7.69603C6.02795 4.67616 9.01391 2.58824 11.9633 2.58824H12.0364C14.9858 2.58824 17.9718 4.67616 18.264 7.69603C18.3188 8.3233 18.264 8.95954 18.3097 9.58681C18.4804 10.4003 18.868 11.1548 19.4328 11.7733C19.7758 12.2797 19.9716 12.8684 19.999 13.4759V13.673C20.0194 14.4923 19.7372 15.2911 19.2045 15.9223C18.5007 16.6602 17.5455 17.1193 16.5199 17.2127C13.5125 17.5353 10.4781 17.5353 7.4707 17.2127C6.44625 17.1153 5.49248 16.6569 4.78608 15.9223C4.26165 15.2907 3.98329 14.4966 4.00078 13.682Z"
          fill={notifications.length <= 0 ? '' : '#083D93'}
          stroke="#083D93"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.69873 20.3311C10.1686 20.9209 10.8587 21.3026 11.6162 21.3917C12.3737 21.4809 13.1361 21.2701 13.7348 20.806C13.9189 20.6688 14.0846 20.5092 14.2279 20.3311"
          stroke="#083D93"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        {renderTick}
      </svg>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={200}>
              <div>
                <Styled.Pointer />
                <Styled.ContainerOptions>
                  {renderNotifications}
                </Styled.ContainerOptions>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Styled.Root>
  )
}

export default withContext(NotificationIndicator, NotificationProvider)
