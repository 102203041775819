import React, { FC, memo } from 'react'

import * as Styled from './styles'

const Info: FC = () => (
  <Styled.Container>
    <h1>
      CRM Produtos.
      <br />
      Nossa plataforma de dados
    </h1>

    <h2>Obtenha todos os dados e informações necessárias.</h2>
  </Styled.Container>
)

export default memo(Info)
