import React, { FC } from 'react'

import { HasQrCodeTypes } from './props'
import {
  ContainerQRCode,
  ICCCIDQRCode,
  TextInput,
  ContainerQrCodeDefault,
} from './styles'

const HasQrcode: FC<HasQrCodeTypes> = props => {
  const { QRCODE } = props
  if (QRCODE) {
    return (
      <ContainerQRCode>
        <ICCCIDQRCode value={QRCODE ? QRCODE : ' '} />
        <TextInput label="Link para venda" variant="outlined" value={QRCODE} />
      </ContainerQRCode>
    )
  } else {
    return (
      <ContainerQrCodeDefault>
        <img src={require('assets/images/QrCode.png').default} />
      </ContainerQrCodeDefault>
    )
  }
}

export default HasQrcode
