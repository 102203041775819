import React, { FC, useEffect, useState } from 'react'

import Card from 'components/Card'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import { ChipsCashFlowProvider, useChipsCashFlow } from 'hooks/ChipsCashFlow'
import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import RoutingService from 'services/routing'
import {
  TranslateCategorySell,
  TranslateToInglishCategorySell,
} from 'utils/Translate'
import withContext from 'utils/withContext'

import { Button, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { ValidationProps } from '../Details/props'
import SaleType from './components/SaleType'
import SelectPackage from './components/SelectPackage'
import Value from './components/SelectValue'
import * as Styled from './styles'
import { Input } from './styles'

const EditSell: FC = () => {
  const [iccid, setIccid] = React.useState<string | undefined>('')
  const [numberChip, setNumberChip] = React.useState<string>('')
  const [sellDetails, setSellDetails] = useState<ValidationProps>()
  const { packageRecharge, chipValue, saleType } = useChipsCashFlow()
  const [updating, setUpdating] = useState(false)
  const { addToast } = useToast()
  const handleChange = async () => {
    setUpdating(true)
    try {
      const params = {
        iccid: iccid,
        phone_number: numberChip,
        pack: packageRecharge,
        value: Number(chipValue.replace('R$', '').replace(',', '.')),
        category: TranslateToInglishCategorySell(saleType),
      }
      await api.put(`sales-to-customers/${sellDetails?.id}`, params)
      addToast({
        type: 'success',
        title: 'Edição concluída com sucesso ',
      })
      setUpdating(false)
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Algo está errado',
      })
      setUpdating(false)
    }
  }

  useEffect(() => {
    const detalhes = sessionStorage.getItem('detalhes')
    if (detalhes) {
      const json = JSON.parse(detalhes)
      setSellDetails(json)
      setIccid(json.chip.iccid)
      setNumberChip(json.phone_number)
    }
  }, [])

  return (
    <Route
      title="Editar Usuário"
      access="auth"
      requireRoles={[
        'admin',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'sale_leader',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Editar Venda',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-reports"
      >
        <PageTitle title="Editar Venda" />

        <Card>
          <Grid container spacing={3}>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <Styled.TitlePanel variant="body1">
                dados da venda
              </Styled.TitlePanel>
            </Grid>
            <Grid item xl={4} md={6} sm={12} xs={12}>
              <SelectPackage package_edit={sellDetails?.pack} />
            </Grid>
            <Grid item xl={4} md={6} sm={12} xs={12}>
              <SaleType
                sale_type_edit={TranslateCategorySell(sellDetails?.category)}
              />
            </Grid>
            <Grid item xl={4} md={6} sm={12} xs={12}>
              <Value sale_value_edit={sellDetails?.price} />
            </Grid>
            <Grid item xl={4} md={6} sm={12} xs={12}>
              <Typography variant="subtitle1">Número do chip</Typography>
              <Styled.CelPhoneNumber
                mask="(99) 99999-9999"
                maskChar={null}
                placeholder="Número do chip"
                value={numberChip}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNumberChip(e.target.value)
                }}
              />
            </Grid>
            <Grid item xl={4} md={6} sm={12} xs={12}>
              <Typography variant="subtitle1">ICCID</Typography>
              <Input
                mask="99999999999999999999"
                maskChar={null}
                placeholder="ICCID"
                value={iccid}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIccid(e.target.value)
                }}
              />
            </Grid>

            <Grid item xl={12} md={12} sm={12} xs={12}>
              <Styled.ButtonContainer>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={handleChange}
                  disabled={updating}
                >
                  Atualizar
                </Button>
              </Styled.ButtonContainer>
            </Grid>
          </Grid>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(EditSell, ChipsCashFlowProvider)
