import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { PaginationParamsWithNameContains } from 'interfaces/common'
import { api } from 'services/api'
import { paginationReducer, PaginationActions } from 'services/pagination'

import { UserContextData, ListUsersResponse, User } from './props'
import { initialState } from './reducer'

const initialValue = {} as UserContextData

export const UserContext = createContext(initialValue)

export const UserProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(paginationReducer<User>(), initialState)

  const getUsers = useCallback(
    async (name_contains?: string) => {
      try {
        dispatch({ type: PaginationActions.Request })

        const params: PaginationParamsWithNameContains = {
          page: state.current_page,
          per_page: state.per_page,
        }

        if (name_contains) {
          params.name_contains = name_contains
        }

        const response = await api.get<ListUsersResponse>('/users', {
          params,
        })

        dispatch({ type: PaginationActions.RequestSuccess, payload: response })
      } catch (e) {
        dispatch({ type: PaginationActions.RequestError })
      }
    },
    [state],
  )

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <UserContext.Provider
      value={{
        ...state,
        getUsers,
        nextPage,
        previousPage,
        setLimit,
        setPage,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUser(): UserContextData {
  const context = useContext(UserContext)

  if (!context || context === initialValue) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return context
}
