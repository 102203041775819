import React, { FC, Fragment, memo, useMemo } from 'react'

import Typography from 'components/Typography'

import Grid from '@material-ui/core/Grid'

import { SectionCalcProps } from './props'
import * as Styled from './styles'

const SectionCalcTransfer: FC<SectionCalcProps> = props => {
  const { title, data } = props

  const renderTitle = useMemo(() => {
    if (title) {
      return (
        <Grid item xs={12}>
          <Typography color="primary" variant="h5">
            {title}
          </Typography>
        </Grid>
      )
    }
    return <></>
  }, [title])

  const renderLines = useMemo(
    () =>
      data.map(item => (
        <Fragment key={`item_${item.label}`}>
          <Grid item xs={3}>
            <Typography color="textPrimary" variant="subtitle1">
              {item.label}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Styled.BoldText color="textPrimary" variant="subtitle1">
              {item.value}
            </Styled.BoldText>
          </Grid>
        </Fragment>
      )),
    [data],
  )

  return (
    <Grid container spacing={1}>
      {renderTitle}

      {renderLines}
    </Grid>
  )
}

export default memo(SectionCalcTransfer)
