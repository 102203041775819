import React, { FC, memo } from 'react'
import ReactExport from 'react-data-export'

import { ExportReportProps } from './props'
import * as Styled from './styles'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportReport: FC<ExportReportProps> = props => {
  const { data, showExcelColumnDaysInOverdue, nameReport } = props

  return (
    <ExcelFile
      filename={nameReport}
      element={
        <Styled.Button variant="contained" color="secondary" type="submit">
          Exportar relatório
        </Styled.Button>
      }
    >
      <ExcelSheet data={data} name="Reports">
        <ExcelColumn label="ID da venda" value="sale_id" />
        <ExcelColumn label="Cidade" value="name_city" />
        <ExcelColumn label="Nome fantasia" value="fantasy_name" />
        <ExcelColumn label="CNPJ" value="document" />
        <ExcelColumn label="Data da venda" value="created_at_sale" />
        <ExcelColumn label="Data de vencimento" value="due_date" />
        {showExcelColumnDaysInOverdue ? (
          <ExcelColumn label="Dias em atraso" value="days_in_overdue" />
        ) : (
          <ExcelColumn label="" value="" />
        )}
        <ExcelColumn label="Qtd. de chips" value="quantity_chips" />
        <ExcelColumn label="Valor" value="total_price" />
        <ExcelColumn label="Data de geração" value="created_at" />
      </ExcelSheet>
    </ExcelFile>
  )
}

export default memo(ExportReport)
