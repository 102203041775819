/* eslint-disable import/no-duplicates */
import { parseISO } from 'date-fns'
import formatDate from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'

export const DATE_FORMAT_DMY = 'dd/MM/yyyy'
export const DATE_FORMAT_DMY_HOUR_MINUTE = "dd/MM/yyyy 'às' HH:mm"
export const DATE_FORMAT_DMY_HOUR_MINUTE_SECOND = "dd/MM/yyyy 'às' HH:mm:ss"

interface FormatDateProps {
  date: string
  format: string
}
function customFormatDate(props: FormatDateProps): string {
  const { date, format } = props
  return formatDate(parseISO(date), format, { locale: ptBR })
}

export default customFormatDate
