import React, { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { BonusProvider, useBonus } from 'hooks/Bonus'
import { Bonus as BonusInterface } from 'interfaces/commission'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import BonusList from './components/BonusListHeader'
import BonusListItem from './components/BonusListItem'

const Bonus: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    getBonus,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
    status,
  } = useBonus()

  const { companyId } = useParams<any>()

  const renderItem = useCallback<TableRenderItem<BonusInterface>>(
    item => <BonusListItem key={item.id} {...item} />,
    [],
  )

  useEffect(() => {
    if (!requestStatus.loading) {
      if (companyId !== ':companyId') {
        getBonus(companyId)
      } else {
        getBonus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, companyId])

  return (
    <Route
      title="Bonificação"
      access="auth"
      requireRoles={[
        'analyst',
        'admin',
        'distributor',
        'dev',
        'company_representative',
        'financial',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          { title: 'Bonificação' },
        ]}
      >
        <PageTitle title="Bonificação" />

        <Table
          data={data}
          loadingRequest={requestStatus.loading}
          renderItem={renderItem}
          HeaderComponent={<BonusList />}
          FooterComponent={
            <TablePagination
              getData={() => getBonus(companyId)}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Bonus, BonusProvider)
