/* eslint-disable no-use-before-define */
import React from 'react'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { SelectStores } from './props'

export default function ComboBox(props: SelectStores) {
  const { width, options, value, labelName, setOption } = props
  return (
    <Autocomplete
      id="controllable-states-demo"
      value={value}
      options={options}
      getOptionLabel={option => option?.name}
      style={{ width: width }}
      disableCloseOnSelect={false}
      onChange={(e, value) => setOption(value)}
      selectOnFocus={true}
      renderInput={params => (
        <TextField {...params} label={labelName} variant="outlined" />
      )}
    />
  )
}
