import React, { FC, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Route from 'components/Route'
import Typography from 'components/Typography'
import { useAuth } from 'hooks/auth'
import { ProductProvider } from 'hooks/product'
import RoutingService from 'services/routing'
import version from 'utils/getVersion'
import { belongsToCashFlow } from 'utils/permissions'
import withContext from 'utils/withContext'

import CardProduct from './components/CardProduct'
import products from './data'
import * as Styled from './styles'

const Products: FC = () => {
  const { user, signOut } = useAuth()
  const router = useHistory()

  const actualYear = useMemo(() => new Date().getFullYear(), [])
  useEffect(() => {
    if (belongsToCashFlow(user?.role.name)) {
      router.push(RoutingService.CASH_FLOW)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role.name])

  const cardsProducts = useMemo(
    () =>
      products.map(product => (
        <CardProduct
          key={`card_product_${product.name}`}
          name={product.name}
          route={product.route}
        />
      )),
    [],
  )

  return (
    <Route title="Produtos" access="auth" requireRoles={[]}>
      <Styled.Main>
        <div>
          <Styled.HeaderContainer>
            <Styled.Container>
              <Styled.Header>
                <h1>CRM Produtos</h1>

                <Styled.Button
                  data-testid="button-logout"
                  variant="outlined"
                  onClick={signOut}
                  size="small"
                >
                  Sair
                </Styled.Button>
              </Styled.Header>
            </Styled.Container>
          </Styled.HeaderContainer>
          <Styled.Container>
            <Styled.Welcome>
              <h2 data-testid="text-welcome">Olá, {user?.name}</h2>
              <h3>Analise a performance do seu produto em tempo real.</h3>
            </Styled.Welcome>

            <Styled.ContainerTitle>
              <h3>Produtos:</h3>
            </Styled.ContainerTitle>
            <Styled.ProductsList data-testid="container-products">
              {cardsProducts}
            </Styled.ProductsList>
          </Styled.Container>
        </div>

        <Styled.Footer>
          <Typography variant="caption" align="center" color="primary">
            CRM Produtos {actualYear} © Brisanet Telecomunicações - Todos os
            direitos reservados. Versão {version}
          </Typography>
        </Styled.Footer>
      </Styled.Main>
    </Route>
  )
}

export default withContext(Products, ProductProvider)
