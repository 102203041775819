import React, { createContext, useContext, useMemo, FC, useState } from 'react'

import { useAuth } from 'hooks/auth'

import { MenuOptionType } from '../components/OptionsList/props'
import { HOME, SALES } from './options'
import { LayoutProductData } from './props'
import {
  adminRoles,
  analystRoles,
  company_representative_franchisee,
  devRoles,
  distributorRoles,
  financial,
  financial_collection,
  stockist,
  cashflow,
} from './roles'

const defaultValue = {} as LayoutProductData

const LayoutProductContext = createContext(defaultValue)

export const LayoutProductProvider: FC = props => {
  const [menuTemplate, setMenuTemplate] = useState<string>('')
  const { children } = props
  const { hasRole, user } = useAuth()

  const menuOptions = useMemo<MenuOptionType[]>(() => {
    // Layout Order
    // HOME, DISTRIBUTORS, USERS, SALES, COMPANIES, STOCKS, MY_STOCK, TRANSFERS, CONSOLIDATED_REPORTS

    const options = [HOME]

    if (menuTemplate === 'cashflow') {
      return cashflow
    }

    if (hasRole('dev')) {
      options.push(...devRoles)
      return options
    }

    if (hasRole(['admin'])) {
      options.push(...adminRoles)
      return options
    }

    if (hasRole(['analyst'])) {
      options.push(...analystRoles)
      return options
    }

    if (hasRole('distributor')) {
      options.push(...distributorRoles)
      return options
    }

    if (hasRole('stockist')) {
      options.push(...stockist)
      return options
    }

    if (hasRole('company_representative')) {
      options.push({ ...SALES, name: 'Compras' })
      if (user?.company_representative?.company.type === 'franchisee') {
        options.push(...company_representative_franchisee)
        return options
      }
      return options
    }

    if (hasRole('financial')) {
      options.push(...financial)
      return options
    }

    if (hasRole('financial_collection')) {
      options.push(...financial_collection)
      return options
    }

    return options
  }, [hasRole, menuTemplate, user?.company_representative?.company.type])

  const value = useMemo(() => ({ menuOptions }), [menuOptions])

  return (
    <LayoutProductContext.Provider
      value={{
        ...value,
        setMenuTemplate,
      }}
    >
      {children}
    </LayoutProductContext.Provider>
  )
}

export function useLayoutProduct(): LayoutProductData {
  const context = useContext(LayoutProductContext)

  if (!context || context == defaultValue) {
    throw new Error(
      'useLayoutProduct must be used within a LayoutProductProvider',
    )
  }

  return context
}
