import React, { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { FormCompanyProvider } from 'components/FormCompany/context'
import LayoutProduct from 'components/LayoutProduct'
import RenderWithRoles from 'components/RenderWithRoles'
import Route from 'components/Route'
// import { useAuth } from 'hooks/auth'
import { useProduct } from 'hooks/product'
import { ProductProps } from 'hooks/product/props'
// import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

// import LinkCard from './components/LinkCard'
import Panel from './components/Panel'
import ReportCommissionPackage from './components/ReportCommissionPackage'
import ReportRechargePackageDischarges from './components/ReportRechargePackageDischarges'
import ProductProvider from './context'
// import * as Styled from './styles'

const Product: FC = () => {
  const { getProductById } = useProduct()
  const params = useParams<any>()
  const { productId } = params

  // const { hasRole } = useAuth()
  const [product, setProduct] = useState<ProductProps>({} as ProductProps)

  useEffect(() => {
    setProduct(getProductById(productId as string))
  }, [getProductById, productId])

  // const renderFastAccessTitle = useMemo(() => {
  //   if (hasRole(['company_representative', 'stockist']) && !hasRole('dev')) {
  //     return null
  //   }

  //   return (
  //     <Styled.SubTitle variant="h5" color="primary">
  //       Acesso rápido
  //     </Styled.SubTitle>
  //   )
  // }, [hasRole])

  return (
    <Route title={product?.name} access="auth">
      <LayoutProduct crumbs={[{ title: '' }]} activeOption="home">
        <RenderWithRoles
          roles={['admin', 'dev', 'company_representative', 'analyst']}
        >
          <Panel />
        </RenderWithRoles>

        <RenderWithRoles
          roles={['admin', 'dev', 'company_representative', 'distributor']}
        >
          <ReportRechargePackageDischarges />
        </RenderWithRoles>

        <RenderWithRoles
          roles={['admin', 'dev', 'company_representative', 'distributor']}
        >
          <ReportCommissionPackage />
        </RenderWithRoles>

        {/* {renderFastAccessTitle} */}

        {/* <Styled.GridCards>
          <RenderWithRoles roles={['admin', 'dev']}>
            <LinkCard
              label="Cadastrar usuário"
              to={RoutingService.NEW_USER}
              data-cy="add-user__a"
            />
          </RenderWithRoles>

          <RenderWithRoles roles={['admin', 'dev', 'analyst', 'distributor']}>
            <LinkCard
              label="Ver estoques"
              to={RoutingService.STOCKS}
              data-cy="list-stock__a"
            />
          </RenderWithRoles>

          <RenderWithRoles roles={['distributor', 'admin', 'dev']}>
            <LinkCard
              label="Cadastrar revendedor"
              to={RoutingService.NEW_COMPANY}
              data-cy="add-company__a"
            />
          </RenderWithRoles>

          <RenderWithRoles roles={['admin', 'dev', 'analyst']}>
            <LinkCard
              label="Ver distribuidores"
              to={RoutingService.DISTRIBUTORS}
              data-cy="list-distributors__a"
            />
          </RenderWithRoles>
        </Styled.GridCards> */}
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Product, ProductProvider, FormCompanyProvider)
