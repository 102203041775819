import React, { FC, memo, useMemo } from 'react'

import TableRowItem from 'components/TableRowItem'
import customFormatDate, {
  DATE_FORMAT_DMY_HOUR_MINUTE_SECOND,
} from 'utils/formatDate'
import customFormatPrice from 'utils/formatPrice'

import TableCell from '@material-ui/core/TableCell'

const SalesListItem: FC<any> = props => {
  const { telefone, valor, cpf, data } = props

  const price = useMemo(() => {
    return customFormatPrice({ price: valor })
  }, [valor])

  const formattedDate = useMemo(() => {
    return customFormatDate({
      date: data,
      format: DATE_FORMAT_DMY_HOUR_MINUTE_SECOND,
    })
  }, [data])

  return (
    <TableRowItem>
      <TableCell>{telefone}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{cpf}</TableCell>
      <TableCell>{formattedDate}</TableCell>
    </TableRowItem>
  )
}

export default memo(SalesListItem)
