import React, { FC, useState, useRef, useCallback, useMemo, memo } from 'react'

import Formik from 'components/Formik'
import { FormikProps } from 'formik'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

import { useUploadFileVivo } from '../../../context'
import { UploadFileDischargesValidate } from '../../../context/props'
import schema from './schema'
import * as Styled from './styles'

const initialValues: UploadFileDischargesValidate = {
  discharges: null,
  referring_date: new Date(),
}

const FormUploadFileDischarges: FC = () => {
  const { sendUploadDischarges, status, listFiles } = useUploadFileVivo()
  const [textButton, setTextButton] = useState('Selecione o arquivo')

  const titleButton = useMemo(
    () => (status.loading ? <CircularProgress size={20} /> : 'Upload'),
    [status.loading],
  )

  const isDisableButton = useMemo(() => !!status.loading, [status.loading])

  const formikRef = useRef<FormikProps<UploadFileDischargesValidate> | null>()

  const handleChangeFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return

      setTextButton(event.target.files[0].name)

      formikRef.current?.setFieldValue('discharges', event.target.files[0])
    },
    [],
  )

  const handleSubmit = useCallback(async values => {
    await sendUploadDischarges({
      discharges: formikRef.current?.values.discharges as File,
      referring_date: values.referring_date,
    })

    setTextButton('Selecione o arquivo')

    await listFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik
      innerRef={formikRef as any}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnMount
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Styled.InputUpload
            accept=".gpg"
            id="button-file-discharges"
            name="input_file"
            onChange={handleChangeFile}
            type="file"
          />

          <Styled.Label htmlFor="button-file-discharges">
            <Styled.ButtonUpload
              variant="outlined"
              color="primary"
              size="large"
              component="span"
            >
              <Styled.TextButton textOverflow="ellipsis" overflow="hidden">
                {textButton}
              </Styled.TextButton>
            </Styled.ButtonUpload>
          </Styled.Label>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Styled.DatePicker
            label="Data de referência"
            inputVariant="outlined"
            name="referring_date"
            format="dd/MM/yyyy"
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Styled.ButtonSubmit
            variant="contained"
            color="primary"
            disabled={isDisableButton}
          >
            {titleButton}
          </Styled.ButtonSubmit>
        </Grid>
      </Grid>
    </Formik>
  )
}

export default memo(FormUploadFileDischarges)
