import React, { FC, memo, useState } from 'react'

import TableHead from 'components/TableHead'
import { useCompany } from 'hooks/company'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const CompanyListHeader: FC = () => {
  const { listCompanies } = useCompany()
  const [search, setSearch] = useState('')

  return (
    <TableHead
      title="Todos os revendedores"
      searchFormPlaceholder="Encontre um revendedor"
      filter={[
        {
          type: 'text',
          value: search,
          onChange: setSearch,
          label: 'Encontre um revendedor',
        },
      ]}
      onClickFilter={() => listCompanies(search)}
    >
      <TableRow>
        <TableCell>Nome</TableCell>
        <TableCell>Documento</TableCell>
        <TableCell>Cidade</TableCell>

        <TableCell colSpan={2}>Status</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default memo(CompanyListHeader)
