import React, { FC, useEffect } from 'react'

import Typography from 'components/Typography'
import { useChipsCashFlow } from 'hooks/ChipsCashFlow'
import { chipValues } from 'pages/NewSalesCashFlow/components/dataValues'

import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

import { EditSale } from '../props'
import { SelectValue } from './styles'
const Value: FC<EditSale> = props => {
  const { sale_value_edit } = props
  const { chipValue, setChipValue } = useChipsCashFlow()
  useEffect(() => {
    if (sale_value_edit) {
      setChipValue(sale_value_edit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sale_value_edit])
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChipValue(event.target.value as string)
  }
  return (
    <FormControl variant="outlined">
      <Typography variant="subtitle1">Valor do chip</Typography>
      <SelectValue value={chipValue} onChange={handleChange}>
        <MenuItem value={chipValue}>{` R$ ${chipValue}`}</MenuItem>
        {chipValues.map(item => (
          <MenuItem value={item} key={`${item}`}>
            {item}
          </MenuItem>
        ))}
      </SelectValue>
    </FormControl>
  )
}

export default Value
