/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useMemo } from 'react'

import {
  Cell,
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import { useProduct } from '../../../context'
import * as Styled from './styles'

const Chips: FC = () => {
  const { data, status, getColor, translateKey } = useProduct()

  const dataSet = useMemo(() => {
    if (status.loading) {
      return null
    }
    return data
  }, [status.loading, data])

  // const formattedData = useMemo<
  //   Record<keyof ChipsStatusResponse, number>
  // >(() => {
  //   if (!data || status.loading)
  //     return {} as Record<keyof ChipsStatusResponse, number>
  //   const newData: Record<keyof ChipsStatusResponse, number> = {} as any
  //   const validKeys: Array<keyof typeof data> = [
  //     'active',
  //     'barred',
  //     'canceled',
  //     // 'finished',
  //     'pre_active',
  //     'restricted',
  //     'suspended',
  //   ]
  //   validKeys.forEach(key => {
  //     newData[key] = data[key]
  //   })

  //   return newData
  // }, [data, status.loading])

  // const dataset = useMemo(() => {
  //   if (!formattedData || status.loading || !data) return []

  //   const baseArray = Object.entries(formattedData)
  //   const baseDataSetArray = baseArray.map(([key, value]) => ({
  //     value,
  //     key: key as keyof ChipsStatusResponse,
  //   }))
  //   const arrayGambis: typeof baseDataSetArray = [
  //     { key: 'total', value: data.total },
  //   ]

  //   function percentage(num: number, per: number) {
  //     if (num == 0) return 0
  //     return (per / num) * 100
  //   }

  //   baseDataSetArray.forEach(item => {
  //     arrayGambis.push(item, {
  //       key: '%' as any,
  //       value: Number(percentage(data.total, item.value).toFixed(2)),
  //     })
  //   })

  //   return arrayGambis
  // }, [data, formattedData, status.loading])

  const CustomTooltip = useCallback(({ active, payload, label }) => {
    if (active && payload) {
      return (
        <Styled.ContainerTooltip>
          <Styled.Date>{label}</Styled.Date>
          <Styled.AverageValue>
            Quantidade: {payload[0]?.value}
          </Styled.AverageValue>
          <Styled.AverageValue>
            Porcentagem: {(payload[1]?.value).toFixed(2) + '%'}
          </Styled.AverageValue>
        </Styled.ContainerTooltip>
      )
    }

    return null
  }, [])

  const cells = useMemo(() => {
    if (!dataSet?.length) return null

    return dataSet.map(item => {
      const color = getColor(item._id.status)
      return <Cell fill={color} />
    })
  }, [dataSet, getColor])

  if (status.loading) {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Styled.Card>
          <CardContent>
            <Grid item>
              <Styled.Skeleton variant="rect" style={{ marginBottom: 20 }} />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" width="100%" height={471} />
            </Grid>
          </CardContent>
        </Styled.Card>
      </Grid>
    )
  }

  if (dataSet == null) return null

  return (
    <Styled.Container data-testid="container-audience-chart">
      <Styled.TitleGraph variant="h5" color="primary">
        Gráfico painel de acompanhamento
      </Styled.TitleGraph>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={dataSet} stackOffset="sign" margin={{ top: 20 }}>
          <XAxis dataKey={values => `${translateKey(values._id.status)}`} />

          <YAxis />
          <Tooltip cursor={{ fill: '#E0E1E6' }} content={<CustomTooltip />} />

          <Bar dataKey="value" label={{ position: 'top' }}>
            {cells}
          </Bar>

          <Bar
            dataKey="percentage"
            fill="#5D90E3"
            label={{
              position: 'top',
              fill: '#5D90E3',
              formatter: value => {
                const formatedValue = Number(value).toFixed(2)
                return formatedValue + '%'
              },
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Styled.Container>
  )
}

export default Chips
