import React, { FC, memo, useMemo } from 'react'

import Button from 'components/Button'

import DateFilter from '../Date'
import SelectFilter from '../Select'
import TextFilter from '../Text'
import { FilterProps } from './props'
import * as Styled from './styles'

const Filter: FC<FilterProps> = props => {
  const { filter, onClickFilter } = props

  const content = useMemo(() => {
    if (!filter || filter.length == 0) return null

    return filter.map(filt => {
      switch (filt.type) {
        case 'date':
          return <DateFilter {...filt} />
        case 'text':
          return <TextFilter {...filt} />
        case 'select':
          return <SelectFilter {...filt} />
        default:
          return null
      }
    })
  }, [filter])

  if (!filter) return null

  return (
    <Styled.FilterContainer>
      {content}
      <Button
        key={'button-filter'}
        variant="contained"
        color="primary"
        size="small"
        onClick={onClickFilter}
      >
        Filtrar
      </Button>
    </Styled.FilterContainer>
  )
}

export default memo(Filter)
