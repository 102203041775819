import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import Typography from 'components/Typography'
import RoutingService from 'services/routing'

import * as Styled from './styles'

const FormCompleted: FC = () => {
  return (
    <div>
      <Typography color="primary" variant="h5">
        Nenhum token de recuperação encontrado
      </Typography>

      <Styled.ButtonsContainer>
        <Link to={RoutingService.LOGIN}>
          <Button variant="outlined" color="secondary" size="large" fullWidth>
            Voltar para login
          </Button>
        </Link>
      </Styled.ButtonsContainer>
    </div>
  )
}

export default memo(FormCompleted)
