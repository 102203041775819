import styled from 'styled-components'

import { Button, Checkbox } from '@material-ui/core'

export const ExportButton = styled(Button)`
  width: 188px;
  height: 42px;
`

export const ContainerModal = styled.div`
  width: 600px;
  height: 408px;
  display: flex;
  flex-direction: column;
`
export const Icon = styled.img`
  cursor: pointer;
  position: relative;
  top: 20px;
  left: 80%;
`

export const ContainerIcon = styled.div``

export const Title = styled.h1`
  margin-left: 64px;
  margin-top: 33px;
  font-size: 26px;
  color: #1e2d69;
  font-weight: 400;
`

export const Subtitle = styled.h2`
  font-size: 20px;
  color: #1e2d69;
  font-weight: 400;
  margin-left: 64px;
  margin-top: 48px;
`

export const Text = styled.div`
  margin-top: 5px;
  font-size: 16px;
  color: #646466;
  margin-left: 64px;
`
export const CheckBox = styled(Checkbox)``

export const Box = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 60px;
`

export const DowloadButton = styled(Button)`
  width: 188px;
  height: 42px;
  margin-left: 350px;
  margin-top: 20px;
`
