import ButtonProps from 'components/ButtonSubmit'
import DatePickerProps from 'components/Inputs/DatePicker'
import SelectProps from 'components/Inputs/Select'
import styled from 'styled-components'

import GridStyles from '@material-ui/core/Grid'
import TextFieldProps from '@material-ui/core/TextField'

export const Select = styled(SelectProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const Button = styled(ButtonProps)`
  width: 100%;
`

export const DatePicker = styled(DatePickerProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const TextField = styled(TextFieldProps)`
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const GridContainer = styled(GridStyles)`
  margin-top: 70px;
`

export const GridItem = styled(GridStyles)``
