import React, { FC, memo, useRef, useEffect } from 'react'

import ChartJS, { ChartConfiguration } from 'chart.js'

const Chart: FC<ChartConfiguration> = props => {
  const chartRef = useRef<HTMLCanvasElement | null>(null)
  const chartInstanceRef = useRef<ChartJS | undefined>()

  useEffect(() => {
    function handleChart() {
      if (!chartRef.current) return
      const canvasContext = chartRef.current.getContext('2d')
      if (!canvasContext) return

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
      const { children, ...chartProps } = props

      chartInstanceRef.current = new ChartJS(
        canvasContext,
        chartProps,
        // plugins: [ChartDataLabels, ...(chartProps.plugins ?? [])],
      )
    }
    handleChart()
  }, [props])

  return (
    <div
      className="chart-container"
      style={{ position: 'relative', height: '100%', width: '100%' }}
    >
      <canvas ref={chartRef} />
    </div>
  )
}
export default memo(Chart)
