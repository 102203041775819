import { FC, memo } from 'react'

import TableHead from 'components/TableHead'
import { useTransfer } from 'hooks/transfers'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const TransfersListHeader: FC = () => {
  const { getTransfers, setStatus, status } = useTransfer()

  return (
    <TableHead
      title="Todas as transferências"
      filter={[
        {
          label: 'Status',
          type: 'select',
          // @ts-ignore
          onChange: setStatus,
          value: status,
          options: [
            {
              label: 'Pendente',
              value: 'pending',
            },
            {
              label: 'Recusado',
              value: 'denied',
            },
            {
              label: 'Cancelado',
              value: 'canceled',
            },
            {
              label: 'Finalizado',
              value: 'finished',
            },
          ],
        },
      ]}
      onClickFilter={getTransfers}
    >
      <TableRow>
        <TableCell>Ordem</TableCell>
        <TableCell>Origem</TableCell>
        <TableCell>Destino</TableCell>
        <TableCell colSpan={2}>Status</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default memo(TransfersListHeader)
