import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export const Location = styled(Typography)`
  text-transform: uppercase;
  font-weight: 500;
`

export const GridTitle = styled(Grid)`
  align-self: center;
`

export const MapContainer = styled(Grid)`
  height: 290px;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

export const ValidationMap = styled(Typography)`
  font-weight: 500;
`
