import Card from 'components/Card'
import styled from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

export const Grid = styled(MUGrid)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const TableCard = styled(Card)`
  margin-top: ${({ theme }) => theme.spacing(6)}px;
`

export const CardsQuantityChips = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    overflow-y: auto;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    button {
      margin-left: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`
