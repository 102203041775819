import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import { AuthState, AuthReducerActionMap, AuthActions } from './props'
import { getToken } from './storage'

const initialState: AuthState = {
  isAuthenticating: false, // tive que deixar assim a início para evitar re-renderizações
  token: getToken(),
  user: null,
  requestProfileStatus: {
    error: false,
    loading: false,
    success: false,
  },
}

const authReducer: Reducer<AuthState, AuthReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case AuthActions.Reset:
      return initialState

    case AuthActions.RequestUser:
      state.isAuthenticating = true
      return state

    case AuthActions.RequestUserSuccess:
      state.user = action.payload.data.user
      state.token = {
        token: action.payload.data.token,
        expiresAt: action.payload.data.expiresAt,
      }
      state.isAuthenticating = false
      return state

    case AuthActions.RequestUserError:
      state.user = null
      state.token = null
      state.isAuthenticating = false
      return state

    case AuthActions.RequestProfile:
      state.isAuthenticating = true
      state.requestProfileStatus.loading = true
      state.requestProfileStatus.success = false
      state.requestProfileStatus.error = false
      return state

    case AuthActions.RequestProfileSuccess:
      state.user = action.payload.data
      state.isAuthenticating = false
      state.requestProfileStatus.success = true
      state.requestProfileStatus.loading = false
      state.requestProfileStatus.error = false
      return state

    case AuthActions.RequestProfileError:
      state.user = null
      state.token = null
      state.isAuthenticating = false
      state.requestProfileStatus.error = true
      state.requestProfileStatus.loading = false
      state.requestProfileStatus.success = false
      return state

    case AuthActions.SignOut:
      state.user = null
      state.token = null
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(authReducer)
