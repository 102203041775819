import React, { FC, useMemo, useCallback } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import Button from 'components/Button'
import Card from 'components/Card'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import QuantityChipsCard from 'components/QuantityChipsCard'
import { PENDING_CHIPS_HELP_TEXT } from 'components/QuantityChipsCard/helpTexts'
import RenderWithRoles from 'components/RenderWithRoles'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablesTransfers from 'components/TablesTransfers'
import Typography from 'components/Typography'
import { useAuth } from 'hooks/auth'
import {
  useDistributorDetail,
  DistributorDetailProvider,
} from 'hooks/distributors/details'
import { Sale } from 'interfaces/sale'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import ListHeader from './components/ListHeader'
import ListItem from './components/ListItem'
import * as Styled from './styles'

const DistributorDetails: FC = () => {
  const { push } = useHistory()
  const { distributorId } = useParams<any>()
  const { distributor, getDistributor, requestStatus } = useDistributorDetail()

  const { hasRole } = useAuth()

  useEffectOnce(() => {
    getDistributor(distributorId)
  })

  const firstName = useMemo(() => {
    if (!distributor) return null
    const [name] = distributor.user.name.split(' ')
    return name
  }, [distributor])

  const renderItem = useCallback<TableRenderItem<Sale>>(
    item => <ListItem key={item.id} {...item} />,
    [],
  )

  const redirectToNew = useCallback(
    () => push(RoutingService.NEW_DISTRIBUTOR),
    [push],
  )

  const editRoute = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.EDIT_DISTRIBUTOR,
        'distributorId',
        distributorId,
      ),
    [distributorId],
  )

  const renderPageTitle = useMemo(() => {
    if (hasRole(['admin', 'dev'])) {
      return (
        <PageTitle
          title={`Detalhes de ${firstName}`}
          showButton
          buttonText="Novo Distribuidor"
          buttonOnClick={redirectToNew}
        />
      )
    }

    return <PageTitle title={`Detalhes de ${firstName}`} />
  }, [firstName, hasRole, redirectToNew])

  if (requestStatus.loading) return <Loading />

  if (requestStatus.error || !distributor) {
    return (
      <Route title="Distribuidor" access="auth" requireRoles={[]}>
        <LayoutProduct
          crumbs={[
            { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
            { title: 'Distribuidores', link: '#' },
          ]}
          activeOption="distributors"
        >
          <Typography color="primary" variant="h6">
            Ocorreu um erro, tente novamente
          </Typography>
        </LayoutProduct>
      </Route>
    )
  }

  return (
    <Route title="Distribuidor" access="auth" requireRoles={[]}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Distribuidores', link: '#' },
        ]}
        activeOption="distributors"
      >
        {renderPageTitle}

        <Styled.CardsQuantityChips>
          <QuantityChipsCard
            title="Quantidade de chips"
            value={distributor?.wallet?.chips_count}
            loading={requestStatus.loading}
          />
          <QuantityChipsCard
            title="Chips pendentes"
            value={distributor?.wallet?.total_pending_chips}
            loading={requestStatus.loading}
            tooltip={PENDING_CHIPS_HELP_TEXT}
          />
        </Styled.CardsQuantityChips>

        <Card shadow>
          <Styled.Grid container spacing={2}>
            <Grid lg={6} md={6} sm={12} xs={12} item>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                Informações
              </Typography>
            </Grid>
          </Styled.Grid>

          <Styled.Grid container spacing={2}>
            <Grid lg={6} md={6} sm={12} xs={12} item>
              <Typography
                color="initial"
                variant="subtitle1"
                capitalize="uppercase"
              >
                Nome
              </Typography>

              <Typography color="primary" variant="h6" capitalize="uppercase">
                {distributor?.user.name}
              </Typography>
            </Grid>

            <Grid lg={6} md={6} sm={12} xs={12} item>
              <Typography
                color="initial"
                variant="subtitle1"
                capitalize="uppercase"
              >
                E-mail
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {distributor?.user.email}
              </Typography>
            </Grid>
          </Styled.Grid>

          <RenderWithRoles roles={['admin', 'dev']}>
            <Styled.Grid>
              <Styled.ButtonsContainer>
                <Link to={editRoute}>
                  <Button color="primary" variant="outlined">
                    Editar
                  </Button>
                </Link>
              </Styled.ButtonsContainer>
            </Styled.Grid>
          </RenderWithRoles>
        </Card>

        <Styled.TableCard shadow>
          <Styled.Grid container spacing={2}>
            <Grid lg={6} md={6} sm={12} xs={12} item>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                Vendas Realizadas
              </Typography>
            </Grid>
          </Styled.Grid>

          <Styled.Grid container spacing={2}>
            <Grid xs={12} item>
              <Table
                data={distributor?.wallet?.sales || []}
                renderItem={renderItem}
                HeaderComponent={<ListHeader />}
                disableShadow
              />
            </Grid>
          </Styled.Grid>
        </Styled.TableCard>

        <TablesTransfers
          dataTransfersSent={distributor?.wallet?.transfers_sent}
          dataTransfersReceived={distributor?.wallet?.transfers_received}
          loading={requestStatus.loading}
          page="distributorDetail"
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(DistributorDetails, DistributorDetailProvider)
