import React, { FC, useMemo, memo } from 'react'

import Typography from 'components/Typography'
import { useCommissionDetail } from 'hooks/commissions/details'
import { getMonth } from 'utils/getMonth'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

const CommissionHeader: FC = () => {
  const { commission } = useCommissionDetail()

  const color = useMemo(() => {
    if (!commission) return '#FFF'
    const { status } = commission

    switch (status) {
      case 'paid':
        return '#61BF33'
      case 'unpaid':
        return '#F44444'
      case 'open':
        return '#F56E28'
      default:
        return '#ccc'
    }
  }, [commission])

  const commissionReference = useMemo(() => {
    if (!commission) return 'error'
    const { month } = commission

    return getMonth(month)
  }, [commission])

  return (
    <Styled.Header color={color}>
      <Styled.Grid container spacing={2}>
        <Grid sm={12} item>
          <Typography color="inherit" variant="h6">
            {`Periodo referência: ${commissionReference} de ${commission?.year}`}
          </Typography>
        </Grid>
      </Styled.Grid>
    </Styled.Header>
  )
}

export default memo(CommissionHeader)
