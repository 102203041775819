import React, { FC, memo, useEffect, useMemo } from 'react'

import CardInfo from 'components/CardInfo'
import RenderWithRoles from 'components/RenderWithRoles'
import Typography from 'components/Typography'
import { useProduct } from 'pages/Product/context'

// import MenuItem from '@material-ui/core/MenuItem'

import Chips from '../Charts/Chips'
import CardTotalChips from './components/CardTotalChips'
// import Cards from './components/Cards'
// import ChipCard from './components/ChipCard'
import FormPanel from './components/FormPanel'
import * as Styled from './styles'

const Panel: FC = () => {
  const {
    getData,
    chipCount,
    statusChipCount,
    data,
    getDataCommission,
    endDatePanel,
    startDatePanel,
    endDateCommission,
    startDateCommission,
  } = useProduct()

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDatePanel, startDatePanel])

  useEffect(() => {
    getDataCommission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateCommission, startDateCommission])

  const totalChips = useMemo(() => {
    if (!data?.length) return 0

    let total = 0

    data.forEach(item => (total += item.value))

    return total
  }, [data])

  return (
    <>
      <Styled.GridContainer container spacing={2} isMarginTop={false}>
        <Styled.GridItem item xs={12}>
          <Typography variant="h5" color="textPrimary">
            Painel de acompanhamento
          </Typography>
        </Styled.GridItem>
      </Styled.GridContainer>

      <Styled.GridContainer container isMarginTop>
        <Styled.GridItem item xs={12} sm={12} md={6}>
          <CardTotalChips value={totalChips} />
        </Styled.GridItem>

        <Styled.GridItem item xs={12} sm={12} md={6}>
          <FormPanel />
        </Styled.GridItem>
      </Styled.GridContainer>

      <Styled.GridContainer container isMarginTop>
        {/* <Grid item xs={12} md={12}>
          <Cards />
        </Grid> */}

        <Styled.GridItem item xs={12} md={12}>
          <Chips />
          <Styled.Divider />
        </Styled.GridItem>
      </Styled.GridContainer>

      <RenderWithRoles roles={['admin', 'dev']}>
        <Styled.GridContainer container spacing={2} isMarginTop={false}>
          <Styled.GridItem item xs={12} md={7}>
            <Styled.Typography variant="h5" color="textPrimary">
              Chips comprados
            </Styled.Typography>
          </Styled.GridItem>
        </Styled.GridContainer>
        <Styled.GridContainer container spacing={2} isMarginTop={false}>
          <CardInfo
            title="Chips comprados Parceiros"
            value={String(chipCount?.chips_sold_partners)}
            loading={statusChipCount.loading}
          />
          <CardInfo
            title="Chips comprados Franqueados"
            value={String(chipCount?.chips_sold_franchises)}
            loading={statusChipCount.loading}
          />
        </Styled.GridContainer>
        <Styled.Divider />
      </RenderWithRoles>
    </>
  )
}

export default memo(Panel)
