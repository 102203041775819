import React, { FC, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import QuantityChipsCard from 'components/QuantityChipsCard'
import { PENDING_CHIPS_HELP_TEXT } from 'components/QuantityChipsCard/helpTexts'
import RenderWithRoles from 'components/RenderWithRoles'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { useAuth } from 'hooks/auth'
import { useStock, StockProvider } from 'hooks/stock'
import { Stock } from 'hooks/stock/props'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import StockListHeader from './components/StockListHeader'
import StockListItem from './components/StockListItem'
import * as Styled from './styles'

const Stocks: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    getStocks,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
    quantityChips,
  } = useStock()

  const { push } = useHistory()

  const { hasRole } = useAuth()

  const renderItem = useCallback<TableRenderItem<Stock>>(
    item => <StockListItem key={item.id} {...item} />,
    [],
  )

  const redirectToNew = useCallback(() => push(RoutingService.NEW_STOCK), [
    push,
  ])

  const renderPageTitle = useMemo(() => {
    if (hasRole(['admin', 'dev'])) {
      return (
        <PageTitle
          title="Estoques"
          showButton
          buttonText="Novo Estoque"
          buttonOnClick={redirectToNew}
        />
      )
    }

    return <PageTitle title="Estoques" />
  }, [hasRole, redirectToNew])

  return (
    <Route
      title="Estoques"
      access="auth"
      requireRoles={['admin', 'dev', 'analyst', 'distributor']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Estoques' },
        ]}
        activeOption="stocks"
      >
        {renderPageTitle}

        <RenderWithRoles roles={['dev', 'admin', 'analyst']}>
          <Styled.CardsQuantityChips data-cy="cards-quantity-chips__div">
            <QuantityChipsCard
              title="Quantidade de chips"
              value={quantityChips.total_chips}
              loading={requestStatus.loading}
            />
            <QuantityChipsCard
              title="Chips pendentes"
              value={quantityChips.total_pending_chips}
              loading={requestStatus.loading}
              tooltip={PENDING_CHIPS_HELP_TEXT}
            />
          </Styled.CardsQuantityChips>
        </RenderWithRoles>

        <Table
          data={data}
          loadingRequest={requestStatus.loading}
          renderItem={renderItem}
          data-testid="stock-list"
          HeaderComponent={<StockListHeader />}
          FooterComponent={
            <TablePagination
              getData={getStocks}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Stocks, StockProvider)
