import React, { FC, useState, memo } from 'react'

import Select from 'components/Inputs/Select'

const SelecTypeRecipient: FC = () => {
  const [value, setValue] = useState('stock')

  const options = [
    {
      name: 'Estoque',
      value: 'stock',
    },
    {
      name: 'Distribuidor',
      value: 'distributor',
    },
    // {
    //   name: 'Revendedor',
    //   value: 'company',
    // },
  ]

  return (
    <Select
      name="recipient.type"
      label="Tipo de Destino"
      autoFocus
      options={options}
      value={value}
      setValue={setValue}
    />
  )
}

export default memo(SelecTypeRecipient)
