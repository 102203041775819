import Typography from 'components/Typography'
import styled, { css } from 'styled-components'

import SkeletonStyles from '@material-ui/lab/Skeleton'

import { ReportType } from '../../../context/props'

interface QuantityProps {
  type: ReportType
}

interface ValueProps {
  type: ReportType
}

export const Skeleton = styled(SkeletonStyles)`
  width: 400px;
  height: 30px;

  @media (max-width: 500px) {
    width: 200px;
    height: 25px;
  }
`

export const Container = styled.div`
  margin-top: 20px;
`

export const Content = styled.div`
  background: transparent;
  border-radius: 6px;
  height: max-content;
  padding: 20px 36px 36px 20px;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.border.main};
`

export const TitleGraph = styled(Typography)`
  margin: 0px 0px 20px 20px;
`

export const Card = styled.div`
  margin-top: 20px;
  height: max-content;
  width: 100%;
  border-radius: 6px;
  background-color: transparent;
  padding: 10px 36px 36px 20px;
  border: 1px solid ${({ theme }) => theme.palette.border.main};
`

export const ContainerTooltip = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.light};
`

export const Date = styled.p``

export const Quantity = styled.p<QuantityProps>`
  ${props =>
    (props.type === 'recharges' || props.type === 'packages') &&
    css`
      color: ${({ theme }) => theme.palette.chart.primary.colorPrimary};
    `}

  ${props =>
    props.type === 'discharges' &&
    css`
      color: ${({ theme }) => theme.palette.chart.secondary.colorPrimary};
    `}

  margin-top: 10px;
`
export const Value = styled.p<ValueProps>`
  ${props =>
    (props.type === 'recharges' || props.type === 'packages') &&
    css`
      color: ${({ theme }) => theme.palette.chart.primary.colorSecondary};
    `}

  ${props =>
    props.type === 'discharges' &&
    css`
      color: ${({ theme }) => theme.palette.chart.secondary.colorSecondary};
    `}

  margin-top: 10px;
`
