import React, { FC, memo, useCallback, useState } from 'react'
import { FiPlus } from 'react-icons/fi'

import { useFormikContext } from 'formik'

import Grid from '@material-ui/core/Grid'

import { DataFormAddTransferType } from '../../context/props'
import IntervalsIccids from '../IntervalsIccids'
import * as Styled from './styles'

const SectionICCIDs: FC = () => {
  const { values, setFieldValue } = useFormikContext<DataFormAddTransferType>()

  const [intervals, setIntervalIccids] = useState(1)

  const handleAddInterval = useCallback(() => {
    setFieldValue('iccids_intervals', [
      ...values.iccids_intervals,
      { first_iccid: '', last_iccid: '' },
    ])
    setIntervalIccids(prev => prev + 1)
  }, [setFieldValue, values.iccids_intervals])

  return (
    <>
      {Array.from({ length: intervals }, (value, indexArray) => indexArray).map(
        index => (
          <IntervalsIccids key={index} index={index} />
        ),
      )}

      <Grid container spacing={0}>
        <Grid>
          <Styled.Button
            onClick={handleAddInterval}
            variant="text"
            color="primary"
            size="large"
            type="button"
          >
            <FiPlus size={20} /> Novo Intervalo
          </Styled.Button>
        </Grid>
      </Grid>
    </>
  )
}

export default memo(SectionICCIDs)
