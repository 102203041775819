import * as Yup from 'yup'

const schema = Yup.object().shape({
  discharges: Yup.mixed()
    .test('fileSize', 'Arquivo obrigatório', value => {
      return !value || (value && value.size > 0)
    })
    .required('Arquivo obrigatório'),
})

export default schema
