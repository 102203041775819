import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import { SaleState, SaleReducerActionMap, SaleDetailActions } from './props'

const initialState: SaleState = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  sale: null,
}

const SaleDetailReducer: Reducer<SaleState, SaleReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case SaleDetailActions.Reset:
      return initialState

    case SaleDetailActions.Request:
      state.requestStatus.loading = true
      state.requestStatus.success = false
      state.requestStatus.error = false
      return state

    case SaleDetailActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.success = false
      state.requestStatus.error = true
      state.sale = null
      return state

    case SaleDetailActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.success = true
      state.requestStatus.error = false
      state.sale = action.payload.data
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(SaleDetailReducer)
