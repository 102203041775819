import PureButton from 'components/Button'
import Typography from 'components/Typography'
import styled, { css } from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

interface ColorTextButton {
  isColorPrimary: boolean
}

export const Grid = styled(MUGrid)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Button = styled(PureButton)<ColorTextButton>`
  background-color: transparent;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    min-width: 100%;
  }

  span {
    ${props =>
      props.isColorPrimary
        ? css`
            color: ${({ theme }) => theme.palette.primary.main};
          `
        : css`
            color: ${({ theme }) => theme.palette.error.main};
          `}
    text-transform: uppercase;
  }

  svg {
    margin-right: 5px;
  }
`

export const TypographyCustom = styled(Typography)`
  color: #23ae89;
`
