import React, { FC, memo } from 'react'

import Card from 'components/Card'
import SectionCalc from 'pages/ReportPages/components/SectionCalc'
import { DataSectionCalc } from 'pages/ReportPages/components/SectionCalc/props'
import formatPrice from 'utils/formatPrice'

import { ReportDetailsProps } from '../../props'
import SectionBasicData from '../SectionBasicData'
import TableRecharges from '../TableRecharges'
import * as Styled from './styles'

const DetailsComponent: FC<ReportDetailsProps> = ({ data }) => {
  const dataCalc: DataSectionCalc[] = [
    {
      label: 'Quantidade de recargas',
      value: String(data?.total_recharges_quantity),
    },
    {
      label: 'Valor apurado',
      value: formatPrice({ price: data?.total_recharges_value || 0 }),
    },
  ]

  return (
    <Card shadow>
      <Styled.Root>
        <SectionBasicData data={data} />

        <Styled.Divider />

        <SectionCalc title="Cálculo de repasse" data={dataCalc} />

        <TableRecharges data={data} />
      </Styled.Root>
    </Card>
  )
}

export default memo(DetailsComponent)
