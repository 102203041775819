import React, { FC, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import RoutingService from 'services/routing'
import Document from 'utils/document'

import TableCell from '@material-ui/core/TableCell'

import { CompanyListItemProps } from './props'
import * as Styled from './styles'

const CompanyListItem: FC<CompanyListItemProps> = props => {
  const { id, document, fantasy_name, active, corporate_name, address } = props

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.COMPANY_DETAILS,
        'companyId',
        id,
      ),
    [id],
  )

  const status = useMemo(() => (active ? 'Ativo' : 'Inativo'), [active])

  const name = useMemo(() => corporate_name ?? fantasy_name, [
    corporate_name,
    fantasy_name,
  ])

  const documentFormatted = useMemo(() => Document.format(document), [document])

  return (
    <TableRowItem>
      <TableCell>{name}</TableCell>
      <TableCell>{documentFormatted}</TableCell>
      <TableCell>{address.city.name}</TableCell>
      <TableCell>{status}</TableCell>

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.Button
            color="primary"
            variant="outlined"
            data-cy="detail-company__button"
          >
            Detalhes
          </Styled.Button>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(CompanyListItem)
