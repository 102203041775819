import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { MergeTypes, RequestStatus } from 'interfaces/common'

export type PaginationState<ItemT extends any> = {
  from: number
  to: number
  per_page: number
  total: number
  current_page: number
  prev_page: number | null
  next_page: number | null
  data: ItemT[]
  requestStatus: RequestStatus
  meta?: Record<string, any>
}

export type PaginatedRouteResponse<ItemT extends any> = {
  from: number
  to: number
  per_page: number
  total: number
  current_page: number
  prev_page: number | null
  next_page: number | null
  data: ItemT[]
  requestStatus: RequestStatus
  meta?: Record<string, any>
}

export type PaginationRouteParams = {
  per_page: number
  page: number
}

export enum PaginationActions {
  NextPage = 'NEXT_PAGE',
  PreviousPage = 'PREVIOUS_PAGE',
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  SetLimit = 'SET_LIMIT',
  SetPage = 'SET_PAGE',
}

export type PaginationPayload<ItemT> = {
  [PaginationActions.NextPage]: undefined
  [PaginationActions.SetPage]: number
  [PaginationActions.PreviousPage]: undefined
  [PaginationActions.Request]: undefined
  [PaginationActions.RequestSuccess]: AxiosResponse<
    PaginatedRouteResponse<ItemT>
  >
  [PaginationActions.RequestError]: undefined
  [PaginationActions.SetLimit]: number
}

export type PaginationActionsMap<ItemT> = ActionMapData<
  PaginationPayload<ItemT>
>

export type PaginatedActions<A extends unknown> = MergeTypes<
  PaginationActions,
  A
>

export const paginationReducerInitialState = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  data: [],
  current_page: 1,
  from: 0,
  per_page: 10,
  total: 0,
  to: 0,
  next_page: null,
  prev_page: null,
}

export function paginationReducer<ItemT extends any>() {
  return (
    state: PaginationState<ItemT>,
    action: PaginationActionsMap<ItemT>,
  ): PaginationState<ItemT> => {
    switch (action.type) {
      case PaginationActions.NextPage:
        return { ...state, current_page: state.current_page + 1 }

      case PaginationActions.PreviousPage:
        return { ...state, current_page: state.current_page - 1 }

      case PaginationActions.SetLimit:
        return {
          ...state,
          per_page: action.payload,
        }
      case PaginationActions.SetPage:
        return {
          ...state,
          current_page: action.payload,
        }

      case PaginationActions.Request:
        return {
          ...state,
          requestStatus: {
            loading: true,
            error: false,
            success: false,
          },
        }

      case PaginationActions.RequestError:
        return {
          ...state,
          requestStatus: {
            loading: false,
            error: true,
            success: false,
          },
          data: [],
          meta: undefined,
        }

      case PaginationActions.RequestSuccess:
        return {
          ...state,
          requestStatus: {
            loading: false,
            error: false,
            success: true,
          },
          next_page: action.payload.data.next_page,
          prev_page: action.payload.data.prev_page,
          data: action.payload.data.data,
          from: action.payload.data.from,
          per_page: action.payload.data.per_page,
          total: action.payload.data.total,
          to: action.payload.data.to,
          current_page: action.payload.data.current_page,
          meta: action.payload.data.meta,
        }

      default:
        return paginationReducerInitialState
    }
  }
}
