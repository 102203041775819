import React, { FC } from 'react'

import Card from 'components/Card'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Form from './components/Form'
import { ReportProvider, useReport } from './context'
import * as Styled from './style'

const BuildReport: FC = () => {
  const { Element: Report } = useReport()

  return (
    <Route
      title="Relatórios"
      access="auth"
      requireRoles={[
        'analyst',
        'admin',
        'distributor',
        'dev',
        'company_representative',
        'financial',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          { title: 'Relatórios' },
        ]}
        activeOption="reports-company"
      >
        <PageTitle title="Relatórios" />
        <Card shadow>
          <Form />
        </Card>
        <Styled.Grid>{Report}</Styled.Grid>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(BuildReport, ReportProvider)
