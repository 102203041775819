import styled from 'styled-components'

import { DatePicker as MuiDatePicker } from '@material-ui/pickers'

export const DatePicker = styled(MuiDatePicker)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;

  input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`
