import React, { FC, memo, useMemo } from 'react'

import DetailsItem from 'components/DetailsPages/DetailsItem'
import formatDocument from 'utils/document'

import Grid from '@material-ui/core/Grid'

import { ReportDetailsProps } from '../../props'

const SectionBasicData: FC<ReportDetailsProps> = ({ data }) => {
  const documentFormatted = useMemo(() => {
    if (!data?.company) {
      return ''
    }
    return formatDocument.format(data?.company.document)
  }, [data?.company])

  const city = useMemo(() => {
    return `${data?.company.address.city.name} / ${data?.company.address.city.state.initials}`
  }, [
    data?.company.address.city.name,
    data?.company.address.city.state.initials,
  ])

  return (
    <Grid container spacing={2}>
      <DetailsItem title="Nome fantasia" capitalize="uppercase" sm={4}>
        {data?.company.fantasy_name}
      </DetailsItem>
      <DetailsItem title="Cidade" capitalize="uppercase" sm={8}>
        {city}
      </DetailsItem>
      <DetailsItem title="CNPJ" capitalize="uppercase" sm={12}>
        {documentFormatted}
      </DetailsItem>
    </Grid>
  )
}

export default memo(SectionBasicData)
