import { FC, useCallback, useEffect } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import { DateFilter } from './components/DateFilter'
import SalesListHeader from './components/SalesListHeader/SalesListHeader'
import SalesListItem from './components/SalesListItem'
import AppRechargesPeriodProvider, { useAppRechargesPeriod } from './context'

const AppRechargesPeriod: FC = () => {
  const {
    data,
    endDate,
    setEndDate,
    setStartDate,
    startDate,
    status,
    getData,
  } = useAppRechargesPeriod()

  const renderItem = useCallback<TableRenderItem<any>>(
    (item, index) => <SalesListItem key={`recharge_item_${index}`} {...item} />,
    [],
  )

  useEffect(() => {
    getData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  return (
    <Route
      title="Recargas App"
      access="auth"
      requireRoles={['dev', 'admin', 'analyst', 'financial_collection']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Recargas App', link: RoutingService.APP_RECHARGES },
          { title: 'Recargas App por perído', link: '#' },
        ]}
        activeOption="recharges"
      >
        <PageTitle title="Recargas" />

        <DateFilter
          endDate={endDate}
          startDate={startDate}
          onEndDateChange={setEndDate}
          onStartDateChange={setStartDate}
        />

        <Table
          data={data}
          renderItem={renderItem}
          loadingRequest={status == 'loading'}
          HeaderComponent={<SalesListHeader />}
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(AppRechargesPeriod, AppRechargesPeriodProvider)
