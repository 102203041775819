/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, memo, useEffect } from 'react'

import Formik from 'components/Formik'
import ComboBox from 'components/SelectStores'
import { Options } from 'components/SelectStores/props'
import { useAuth } from 'hooks/auth'
import { useSaleCashFlow } from 'hooks/salesCashFlow'
import { OfficeData } from 'hooks/salesCashFlow/props'
import { DataGenerateCommissionProps } from 'pages/GenerateCommission/context/props'
import { initialSeller, initialStore } from 'utils/initialStateForSelects'
import { GetOffices, GetSellers } from 'utils/officesAndSellers'
import { rolePermission } from 'utils/permissions'

import { DatePicker } from '@material-ui/pickers'

import * as Styled from './styles'

const HeaderTableSalesCashFlow: FC = () => {
  const initialValues: DataGenerateCommissionProps = {
    month: '',
    year: '',
  }
  const {
    store,
    startDate,
    endDate,
    status,
    seller,
    sellCategory,
    setSellCategory,
    setEndDate,
    setStore,
    setSeller,
    setStatus,
    setStartDate,
    getSalesCashFlow,
  } = useSaleCashFlow()
  const [offices, setOffices] = React.useState<OfficeData[]>([])
  const [sellers, setSellers] = React.useState<Options[]>([])
  const [permission, setPermission] = React.useState<string>('')
  const { user } = useAuth()

  useEffect(() => {
    const GetData = async () => {
      const dataOffices = await GetOffices()
      setOffices(dataOffices)
    }
    if (offices.length === 0 || !offices) {
      GetData()
    }
    if (store) {
      // eslint-disable-next-line prettier/prettier
      ;(async () => {
        const dataSellers = await GetSellers(store.id)
        setSellers(dataSellers)
      })()
    }
    if (user) {
      const role = rolePermission(user?.role.name)
      setPermission(role)
    }

    return () => {
      setOffices([])
      setSellers([])
      setPermission('')
    }
  }, [seller, store])

  useEffect(() => {
    if (!store?.name) {
      setSellers([])
      setSeller(initialSeller)
      setStore(initialStore)
    }
    getSalesCashFlow()
  }, [startDate, endDate, sellCategory, store, seller, status])

  useEffect(() => {
    if (
      store?.name == '' &&
      permission == 'midlePermission' &&
      offices.length > 0
    ) {
      setStore(offices[0])
    }
  }, [offices, permission])

  const icon = <img src={require('assets/images/icons/calendar.svg').default} />

  return (
    <Formik initialValues={initialValues} onSubmit={getSalesCashFlow}>
      <Styled.FormControl fullWidth>
        <Styled.Container>
          <DatePicker
            variant="inline"
            label="Data de início"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={startDate}
            InputProps={{
              endAdornment: icon,
            }}
            onChange={value => setStartDate(value!)}
          />
          <DatePicker
            variant="inline"
            label="Data final"
            inputVariant="outlined"
            value={endDate}
            format="dd/MM/yyyy"
            InputProps={{
              endAdornment: icon,
            }}
            onChange={value => setEndDate(value!)}
          />
          <ComboBox
            width="180px"
            labelName="Status"
            options={[
              { id: 'Status-1', name: 'Pago', labelName: 'paid' },
              { id: 'Status-2', name: 'Cancelado', labelName: 'canceled' },
              {
                id: 'Status-3',
                name: 'Em andamento',
                labelName: 'in_progress',
              },
            ]}
            setOption={setStatus}
            value={status}
          />
          {permission === 'higherPermission' && (
            <ComboBox
              width="250px"
              labelName="Lojas"
              options={offices}
              setOption={setStore}
              value={store}
            />
          )}
          {(permission === 'midlePermission' ||
            permission === 'higherPermission') && (
            <ComboBox
              width="250px"
              labelName="Vendedores"
              options={sellers}
              setOption={setSeller}
              value={seller}
            />
          )}
          <ComboBox
            width="200px"
            labelName="Tipo de venda"
            options={[
              {
                id: 'Category_seller_1',
                name: 'Venda avulsa',
                labelName: 'single',
              },
              {
                id: 'Category_seller_2',
                name: 'Venda ativação',
                labelName: 'activation',
              },
              {
                id: 'Category_seller_3',
                name: 'Portabilidade/Migração',
                labelName: 'portability',
              },
            ]}
            setOption={setSellCategory}
            value={sellCategory}
          />
        </Styled.Container>
      </Styled.FormControl>
    </Formik>
  )
}

export default memo(HeaderTableSalesCashFlow)
