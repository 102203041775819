import React, { FC, memo, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import RoutingService from 'services/routing'

import TableCell from '@material-ui/core/TableCell'

import { TransferListItemProps } from './props'
import * as Styled from './styles'

const TransferListItem: FC<TransferListItemProps> = props => {
  const {
    id,
    status,
    order_number,
    wallet_recipient,
    wallet_sender,
    page,
  } = props
  const [wallet, setWallet] = useState<any>()
  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.TRANSFER_DETAILS,
        'transferId',
        id,
      ),
    [id],
  )

  const sourceOrDestiny = useMemo(() => {
    if (page === 'stockDetail') {
      if (wallet_recipient) {
        setWallet(wallet_recipient)
      }
    } else {
      setWallet(wallet_sender)
    }
    switch (wallet?.owner_type) {
      case 'company':
        return `Empresa: ${wallet.company?.fantasy_name}`
      case 'distributor':
        return `Distribuidor: ${wallet.distributor?.user.name}`
      case 'stock':
        return `Estoque: ${wallet.stock?.name}`
      default:
        return '-'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, wallet_recipient, wallet_sender])

  const statusText = useMemo(() => {
    switch (status) {
      case 'canceled':
        return 'Cancelado'
      case 'denied':
        return 'Negado'
      case 'finished':
        return 'Finalizado'

      default:
        return 'Pendente'
    }
  }, [status])

  return (
    <TableRowItem>
      <TableCell>{order_number}</TableCell>
      <TableCell>{sourceOrDestiny}</TableCell>
      <TableCell>{statusText}</TableCell>

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.Button color="primary" variant="outlined">
            Detalhes
          </Styled.Button>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(TransferListItem)
