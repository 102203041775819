import React, { FC, useState, useMemo, useCallback, memo } from 'react'

import Formik from 'components/Formik'

import { useProduct } from '../../../../context'
import { ChartRequest } from '../../../../context/props'
import { ReportType, PeriodType } from '../../../../context/props'
import { OPTIONS_SELECT_REPORT, OPTIONS_SELECT_PERIOD } from './options'
import schema from './schema'
import * as Styled from './styles'

const FormRechargePackageDischarges: FC = () => {
  const { getChart, dataChartParams, setDataChartParams } = useProduct()

  const [reportType, setReportType] = useState<ReportType>('recharges')
  const [periodType, setPeriodType] = useState<PeriodType>('yearly')

  const initialValues: ChartRequest = {
    report_type: reportType,
    period: periodType,
    date: '',
  }

  const textField = useMemo(() => {
    if (periodType === 'yearly') {
      return null
    }
    return (
      <Styled.Container item xs={12} sm={3} md={3}>
        <Styled.DatePicker
          label="Data"
          inputVariant="outlined"
          name="date"
          format="MM/yyyy"
          views={['year', 'month']}
          maxDate={new Date()}
        />
      </Styled.Container>
    )
  }, [periodType])

  const handleSubmit = useCallback(
    values => {
      dataChartParams.period = values.period
      dataChartParams.report_type = values.report_type
      dataChartParams.date = values.date

      setDataChartParams(dataChartParams)
      getChart()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataChartParams,
      initialValues.date,
      initialValues.period,
      initialValues.report_type,
    ],
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Styled.Container container spacing={2}>
        <Styled.Container item xs={12}>
          <Styled.Container container spacing={2}>
            {textField === null && (
              <Styled.Container item xs={12} md={3}></Styled.Container>
            )}
            <Styled.Container item xs={12} md={3}>
              <Styled.Span>Outros filtros</Styled.Span>
            </Styled.Container>
          </Styled.Container>
          <Styled.Container
            container
            spacing={2}
            justify="flex-end"
            style={{ marginTop: 8 }}
          >
            <Styled.Container item xs={12} sm={3} md={3}>
              <Styled.Select
                name="report_type"
                label="Relatório"
                value={reportType}
                setValue={(event: string) => setReportType(event as ReportType)}
                options={OPTIONS_SELECT_REPORT}
              />
            </Styled.Container>
            <Styled.Container item xs={12} sm={3} md={3}>
              <Styled.Select
                name="period"
                label="Período"
                value={periodType}
                setValue={(event: string) => setPeriodType(event as PeriodType)}
                options={OPTIONS_SELECT_PERIOD}
              />
            </Styled.Container>

            {textField}

            <Styled.Container item xs={12} sm={3} md={3}>
              <Styled.Button variant="contained" color="primary">
                Filtrar
              </Styled.Button>
            </Styled.Container>
          </Styled.Container>
        </Styled.Container>
      </Styled.Container>
    </Formik>
  )
}

export default memo(FormRechargePackageDischarges)
