import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import { CardProductProps } from './props'
import * as Styled from './styles'

const CardProduct: FC<CardProductProps> = props => {
  const { name, route } = props

  return (
    <Link to={route}>
      <Styled.Container data-cy={name}>
        <Styled.ContentButton>
          <img
            src={require('assets/images/images-product/movel.svg').default}
            alt="Imagem movel"
          />
        </Styled.ContentButton>

        <Styled.ContentText>
          <span>Produto</span>
          <h1>{name}</h1>
        </Styled.ContentText>
      </Styled.Container>
    </Link>
  )
}

export default memo(CardProduct)
