import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { useAuth } from 'hooks/auth'
import { SaleProvider, useSale } from 'hooks/sales'
import { Sale } from 'interfaces/sale'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import SalesListHeader from './components/SalesListHeader/SalesListHeader'
import SalesListItem from './components/SalesListItem'

const MovelSalesPage: FC = () => {
  const { push } = useHistory()

  const { hasRole, user } = useAuth()

  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    getSales,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
  } = useSale()

  const renderItem = useCallback<TableRenderItem<Sale>>(
    item => <SalesListItem key={item.id} {...item} />,
    [],
  )

  const redirectToNew = useCallback(() => push(RoutingService.NEW_SALE), [push])

  const renderPageTitle = useMemo(() => {
    if (hasRole(['distributor'])) {
      return (
        <PageTitle
          title="Vendas"
          showButton
          buttonOnClick={redirectToNew}
          buttonText="Nova venda"
        />
      )
    }

    return (
      <PageTitle
        title={
          user?.role.name === 'company_representative' ? 'Compras' : 'Vendas'
        }
      />
    )
  }, [hasRole, redirectToNew, user])

  return (
    <Route
      title="Vendas"
      access="auth"
      requireRoles={[
        'dev',
        'admin',
        'analyst',
        'distributor',
        'company_representative',
        'financial_collection',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title:
              user?.role.name === 'company_representative'
                ? 'Compras'
                : 'Vendas',
            link: '#',
          },
        ]}
        activeOption="sales"
      >
        {renderPageTitle}

        <Table
          data={data}
          renderItem={renderItem}
          loadingRequest={requestStatus.loading}
          HeaderComponent={<SalesListHeader />}
          FooterComponent={
            <TablePagination
              getData={getSales}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(MovelSalesPage, SaleProvider)
