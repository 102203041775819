import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  button:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`
