import React, { memo, forwardRef, useMemo } from 'react'

import Button from 'components/Button'
import { useFormikContext } from 'formik'

import { ButtonSubmitProps } from './props'

const ButtonSubmit = forwardRef<HTMLButtonElement, ButtonSubmitProps>(
  (props, ref) => {
    const { children, disabled, ...rest } = props
    const { isValid } = useFormikContext()

    const isDisabled = useMemo(() => !isValid || disabled, [disabled, isValid])

    return (
      <Button
        ref={ref}
        type="submit"
        {...rest}
        disabled={isDisabled}
        disableElevation
      >
        {children}
      </Button>
    )
  },
)

ButtonSubmit.displayName = 'ButtonSubmit'

export default memo(ButtonSubmit)
