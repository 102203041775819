import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
  useState,
} from 'react'

import { api } from 'services/api'
import {
  paginationReducer,
  PaginationActions,
  PaginationRouteParams,
} from 'services/pagination'

import {
  TransferContextData,
  Transfer,
  ListTransferResponse,
  FilterStatusParam,
  TransferStatusType,
} from './props'
import { initialState } from './reducer'

const initialValue = {} as TransferContextData

export const TransferContext = createContext(initialValue)

export const TransferProvider: FC = props => {
  const { children } = props

  const [status, setStatus] = useState<TransferStatusType | undefined>()

  const [state, dispatch] = useReducer(
    paginationReducer<Transfer>(),
    initialState,
  )

  const getTransfers = useCallback(async () => {
    try {
      dispatch({ type: PaginationActions.Request })

      const params: PaginationRouteParams & FilterStatusParam = {
        page: state.current_page,
        per_page: state.per_page,
        status_eq: status,
      }

      const response = await api.get<ListTransferResponse>('/transfers', {
        params,
      })

      dispatch({ type: PaginationActions.RequestSuccess, payload: response })
    } catch (e) {
      dispatch({ type: PaginationActions.RequestError })
    }
  }, [state.current_page, state.per_page, status])

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <TransferContext.Provider
      value={{
        ...state,
        status,
        setStatus,
        getTransfers,
        nextPage,
        previousPage,
        setLimit,
        setPage,
      }}
    >
      {children}
    </TransferContext.Provider>
  )
}

export function useTransfer(): TransferContextData {
  const context = useContext(TransferContext)

  if (!context || context === initialValue) {
    throw new Error('useTransfer must be used within a TransferProvider')
  }

  return context
}
