import Typography from 'components/Typography'
import styled from 'styled-components'

import SkeletonStyles from '@material-ui/lab/Skeleton'

export const Skeleton = styled(SkeletonStyles)`
  width: 400px;
  height: 30px;

  @media (max-width: 500px) {
    width: 200px;
    height: 25px;
  }
`

export const Container = styled.div`
  margin-top: 20px;
`

export const Content = styled.div`
  margin-top: 20px;
  background: transparent;
  border-radius: 6px;
  height: max-content;
  padding: 20px 36px 36px 20px;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.border.main};
`

export const ContentTitle = styled.div`
  margin: 0px 0px 20px 20px;

  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`

export const TitleGraph = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Card = styled.div`
  margin-top: 20px;
  height: max-content;
  border-radius: 6px;
  width: 100%;
  background-color: transparent;
  padding: 10px 36px 36px 20px;
  border: 1px solid ${({ theme }) => theme.palette.border.main};
`

export const ContainerTooltip = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.light};
`

export const Date = styled.p``

export const Commission = styled.p`
  color: ${({ theme }) => theme.palette.chart.primary.colorPrimary};
  margin-top: 10px;
`
export const Packages = styled.p`
  color: ${({ theme }) => theme.palette.chart.primary.colorSecondary};
  margin-top: 10px;
`
