import React, { FC, useMemo, memo, Fragment, useRef, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'

import Loading from 'components/Loading'
import { useAuth } from 'hooks/auth'
import RoutingService from 'services/routing'

import { RouteProps } from './props'
import { Main } from './styles'

const Route: FC<RouteProps> = props => {
  const { title, access, children, requireRoles, showLoader } = props
  const { isAuthenticating, isAuthenticated, hasRole } = useAuth()
  const loadedRef = useRef(false)

  useEffect(() => {
    if (!isAuthenticating) {
      loadedRef.current = true
    }
    return () => {
      loadedRef.current = false
    }
  }, [isAuthenticating])

  const routeTitle = useMemo(() => {
    if (!title) return 'CRM Produtos'
    return `CRM Produtos - ${title}`
  }, [title])

  const roleCanAccess = useMemo(() => {
    if (!requireRoles || requireRoles.length === 0) return true
    return hasRole(requireRoles)
  }, [hasRole, requireRoles])

  const pageToRedirect = useMemo(() => {
    if (access === 'auth' && !isAuthenticated) return RoutingService.LOGIN
    if (!roleCanAccess) return RoutingService.UNAUTHORIZED_ACCESS
    if (access === 'guest' && isAuthenticated) return RoutingService.PRODUCTS

    return null
  }, [access, isAuthenticated, roleCanAccess])

  if (showLoader) return <Loading />
  if (isAuthenticating && !loadedRef.current) return <Loading />
  if (pageToRedirect) return <Redirect to={pageToRedirect} />

  return (
    <>
      <Helmet>
        <title>{routeTitle}</title>
      </Helmet>
      <Main>{children}</Main>
    </>
  )
}

export default memo(Route)
