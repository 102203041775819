export const OPTIONS_TYPE_FILTER = [
  {
    name: 'Rede',
    value: 'rede',
  },
  {
    name: 'Cielo',
    value: 'cielo',
  },
]
