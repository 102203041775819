import { Token } from './props'

const storage = typeof window !== 'undefined' ? localStorage : null

const AUTH_PERSIST_KEY = '@CRMProdutos:persist'

export const persistToken = (token: Token | null) => {
  if (!storage) return
  storage.setItem(AUTH_PERSIST_KEY, JSON.stringify(token))
}

export const clearPersistedToken = () => {
  if (!storage) return
  storage.removeItem(AUTH_PERSIST_KEY)
}

export const getToken = () => {
  if (!storage) return null

  const rawToken = storage.getItem(AUTH_PERSIST_KEY)
  if (!rawToken) return null

  return JSON.parse(rawToken) as Token
}
