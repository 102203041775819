import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const TitlePanel = styled(Typography)`
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const ContainerOptions = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    justify-content: flex-start;
  }
`

export const LabelTypeCompany = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
