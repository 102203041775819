import React, { FC } from 'react'

import { useChipsCashFlow } from 'hooks/ChipsCashFlow'

import { MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import { valuesRecharge } from '../dataValues'
import { SelectRecharge } from './styles'

const Recharge: FC = () => {
  const { recharge, setRecharge } = useChipsCashFlow()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRecharge(event.target.value as string)
  }
  return (
    <FormControl variant="outlined">
      <InputLabel>Valor da Recarga</InputLabel>
      <SelectRecharge value={recharge} onChange={handleChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {valuesRecharge.map(item => (
          <MenuItem value={item} key={`${item}`}>
            {item}
          </MenuItem>
        ))}
      </SelectRecharge>
    </FormControl>
  )
}

export default Recharge
