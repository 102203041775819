import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
  useState,
} from 'react'

import { startOfMonth } from 'date-fns'
import { Sale } from 'interfaces/sale'
import { api } from 'services/api'
import {
  paginationReducer,
  PaginationActions,
  PaginationRouteParams,
} from 'services/pagination'

import { SaleContextData, ListSalesResponse, SalesStatusParam } from './props'
import { initialState } from './reducer'

const initialValue = {} as SaleContextData
export const SaleContext = createContext(initialValue)

export const SaleProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(paginationReducer<Sale>(), initialState)

  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()))
  const [endDate, setEndDate] = useState<Date>(new Date())

  const getSales = useCallback(async () => {
    try {
      dispatch({ type: PaginationActions.Request })

      const params: PaginationRouteParams & SalesStatusParam = {
        page: state.current_page,
        per_page: state.per_page,
      }
      if (startDate) {
        params.date_gt = startDate
      }
      if (endDate) {
        params.date_lt = endDate
      }

      const response = await api.get<ListSalesResponse>('/sales', {
        params,
      })

      dispatch({
        type: PaginationActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      dispatch({ type: PaginationActions.RequestError })
    }
  }, [state, endDate, startDate])

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <SaleContext.Provider
      value={{
        ...state,
        getSales,
        nextPage,
        previousPage,
        setLimit,
        setPage,
        startDate,
        endDate,
        setEndDate,
        setStartDate,
      }}
    >
      {children}
    </SaleContext.Provider>
  )
}

export function useSale(): SaleContextData {
  const context = useContext(SaleContext)

  if (!context || context === initialValue) {
    throw new Error('useSale must be used within a SaleProvider')
  }

  return context
}
