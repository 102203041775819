/* eslint-disable react/destructuring-assignment */
import React from 'react'

import * as Styled from './styles'

const Cards = props => {
  return (
    <Styled.SvgContainer {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.7713 3.2H18.6459C19.8352 3.2 20.8 4.17319 20.8 5.37376V8.27241C20.8 9.47207 19.8352 10.4462 18.6459 10.4462H15.7713C14.581 10.4462 13.6162 9.47207 13.6162 8.27241V5.37376C13.6162 4.17319 14.581 3.2 15.7713 3.2Z"
          stroke={props.stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.355 3.2H8.2287C9.41894 3.2 10.3838 4.17319 10.3838 5.37376V8.27241C10.3838 9.47207 9.41894 10.4462 8.2287 10.4462H5.355C4.16476 10.4462 3.19995 9.47207 3.19995 8.27241V5.37376C3.19995 4.17319 4.16476 3.2 5.355 3.2Z"
          stroke={props.stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.355 13.5538H8.2287C9.41894 13.5538 10.3838 14.527 10.3838 15.7285V18.6262C10.3838 19.8268 9.41894 20.8 8.2287 20.8H5.355C4.16476 20.8 3.19995 19.8268 3.19995 18.6262V15.7285C3.19995 14.527 4.16476 13.5538 5.355 13.5538Z"
          stroke={props.stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.7713 13.5538H18.6459C19.8352 13.5538 20.8 14.527 20.8 15.7285V18.6262C20.8 19.8268 19.8352 20.8 18.6459 20.8H15.7713C14.581 20.8 13.6162 19.8268 13.6162 18.6262V15.7285C13.6162 14.527 14.581 13.5538 15.7713 13.5538Z"
          stroke={props.stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Styled.SvgContainer>
  )
}

export default Cards
