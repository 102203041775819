import React, { FC, memo, useMemo, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'hooks/auth'
import routes from 'services/routing'
import { belongsToCashFlow } from 'utils/permissions'

import { Hidden } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Fade from '@material-ui/core/Fade'
import Popper from '@material-ui/core/Popper'

import * as Styled from './styles'

const UserIndicator: FC = () => {
  const { user, signOut } = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const id = useMemo(
    () => (open ? 'spring-popper-user-indicator' : undefined),
    [open],
  )

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    },
    [anchorEl],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <Styled.Root onClick={handleOpen} disableRipple>
      <Hidden smDown>
        <Styled.NameUser variant="subtitle2">{user?.name}</Styled.NameUser>
      </Hidden>

      {user?.avatar_url ? (
        <Styled.AvatarPicture
          src={user?.avatar_url}
          visibleBackground={false}
        />
      ) : (
        <Styled.AvatarPicture
          src={require('assets/images/icons/profile.svg').default}
          visibleBackground
        />
      )}
      {!belongsToCashFlow(user?.role.name) && (
        <Popper id={id} open={open} anchorEl={anchorEl} transition>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps} timeout={350}>
                <Styled.ContainerOptions>
                  <Styled.ContainerOption>
                    <Styled.Icon
                      src={require('assets/images/icons/edit.svg').default}
                      alt="Icone editar"
                    />
                    <Link to={routes.EDIT_PROFILE}>
                      <Styled.OptionEditPerfil variant="subtitle2">
                        Editar perfil
                      </Styled.OptionEditPerfil>
                    </Link>
                  </Styled.ContainerOption>
                  <Styled.ContainerOption>
                    <Styled.Icon
                      src={require('assets/images/icons/logout.svg').default}
                      alt="Icone logout"
                    />
                    <Styled.OptionLogout variant="subtitle2" onClick={signOut}>
                      Sair do CRM
                    </Styled.OptionLogout>
                  </Styled.ContainerOption>
                </Styled.ContainerOptions>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </Styled.Root>
  )
}

export default memo(UserIndicator)
