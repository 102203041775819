import React, { FC, memo } from 'react'

import Card from 'components/Card'

import SectionBasicData from '../SectionBasicData'
import SectionCalcTransfer from '../SectionCalcTransfer'
import TableCounting from '../TableCouting'
import * as Styled from './styles'

const DetailsComponent: FC = () => {
  return (
    <Card shadow>
      <Styled.Root>
        <SectionBasicData />

        <Styled.Divider />

        <SectionCalcTransfer />

        <Styled.Divider />

        <TableCounting />
      </Styled.Root>
    </Card>
  )
}

export default memo(DetailsComponent)
