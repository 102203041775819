import React from 'react'

import styled from 'styled-components'

import MUTypography from '@material-ui/core/Typography'

import { TypographyProps } from './props'

export const Typography = styled(
  ({ capitalize, ...props }: TypographyProps) => <MUTypography {...props} />,
)`
  text-transform: ${({ capitalize }) => capitalize || 'none'};
`
