import React, {
  FC,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'

import { EditStockData, EditStockContextData } from './props'

const initialValue = {} as EditStockContextData
const EditStockContext = createContext(initialValue)

export const EditStockProvider: FC = props => {
  const { children } = props

  const [status, setStatus] = useState<RequestStatus>({
    loading: false,
    error: false,
    success: false,
  })

  const { addToast } = useToast()
  const history = useHistory()

  const editStock = useCallback(
    async (id: string, stock: EditStockData) => {
      try {
        if (!id) {
          throw new Error('ID cannot be empty')
        }

        const { state_id, ...stockData } = stock

        setStatus({
          error: false,
          loading: true,
          success: false,
        })

        await api.put(`/stocks/${id}`, stockData)

        setStatus({
          error: false,
          loading: false,
          success: true,
        })
        addToast({
          type: 'success',
          title: 'Estoque atualizado com sucesso',
        })
        history.push(
          RoutingService.getRouteString(
            RoutingService.STOCK_DETAILS,
            'stockId',
            id,
          ),
        )
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        setStatus({
          error: true,
          loading: false,
          success: false,
        })
      }
    },
    [addToast, history],
  )

  return (
    <EditStockContext.Provider value={{ status, editStock }}>
      {children}
    </EditStockContext.Provider>
  )
}

export const useEditStock = () => {
  const context = useContext(EditStockContext)
  if (!context || context === initialValue) {
    throw new Error('useEditStock must be used within a EditStockProvider')
  }
  return context
}
