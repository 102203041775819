import React, { FC } from 'react'

import TableHead from 'components/TableHead'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const SalesListHeader: FC = () => {
  return (
    <TableHead title="Todas as recargas neste período">
      <TableRow>
        <TableCell>Número</TableCell>
        <TableCell>Valor</TableCell>
        <TableCell>CPF</TableCell>
        <TableCell>Data</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default SalesListHeader
