import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState,
} from 'react'

import {
  Commission,
  CommissionsStatusType,
  BonusStatusParam,
} from 'interfaces/commission'
import { api } from 'services/api'
import {
  paginationReducer,
  PaginationActions,
  PaginationRouteParams,
} from 'services/pagination'

import { CommissionContextData } from './props'
import { initialState } from './reducer'

const initialValue = {} as CommissionContextData
export const BonusContext = createContext(initialValue)

export const BonusProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    paginationReducer<Commission>(),
    initialState,
  )

  const [status, setStatus] = useState<CommissionsStatusType | undefined>()

  const preview = null

  const getBonus = useCallback(
    async (company_id: string) => {
      try {
        const params: PaginationRouteParams & BonusStatusParam = {
          page: state.current_page,
          per_page: state.per_page,
          status_eq: status,
        }

        const response = await api.get<any>(
          `/companies/${company_id}/bonuses`,
          {
            params,
          },
        )

        dispatch({
          type: PaginationActions.RequestSuccess,
          payload: response,
        })
      } catch (e) {
        dispatch({ type: PaginationActions.RequestError })
      }
    },
    [state, status],
  )

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <BonusContext.Provider
      value={{
        ...state,
        preview,
        getBonus,
        nextPage,
        previousPage,
        setLimit,
        status,
        setStatus,
        setPage,
      }}
    >
      {children}
    </BonusContext.Provider>
  )
}

export function useBonus() {
  const context = useContext(BonusContext)
  if (!context || context === initialValue) {
    throw new Error('useBonus must be within as CommissionContext')
  }

  return context
}
