import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import Route from 'components/Route'
import Typography from 'components/Typography'
import { useAuth } from 'hooks/auth'
import RoutingService from 'services/routing'

import * as Styled from './styles'

const UnauthorizedPage: FC = () => {
  const { isAuthenticated } = useAuth()
  const route = useMemo(
    () => (isAuthenticated ? RoutingService.PRODUCTS : RoutingService.LOGIN),
    [isAuthenticated],
  )

  return (
    <Route title="Acesso não autorizado" access="auth">
      <Styled.Container>
        <Typography color="primary" variant="h2">
          Acesso não autorizado
        </Typography>

        <Link to={route}>
          <Button color="primary" variant="outlined" size="large">
            Voltar ao Início
          </Button>
        </Link>
      </Styled.Container>
    </Route>
  )
}

export default UnauthorizedPage
