import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 265px;
  height: 121px;
  padding: 22px;

  cursor: default;

  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.border.main};
`

export const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(24, 29, 196, 0.15);
`

export const ContentText = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)}px;

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.grey[600]};
    font-weight: 400;
  }

  h1 {
    font-size: 30px;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 500;
  }
`
