import React, { FC } from 'react'
import ReactExport from 'react-data-export'

import { format } from 'date-fns'
import { ChipsLeavesCashFlow } from 'hooks/ChipsLeavesCashFlow/props'
import { TranslateCategoryReports } from 'utils/Translate'

import { ModalReportsProps } from '../../props'
import { DowloadButton } from '../../styles'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export const ExcellLeaves: FC<ModalReportsProps> = props => {
  const { data } = props

  const dataExcell = data.map((item: ChipsLeavesCashFlow) => {
    return {
      created_at: format(new Date(item.created_at), 'dd-MM-yyyy'),
      category: TranslateCategoryReports(item.category),
      iccid: item.chip.iccid,
    }
  })
  return (
    <ExcelFile
      element={
        <DowloadButton variant="contained" color="primary">
          Baixar
        </DowloadButton>
      }
    >
      <ExcelSheet data={dataExcell} name="Employees">
        <ExcelColumn label="Data" value="created_at" />
        <ExcelColumn label="Categoria" value="category" />
        <ExcelColumn label="ICCID" value="iccid" />
      </ExcelSheet>
    </ExcelFile>
  )
}
