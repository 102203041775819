import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { useAuth } from 'hooks/auth'
import { GetDistributorProvider, useGetDistributors } from 'hooks/distributors'
import { Distributor } from 'interfaces/distributor'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import StockListHeader from './components/DistributorListHeader'
import StockListItem from './components/DistributorListItem'

const Distributors: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    getDistributors,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
  } = useGetDistributors()

  const { push } = useHistory()

  const { hasRole } = useAuth()

  const renderItem = useCallback<TableRenderItem<Distributor>>(
    item => <StockListItem key={item.id} {...item} />,
    [],
  )

  const redirectToNew = useCallback(
    () => push(RoutingService.NEW_DISTRIBUTOR),
    [push],
  )

  const renderPageTitle = useMemo(() => {
    if (hasRole(['admin', 'dev'])) {
      return (
        <PageTitle
          title="Distribuidores"
          showButton
          buttonText="Novo Distribuidor"
          buttonOnClick={redirectToNew}
        />
      )
    }

    return <PageTitle title="Distribuidores" />
  }, [hasRole, redirectToNew])

  return (
    <Route
      title="Distribuidores"
      access="auth"
      requireRoles={['dev', 'admin', 'analyst']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Distribuidores' },
        ]}
        activeOption="distributors"
      >
        {renderPageTitle}

        <Table
          data={data}
          loadingRequest={requestStatus.loading}
          renderItem={renderItem}
          HeaderComponent={<StockListHeader />}
          FooterComponent={
            <TablePagination
              getData={getDistributors}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Distributors, GetDistributorProvider)
