import React, { FC, memo } from 'react'

import TableRowItem from 'components/TableRowItem'
import format from 'date-fns/format'
import { ChipsLeavesCashFlow } from 'hooks/ChipsLeavesCashFlow/props'
import { TranslateCategoryReports } from 'utils/Translate'

import TableCell from '@material-ui/core/TableCell'

const ChipsLeavesListItem: FC<ChipsLeavesCashFlow> = props => {
  const {
    category,
    created_at,
    chip: { iccid },
    user,
  } = props

  return (
    <TableRowItem>
      <TableCell>{user?.name}</TableCell>
      <TableCell>{format(new Date(created_at), 'dd-MM-yyyy')}</TableCell>
      <TableCell>{TranslateCategoryReports(category)}</TableCell>
      <TableCell>{iccid}</TableCell>
    </TableRowItem>
  )
}

export default memo(ChipsLeavesListItem)
