import React, { FC, useEffect, useState } from 'react'

import Card from 'components/Card'
import Formik from 'components/Formik'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import { Field } from 'formik'
import { ICCIDSProvider, useICCIDS } from 'hooks/iccids'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import AutocompleteRecipient from './components/AutocompleteRecipient'
import ButtonSubmit from './components/ButtonSubmit'
import SectionICCIDs from './components/SectionICCIDs'
import SelecTypeRecipient from './components/SelecTypeRecipient'
import { NewTransferProvider, useNewTransfer } from './context'
import { DataFormAddTransferType } from './context/props'
import schema from './schemas'
import * as Styled from './styles'
const NewTransfer: FC = () => {
  const { addTransfer, requestStatus } = useNewTransfer()

  const initialValues: DataFormAddTransferType = {
    iccids_intervals: [{ first_iccid: '', last_iccid: '' }],
    sender: {
      id: '',
      type: '',
    },
    recipient: {
      id: '',
      type: 'stock',
    },
    quantityChips: false,
  }
  const { quantityChips } = useICCIDS()
  const [greaterThanAhundred, setGreaterThanAhundred] = useState<boolean>(false)

  useEffect(() => {
    if (quantityChips >= 100) {
      setGreaterThanAhundred(true)
    } else {
      setGreaterThanAhundred(false)
    }
  }, [quantityChips])

  return (
    <Route
      title="Nova Transferência"
      access="auth"
      requireRoles={[
        'stockist',
        'distributor',
        'company_representative',
        'dev',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Transferências', link: RoutingService.TRANSFERS },
          { title: 'Nova Transferência' },
        ]}
        activeOption="transactions"
      >
        <PageTitle title="Nova transferência" />

        <Card shadow>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={addTransfer}
            validateOnMount
          >
            <Grid container spacing={3}>
              <Grid item xl={12} md={12} sm={12} xs={12}>
                <Styled.TitlePanel>Detalhes da transferência</Styled.TitlePanel>
              </Grid>

              <Grid item xl={6} md={6} sm={12} xs={12}>
                <SelecTypeRecipient />
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <Field component={AutocompleteRecipient} name="recipient.id" />
              </Grid>

              <Grid item xl={12} md={12} sm={12} xs={12}>
                <Styled.TitlePanel>quantidade de chips</Styled.TitlePanel>
              </Grid>
              <Styled.FieldQuantity
                name="quantityChips"
                value={greaterThanAhundred}
              />
              <SectionICCIDs />

              <Grid item xl={12} md={12} sm={12} xs={12}>
                {requestStatus.loading && <CircularProgress color="primary" />}
                <Styled.ButtonContainer>
                  <ButtonSubmit />
                </Styled.ButtonContainer>
              </Grid>
            </Grid>
          </Formik>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(NewTransfer, NewTransferProvider, ICCIDSProvider)
