import * as Yup from 'yup'

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Senha deve ter no mínimo 8 caracteres')
    .required('Campo obrigatório'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Senhas não conferem')
    .required('Campo obrigatório'),
  token: Yup.string().required('O token é obrigatório'),
})

export default schema
