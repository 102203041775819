import styled from 'styled-components'

import MUTextField from '@material-ui/core/TextField'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const Select = styled(MUTextField)`
  width: 150px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  .MuiSelect-selectMenu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiInputBase-root {
    height: ${({ theme }) => theme.spacing(6)}px;
    border-right-style: none;
    border-color: ${({ theme }) => theme.palette.grey[500]};
  }
  .MuiInput-underline:before {
    display: none;
  }
`
