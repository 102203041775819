import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'

export interface Plan {
  description: string
  quantity: number
  total_value: number
}

export interface Recharge {
  value: number
  quantity: number
  total_value: number
}
export interface ResponseReportAltas {
  chips_purchased: number
  chips_awaiting_sale: number
  total_plans_value: number
  chips_with_plans: number
  chips_without_plans: number
  chips_with_portability: number
  chips_removed: number
  plans: Plan[]
}

export interface ResponseReportOfRecharges {
  total_recharges_value: number
  total_recharges_quantity: number
  recharges: Recharge[]
}

export interface ResponseReportOfPackage {
  total_plans_value: number
  total_plans_quantity: number
  plans: Plan[]
}

export interface ReportData {
  type: string
  company_type_eq: string
  date_lt: string | Date
  date_gt: string | Date
}

export type ReportsState = {
  requestAltasStatus: RequestStatus
  report: ResponseReportAltas | null

  requestOfRechargesStatus: RequestStatus
  reportOfRecharges: ResponseReportOfRecharges | null

  requestOfPackagesStatus: RequestStatus
  reportOfPackages: ResponseReportOfPackage | null
}

export enum ReportActions {
  RequestReportAltas = 'REQUEST_REPORT_ALTAS',
  RequestSuccessReportAltas = 'REQUEST_SUCCESS_REPORT_ALTAS',
  RequestErrorReportAltas = 'REQUEST_ERROR_REPORT_ALTAS',
  ResetReportAltas = 'RESET_REPORT_ALTAS',

  RequestReportOfRecharges = 'REQUEST_REPORT_OF_RECHARGES',
  RequestSuccessReportOfRecharges = 'REQUEST_SUCCESS_REPORT_OF_RECHARGES',
  RequestErrorReportOfRecharges = 'REQUEST_ERROR_REPORT_OF_RECHARGES',
  ResetReportOfRecharges = 'RESET_REPORT_OF_RECHARGES',

  RequestReportOfPackage = 'REQUEST_REPORT_OF_PACKAGES',
  RequestSuccessReportOfPackage = 'REQUEST_SUCCESS_REPORT_OF_PACKAGES',
  RequestErrorReportOfPackage = 'REQUEST_ERROR_REPORT_OF_PACKAGES',
  ResetReportOfPackage = 'RESET_REPORT_OF_PACKAGES',
}

export type ReportAltasPayload = {
  [ReportActions.RequestReportAltas]: undefined
  [ReportActions.RequestSuccessReportAltas]: AxiosResponse<ResponseReportAltas>
  [ReportActions.RequestErrorReportAltas]: undefined
  [ReportActions.ResetReportAltas]: undefined

  // Report Recharges
  [ReportActions.RequestReportOfRecharges]: undefined
  [ReportActions.RequestSuccessReportOfRecharges]: AxiosResponse<ResponseReportOfRecharges>
  [ReportActions.RequestErrorReportOfRecharges]: undefined
  [ReportActions.ResetReportOfRecharges]: undefined

  [ReportActions.RequestReportOfPackage]: undefined
  [ReportActions.RequestSuccessReportOfPackage]: AxiosResponse<ResponseReportOfPackage>
  [ReportActions.RequestErrorReportOfPackage]: undefined
  [ReportActions.ResetReportOfPackage]: undefined
}

export type ReportAltasReducerActionMap = ActionMapData<ReportAltasPayload>

export type ReportContextData = {
  Element: React.ReactNode | undefined

  /**
   * Request de recargas
   */
  requestAltasStatus: RequestStatus

  report: ResponseReportAltas | null

  /**
   * Request de recargas
   */
  reportOfRecharges: ResponseReportOfRecharges | null

  requestOfRechargesStatus: RequestStatus

  /**
   * Request de pacotes
   */
  requestOfPackagesStatus: RequestStatus

  reportOfPackages: ResponseReportOfPackage | null

  /**
   *Função para renderizar o componente de relatório
   * @param report
   */
  renderReport(report: ReportData): Promise<void>

  getReportAltas(data: ReportData): Promise<void>

  getReportOfPackages(data: ReportData): Promise<void>

  getReportOfRecharges(data: ReportData): Promise<void>
}
