import Typography from 'components/Typography'
import styled from 'styled-components'
import { largeShadow } from 'styles/mixins/shadows'

import MUAutocomplete from '@material-ui/lab/Autocomplete'

export const Autocomplete = styled(MUAutocomplete)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 6px;

  .PrivateNotchedOutline-root-16 {
    border-width: 1.5px;
  }

  .MuiOutlinedInput-root {
    padding-right: ${({ theme }) => theme.spacing(2)}px !important;
    border-radius: 6px;
    height: ${({ theme }) => theme.spacing(6)}px;
  }
  .MuiAutocomplete-popupIndicator {
    display: none;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 0 4px;
  }
`
export const ContainerOptions = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 6px;
  padding-bottom: 10px;
  ${largeShadow}
`

export const NewCompanyOption = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`
export const ContainerNewCompanyOption = styled.div`
  padding-left: 15px;
  cursor: pointer;
`

export const Icon = styled.img`
  margin-left: 5px;
`
