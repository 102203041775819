import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  useMemo,
  memo,
} from 'react'

import { Step2Props } from 'components/FormCompany/props'
import Formik from 'components/Formik'
import TextField from 'components/Inputs/TextField'
import { useStepper } from 'hooks/formStepper'
import * as Yup from 'yup'

import Grid from '@material-ui/core/Grid'
import { GoogleMap, Marker } from '@react-google-maps/api'

import { useFormCompany } from '../../context'
import AutocompleteCity from '../AutocompleteCity'
import AutocompletePlaces from '../AutocompletePlaces'
import AutocompleteState from '../AutocompleteState'
import ControlerSteps from '../ControllerSteps'
import Stepper from '../Stepper'
import schema from './schema'
import * as Styled from './styles'

const Step2: FC = () => {
  const {
    setActualStepForm,
    dataFormCompany,
    setDataFormCompany,
  } = useFormCompany()
  const [marker, setMarker] = useState({ lat: 0, lng: 0 })
  const [errorLocation, setErrorLocation] = useState(false)
  const { nextStep } = useStepper()

  useEffect(() => {
    if (dataFormCompany.latitude && dataFormCompany.longitude) {
      setMarker({
        lat: parseFloat(dataFormCompany.latitude),
        lng: parseFloat(dataFormCompany.longitude),
      })
    }
  }, [dataFormCompany.latitude, dataFormCompany.longitude])

  const renderCenterMap = useMemo(() => {
    if (marker.lat === 0 && marker.lng === 0) {
      // Brisanet geoIndex
      return { lat: -6.168473, lng: -38.490263 }
    }
    return marker
  }, [marker])

  const handleSubmit = useCallback(
    async (data: Step2Props) => {
      data.latitude = marker.lat.toString()
      data.longitude = marker.lng.toString()

      try {
        const schema = Yup.object().shape({
          phone: Yup.string().required('Telefone é obrigatório'),
          email: Yup.string().required('E-mail é obrigatório'),
          latitude: Yup.number().notOneOf([0]),
          longitude: Yup.number().notOneOf([0]),
        })
        await schema.validate(data, { abortEarly: false })

        setDataFormCompany({ ...dataFormCompany, ...data })

        // Verifica se a etapa atual do form é de revisão. Caso seja de revisão os estados de controle do form não são alterados
        setActualStepForm(2)
        nextStep()
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setErrorLocation(true)
        }
      }
    },
    [
      marker.lat,
      marker.lng,
      setDataFormCompany,
      dataFormCompany,
      setActualStepForm,
      nextStep,
    ],
  )

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  }

  const renderErrorLocation = useMemo(() => {
    errorLocation && (
      <Styled.ValidationMap variant="caption" color="error">
        Localização necessária no mapa
      </Styled.ValidationMap>
    )
  }, [errorLocation])

  return (
    <Formik
      initialValues={dataFormCompany}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Stepper />
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            name="email"
            label="E-mail da empresa *"
            type="email"
            defaultValue={dataFormCompany.email}
            autoFocus
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            name="phone"
            label="Telefone da empresa *"
            placeholder="DDD + Número"
            mask="(99) 99999-9999"
            defaultValue={dataFormCompany.phone}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <AutocompleteState />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <AutocompleteCity />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <TextField
            name="street"
            label="Rua/avenida"
            placeholder="ex: Rua exemplo ou Avenida exemplo"
            defaultValue={dataFormCompany.street}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <TextField
            name="district"
            label="Bairro"
            defaultValue={dataFormCompany.district}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <TextField
            name="number"
            label="Número"
            type="number"
            defaultValue={String(dataFormCompany.number)}
          />
        </Grid>
        <Styled.GridTitle item lg={12} md={12} sm={12} xs={12}>
          <Styled.Location variant="subtitle1" color="primary">
            Selecione a localização
          </Styled.Location>
        </Styled.GridTitle>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <AutocompletePlaces setMarker={setMarker} />
        </Grid>
        <Styled.MapContainer item lg={12} md={12} sm={12} xs={12}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: 250 }}
            zoom={18}
            center={renderCenterMap}
            options={options}
            onClick={event => {
              setMarker({ lat: event.latLng.lat(), lng: event.latLng.lng() })
            }}
          >
            <Marker position={{ lat: marker.lat, lng: marker.lng }} />
          </GoogleMap>
          {renderErrorLocation}
        </Styled.MapContainer>
        <ControlerSteps />
      </Grid>
    </Formik>
  )
}

export default memo(Step2)
