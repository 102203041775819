import React, { FC } from 'react'

import ptBR from 'date-fns/locale/pt-BR'
import AppProvider from 'hooks/index'
import { ThemeProvider as StyledProvider } from 'styled-components'
import GlobalStyles from 'styles/index'
import materialTheme from 'styles/themes/material-ui'

import DateFnsUtils from '@date-io/date-fns'
import {
  ThemeProvider as MaterialProvider,
  CssBaseline,
  StylesProvider,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import Routes from './routes'

const App: FC = () => {
  return (
    <MaterialProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <StylesProvider injectFirst>
          <StyledProvider theme={materialTheme}>
            <GlobalStyles />
            <CssBaseline />
            <AppProvider>
              <Routes />
            </AppProvider>
          </StyledProvider>
        </StylesProvider>
      </MuiPickersUtilsProvider>
    </MaterialProvider>
  )
}

export default App
