interface FormatDateCommissionProps {
  date: string
}

interface KeyMapProps {
  [key: number]: string
}

const formatDateCommission = (props: FormatDateCommissionProps): string => {
  const nameOfMonths: KeyMapProps = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
  }

  const { date } = props
  const [day, month, year] = date.split('-')

  if (!day) {
    return `${nameOfMonths[month]}-${year}`
  }
  return `${day}-${nameOfMonths[month]}`
}

export default formatDateCommission
