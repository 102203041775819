import styled from 'styled-components'

export const Container = styled.div`
  width: calc(100vw - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  top: 20px;
  overflow: hidden;
  z-index: 10010;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    right: 30px;
    top: 30px;
  }
`
