import { format } from 'date-fns'

interface FormateDateReportProps {
  date: Date | string
}

function customDateReport(props: FormateDateReportProps): string {
  const { date } = props

  return format(new Date(date), 'dd/MM/yyyy')
}

export default customDateReport
