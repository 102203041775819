/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, memo, useMemo } from 'react'

import Typography from 'components/Typography'
import Document from 'utils/document'
import customFormatDate from 'utils/formatDate'
import customFormatPrice from 'utils/formatPrice'

import ExportReport from './components/ExportReport'
import Filter from './components/Filter'
import { TableHeadProps, TableHeadWithExportReport } from './props'
import * as Styled from './styles'

const TableHead: FC<TableHeadProps> = props => {
  const {
    children,
    searchForm,
    searchByDate,
    exportReport,
    title,
    filter,
    filters,
    onClickFilter,
    ...rest
  } = props

  // const currentDate = new Date()

  // const renderSearchForm = useMemo(() => {
  //   if (!searchForm) return null
  //   const {
  //     handleInputChange,
  //     searchFormPlaceholder,
  //   } = props as TableHeadWithSearchForm
  //   return (
  //     <SearchField
  //       onChange={handleInputChange}
  //       placeholder={searchFormPlaceholder}
  //     />
  //   )
  // }, [props, searchForm])

  // const renderSearchDateForm = useMemo(() => {
  //   if (!searchByDate) return null

  //   const {
  //     handleSubmit,
  //     startDate,
  //     endDate,
  //     setEndDate,
  //     setStartDate,
  //   } = props as TableHeadWithSearchDateForm & TableHeadWithExportReport

  // const dataFormated = data.map(item => ({
  //   payment_provider_transaction_id: item?.payment_provider_transaction_id,
  //   sale_id: item?.sale_id.split('-')[0],
  //   name_city: `${item?.sale?.wallet_recipient?.company?.address?.city?.name}/ ${item?.sale?.wallet_recipient?.company?.address?.city?.state?.initials}`,
  //   fantasy_name: item?.sale?.wallet_recipient?.company?.fantasy_name,
  //   document: Document.format(
  //     item?.sale?.wallet_recipient?.company?.document,
  //   ),
  //   created_at_sale: customFormatDate({
  //     date: item?.sale?.created_at,
  //     format: 'dd/MM/yyyy',
  //   }),
  //   due_date: customFormatDate({
  //     date: item?.due_date,
  //     format: 'dd/MM/yyyy',
  //   }),
  //   days_in_overdue: isAfter(new Date(item?.due_date), currentDate)
  //     ? 0
  //     : eachDayOfInterval({
  //         start: new Date(item?.due_date),
  //         end: currentDate,
  //       }).length - 1,
  //   quantity_chips: item?.sale?.chips?.length,
  //   total_price: customFormatPrice({ price: item?.sale?.total_price }),
  //   created_at: customFormatDate({
  //     date: item?.created_at,
  //     format: 'dd/MM/yyyy',
  //   }),
  // }))

  //   return (
  //     <TableCell colSpan={1000}>
  //       <Styled.ContainerExportReport>
  //         <DateFilterOld
  //           handleSubmit={handleSubmit}
  //           startDate={startDate}
  //           endDate={endDate}
  //           setEndDate={setEndDate}
  //           setStartDate={setStartDate}
  //         />
  //       </Styled.ContainerExportReport>
  //     </TableCell>
  //   )
  // }, [searchByDate, props, currentDate])

  const renderExportReport = useMemo(() => {
    if (!exportReport) return null

    const {
      data,
      showExcelColumnDaysInOverdue,
      nameReport,
    } = props as TableHeadWithExportReport

    const dataFormated = data.map(item => ({
      payment_provider_transaction_id: item?.payment_provider_transaction_id,
      sale_id: item?.sale_id.split('-')[0],
      name_city: `${item?.sale?.wallet_recipient?.company?.address?.city?.name}/ ${item?.sale?.wallet_recipient?.company?.address?.city?.state?.initials}`,
      fantasy_name: item?.sale?.wallet_recipient?.company?.fantasy_name,
      document: Document.format(
        item?.sale?.wallet_recipient?.company?.document,
      ),
      created_at_sale: customFormatDate({
        date: item?.sale?.created_at,
        format: 'dd/MM/yyyy',
      }),
      due_date: customFormatDate({
        date: item?.due_date,
        format: 'dd/MM/yyyy',
      }),
      quantity_chips: item?.sale?.chips?.length,
      total_price: customFormatPrice({ price: item?.sale?.total_price }),
      created_at: customFormatDate({
        date: item?.created_at,
        format: 'dd/MM/yyyy',
      }),
    }))

    return (
      <ExportReport
        data={dataFormated}
        showExcelColumnDaysInOverdue={showExcelColumnDaysInOverdue}
        nameReport={nameReport}
      />
    )
  }, [exportReport, props])

  const TitleComponent = useMemo(() => {
    if (!title) return null

    return (
      <tr>
        <td colSpan={10000}>
          <Styled.TitleContainer>
            <Typography color="primary" variant="h5">
              {title}
            </Typography>
            <Styled.TitleContent>
              <Filter onClickFilter={onClickFilter} filter={filter} />

              {/* {renderFilter}
        {renderSearchForm}
        {renderSearchDateForm}
      {renderExportReport} */}

              {renderExportReport}
            </Styled.TitleContent>
          </Styled.TitleContainer>
        </td>
      </tr>
    )
  }, [title, renderExportReport, filter])

  return (
    <>
      <Styled.TableHead>{TitleComponent}</Styled.TableHead>
      <Styled.TableHead {...rest}>{children}</Styled.TableHead>
    </>
  )
}

export default memo(TableHead)
