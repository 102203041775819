import styled from 'styled-components'

import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'

export const Root = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px;
`

export const Pointer = styled.div`
  position: absolute;
  top: 1px;
  left: 215px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${({ theme }) => theme.palette.common.white};
  margin-left: -10px;
  margin-top: 5px;
`

export const ContainerOptions = styled.div`
  z-index: 1001;
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  overflow-y: auto;
  width: 250px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.border.main};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-right: 80px;
    max-height: 400px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-right: 180px;
  }
`

export const Option = styled(Typography)`
  padding: 16px 16px;
  cursor: pointer;
  transition: ease 0.2s;
  color: ${({ theme }) => theme.palette.primary.main};

  :nth-of-type(odd) {
    background-color: transparent;
  }
  :hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
