import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

import { MenuOptionActiveProps } from './props'

export const Option = styled(Link)`
  & > div {
    display: flex;
    padding: 10px 0px;
    cursor: pointer;
    transition: 0.3s ease-in;
  }
`

export const OptionLabel = styled.h4<MenuOptionActiveProps>`
  text-transform: capitalize;
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.grey[500]};
  font-style: normal;
  font-weight: 500;
`

export const ActiveLabel = styled.div<MenuOptionActiveProps>`
  display: flex;
  width: 100%;
  height: 42px;
  margin: 0 ${({ theme }) => theme.spacing(2)}px;
  border-radius: 6px;

  transition: ease 0.3s;

  :hover {
    background: #f0f0f4;
    ${props =>
      props.active &&
      css`
        background-color: rgba(93, 144, 227, 0.15);
      `};
  }

  ${props =>
    props.active
      ? css`
          background-color: rgba(93, 144, 227, 0.15);
        `
      : css`
          background-color: transparent;
        `};
`

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
`

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 15px;
  pointer-events: none;
`
