export const OPTIONS_SELECT_ALL = [
  {
    name: 'Todos',
    value: 'all',
  },
  {
    name: 'Parceiro',
    value: 'partner',
  },
  {
    name: 'Franqueado',
    value: 'franchisee',
  },
  {
    name: 'Por nome',
    value: 'name',
  },
]

export const OPTIONS_SELECT_DISTRIBUTOR = [
  {
    name: 'Parceiro',
    value: 'partner',
  },
  {
    name: 'Por nome',
    value: 'name',
  },
]

export type TypesFilters = 'all' | 'partner' | 'franchisee' | 'name' | ''
