/* eslint-disable no-underscore-dangle */
import React, { FC, memo, useCallback, useMemo } from 'react'

import { Obj } from 'interfaces/common'
import { ReportType } from 'pages/Product/context/props'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import formatDateCommission from 'utils/formatDateCommission'
import customFormatPrice from 'utils/formatPrice'

import { useTheme } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import { useProduct } from '../../../context'
import {
  RechargePackageDischargesProps,
  CustomLabelTooltipProps,
} from './props'
import * as Styled from './styles'

const RechargePackageDischarges: FC = () => {
  const { dataChart, statusChart, dataChartParams } = useProduct()

  const theme = useTheme()

  const colorByReportType = useMemo(() => {
    const colors: RechargePackageDischargesProps = {
      recharges: theme.palette.chart.primary,
      packages: theme.palette.chart.primary,
      discharges: theme.palette.chart.secondary,
    }

    if (!dataChartParams.report_type) return colors['recharges']

    return colors[dataChartParams.report_type]
  }, [
    dataChartParams.report_type,
    theme.palette.chart.primary,
    theme.palette.chart.secondary,
  ])

  const customTitleChart: Obj = useMemo(
    () => ({
      recharges: 'recargas',
      packages: 'pacotes',
      discharges: 'altas',
    }),
    [],
  )

  const dataSet = useMemo(() => {
    if (!statusChart.loading) {
      return dataChart
    }

    return null
  }, [dataChart, statusChart.loading])

  const customLabelTooltip = useMemo(() => {
    const labels: CustomLabelTooltipProps = {
      recharges: { labelOne: 'Quantidade', labelTwo: 'Valor (R$)' },
      packages: { labelOne: 'Quantidade', labelTwo: 'Valor (R$)' },
      discharges: { labelOne: 'Altas', labelTwo: 'Baixas' },
    }

    if (!dataChartParams.report_type) return labels['recharges']

    return labels[dataChartParams.report_type]
  }, [dataChartParams.report_type])

  const dataChartTypeKey = useMemo(() => {
    const typesKeys: Record<ReportType, [string, string]> = {
      recharges: ['count', 'total_period'],
      packages: ['count', 'total_period'],
      discharges: ['up', 'down'],
    }

    return typesKeys[dataChartParams.report_type || 'recharges']
  }, [dataChartParams.report_type])

  const CustomTooltip = useCallback(
    ({ active, payload, label }) => {
      if (active && payload) {
        return (
          <Styled.ContainerTooltip>
            <Styled.Date>Data: {label}</Styled.Date>
            <Styled.Value type={dataChartParams.report_type!}>
              {customLabelTooltip.labelTwo}:{' '}
              {dataChartParams.report_type === 'recharges' ||
              dataChartParams.report_type === 'packages'
                ? customFormatPrice({
                    price: payload[1].value,
                    showCurrencyPreffix: false,
                  })
                : payload[1].value}
            </Styled.Value>
            <Styled.Quantity type={dataChartParams.report_type!}>
              {customLabelTooltip.labelOne}: {payload[0].value}
            </Styled.Quantity>
          </Styled.ContainerTooltip>
        )
      }

      return null
    },
    [
      customLabelTooltip.labelOne,
      customLabelTooltip.labelTwo,
      dataChartParams.report_type,
    ],
  )

  if (statusChart.loading) {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Styled.Card>
          <CardContent>
            <Grid item>
              <Styled.Skeleton variant="rect" style={{ marginBottom: 20 }} />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" width="100%" height={371} />
            </Grid>
          </CardContent>
        </Styled.Card>
      </Grid>
    )
  }

  if (dataSet == null) return null

  return (
    <Styled.Container>
      <Styled.Content data-testid="container-commissions-chart">
        {dataChartParams.report_type && (
          <Styled.TitleGraph variant="h5" color="primary">
            Gráfico de {customTitleChart[dataChartParams.report_type]}
          </Styled.TitleGraph>
        )}

        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={dataSet}
            stackOffset="sign"
            margin={{
              top: 20,
              right: 20,
            }}
          >
            <XAxis
              dataKey={value =>
                `${value._id.day ? value._id.day : ''}-${value._id.month}-${
                  value._id.year
                }`
              }
              tickFormatter={value => {
                return formatDateCommission({ date: value })
              }}
            />

            <YAxis />
            <Tooltip cursor={{ fill: '#E0E1E6' }} content={<CustomTooltip />} />
            <Tooltip />

            <Legend
              formatter={value => {
                const keyMap = {
                  count: 'Quantidade',
                  total_period: 'Valor (R$)',
                  up: 'Altas',
                  down: 'Baixas',
                }
                if (keyMap[value]) return keyMap[value]
                return value
              }}
            />

            <Bar
              dataKey={dataChartTypeKey[0]}
              fill={colorByReportType.colorPrimary}
              label={{
                position: 'top',
              }}
            />
            <Bar
              dataKey={dataChartTypeKey[1]}
              fill={colorByReportType.colorSecondary}
              label={{
                position: 'top',
                formatter: value => {
                  if (
                    dataChartParams.report_type === 'recharges' ||
                    dataChartParams.report_type === 'packages'
                  ) {
                    return customFormatPrice({
                      price: Number(value),
                      showCurrencyPreffix: false,
                    })
                  }
                  return value
                },
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Styled.Content>
    </Styled.Container>
  )
}

export default memo(RechargePackageDischarges)
