import React, { FC, memo } from 'react'

import { AuthProvider } from './auth'
import { ProductProvider } from './product'
import { ToastProvider } from './toast'

type AppProviderProps = {
  children: React.ReactNode
}
const AppProvider: FC = props => {
  const { children } = props

  return (
    <ToastProvider>
      <AuthProvider>
        <ProductProvider>{children}</ProductProvider>
      </AuthProvider>
    </ToastProvider>
  )
}

export default memo<AppProviderProps>(AppProvider)
