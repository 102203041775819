import { PaginationState } from 'services/pagination'

import { User } from './props'

const initialState: PaginationState<User> = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  data: [],
  current_page: 1,
  from: 0,
  per_page: 10,
  total: 0,
  to: 0,
  next_page: null,
  prev_page: null,
}

export { initialState }
