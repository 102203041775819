import React, { FC } from 'react'

import Card from 'components/Card'
import Formik from 'components/Formik'
import TextField from 'components/Inputs/TextField'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Typography from 'components/Typography'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import AutocompleteCity from './components/AutocompleteCity'
import AutocompleteState from './components/AutocompleteState'
import { useNewStock, NewStockProvider } from './context'
import schema from './schema'
import * as Styled from './styles'

const NewStock: FC = () => {
  const { createStock, requestStatus } = useNewStock()

  return (
    <Route title="Estoques" access="auth" requireRoles={['admin', 'dev']}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Estoques', link: RoutingService.STOCKS },
          { title: 'Novo estoque', link: '#' },
        ]}
        activeOption="stocks"
      >
        <PageTitle title="Novo estoque" />

        <Card shadow>
          <Formik
            onSubmit={createStock}
            validationSchema={schema}
            initialValues={{
              name: '',
              stockist: {
                name: '',
                email: '',
                phone: '',
              },
              city_id: '',
              state_id: '',
            }}
          >
            <Typography color="primary" variant="h6" capitalize="uppercase">
              Dados do Estoque
            </Typography>

            <Styled.Grid container spacing={2}>
              <Grid sm={6} md={4} item>
                <TextField label="Nome" name="name" />
              </Grid>
              <Grid sm={6} md={4} item>
                <AutocompleteState />
              </Grid>
              <Grid sm={6} md={4} item>
                <AutocompleteCity />
              </Grid>
            </Styled.Grid>

            <Typography color="primary" variant="h6" capitalize="uppercase">
              Criar usuário estoquista
            </Typography>

            <Styled.Grid container spacing={2}>
              <Grid sm={6} md={4} item>
                <TextField label="Nome" name="stockist.name" />
              </Grid>
              <Grid sm={6} md={4} item>
                <TextField label="Email" name="stockist.email" />
              </Grid>
              <Grid sm={6} md={4} item>
                <TextField label="Telefone" name="stockist.phone" />
              </Grid>
            </Styled.Grid>

            <Styled.ButtonContainer>
              <Styled.Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                disabled={requestStatus.loading}
              >
                Cadastrar Estoque
              </Styled.Button>
            </Styled.ButtonContainer>
          </Formik>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(NewStock, NewStockProvider)
