import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import RequestError from 'components/DetailsPages/RequestError'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import Route from 'components/Route'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import CompanyDetailsPageTitle from './components/CompanyDetailsPageTitle'
import DetailsComponent from './components/DetailsComponent'
import RepresentativeCard from './components/RepresentativeCard'
import TablePurchases from './components/TablePurchases'
import { CompanyDetailProvider, useCompanyDetail } from './context'

const CompanyDetails: FC = () => {
  const { companyId } = useParams<any>()
  const { getCompany, company, requestStatus } = useCompanyDetail()

  useEffectOnce(() => {
    getCompany(companyId)
  })

  const Content = useCallback(() => {
    if (requestStatus.success) return <DetailsComponent />
    if (requestStatus.error) return <RequestError />
    return <Loading />
  }, [requestStatus])

  return (
    <Route
      title="Revendedor"
      access="auth"
      requireRoles={[]}
      showLoader={requestStatus.loading}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          { title: 'Detalhes de Revendedor' },
        ]}
        activeOption="companies"
      >
        <CompanyDetailsPageTitle />

        <Grid container spacing={4}>
          <Content />

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <RepresentativeCard representative={company?.representative} />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TablePurchases
              data={company?.wallet?.purchases}
              status={requestStatus}
            />
          </Grid>
        </Grid>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(CompanyDetails, CompanyDetailProvider)
