import React, { FC, useCallback, memo } from 'react'
import { useHistory } from 'react-router-dom'

import PageTitle from 'components/PageTitle'
import { useAuth } from 'hooks/auth'
import RoutingService from 'services/routing'

const CompanyDetailsPageTitle: FC = () => {
  const { push } = useHistory()

  const { hasRole } = useAuth()

  const redirectToNew = useCallback(() => push(RoutingService.NEW_COMPANY), [
    push,
  ])

  if (hasRole(['distributor', 'admin', 'dev'])) {
    return (
      <PageTitle
        title="Detalhes do revendedor"
        showButton
        buttonText="Novo Revendedor"
        buttonOnClick={redirectToNew}
      />
    )
  }
  return <PageTitle title="Detalhes do revendedor" />
}

export default memo(CompanyDetailsPageTitle)
