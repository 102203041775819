import ReactInputMask from 'react-input-mask'

import styled from 'styled-components'

import { Select } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

export const Card = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(3)}px
    ${({ theme }) => theme.spacing(4)}px;
  box-shadow: ${({ theme }) => `1px 2px 24px ${theme.palette.action.hover}`};
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
`
export const TitlePanel = styled(Typography)`
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const ContainerOptions = styled.div`
  display: flex;
  justify-content: center;
`

export const LabelTypeCompany = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const LabelOptions = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Input = styled(ReactInputMask)`
  height: 42px;
  border-radius: 5px;
  border-style: none;
  border: 1px solid #999;
  font-size: 14px;
  color: #333;
  padding: 12px;
  margin-right: 3px;
  margin-left: 3px;

  margin-bottom: 10px;
  width: 350px;
`
export const SelectOption = styled(Select)`
  width: 410px;
  height: 42px;
  margin-top: 8px;
  margin-bottom: 15px;
  margin-right: 10px;
`
export const CelPhoneNumber = styled(ReactInputMask)`
  height: 42px;
  border-radius: 5px;
  border-style: none;
  border: 1px solid #999;
  font-size: 14px;
  color: #333;
  padding: 12px;
  margin-right: 9px;
  margin-top: 7px;
  width: 350px;
`
