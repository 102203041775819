import React, { FC, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import RoutingService from 'services/routing'

import TableCell from '@material-ui/core/TableCell'

import { DistributorsListItemProps } from './props'
import * as Styled from './styles'

const DistributorListItem: FC<DistributorsListItemProps> = props => {
  const { user, id } = props

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.DISTRIBUTOR_DETAILS,
        'distributorId',
        id,
      ),
    [id],
  )

  return (
    <TableRowItem>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.Button color="primary" variant="outlined">
            Detalhes
          </Styled.Button>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(DistributorListItem)
