import ReactInputMask from 'react-input-mask'

import styled from 'styled-components'

import { Typography, Container as MUContainer } from '@material-ui/core'
import { TextField, Button } from '@material-ui/core'

export const Container = styled(MUContainer)``

export const Option = styled.div`
  margin-left: 20px;
`
export const ContainerInputs = styled.div`
  width: 1100px;
  padding-left: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 1380px) {
    font-size: 10px;
    width: 1000px;
    gap: 2px;
  }
  @media (max-width: 1280px) {
    width: 900px;
    gap: 2px;
  }
  @media (max-width: 1180px) {
    width: 780px;
    gap: 1px;
  }
`
export const HeaderTitle = styled(Typography)``

export const TextInput = styled(TextField)`
  width: 256px;
  min-width: 100px;
  @media (max-width: 1180px) {
    width: 180px;
  }
`

export const SendButton = styled(Button)`
  width: 100px;
  margin-left: 1px;
  padding: 2px;
  height: 55px;
  @media (max-width: 1180px) {
    width: 90px;
  }
`
export const Input = styled(ReactInputMask)`
  height: 54px;
  border-radius: 5px;
  border-style: none;
  border: 1px solid #999;
  font-size: 14px;
  color: #333;
  padding: 12px;
  margin-right: 2px;
  margin-left: 2px;

  margin-bottom: 10px;
  width: 130px;
  @media (max-width: 1380px) {
    width: 110px;
  }
`
