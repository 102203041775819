import styled from 'styled-components'

import Button from '@material-ui/core/Button'

export const ButtonAddUser = styled(Button)`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.success.light};
  text-transform: capitalize;
  width: 120px;
  height: 40px;
  color: ${({ theme }) => theme.palette.success.contrastText};
  box-shadow: none;

  :hover {
    background-color: ${({ theme }) => theme.palette.success.light};
    box-shadow: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`
