import React, { FC, memo } from 'react'

import Card from 'components/Card'
import Typography from 'components/Typography'

const RequestError: FC = props => {
  return (
    <Card {...props}>
      <Typography color="primary" variant="h6" align="center">
        Ocorreu um erro ao receber informações
      </Typography>
    </Card>
  )
}

export default memo(RequestError)
