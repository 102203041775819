import React, { FC, useMemo } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import { SaleProvider } from 'hooks/sales'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Card from './components/Card'
import products from './components/mock/data'
import { ProductsList } from './styles'

const Reports: FC = () => {
  const cardsProducts = useMemo(
    () =>
      products.map(product => (
        <Card
          key={`card_product_${product.name}`}
          name={product.name}
          route={product.route}
          icon={product.icon}
        />
      )),
    [],
  )

  return (
    <Route
      title="Relatórios"
      access="auth"
      requireRoles={[
        'admin',
        'attendant',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'office_stockist',
        'sale_leader',
        'pap_leader',
        'pap_seller',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Relatórios',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-reports"
      >
        <PageTitle title={'Relatórios'} />
        <ProductsList data-testid="container-products">
          {cardsProducts}
        </ProductsList>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Reports, SaleProvider)
