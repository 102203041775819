import Typography from 'components/Typography'
import styled from 'styled-components'
import { largeShadow } from 'styles/mixins/shadows'

import MUTextField from '@material-ui/core/TextField'
import MUAutocomplete from '@material-ui/lab/Autocomplete'

export const Autocomplete = styled(MUAutocomplete)`
  .PrivateNotchedOutline-root-16 {
    border-width: 1.5px;
  }
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  .MuiAutocomplete-popupIndicator {
    display: none;
  }
`
export const ContainerOptions = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  padding-bottom: 10px;
  ${largeShadow}
`

export const NewCompanyOption = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`
export const ContainerNewCompanyOption = styled.div`
  padding-left: 15px;
  cursor: pointer;
`

export const TextField = styled(MUTextField)`
  border-radius: 6px;

  .MuiOutlinedInput-root {
    height: ${({ theme }) => theme.spacing(6)}px;
    border-radius: 6px;
  }
`
