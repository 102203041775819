import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'

import FormProps from '../components/CreateChipForm/props'

export type CreateChipsState = {
  requestStatus: RequestStatus
  chips: FormProps
}

export enum CreateChipsActions {
  RequestCreateChips = 'REQUEST_CREATE_CHIPS',
  RequestSuccessCreateChips = 'REQUEST_SUCCESS_CREATE_CHIPS',
  RequestErrorCreateChips = 'REQUEST_ERROR_CREATE_CHIPS',

  Reset = 'RESET',
}

export type CreateChipsPayload = {
  [CreateChipsActions.RequestCreateChips]: undefined
  [CreateChipsActions.RequestSuccessCreateChips]: AxiosResponse<any>
  [CreateChipsActions.RequestErrorCreateChips]: undefined

  [CreateChipsActions.Reset]: undefined
}

export interface DataFormAddChipsType {
  first_iccid: string
  last_iccid: string
}

export type CreateChipsContextProps = CreateChipsState & {
  requestStatus: RequestStatus

  /**
   * Adiciona Chips na Base
   */
  addChips(data: any, id: string, biggerThanOneHundred?: boolean): Promise<void>

  /**
   * Define o estado do modal
   */
  modalActive: boolean

  /**
   * Abre o modal
   */
  openModal(): void

  /**
   * Fecha o modal
   */
  closeModal(): void

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}

export type CreateChipsReducerActionMap = ActionMapData<CreateChipsPayload>
