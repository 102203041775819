import React, { FC, memo, useCallback, useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'

import Button from 'components/Button'
import Card from 'components/Card'
import DetailsPageItem from 'components/DetailsPages/DetailsItem'
import DetailsPageSection from 'components/DetailsPageSection'
import QuantityChipsCard from 'components/QuantityChipsCard'
import RenderWithRoles from 'components/RenderWithRoles'
import Typography from 'components/Typography'
import RoutingService from 'services/routing'
import Document from 'utils/document'
import { formatPhone } from 'utils/formatPhone'

import Grid from '@material-ui/core/Grid'

import { useCompanyDetail } from '../../context'
import * as Styled from './styles'

const DetailsComponent: FC = () => {
  const { companyId } = useParams<any>()
  const { company, requestStatus } = useCompanyDetail()

  const updatePageUrl = useCallback(
    () =>
      RoutingService.getRouteString(
        RoutingService.COMPANY_UPDATE,
        'companyId',
        companyId,
      ),
    [companyId],
  )

  const buildReport = useCallback(
    () =>
      RoutingService.getRouteString(
        RoutingService.BUILD_REPORT,
        'companyId',
        companyId,
      ),
    [companyId],
  )

  const commissions = useCallback(
    () =>
      RoutingService.getRouteString(
        RoutingService.COMMISSIONS,
        'companyId',
        companyId,
      ),
    [companyId],
  )

  const bonus = useCallback(
    () =>
      RoutingService.getRouteString(
        RoutingService.BONUS,
        'companyId',
        companyId,
      ),
    [companyId],
  )

  const type = useMemo(
    () => (company?.type === 'partner' ? 'Parceira' : 'Franqueada'),
    [company],
  )

  const renderDocument = useMemo(() => {
    if (requestStatus.loading || requestStatus.error || !company) return null

    return (
      <>
        <Typography color="initial" variant="subtitle1">
          {Document.type(company.document)}
        </Typography>
        <Typography color="primary" variant="h5">
          {Document.format(company.document)}
        </Typography>
      </>
    )
  }, [requestStatus, company])

  const status = useMemo(() => {
    if (requestStatus.loading || requestStatus.error) return ''
    return company?.active ? 'Ativo' : 'Inativo'
  }, [requestStatus, company])

  const phone = useMemo(() => {
    if (requestStatus.loading || requestStatus.error || !company) return ''
    if (!company.phone) return ''
    return formatPhone('(XX) X XXXX XXXX', company.phone)
  }, [requestStatus, company])

  const formatedAddressContact = useMemo(() => {
    if (
      !company?.address.street ||
      !company?.address.district ||
      !company?.address.number
    )
      return 'Atualizar endereço de contato'

    return `${company?.address.street}, N° ${company?.address.number}, Bairro ${company?.address.district}`
  }, [
    company?.address.district,
    company?.address.number,
    company?.address.street,
  ])

  const buttonCommission = useMemo(() => {
    return company?.commission_model === 'commission' ? (
      <RenderWithRoles roles={['distributor', 'admin', 'dev', 'financial']}>
        <Link to={commissions}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            data-cy="commission__button"
          >
            Comissões
          </Button>
        </Link>
      </RenderWithRoles>
    ) : (
      ''
    )
  }, [commissions, company?.commission_model])

  const buttonBonus = useMemo(() => {
    return company?.commission_model === 'bonus' ? (
      <RenderWithRoles roles={['distributor', 'admin', 'dev', 'financial']}>
        <Link to={bonus}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            data-cy="commission__button"
          >
            Bonificação
          </Button>
        </Link>
      </RenderWithRoles>
    ) : (
      ''
    )
  }, [bonus, company?.commission_model])

  return (
    <Grid sm={12} item data-cy="detail-company__div">
      <Styled.CardsQuantityChips>
        <QuantityChipsCard
          title="Chips comprados"
          value={company?.wallet.chips_count}
          loading={requestStatus.loading}
        />
      </Styled.CardsQuantityChips>

      <Card shadow>
        <DetailsPageSection title="informações">
          <Grid item lg={5} md={5} sm={12} xs={12}>
            {renderDocument}
          </Grid>
          <DetailsPageItem
            title="Tipo"
            lg={4}
            md={4}
            sm={12}
            capitalize="uppercase"
          >
            {type}
          </DetailsPageItem>

          <DetailsPageItem
            title="Status"
            lg={3}
            md={3}
            sm={12}
            capitalize="uppercase"
          >
            {status}
          </DetailsPageItem>

          <DetailsPageItem
            title="Nome Fantasia"
            lg={5}
            md={5}
            sm={12}
            capitalize="uppercase"
          >
            {company?.fantasy_name}
          </DetailsPageItem>

          <DetailsPageItem title="Telefone" lg={7} md={7} sm={12}>
            {phone}
          </DetailsPageItem>

          <DetailsPageItem
            title="Razão Social"
            lg={5}
            md={5}
            sm={12}
            capitalize="uppercase"
          >
            {company?.corporate_name || '-'}
          </DetailsPageItem>

          <DetailsPageItem title="E-mail" lg={7} md={7} sm={12}>
            {company?.email}
          </DetailsPageItem>

          <DetailsPageItem title="Estado" sm={3}>
            {company?.address.city.state.name}
          </DetailsPageItem>
          <DetailsPageItem title="Cidade" sm={3}>
            {company?.address.city.name}
          </DetailsPageItem>

          <DetailsPageItem title="Endereço de contato" sm={12} md={7} lg={7}>
            {formatedAddressContact}
          </DetailsPageItem>

          <DetailsPageItem title="Total de Bonus" sm={12} md={7} lg={7}>
            {company?.total_bonus}
          </DetailsPageItem>
        </DetailsPageSection>

        <Styled.ButtonContainer>
          <RenderWithRoles roles={['distributor', 'admin', 'dev']}>
            <Link to={buildReport}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                data-cy="reports__button"
              >
                Relatórios
              </Button>
            </Link>
          </RenderWithRoles>

          {buttonCommission}
          {buttonBonus}

          <RenderWithRoles roles={['distributor', 'admin', 'dev']}>
            <Link to={updatePageUrl}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                data-cy="update-company__button"
              >
                Atualizar
              </Button>
            </Link>
          </RenderWithRoles>
        </Styled.ButtonContainer>
      </Card>
    </Grid>
  )
}

export default memo(DetailsComponent)
