import React, { FC, memo } from 'react'

import Typography from 'components/Typography'

import TableCell, { TableCellProps } from '@material-ui/core/TableCell'

const TableCellHead: FC<Partial<TableCellProps>> = props => {
  const { children } = props

  return (
    <TableCell {...props}>
      <Typography color="primary" variant="subtitle1">
        {children}
      </Typography>
    </TableCell>
  )
}

export default memo(TableCellHead)
