import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { CompanyDetail } from 'interfaces/company'
import { api } from 'services/api'

import { CompanyDetailActions, CompanyDetailContextData } from './props'
import CompanyDetailReducer, { initialState } from './reducer'

const initialValue = {} as CompanyDetailContextData

export const DetailCompanyContext = createContext(initialValue)

export const CompanyDetailProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(CompanyDetailReducer, initialState)

  const getCompany = useCallback(async (id: string) => {
    try {
      if (!id) {
        throw Error('Company ID cannot be empty')
      }

      dispatch({ type: CompanyDetailActions.Request })

      const companyData = await api.get<CompanyDetail>(`/companies/${id}`)
      const bonus = await api.get<any>(`/companies/${id}/bonus`)

      let response = companyData
      response.data.total_bonus = bonus.data.total_bonus

      return dispatch({
        type: CompanyDetailActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      return dispatch({ type: CompanyDetailActions.RequestError })
    }
  }, [])

  const resetState = useCallback(
    () => dispatch({ type: CompanyDetailActions.Reset }),
    [],
  )

  return (
    <DetailCompanyContext.Provider
      value={{
        ...state,
        getCompany,
        resetState,
      }}
    >
      {children}
    </DetailCompanyContext.Provider>
  )
}

export function useCompanyDetail(): CompanyDetailContextData {
  const context = useContext(DetailCompanyContext)

  if (!context || context === initialValue) {
    throw new Error(
      'useCompanyDetail must be used within a CompanyDetailProvider',
    )
  }

  return context
}
