import React, { FC } from 'react'

import TableHead from 'components/TableHead'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { Box } from './styles'

const CashFlowListHeader: FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Box>Vendedor</Box>
        </TableCell>
        <TableCell>
          <Box>ICCID</Box>
        </TableCell>
        <TableCell>
          <Box>Valor</Box>
        </TableCell>
        <TableCell>
          <Box>Tipo de venda</Box>
        </TableCell>
        <TableCell>
          <Box>Pacote venda</Box>
        </TableCell>
        <TableCell>
          <Box>Pacote Auditado</Box>
        </TableCell>
        <TableCell>
          <Box>status</Box>
        </TableCell>
        <TableCell>
          <Box>Nome</Box>
        </TableCell>
        <TableCell>
          <Box>CPF</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default CashFlowListHeader
