/* eslint-disable react/destructuring-assignment */
import React from 'react'

import * as Styled from './styles'

const Logout = props => {
  return (
    <Styled.SvgContainer {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0161 7.38951V6.45651C15.0161 4.42151 13.3661 2.77151 11.3311 2.77151H6.45609C4.42209 2.77151 2.77209 4.42151 2.77209 6.45651V17.5865C2.77209 19.6215 4.42209 21.2715 6.45609 21.2715H11.3411C13.3701 21.2715 15.0161 19.6265 15.0161 17.5975V16.6545"
          stroke={props.stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.8097 12.0214H9.76868"
          stroke={props.stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.8812 9.10631L21.8092 12.0213L18.8812 14.9373"
          stroke={props.stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Styled.SvgContainer>
  )
}

export default Logout
