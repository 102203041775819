import React, { FC, memo, useMemo } from 'react'

import { Typography } from 'components/Typography/styles'
import Table from 'pages/ReportPages/components/Table'
import TableCellBody from 'pages/ReportPages/components/TableCellBody'
import TableCellHead from 'pages/ReportPages/components/TableCellHead'
import formatPrice from 'utils/formatPrice'

import TableRow from '@material-ui/core/TableRow'

import { Recharge } from '../../../../context/props'
import { ReportDetailsProps } from '../../props'
import * as Styled from './styles'

const TableRecharges: FC<ReportDetailsProps> = ({ data }) => {
  const tableHead = useMemo(() => {
    return (
      <>
        <TableCellHead> Recargas </TableCellHead>
        <TableCellHead align="right"> Quantidade </TableCellHead>
        <TableCellHead align="right"> Valor liquído </TableCellHead>
      </>
    )
  }, [])

  const tableBody = useMemo(() => {
    if (data?.recharges.length == 0) {
      return (
        <TableRow>
          <TableCellBody
            capitalizeText="uppercase"
            component="th"
            scope="row"
            colSpan={3}
            align="center"
          >
            <Typography variant="subtitle1"> Sem dados </Typography>
          </TableCellBody>
        </TableRow>
      )
    }

    return data?.recharges.map((recharge: Recharge) => (
      <TableRow>
        <TableCellBody capitalizeText="uppercase" component="th" scope="row">
          {recharge.value}
        </TableCellBody>
        <Styled.TableCellBody align="right">
          {recharge.quantity}
        </Styled.TableCellBody>
        <Styled.TableCellBody align="right">
          {formatPrice({ price: recharge.total_value })}
        </Styled.TableCellBody>
      </TableRow>
    ))
  }, [data?.recharges])

  return (
    <Table
      title="Detalhamento recargas"
      tableHead={tableHead}
      tableBody={tableBody}
    />
  )
}

export default memo(TableRecharges)
