import React, { FC, memo } from 'react'

import Typography from 'components/Typography'

import Grid from '@material-ui/core/Grid'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'

import { TableProps } from './props'
import * as Styled from './styles'

const Table: FC<TableProps> = props => {
  const { title, tableHead, tableBody } = props

  return (
    <Grid container>
      <TableContainer>
        <Typography color="primary" variant="h5">
          {title}
        </Typography>

        <Styled.Table size="small" padding="none">
          <TableHead>
            <Styled.TableRow>{tableHead}</Styled.TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Styled.Table>
      </TableContainer>
    </Grid>
  )
}

export default memo(Table)
