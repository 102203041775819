import React, { useMemo } from 'react'

import TableRowItem from 'components/TableRowItem'

import CircularProgress from '@material-ui/core/CircularProgress'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

import { TableProps } from './props'
import * as Styled from './styles'

function Table<ItemT extends any>(props: TableProps<ItemT>) {
  const {
    children,
    FooterComponent,
    HeaderComponent,
    renderItem,
    data,
    emptyMessage,
    loadingRequest,
    disableShadow,
    className,
    ...rest
  } = props

  const renderItems = useMemo(() => {
    if (loadingRequest) {
      return (
        <TableRowItem>
          <TableCell colSpan={1000} align="center">
            <CircularProgress size={20} />
            <Typography variant="subtitle1">Carregando</Typography>
          </TableCell>
        </TableRowItem>
      )
    }

    if (!data || data.length === 0) {
      return (
        <TableRowItem>
          <TableCell colSpan={1000} align="center">
            <Typography variant="subtitle1">
              {emptyMessage || 'Nenhum encontrado'}
            </Typography>
          </TableCell>
        </TableRowItem>
      )
    }

    return data.map(renderItem)
  }, [data, emptyMessage, loadingRequest, renderItem])

  const classes = useMemo(() => {
    if (disableShadow) return [className, 'hide-shadow'].join(' ')

    return className
  }, [disableShadow, className])

  return (
    <Styled.TableContainer>
      <Styled.Table className={classes} data-cy="table__div" {...rest}>
        {HeaderComponent}

        <TableBody>{renderItems}</TableBody>

        {FooterComponent}
      </Styled.Table>
    </Styled.TableContainer>
  )
}

// Se usar o Memo aqui, os tipos customizados dão problema por algum motivo que desconheço
export default Table
