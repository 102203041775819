import styled from 'styled-components'

import MUTextField from '@material-ui/core/TextField'
import MUAutocomplete from '@material-ui/lab/Autocomplete'

export const Autocomplete = styled(MUAutocomplete)`
  .PrivateNotchedOutline-root-16 {
    border-width: 1.5px;
  }
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  .MuiAutocomplete-popupIndicator {
    display: none;
  }
`

export const TextField = styled(MUTextField)`
  border-radius: 6px;

  .MuiOutlinedInput-root {
    height: ${({ theme }) => theme.spacing(6)}px;
    border-radius: 6px;
  }
`
