import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'

import { NewStockActions, NewStockContextData, CreateStockData } from './props'
import stocksReducer, { initialState } from './reducer'

const initialValue = {} as NewStockContextData

export const NewStockContext = createContext(initialValue)

export const NewStockProvider: FC = props => {
  const { children } = props
  const [state, dispatch] = useReducer(stocksReducer, initialState)
  const { addToast } = useToast()

  const history = useHistory()

  const createStock = useCallback(
    async (stock: CreateStockData) => {
      try {
        if (!stock) {
          throw Error('Stock Data cannot be empty')
        }

        dispatch({ type: NewStockActions.Request })
        const { state_id, ...stockData } = stock

        await api.post(`/stocks/`, stockData)

        dispatch({
          type: NewStockActions.RequestSuccess,
        })
        addToast({
          title: 'Estoque criado!',
          description: 'Estoque criado com sucesso',
          type: 'success',
        })
        history.push(RoutingService.STOCKS)
      } catch (error) {
        addToast({
          title: 'Estoque não criado',
          description: ErrorHandler.getMessage(error),
          type: 'error',
        })
        dispatch({ type: NewStockActions.RequestError })
      }
    },
    [addToast, history],
  )

  const resetState = useCallback(
    () => dispatch({ type: NewStockActions.Reset }),
    [],
  )

  return (
    <NewStockContext.Provider
      value={{
        ...state,
        createStock,
        resetState,
      }}
    >
      {children}
    </NewStockContext.Provider>
  )
}

export function useNewStock(): NewStockContextData {
  const context = useContext(NewStockContext)

  if (!context || context === initialValue) {
    throw new Error('useNewStock must be used within a NewStockProvider')
  }

  return context
}
