import React, { FC, memo } from 'react'

import Typography from 'components/Typography'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

const SectionCalcTransfer: FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h5">
          Cálculo de repasse
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textPrimary" variant="subtitle1">
          Valor apurado
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Styled.BoldText color="textPrimary" variant="subtitle1">
          R$ 500,00
        </Styled.BoldText>
      </Grid>
      <Grid item xs={3}>
        <Typography color="textPrimary" variant="subtitle1">
          Dedução
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Styled.BoldText color="textPrimary" variant="subtitle1">
          -
        </Styled.BoldText>
      </Grid>
      <Grid item xs={3}>
        <Typography color="textPrimary" variant="subtitle1">
          Valor do repasse
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Styled.BoldText color="textPrimary" variant="subtitle1">
          R$ 500,00
        </Styled.BoldText>
      </Grid>
      <Grid item xs={3}>
        <Typography color="textPrimary" variant="subtitle1">
          Período apurado
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Styled.BoldText color="textPrimary" variant="subtitle1">
          de 01/10/2020 a 07/10/2020
        </Styled.BoldText>
      </Grid>
    </Grid>
  )
}

export default memo(SectionCalcTransfer)
