export const OPTIONS_SELECT_REPORT = [
  {
    name: 'Altas',
    value: 'discharges',
  },
  {
    name: 'Recargas',
    value: 'recharges',
  },
  {
    name: 'Pacotes',
    value: 'packages',
  },
]

export const OPTIONS_SELECT_PERIOD = [
  {
    name: 'Anual',
    value: 'yearly',
  },
  {
    name: 'Mensal',
    value: 'monthly',
  },
]
