import React, { FC, memo } from 'react'

import { useValidateCommission } from 'components/Modals/ValidateCommission/context'
import RenderWithRoles from 'components/RenderWithRoles'

import { PageTitleProps } from './props'
import * as Styled from './styles'

const PageTitle: FC<PageTitleProps> = props => {
  const { title } = props

  const { openModal: openModalValidation } = useValidateCommission()

  return (
    <Styled.Header>
      <Styled.HeaderTitle variant="h3" color="primary">
        {title}
      </Styled.HeaderTitle>

      <RenderWithRoles roles={['financial']}>
        <Styled.Button
          data-cy="page-title__button"
          color="primary"
          variant="outlined"
          size="large"
          disableElevation
          onClick={openModalValidation}
        >
          Validar Status
        </Styled.Button>
      </RenderWithRoles>

      {/* <Styled.Button
        data-cy="page-title__button"
        color="primary"
        variant="contained"
        size="large"
        disableElevation
        onClick={buttonOnClick}
      >
        Imprimir relatório
      </Styled.Button> */}
    </Styled.Header>
  )
}

export default memo(PageTitle)
