import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { PaginationParamsWithNameContains } from 'interfaces/common'
import { Distributor } from 'interfaces/distributor'
import { api } from 'services/api'
import { paginationReducer, PaginationActions } from 'services/pagination'

import { GetDistributorsContextData, GetDistributorsResponse } from './props'
import { initialState } from './reducer'

const initialValue = {} as GetDistributorsContextData

export const GetDistributorsContext = createContext(initialValue)

export const GetDistributorProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(
    paginationReducer<Distributor>(),
    initialState,
  )

  const getDistributors = useCallback(
    async (name_contains?: string) => {
      try {
        dispatch({ type: PaginationActions.Request })

        const params: PaginationParamsWithNameContains = {
          page: state.current_page,
          per_page: state.per_page,
        }

        if (name_contains) {
          params.name_contains = name_contains
        }

        const response = await api.get<GetDistributorsResponse>(
          '/distributors',
          {
            params,
          },
        )

        dispatch({
          type: PaginationActions.RequestSuccess,
          payload: response,
        })
      } catch (e) {
        dispatch({ type: PaginationActions.RequestError })
      }
    },
    [state],
  )

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <GetDistributorsContext.Provider
      value={{
        ...state,
        getDistributors,
        nextPage,
        previousPage,
        setLimit,
        setPage,
      }}
    >
      {children}
    </GetDistributorsContext.Provider>
  )
}

export function useGetDistributors(): GetDistributorsContextData {
  const context = useContext(GetDistributorsContext)

  if (!context || context === initialValue) {
    throw new Error(
      'useGetDistributors must be used within a GetDistributorProviders',
    )
  }

  return context
}
