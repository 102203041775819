import React, { FC, useCallback, useMemo, useRef } from 'react'

import Formik from 'components/Formik'
import { FormikProps } from 'formik'

import Grid from '@material-ui/core/Grid'

import { useProduct } from '../../../../context'
import schema from './schema'
import * as Styled from './styles'

const FormCommissionPackage: FC = () => {
  const {
    setEndDateCommission,
    setStartDateCommission,
    startDateCommission,
    endDateCommission,
  } = useProduct()

  const formikRef = useRef<FormikProps<any> | null>()

  const handleSubmit = useCallback(async values => {
    setStartDateCommission(values.startDateCommission)
    setEndDateCommission(values.endDateCommission)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetFilter = useCallback(() => {
    setStartDateCommission(null)
    setEndDateCommission(null)

    formikRef.current?.resetForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderButtonClearFilter = useMemo(() => {
    if (startDateCommission && endDateCommission !== null) {
      return (
        <Grid item xs={12} sm={3} md={3}>
          <Styled.Button
            variant="outlined"
            color="primary"
            onClick={handleResetFilter}
          >
            Limpar filtro
          </Styled.Button>
        </Grid>
      )
    }
    return <></>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateCommission, endDateCommission])

  return (
    <Formik
      initialValues={{ startDateCommission, endDateCommission }}
      innerRef={formikRef as any}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize={false}
      validateOnMount
    >
      <Grid container spacing={2} justify="flex-end">
        <Grid item xs={12} sm={3} md={3}>
          <Styled.DatePicker
            label="Data inicial"
            inputVariant="outlined"
            name="startDateCommission"
            format="MM/yyyy"
            views={['year', 'month']}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Styled.DatePicker
            label="Data final"
            inputVariant="outlined"
            name="endDateCommission"
            format="MM/yyyy"
            views={['year', 'month']}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Styled.Button variant="contained" color="primary">
            Filtrar
          </Styled.Button>
        </Grid>
        {renderButtonClearFilter}
      </Grid>
    </Formik>
  )
}

export default FormCommissionPackage
