import React, { FC, memo } from 'react'

import ButtonSubmit from 'components/ButtonSubmit'
import Formik from 'components/Formik'
import TextField from 'components/Inputs/TextField'
import Typography from 'components/Typography'
import { useSetNewPassword } from 'hooks/auth/password/SetNewPassword'

import { FormProps } from './props'
import schema from './schema'

const Form: FC<FormProps> = props => {
  const { token } = props
  const { setNewPassword, status } = useSetNewPassword()

  return (
    <Formik
      initialValues={{
        password: '',
        password_confirmation: '',
        token: token || '',
      }}
      onSubmit={setNewPassword}
      validationSchema={schema}
    >
      <Typography color="primary" variant="h5">
        Crie uma nova senha
      </Typography>

      <TextField
        name="password"
        label="Nova senha"
        placeholder="Digite sua senha"
        type="password"
      />

      <TextField
        name="password_confirmation"
        label="Confirmar senha"
        placeholder="Confirme sua senha"
        type="password"
      />

      <ButtonSubmit
        data-testid="button-enter"
        type="submit"
        color="secondary"
        variant="contained"
        size="large"
        disableElevation
        fullWidth
        disabled={status.loading}
      >
        Alterar senha
      </ButtonSubmit>
    </Formik>
  )
}

export default memo(Form)
