import { keyframes } from 'styled-components'

export const appearFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-100px)
}
to {
  opacity: 1;
  transform: translateX(0);
}
`

export const appearFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(100px)
}
to {
  opacity: 1;
  transform: translateX(0);
}
`
export const scaleOutCenter = keyframes`
from {
  transform: scale(1);
  opacity: 1;
}
to {
  transform: scale(0);
  opacity: 1;
}
`

export const scaleInCenter = keyframes`
from {
  transform: scale(0);
  opacity: 1;
}
to {
  transform: scale(1);
  opacity: 1;
}
`
