import styled from 'styled-components'

export const ContainerOptions = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    justify-content: flex-start;
  }
`
