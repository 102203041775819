interface Params {
  status_eq?: string
  office_id_eq?: string
  seller_user_id_eq?: string
  seller_role_name_eq?: string
  category_eq?: string
}

interface ParamsForRequest {
  type: string
  params: Params
}

export const LeavesParams = (props: ParamsForRequest): Params => {
  const defaults = {
    sales: [
      '_defaultcategory',
      '_storeChips',
      '_sellerId',
      '_defaultRole',
      '_defaultStatus',
    ],
    leaves_change: [
      '_defaultcategory',
      '_storeChips',
      '_sellerId',
      '_defaultRole',
    ],
  }
  const resultados = Object.entries(props.params)
  let filtro = resultados.map(array => {
    defaults[props.type].filter((item: string) => {
      if (array.includes(item)) {
        array[1] = undefined
        return array
      }
      return null
    })
    return array
  })
  let resultParams = {}
  filtro.forEach(elemento => {
    resultParams[elemento[0]] = elemento[1]
  })
  return resultParams
}
