interface EnableLeavesProps {
  documentLeaves: number | undefined
  iccidName: number
  category: number
  nameLeaves: number
  actions: number
}

interface EnableChangeProps {
  documentChange: number | undefined
  iccidName: number
  oldiccd: number
  category: number
  nameChange: number
  actions: number
}

export const EnableLeaves = (props: EnableLeavesProps): boolean => {
  const { actions, category, documentLeaves, iccidName, nameLeaves } = props

  let condition =
    documentLeaves &&
    documentLeaves > 10 &&
    iccidName > 8 &&
    (category > 1 || actions > 1) &&
    nameLeaves > 3

  if (condition) {
    return true
  } else {
    return false
  }
}

export const EnableChange = (props: EnableChangeProps): boolean => {
  const {
    actions,
    category,
    documentChange,
    iccidName,
    nameChange,
    oldiccd,
  } = props

  let condition =
    documentChange &&
    documentChange > 10 &&
    iccidName > 8 &&
    oldiccd > 8 &&
    (category > 1 || actions > 1) &&
    nameChange > 3

  if (condition) {
    return true
  } else {
    return false
  }
}
