import { AxiosResponse } from 'axios'
import { User } from 'hooks/auth/props'
import { ActionMapData } from 'hooks/props'

export type RequestStatus = {
  loading: boolean
  error: boolean
}

export type NewUserState = {
  requestStatus: RequestStatus
  user: User | null
}

export enum NewUserActions {
  RequestNewUser = 'REQUEST_NEW_USER',
  RequestSuccessNewUser = 'REQUEST_SUCCESS_NEW_USER',
  RequestErrorNewUser = 'REQUEST_ERROR_NEW_USER',
  Reset = 'RESET',
}

export type NewUserPayload = {
  [NewUserActions.RequestNewUser]: undefined
  [NewUserActions.RequestSuccessNewUser]: AxiosResponse<any>
  [NewUserActions.RequestErrorNewUser]: undefined

  [NewUserActions.Reset]: undefined
}

export interface DataFormAddUserType {
  name: string
  email: string
  phone: string
  role_name: string
  products_ids: string[]
  company_who_can_sell?: string[] | string
}

export type NewUserContextProps = {
  requestStatus: RequestStatus

  /**
   * Adiciona Usuário na Base
   */
  addUser(data: any): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}

export type NewUserReducerActionMap = ActionMapData<NewUserPayload>
