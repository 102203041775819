import React, { FC } from 'react'
import ReactExport from 'react-data-export'

import { format } from 'date-fns'
import { ChangeChipsCashFlow } from 'hooks/ChangeChipsCashFlow/props'
import { TranslateCategoryReports } from 'utils/Translate'

import { ModalReportsProps } from '../../props'
import { DowloadButton } from '../../styles'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export const ExcellChange: FC<ModalReportsProps> = props => {
  const { data } = props

  const data1 = data.map((item: ChangeChipsCashFlow) => {
    return {
      created_at: format(new Date(item.created_at), 'dd-MM-yyyy'),
      category: TranslateCategoryReports(item.category),
      old_iccid: item.old_chip.iccid,
      new_iccid: item.new_chip.iccid,
      name: item.name,
      cpf: item.document,
    }
  })
  return (
    <ExcelFile
      element={
        <DowloadButton variant="contained" color="primary">
          Baixar
        </DowloadButton>
      }
    >
      <ExcelSheet data={data1} name="Employees">
        <ExcelColumn label="Data da troca" value="created_at" />
        <ExcelColumn label="Categoria" value="category" />
        <ExcelColumn label="ICCID antigo" value="old_iccid" />
        <ExcelColumn label="ICCID novo" value="new_iccid" />
        <ExcelColumn label="Nome" value="name" />
        <ExcelColumn label="CPF" value="cpf" />
      </ExcelSheet>
    </ExcelFile>
  )
}
