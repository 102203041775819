import React, { FC, useMemo, useRef, memo, useCallback } from 'react'

import { useField } from 'formik'

import MenuItem from '@material-ui/core/MenuItem'

import { SelectProps, OptionSelectType } from './props'
import * as Styled from './styles'

const Select: FC<SelectProps> = props => {
  const { name, label, options, value, setValue, ...rest } = props
  const inputRef = useRef(null)
  const [field, meta] = useField<string>({ name, value })

  const optionsMap = useMemo(
    () =>
      options.map((item: OptionSelectType) => (
        <MenuItem value={item.value} key={item.value}>
          {item.name}
        </MenuItem>
      )),
    [options],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      setValue(event.target.value)
      field.onChange(event)
      // (name, event.target.value)
    },
    [field, setValue],
  )

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }

    return {}
  }, [meta.error, meta.initialError, meta.touched])

  return (
    <Styled.Select
      fullWidth
      select
      label={label}
      variant="outlined"
      inputRef={inputRef}
      id={name}
      name={field.name}
      value={field.value}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
      {...errorProps}
    >
      {optionsMap}
    </Styled.Select>
  )
}

export default memo(Select)
