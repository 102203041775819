import { FC, useEffect } from 'react'
import React from 'react'

import PageTitle from 'components/PageTitle'
import ComboBox from 'components/SelectStores'
import { Options } from 'components/SelectStores/props'
import { useChipsCashFlow } from 'hooks/ChipsCashFlow'
import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import { EnableLeaves } from 'utils/enablesForLeavePage'
import { initialCCID } from 'utils/initialStateForSelects'

import { AtualWallet } from '../Wallet'
import { actionOptions, categoryOptions } from './LeaveOptions'
import { ContainerInputsOut, Input, SendButton, TextInput } from './styles'

const LeavesChip: FC = () => {
  const { ICCID, setICCID } = useChipsCashFlow()

  const [documentLeaves, setDocumentLeaves] = React.useState<
    string | undefined
  >('')

  const [category, setCategory] = React.useState<Options>(categoryOptions[0])
  const [actions, setAction] = React.useState<Options>(actionOptions[0])
  const [nameLeaves, setNameLeaves] = React.useState<string>('')
  const [enabled, setEnabled] = React.useState<boolean>(false)
  const { addToast } = useToast()

  useEffect(() => {
    const enable = EnableLeaves({
      actions: actions?.name?.length,
      documentLeaves: documentLeaves?.length,
      iccidName: ICCID?.name.length,
      category: category?.name.length,
      nameLeaves: nameLeaves.length,
    })
    setEnabled(enable)
  }, [documentLeaves, ICCID?.name, category, actions, nameLeaves])
  const SubmitLeaves = async () => {
    try {
      setEnabled(false)
      let params = {
        category: category?.labelName,
        iccid: ICCID.name,
        name: nameLeaves,
        document: documentLeaves,
      }
      if (category?.name === 'Ação') {
        params.category = actions?.labelName
      }
      await api.post('chips/chips-output', params)
      addToast({
        type: 'success',
        title: 'Saída efetivada',
      })
      handleStates()
    } catch (e) {
      handleStates()
      addToast({
        type: 'error',
        title: 'Saída não efetuada',
      })
    }
  }

  const handleStates = () => {
    setDocumentLeaves('')
    setICCID(initialCCID)
    setAction(actionOptions[0])
    setCategory(categoryOptions[0])
    setNameLeaves('')
  }
  return (
    <div>
      <PageTitle title={'Saídas'} />
      <ContainerInputsOut>
        <AtualWallet iccid={ICCID} setOption={setICCID} />
        <TextInput
          label="Nome"
          placeholder="Nome"
          variant="outlined"
          value={nameLeaves}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNameLeaves(e.target.value)
          }}
        />
        <Input
          mask="99999999999999"
          maskChar={null}
          placeholder="CPF/CNPJ"
          value={documentLeaves}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDocumentLeaves(e.target.value)
          }}
        />
        <ComboBox
          width={'330px'}
          options={categoryOptions}
          labelName={'Categorias'}
          value={category}
          setOption={setCategory}
        />
        {category?.name === 'Ação' && (
          <ComboBox
            width={'230px'}
            options={actionOptions}
            labelName={'Setor'}
            setOption={setAction}
            value={actions}
          />
        )}
        <SendButton
          variant="contained"
          color="primary"
          size="small"
          id="outButton"
          onClick={SubmitLeaves}
          disabled={!enabled}
        >
          Enviar
        </SendButton>
      </ContainerInputsOut>
    </div>
  )
}

export default LeavesChip
