import React, { FC, useCallback, useState, memo } from 'react'

import Formik from 'components/Formik'
import { useRecharge } from 'hooks/recharges'

import Grid from '@material-ui/core/Grid'

import { OPTIONS_TYPE_FILTER } from './options'
import schema from './schema'
import * as Styled from './styles'

const FormGenerateReceipt: FC = () => {
  const { generateReceipt } = useRecharge()

  const [valueFilter, setValueFilter] = useState('')

  const initialValues = {
    nsu: '',
    data: '',
  }

  const handleSubmit = useCallback(
    async values => {
      await generateReceipt(values.nsu, values.data, valueFilter)
    },
    [generateReceipt, valueFilter],
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={2}>
          <Styled.TextField
            name="nsu"
            variant="outlined"
            label="NSU"
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Styled.DatePicker inputVariant="outlined" name="data" label="Data" />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Styled.Select
            name="type"
            label="Tipo"
            options={OPTIONS_TYPE_FILTER}
            variant="outlined"
            value={valueFilter}
            setValue={setValueFilter}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Styled.Button type="submit" color="primary" variant="contained">
            Gerar
          </Styled.Button>
        </Grid>
      </Grid>
    </Formik>
  )
}

export default memo(FormGenerateReceipt)
