import React, { FC, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import RenderWithRoles from 'components/RenderWithRoles'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { RechargeProvider, useRecharge } from 'hooks/recharges'
import { Recharge } from 'hooks/recharges/props'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import { DateFilter } from './components/DateFilter'
import FormGenerateReceipt from './components/FormGenerateReceipt'
import RechargesLineChart from './components/LineChart'
import RechargesReportCards from './components/ReportCards'
import SalesListHeader from './components/SalesListHeader/SalesListHeader'
import SalesListItem from './components/SalesListItem'

const RechargesPage: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    getRecharges,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    endDate,
    setEndDate,
    setStartDate,
    startDate,
    status,
    setPage,
  } = useRecharge()

  const renderItem = useCallback<TableRenderItem<Recharge>>(
    (item, index) => <SalesListItem key={`recharge_item_${index}`} {...item} />,
    [],
  )

  useEffect(() => {
    if (!requestStatus.loading) {
      getRecharges()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, startDate, endDate])

  return (
    <Route
      title="Recargas App"
      access="auth"
      requireRoles={['dev', 'admin', 'analyst', 'financial_collection']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Recargas App', link: '#' },
        ]}
        activeOption="recharges"
      >
        <PageTitle title="Recargas" />

        <DateFilter
          endDate={endDate}
          startDate={startDate}
          onEndDateChange={setEndDate}
          onStartDateChange={setStartDate}
        />

        <RechargesReportCards />

        <Link to={RoutingService.APP_RECHARGES_DATE_PERIOD_REPORT}>
          <Button color="primary" variant="contained">
            Visualizar recargas por período
          </Button>
        </Link>

        <RenderWithRoles roles={['admin', 'dev', 'financial_collection']}>
          <FormGenerateReceipt />
        </RenderWithRoles>

        <RechargesLineChart />

        <Table
          data={data}
          renderItem={renderItem}
          loadingRequest={requestStatus.loading}
          HeaderComponent={<SalesListHeader />}
          FooterComponent={
            <TablePagination
              getData={getRecharges}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(RechargesPage, RechargeProvider)
