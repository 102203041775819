/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from 'hooks/auth'
import RoutingService from 'services/routing'
import { belongsToCashFlow } from 'utils/permissions'

import Hidden from '@material-ui/core/Hidden'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AttachMoney from '@material-ui/icons/AttachMoney'

import ButtonSidebar from './components/ButtonSidebar'
import Cards from './components/ButtonSidebar/components/Cards'
import Logout from './components/ButtonSidebar/components/Logout'
import Header from './components/Header'
import OptionsList from './components/OptionsList'
import { LayoutProductProvider } from './context'
import { LayoutProductProps } from './props'
import * as Styled from './styles'

const LayoutProduct: FC<LayoutProductProps> = props => {
  const {
    children,
    crumbs,
    activeOption,
    customizable,
    titleMenu,
    menuLayout,
  } = props
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { signOut, user } = useAuth()
  const router = useHistory()

  const showAllProducts = useCallback(
    () => router.push(RoutingService.PRODUCTS),
    [router],
  )

  const showCashFlow = useCallback(
    () => router.push(RoutingService.CASH_FLOW),
    [router],
  )

  const showMainPage = useCallback(
    () => router.push(RoutingService.BRISAMOVEL),
    [router],
  )

  const handleDrawerToggle = useCallback(
    () => setDrawerOpen(drawerOpen => !drawerOpen),
    [],
  )
  //TODO não esta sendo chamado temporariamente
  const ButtonLayout = useCallback(() => {
    if (menuLayout === 'cashflow') {
      return (
        <ButtonSidebar typeIcon="return" onClick={showMainPage}>
          <ArrowBack style={{ color: '#989898', marginRight: 5 }} />
          <Styled.Label colorLabel="#8A8A8A">Voltar ao CRM</Styled.Label>
        </ButtonSidebar>
      )
    }
    return (
      <ButtonSidebar typeIcon="cashflow" onClick={showCashFlow}>
        <AttachMoney style={{ color: '#0b8300' }} />
        <Styled.Label colorLabel="#0b8300">Fluxo de Caixa</Styled.Label>
      </ButtonSidebar>
    )
  }, [menuLayout, showCashFlow, showMainPage])

  const drawerElement = useMemo(
    () => (
      <Styled.ContainerDrawer>
        <Styled.Content>
          <Styled.Title>
            {customizable && titleMenu ? titleMenu : 'CRM Produtos'}
          </Styled.Title>
          <Styled.Hr />
          <OptionsList
            activeOption={activeOption || 'home'}
            menuTemplate={menuLayout}
          />
        </Styled.Content>

        <Styled.Footer>
          <ButtonSidebar typeIcon="all-products" onClick={showAllProducts}>
            <Cards stroke="#083D93" />
            <Styled.Label>Todos produtos</Styled.Label>
          </ButtonSidebar>
          <ButtonSidebar typeIcon="logout" onClick={signOut}>
            <Logout stroke="#F44444" />
            <Styled.Label colorLabel="#EB5757">Sair do CRM</Styled.Label>
          </ButtonSidebar>
        </Styled.Footer>
      </Styled.ContainerDrawer>
    ),
    [
      activeOption,
      customizable,
      menuLayout,
      showAllProducts,
      signOut,
      titleMenu,
    ],
  )

  return (
    <LayoutProductProvider>
      <Styled.Root>
        <Hidden mdUp implementation="css">
          <Styled.Drawer
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            anchor="left"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerElement}
          </Styled.Drawer>
        </Hidden>

        <Hidden smDown implementation="css">
          <Styled.Drawer variant="permanent" anchor="left" open>
            {drawerElement}
          </Styled.Drawer>
        </Hidden>

        <Styled.Main>
          <Header crumbs={crumbs} toggleBar={handleDrawerToggle} />
          <Styled.MainContent maxWidth={false}>{children}</Styled.MainContent>
        </Styled.Main>
      </Styled.Root>
    </LayoutProductProvider>
  )
}

export default memo(LayoutProduct)
