import React, { FC, memo } from 'react'

import Button from 'components/Button'

import { CircularProgress } from '@material-ui/core'

import { useDenyTransfer } from './useDenyTransfer'

const DenyButton: FC = () => {
  const { denyTransfer, status } = useDenyTransfer()

  return (
    <Button
      variant="outlined"
      color="primary"
      size="large"
      onClick={denyTransfer}
      disabled={status.loading}
      startIcon={status.loading && <CircularProgress color="primary" />}
    >
      {!status.loading && 'Negar'}
    </Button>
  )
}

export default memo(DenyButton)
