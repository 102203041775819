import React, { FC, useMemo, memo } from 'react'

import Grid from '@material-ui/core/Grid'

import { useFormCompany } from '../../context'
import { StepperItemProps } from './props'
import * as Styled from './styles'

const StepperItem: FC<StepperItemProps> = props => {
  const { step, text, separator, ...rest } = props
  const { actualStepForm } = useFormCompany()

  const actualState = useMemo(() => {
    if (actualStepForm === step) {
      return true
    }

    return false
  }, [actualStepForm, step])

  const renderIcon = useMemo(() => {
    if (actualStepForm >= step + 1) {
      return require('assets/images/icon-right-chevron/chevron-primary.svg')
        .default
    }
    return require('assets/images/icon-right-chevron/chevron-secondary.svg')
      .default
  }, [actualStepForm, step])

  const renderComponent = useMemo(() => {
    if (separator) return <img src={renderIcon} />
    return (
      <Styled.Typography actualState={actualState}>{text}</Styled.Typography>
    )
  }, [renderIcon, separator, text, actualState])

  return <Grid {...rest}>{renderComponent}</Grid>
}

export default memo(StepperItem)
