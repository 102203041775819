import React, { useMemo } from 'react'

import Badge from 'components/Badge'
import TableRowItem from 'components/TableRowItem'
import { Bonus } from 'interfaces/commission'

import { TableCell } from '@material-ui/core'

const BonusListItem: React.FC<Bonus> = props => {
  const { quantity, type, date } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statusSale = {
    outcome: 'Pago',
    income: 'Não pago',
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colorsStatus = {
    outcome: 'green',
    income: 'orange',
  }

  const dateFormatted = () => {
    const day = new Date(date).getDate()
    const month = new Date(date).getMonth() + 1
    const year = new Date(date).getFullYear()

    return `${day}/${month}/${year}`
  }

  const StatusSaleColor = useMemo(() => {
    return statusSale[type]
  }, [type, statusSale])

  const color = useMemo(() => {
    return colorsStatus[type]
  }, [colorsStatus, type])

  return (
    <TableRowItem>
      <TableCell>{dateFormatted()}</TableCell>
      <TableCell align="center">{quantity}</TableCell>
      <TableCell align="center">
        <Badge colorVariation={color}>{StatusSaleColor}</Badge>
      </TableCell>
    </TableRowItem>
  )
}

export default BonusListItem
