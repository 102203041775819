import React, { FC, memo, useEffect, useState } from 'react'

import Formik from 'components/Formik'
import DatePicker from 'components/Inputs/DatePicker'
import Select from 'components/Inputs/Select'
import { startOfMonth } from 'date-fns'

import Grid from '@material-ui/core/Grid'

import { useReport } from '../../context'
import { ReportData } from '../../context/props'
import { OPTIONS_TYPE_REPORT, OPTIONS_TYPE_COMPANY } from './options'
import schema from './schemas'
import * as Styled from './styles'

const Form: FC = () => {
  const [type, setType] = useState('')
  const [company_type_eq, setCompany_type_eq] = useState('')

  const { renderReport } = useReport()

  const initialValues: ReportData = {
    type: 'discharges',
    company_type_eq: 'all',
    date_gt: startOfMonth(new Date()),
    date_lt: new Date(),
  }

  useEffect(() => {
    renderReport(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={renderReport}
    >
      <Grid container spacing={2}>
        <Grid item md={3} xs={6} sm={6}>
          <Select
            name="company_type_eq"
            label="Tipo de revendedor"
            value={company_type_eq}
            setValue={setCompany_type_eq}
            options={OPTIONS_TYPE_COMPANY}
          />
        </Grid>

        <Grid item md={3} xs={6} sm={6}>
          <Select
            name="type"
            label="Tipo de relatório"
            value={type}
            setValue={setType}
            options={OPTIONS_TYPE_REPORT}
          />
        </Grid>

        <Grid item md={2} xs={6} sm={6}>
          <DatePicker
            label="Data inicial"
            inputVariant="outlined"
            name="date_gt"
          />
        </Grid>
        <Grid item md={2} xs={6} sm={6}>
          <DatePicker
            label="Data final"
            inputVariant="outlined"
            name="date_lt"
            maxDate={new Date()}
          />
        </Grid>

        <Grid item md={2} xs={6} sm={6}>
          <Styled.ButtonBuildReport type="submit" size="large" fullWidth>
            Filtrar
          </Styled.ButtonBuildReport>
        </Grid>
      </Grid>
    </Formik>
  )
}

export default memo(Form)
