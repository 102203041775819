/**
 * Insere ponto a cada 3 digitos
 * @param value 1000
 * Output Examples: 1.000 | 50.000
 */
export function insertDots(value: number) {
  const pattern = /(\d)(?=(\d\d\d)+(?!\d))/g
  const numberWithDots = value.toString().replace(pattern, '$1.')

  return numberWithDots
}
