import React, { FC, useMemo, memo, Fragment } from 'react'

import { useAuth } from 'hooks/auth'

import { RenderWithRolesProps } from './props'

const RenderWithRoles: FC<RenderWithRolesProps> = props => {
  const { children, roles } = props
  const { hasRole } = useAuth()

  const shouldRender = useMemo(() => {
    if (!roles || roles.length === 0) return true
    return hasRole(roles)
  }, [hasRole, roles])

  if (!shouldRender) return null

  return <>{children}</>
}

export default memo(RenderWithRoles)
