import React, { FC, memo } from 'react'

import CommissionPackage from '../Charts/CommissionPackage'
import * as Styled from './styles'

const ReportCommissionPackage: FC = () => {
  return (
    <Styled.Container>
      <CommissionPackage />
    </Styled.Container>
  )
}

export default memo(ReportCommissionPackage)
