import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useReducer,
  useState,
} from 'react'

import { useToast } from 'hooks/toast'
import {
  RequestStatus,
  PaginationParamsWithNameContains,
} from 'interfaces/common'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import { paginationReducer, PaginationActions } from 'services/pagination'

import { UploadFileListItemProps } from '../components/UploadFileListItem/props'
import {
  UploadFileVivoContextProps,
  UploadFileRechargePackageValidate,
  UploadFileDischargesValidate,
  UploadFileBaseValidate,
  ValueTypesFilter,
} from './props'
import { initialState } from './reducer'

const UploadFileVivoContext = createContext({} as UploadFileVivoContextProps)

const UploadFileVivoProvider: FC = props => {
  const { children } = props

  const { addToast } = useToast()

  const [state, dispatch] = useReducer(
    paginationReducer<UploadFileListItemProps>(),
    initialState,
  )

  const [status, setStatus] = useState<RequestStatus>({
    error: false,
    loading: false,
    success: false,
  })

  const [valueFilter, setValueFilter] = useState<ValueTypesFilter>('')

  const sendUploadFileRechargePackage = useCallback(
    async (data: UploadFileRechargePackageValidate) => {
      try {
        setStatus({ error: false, loading: true, success: false })
        const formData = new FormData()
        formData.append('chips', data.chips as File)
        formData.append('referring_date', data.referring_date.toISOString())

        await api.post('/chips/upload-attach-chips', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 120000,
        })

        setStatus(prev => ({ ...prev, loading: false, success: true }))
        addToast({
          title: 'Arquivo enviado com sucesso!',
          type: 'success',
        })
      } catch (error) {
        setStatus(prev => ({ ...prev, loading: false, success: true }))
        addToast({ title: ErrorHandler.getMessage(error), type: 'error' })
      }
    },
    [addToast],
  )

  const sendUploadDischarges = useCallback(
    async (data: UploadFileDischargesValidate) => {
      try {
        setStatus({ error: false, loading: true, success: false })
        const formData = new FormData()
        formData.append('discharges', data.discharges as File)
        formData.append('referring_date', data.referring_date.toISOString())

        await api.post('/chips/upload-attach-discharges', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 120000,
        })

        setStatus(prev => ({ ...prev, loading: false, success: true }))
        addToast({
          title: 'Arquivo enviado com sucesso!',
          type: 'success',
        })
      } catch (error) {
        setStatus(prev => ({ ...prev, loading: false, success: true }))
        addToast({ title: ErrorHandler.getMessage(error), type: 'error' })
      }
    },
    [addToast],
  )

  const sendUploadBase = useCallback(
    async (data: UploadFileBaseValidate) => {
      try {
        setStatus({ error: false, loading: true, success: false })
        const formData = new FormData()
        formData.append('optin', data.optin as File)
        formData.append('referring_date', data.referring_date.toDateString())

        await api.post('/chips/upload-attach-optin', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 300000,
        })

        setStatus(prev => ({ ...prev, loading: false, success: true }))
        addToast({
          title: 'Arquivo enviado com sucesso!',
          type: 'success',
        })
      } catch (error) {
        setStatus(prev => ({ ...prev, loading: false, success: true }))
        addToast({ title: ErrorHandler.getMessage(error), type: 'error' })
      }
    },
    [addToast],
  )

  const listFiles = useCallback(async () => {
    try {
      dispatch({ type: PaginationActions.Request })
      const paginationParams: PaginationParamsWithNameContains = {
        page: state.current_page,
        per_page: state.per_page,
      }

      const response = await api.get('/chips/upload-attach-chips', {
        params: paginationParams,
      })

      dispatch({ type: PaginationActions.RequestSuccess, payload: response })
    } catch (error) {
      dispatch({ type: PaginationActions.RequestError })
    }
  }, [state])

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback(
    (limit: number) =>
      dispatch({ type: PaginationActions.SetLimit, payload: limit }),
    [],
  )

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  const addValueFilter = useCallback((value: ValueTypesFilter) => {
    setValueFilter(value)
  }, [])

  return (
    <UploadFileVivoContext.Provider
      value={{
        ...state,
        listFiles,
        sendUploadFileRechargePackage,
        sendUploadDischarges,
        sendUploadBase,
        valueFilter,
        addValueFilter,
        status,
        nextPage,
        previousPage,
        setLimit,
        setPage,
      }}
    >
      {children}
    </UploadFileVivoContext.Provider>
  )
}

export const useUploadFileVivo = () => useContext(UploadFileVivoContext)

export default UploadFileVivoProvider
