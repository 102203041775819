import RoutingService from 'services/routing'

const products = [
  {
    name: 'Saídas de chip',
    route: RoutingService.REPORTS_CHIPLEAVES_CASHFLOW,
    icon: 'chip',
  },
  {
    name: 'Trocas de chip',
    route: RoutingService.REPORTS_CHANGES_CASHFLOW,
    icon: 'chip',
  },
  {
    name: 'Validações',
    route: RoutingService.REPORTS_VALIDATION_CASHFLOW,
    icon: 'money',
  },
]

export default products
