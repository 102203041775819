/* eslint-disable react/destructuring-assignment */
import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useReducer,
  memo,
} from 'react'
import { useHistory } from 'react-router-dom'

import { User } from 'hooks/auth/props'
import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import RoutingService from 'services/routing'
import Document from 'utils/document'

import {
  NewUserContextProps,
  NewUserActions,
  DataFormAddUserType,
} from './props'
import newUserReducer, { initialState } from './reducer'

const NewUserContext = createContext({} as NewUserContextProps)

const NewUserProvider: FC = props => {
  const [state, dispatch] = useReducer(newUserReducer, initialState)
  const { addToast } = useToast()
  const history = useHistory()

  const addUser = useCallback(
    async (data: DataFormAddUserType) => {
      try {
        dispatch({ type: NewUserActions.RequestNewUser })

        data.phone = Document.removeMask(data.phone)

        const response = await api.post<User>('/users', data)

        addToast({
          type: 'success',
          title: 'Usuário adicionado!',
        })
        dispatch({
          type: NewUserActions.RequestSuccessNewUser,
          payload: response,
        })
        history.push(
          RoutingService.getRouteString(
            RoutingService.USER_DETAILS,
            'userId',
            response.data.id,
          ),
        )
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Ocorreu algum erro!',
        })
        dispatch({ type: NewUserActions.RequestErrorNewUser })
      }
    },
    [addToast, history],
  )

  const resetState = useCallback(
    () => dispatch({ type: NewUserActions.Reset }),
    [],
  )

  return (
    <NewUserContext.Provider value={{ ...state, addUser, resetState }}>
      {props.children}
    </NewUserContext.Provider>
  )
}

export const useAddUser = () => useContext(NewUserContext)

export default memo(NewUserProvider)
