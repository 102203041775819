import React, { FC, memo, useMemo } from 'react'

import TableRowItem from 'components/TableRowItem'
import { Recharge } from 'hooks/recharges/props'
import customFormatPrice from 'utils/formatPrice'

import TableCell from '@material-ui/core/TableCell'

const SalesListItem: FC<Recharge> = props => {
  const { date, distinct_users, count_of_day, total_of_day } = props

  const price = useMemo(() => {
    return customFormatPrice({ price: total_of_day })
  }, [total_of_day])

  return (
    <TableRowItem>
      <TableCell>{date}</TableCell>
      <TableCell>{count_of_day}</TableCell>
      <TableCell>{distinct_users}</TableCell>
      <TableCell>{price}</TableCell>
    </TableRowItem>
  )
}

export default memo(SalesListItem)
