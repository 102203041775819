/*
 * Major Industry Identifier (MII): 89 - Telecom - Private agency
 * International Calling Region: 55 - Brazil
 * MNC (Mobile Network Code): 10 - Vivo S.A./Telemig
 * Account Number: d{13}
 * Checksum: d{1}
 */

type Params = string | Array<string> | Array<string[]>
/**
 *
 * @param data
 * Exemplos de entradas válidas: 89551094273002570477 | 89551094273002570519 | ['89551094273002570519', '89551094273002570477']
 * Exemplos de entradas inválidas: 99999999999999999999 | ['89551094273002570519', '99999999999999999999']
 *
 */
export default function validateICCID(data: Params) {
  const pattern = new RegExp('^(89)(55)(10)([0-9]{13})([0-9])$')

  if (Array.isArray(data)) {
    // Because TS sucks
    const dataClone = data as any
    // Array de String
    if (
      dataClone.every(
        (item: Array<string> | Array<Array<string>>) => typeof item == 'string',
      )
    ) {
      return dataClone.every((item: string) => pattern.test(item))
    }
    // Array de Array de String
    return dataClone.every((item: Array<string>) =>
      item.every(subitem => pattern.test(subitem)),
    )
  }

  return pattern.test(data)
}
