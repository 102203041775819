import TypographyStyles from 'components/Typography'
import styled, { css } from 'styled-components'

import DividerStyles from '@material-ui/core/Divider'
import GridStyles from '@material-ui/core/Grid'

interface GridPros {
  isMarginTop: boolean
}

export const GridContainer = styled(GridStyles)<GridPros>`
  ${props =>
    props.isMarginTop &&
    css`
      margin-top: 40px;
    `}
`

export const GridItem = styled(GridStyles)``

export const Typography = styled(TypographyStyles)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const Divider = styled(DividerStyles)`
  margin: ${({ theme }) => theme.spacing(3)}px 0
    ${({ theme }) => theme.spacing(2)}px 0;
`
