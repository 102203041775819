import React, {
  FC,
  useState,
  createContext,
  useMemo,
  useContext,
  useCallback,
} from 'react'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import { ValidateCommissionContextProps, CommissionIValidate } from './props'

const ValidateCommissionContext = createContext(
  {} as ValidateCommissionContextProps,
)

export const ValidateCommissionProvider: FC = props => {
  const { children } = props

  const [modalActive, setModalAtive] = useState(false)
  const { addToast } = useToast()

  const validateCommission = useCallback(
    async ({ status, commissionId }: CommissionIValidate) => {
      try {
        await api
          .patch(`/commissions/${commissionId}/status`, { status })
          .catch(error => {
            throw error
          })

        addToast({
          title: 'Comissão Validada!',
          type: 'success',
        })
      } catch (error) {
        addToast({
          title: ErrorHandler.getMessage(error),
          type: 'error',
        })
      }
      setModalAtive(false)
    },
    [addToast],
  )

  const value = useMemo(
    () => ({
      modalActive,
      openModal: () => setModalAtive(true),
      closeModal: () => setModalAtive(false),
      validateCommission,
    }),
    [validateCommission, modalActive],
  )

  return (
    <ValidateCommissionContext.Provider value={value}>
      {children}
    </ValidateCommissionContext.Provider>
  )
}

export function useValidateCommission() {
  const context = useContext(ValidateCommissionContext)
  if (!context) {
    throw new Error(
      'useCommission must be used within a CommissionDetailContext',
    )
  }
  return context
}
