import React, { FC, useCallback, useMemo, useRef, useState, memo } from 'react'

import Card from 'components/Card'
import Formik from 'components/Formik'
import TextField from 'components/Inputs/TextField'
import { FormikProps } from 'formik'
import { useAuth } from 'hooks/auth'
import { useEditProfile } from 'pages/EditProfile/context'
import Document from 'utils/document'

import { UploadPhotoValidate } from '../../context/props'
import * as Styled from './styles'

const EditProfileCard: FC = () => {
  const { user } = useAuth()
  const { editProfile } = useEditProfile()
  const [photoPreview, setPhotoPreview] = useState('')

  const formikRef = useRef<FormikProps<UploadPhotoValidate> | null>()

  const viewImage = useMemo(() => {
    if (photoPreview.length > 0) {
      return photoPreview
    }

    if (user?.avatar_url) {
      return user?.avatar_url
    }

    return require('assets/images/icons/profile.svg').default
  }, [photoPreview, user?.avatar_url])

  const handleChangePhotoAvatar = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) return

      formikRef.current?.setFieldValue('avatar', event.target.files[0])

      const objectUrl = window.URL.createObjectURL(event.target.files[0])
      setPhotoPreview(objectUrl)
    },
    [],
  )

  const handleSubmit = useCallback(
    async values => {
      await editProfile({
        name: values.name,
        email: values.email,
        phone: Document.removeMask(values.phone),
        photoAvatar: formikRef.current?.values.avatar as File,
      })
      setPhotoPreview('')
      formikRef.current?.setFieldValue('avatar', '')
    },
    [editProfile],
  )

  return (
    <Styled.Container>
      <Card>
        <Styled.Span>Dados pessoais</Styled.Span>

        <Formik
          innerRef={formikRef as any}
          initialValues={{
            name: user?.name || '',
            email: user?.email || '',
            phone: user?.phone || '',
          }}
          onSubmit={handleSubmit}
        >
          <Styled.ContentHeader>
            <Styled.AvatarPicture
              src={viewImage}
              alt="Avatar profile"
              visibleBackground={
                user?.avatar_url || photoPreview ? false : true
              }
            />

            <Styled.InputUpload
              accept="image/*"
              id="button-photo-avatar"
              name="input_file"
              onChange={handleChangePhotoAvatar}
              type="file"
            />
            <Styled.Label htmlFor="button-photo-avatar">
              <Styled.ButtonUploadPhoto
                variant="outlined"
                color="primary"
                component="span"
              >
                Upload nova foto
              </Styled.ButtonUploadPhoto>
            </Styled.Label>
          </Styled.ContentHeader>

          <TextField name="name" label="Nome" />
          <TextField name="email" label="E-mail" />
          <TextField name="phone" label="Telefone" mask="(99) 99999-9999" />
          <Styled.ButtonContainer>
            <Styled.ButtonSubmit
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
            >
              Salvar alterações
            </Styled.ButtonSubmit>
          </Styled.ButtonContainer>
        </Formik>
      </Card>
    </Styled.Container>
  )
}

export default memo(EditProfileCard)
