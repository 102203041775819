import styled from 'styled-components'

import { Typography, Container as MUContainer } from '@material-ui/core'

export const Container = styled(MUContainer)``

export const HeaderTitle = styled(Typography)``

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)}px 0;

  & ${HeaderTitle} {
    display: inline-block;
    flex: 1;
  }
`
export const InputsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin: 10px;
  background-color: #f1f3f5;
  border-radius: 5px;
  width: 100%;
  & > div {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
  }
`
