import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'
import { CompanyDetail } from 'interfaces/company'

export interface SalesProps {
  id: string
  status: string
  unit_chip_price: string
  total_price: string
  created_at: string
}

export interface User {
  name: string
  email: string
  phone: string
}

export interface Representative {
  user: User
}

export enum CompanyDetailActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type CompanyState = {
  requestStatus: RequestStatus
  company: CompanyDetail | null
}

export type CompanyPayload = {
  [CompanyDetailActions.Request]: undefined
  [CompanyDetailActions.RequestSuccess]: AxiosResponse<CompanyDetail>
  [CompanyDetailActions.RequestError]: undefined
  [CompanyDetailActions.Reset]: undefined
}

export type CompanyReducerActionMap = ActionMapData<CompanyPayload>

export type CompanyDetailContextData = {
  company: CompanyDetail | null
  requestStatus: RequestStatus

  /**
   * Recebe os dados da API
   */
  getCompany(id: string): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}
