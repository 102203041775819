import React, { FC, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import TableRowItem from 'components/TableRowItem'
import RoutingService from 'services/routing'
import customFormatDate from 'utils/formatDate'
import customFormatPrice from 'utils/formatPrice'

import TableCell from '@material-ui/core/TableCell'

import { SalesListItemProps } from './props'
import * as Styled from './styles'

const SalesListItem: FC<SalesListItemProps> = props => {
  const { id, created_at, total_price, wallet_sender, wallet_recipient } = props

  const date = useMemo(() => {
    return customFormatDate({
      format: 'dd/MM/yyyy',
      date: created_at.toString(),
    })
  }, [created_at])

  const price = useMemo(() => {
    return customFormatPrice({ price: total_price })
  }, [total_price])

  const detailUrl = useMemo(
    () =>
      RoutingService.getRouteString(RoutingService.SALE_DETAILS, 'saleId', id),
    [id],
  )

  return (
    <TableRowItem>
      <TableCell>{date}</TableCell>
      <TableCell>{wallet_sender.distributor.user.name}</TableCell>
      <TableCell>{wallet_recipient.company.fantasy_name}</TableCell>
      <TableCell>
        {wallet_recipient?.company?.address?.city?.name} -{' '}
        {wallet_recipient?.company?.address?.city?.state?.initials}
      </TableCell>
      <TableCell>{price}</TableCell>

      <TableCell align="right">
        <Link to={detailUrl}>
          <Styled.Button color="primary" variant="outlined">
            Detalhes
          </Styled.Button>
        </Link>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(SalesListItem)
