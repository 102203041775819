import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useState,
} from 'react'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import {
  ListIntervalIccidsContextProps,
  ListIntervalIccidsProps,
} from './props'

const ListIntervalIccidsContext = createContext(
  {} as ListIntervalIccidsContextProps,
)

const ListIntervalIccidsProvider: FC = props => {
  const { children } = props
  const { addToast } = useToast()

  const [modalActive, setModalActive] = useState(false)

  const openModal = useCallback(() => {
    setModalActive(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalActive(false)
  }, [])

  const [intervalListIccids, setIntervalListIccids] = useState<
    ListIntervalIccidsProps[]
  >([])

  const getListIntervalIccids = useCallback(
    async (id: string) => {
      try {
        const response = await api.get<ListIntervalIccidsProps[]>(
          `chips/interval/${id}`,
        )

        setIntervalListIccids(response.data)
      } catch (error) {
        addToast({
          title: ErrorHandler.getMessage(error),
          type: error,
        })
      }
    },
    [addToast],
  )

  return (
    <ListIntervalIccidsContext.Provider
      value={{
        modalActive,
        openModal,
        closeModal,
        intervalListIccids,
        getListIntervalIccids,
      }}
    >
      {children}
    </ListIntervalIccidsContext.Provider>
  )
}

export const useListIntervalIccids = () => useContext(ListIntervalIccidsContext)

export default ListIntervalIccidsProvider
