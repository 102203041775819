import ButtonSubmitUnstyled from 'components/ButtonSubmit'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export const Title = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 400;
`
export const FooterModal = styled(Grid)`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ButtonSubmit = styled(ButtonSubmitUnstyled)`
  background-color: ${({ theme }) => theme.palette.success.light};

  color: ${({ theme }) => theme.palette.success.contrastText};
  :hover {
    background-color: ${({ theme }) => theme.palette.success.light};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    min-width: 100%;
    margin-top: 20px;
  }
`
