import React, { FC, memo } from 'react'

import TableRowItem from 'components/TableRowItem'
import { ChipsCashFlow } from 'hooks/ChipsCashFlow/props'
import { TranslateStatusChip } from 'utils/Translate'

import TableCell from '@material-ui/core/TableCell'

const CashFlowListItem: FC<ChipsCashFlow> = props => {
  const { iccid, status } = props

  return (
    <TableRowItem>
      <TableCell>{iccid}</TableCell>
      <TableCell>{TranslateStatusChip(status)}</TableCell>
    </TableRowItem>
  )
}

export default memo(CashFlowListItem)
