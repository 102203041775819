import styled from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

import { TransferHeaderStyle } from './props'

export const Header = styled.header<TransferHeaderStyle>`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.palette.common.white};
`
export const Grid = styled(MUGrid)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`
