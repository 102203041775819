import { FC, memo } from 'react'

import TableRowItem from 'components/TableRowItem'
import { SaleCashFlow } from 'interfaces/SalesCashFlow'
import { TranslateCategorySell, TranslateStatusSell } from 'utils/Translate'

import TableCell from '@material-ui/core/TableCell'

import { Row } from './styles'

const CashFlowListItem: FC<SaleCashFlow> = props => {
  const {
    chip: { iccid },
    price,
    category,
    pack,
    customer_document,
    customer_name,
    status,
    user,
    audited_pack,
  } = props

  return (
    <TableRowItem>
      <TableCell>
        <Row>{user?.name}</Row>
      </TableCell>
      <TableCell>
        <Row>{iccid}</Row>
      </TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>
        <Row>{TranslateCategorySell(category)}</Row>
      </TableCell>
      <TableCell>
        <Row>{pack}</Row>
      </TableCell>
      <TableCell>
        <Row>{audited_pack}</Row>
      </TableCell>
      <TableCell>
        <Row>{TranslateStatusSell(status)}</Row>
      </TableCell>
      <TableCell>
        <Row>{customer_name}</Row>
      </TableCell>
      <TableCell>
        <Row>{customer_document}</Row>
      </TableCell>
    </TableRowItem>
  )
}

export default memo(CashFlowListItem)
