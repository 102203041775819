import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import LayoutProduct from 'components/LayoutProduct'
import { ValidateCommissionProvider } from 'components/Modals/ValidateCommission/context'
import Route from 'components/Route'
import { CommissionDetailProvider } from 'hooks/commissions/details'
import RoutingService from 'services/routing'
import { getMonth } from 'utils/getMonth'
import withContext from 'utils/withContext'

import DetailsComponent from './components/DetailsComponent'
import PageTitle from './components/PageTitle'

const ReportOfCommissions: FC = () => {
  const { companyId, date } = useParams<any>()

  const dateFormatted = useMemo(() => {
    if (!date) return 'error'
    const [year, month] = date.split('-')
    const monthCommission = getMonth(month)

    return `${monthCommission} de ${year}`
  }, [date])

  return (
    <Route
      title="Relatórios"
      access="auth"
      requireRoles={[
        'analyst',
        'admin',
        'distributor',
        'dev',
        'company_representative',
        'financial',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          {
            title: 'Relatórios',
            link: RoutingService.getRouteString(
              RoutingService.COMMISSIONS,
              'companyId',
              companyId,
            ),
          },
          { title: dateFormatted },
        ]}
        activeOption="companies"
      >
        <PageTitle title="Relatório de Comissões" />
        <DetailsComponent />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(
  ReportOfCommissions,
  ValidateCommissionProvider,
  CommissionDetailProvider,
)
