import React, { FC, memo, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

import Button from 'components/Button'
import Card from 'components/Card'
import DetailsPageItem from 'components/DetailsPages/DetailsItem'
import { useAskReplacement } from 'components/Modals/AskReplacement/context'
import { useCreateChips } from 'components/Modals/CreateChip/context'
import QuantityChipsCard from 'components/QuantityChipsCard'
import { PENDING_CHIPS_HELP_TEXT } from 'components/QuantityChipsCard/helpTexts'
import RenderWithRoles from 'components/RenderWithRoles'
import Typography from 'components/Typography'
import { useStockDetail } from 'hooks/stock/details'
import RoutingService from 'services/routing'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

const DetailsComponent: FC = () => {
  const { stock, requestStatus } = useStockDetail()
  const { openModal: openModalCreateChips } = useCreateChips()
  const { openModal: openModalAskReplacement } = useAskReplacement()

  const { stockId } = useParams<any>()

  const status = useMemo(() => {
    if (requestStatus.loading || requestStatus.error) return ''
    return stock?.stock?.active ? 'Ativo' : 'Inativo'
  }, [requestStatus, stock])

  const editStockLink = useMemo(
    () =>
      RoutingService.getRouteString(
        RoutingService.EDIT_STOCK,
        'stockId',
        stockId,
      ),
    [stockId],
  )

  return (
    <Styled.Container data-cy="detail-stock__div">
      <Styled.CardsQuantityChips>
        <QuantityChipsCard
          title="Quantidade de chips"
          value={stock?.total_available_chips}
          loading={requestStatus.loading}
        />
        <QuantityChipsCard
          title="Chips pendentes"
          value={stock?.total_pending_chips}
          loading={requestStatus.loading}
          tooltip={PENDING_CHIPS_HELP_TEXT}
        />
      </Styled.CardsQuantityChips>

      <Card shadow>
        <Styled.Grid container spacing={2}>
          <Grid xs={12} sm={3} item>
            <Typography color="primary" variant="h6">
              Detalhes de estoque
            </Typography>
          </Grid>
          <Grid xs={12} sm={9} item>
            <RenderWithRoles roles={['stockist', 'dev', 'admin']}>
              <Styled.ButtonContainer>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={openModalCreateChips}
                  data-cy="create-chips__button"
                >
                  Cadastrar chips
                </Button>
              </Styled.ButtonContainer>
            </RenderWithRoles>
          </Grid>
        </Styled.Grid>

        <Styled.Grid container spacing={2}>
          <DetailsPageItem title="Nome" sm={3}>
            {stock?.stock?.name}
          </DetailsPageItem>

          <DetailsPageItem title="Status" sm={3}>
            {status}
          </DetailsPageItem>

          <DetailsPageItem title="Estoquista" sm={3} capitalize="uppercase">
            {stock?.stock?.stockist?.user.name}
          </DetailsPageItem>
        </Styled.Grid>

        <Styled.Grid container spacing={2}>
          <DetailsPageItem title="Estado" sm={3}>
            {stock?.stock?.address?.city.state.name}
          </DetailsPageItem>

          <DetailsPageItem title="Cidade" sm={3}>
            {stock?.stock?.address?.city.name}
          </DetailsPageItem>
        </Styled.Grid>

        <Styled.ButtonContainer>
          <RenderWithRoles roles={['stockist', 'dev']}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={openModalAskReplacement}
              data-cy="ask-replacement-chips__button"
            >
              Solicitar reposição
            </Button>
          </RenderWithRoles>
          <RenderWithRoles roles={['admin', 'dev']}>
            <Link to={editStockLink}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                data-cy="edit-stock__button"
              >
                Editar Estoque
              </Button>
            </Link>
          </RenderWithRoles>
        </Styled.ButtonContainer>
      </Card>
    </Styled.Container>
  )
}

export default memo(DetailsComponent)
