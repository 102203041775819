import ReactInputMask from 'react-input-mask'

import styled from 'styled-components'

import { Select, Button } from '@material-ui/core'
export const ContainerButtons = styled.div`
  display: flex;
  margin-bottom: 50px;
`
export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SelectICCID = styled(Select)`
  width: 410px;
  height: 42px;
  margin-top: 7px;
`
export const CelPhoneNumber = styled(ReactInputMask)`
  height: 42px;
  border-radius: 5px;
  border-style: none;
  border: 1px solid #999;
  font-size: 14px;
  color: #333;
  padding: 12px;
  margin-right: 9px;
  margin-top: 7px;
  width: 410px;
`

export const ActionButton = styled(Button)`
  align-self: flex-start;
  width: 188px;
  height: 42px;
  margin-top: 10px;
  @media (max-width: 1380px) {
    margin-top: 20px;
  }
`
export const StepButton = styled(Button)`
  width: 188px;
  height: 42px;
  margin-left: 24px;
`
export const TextInput = styled.input`
  width: 150px;
  height: 45px;
  border-radius: 3px;
  border-style: none;
  border: 1px solid #ccc;
  margin-left: 20px;
  padding: 10px;

  &:hover {
    border: 1px solid black;
  }
`
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
