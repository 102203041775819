import React, { FC } from 'react'

import { FormStepperProvider } from 'hooks/formStepper'
import { FormsType } from 'hooks/formStepper/props'

import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'

const forms: FormsType = { 0: <Step1 />, 1: <Step2 />, 2: <Step3 /> }

const FormCompany: FC = () => {
  return <FormStepperProvider forms={forms} />
}

export default FormCompany
