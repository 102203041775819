import React, { FC, useEffect } from 'react'

import { useChipsCashFlow } from 'hooks/ChipsCashFlow'
import { Packagevalues } from 'pages/NewSalesCashFlow/components/dataValues'

import { MenuItem, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'

import { EditSale } from '../props'
import { SelectPackage } from './styles'
const Package: FC<EditSale> = props => {
  const { package_edit } = props
  const { packageRecharge, setPackage } = useChipsCashFlow()
  useEffect(() => {
    if (package_edit) {
      setPackage(package_edit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [package_edit])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPackage(event.target.value as string)
  }
  return (
    <FormControl variant="outlined">
      <Typography variant="subtitle1">Pacote</Typography>
      <SelectPackage value={packageRecharge} onChange={handleChange}>
        <MenuItem value={packageRecharge}>{packageRecharge}</MenuItem>
        {Packagevalues.map(item => (
          <MenuItem value={item} key={`${item}`}>
            {item}
          </MenuItem>
        ))}
      </SelectPackage>
    </FormControl>
  )
}

export default Package
