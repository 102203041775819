import React, { FC, useCallback, useMemo } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Textfit } from 'react-textfit'
import { useEffectOnce } from 'react-use'

import Button from 'components/Button'
import Card from 'components/Card'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import RenderWithRoles from 'components/RenderWithRoles'
import Route from 'components/Route'
import Typography from 'components/Typography'
import { useAuth } from 'hooks/auth'
import { UserDetailProvider, useUserDetail } from 'hooks/users/details'
import RoutingService from 'services/routing'
import { formatPhone } from 'utils/formatPhone'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

const UserDetails: FC = () => {
  const { push } = useHistory()
  const { userId } = useParams<any>()
  const { getUser, user, requestStatus } = useUserDetail()
  const { hasRole } = useAuth()

  useEffectOnce(() => {
    getUser(userId)
  })

  const redirectToNew = useCallback(() => push(RoutingService.NEW_USER), [push])

  const status = useMemo(() => {
    if (requestStatus.loading || requestStatus.error) return ''
    return user?.active ? 'Ativo' : 'Inativo'
  }, [requestStatus, user])

  const firstName = useMemo(() => {
    if (!user) return null

    const [name] = user.name.split(' ')
    return name
  }, [user])

  const editUserLink = useMemo(
    () =>
      RoutingService.getRouteString(RoutingService.EDIT_USER, 'userId', userId),
    [userId],
  )

  const phone = useMemo(() => {
    if (!user) return '-'
    return formatPhone('(XX) X XXXX XXXX', user.phone)
  }, [user])

  const renderPageTitle = useMemo(() => {
    if (hasRole(['dev', 'admin'])) {
      return (
        <PageTitle
          title={`Detalhes de ${firstName}`}
          showButton
          buttonText="Novo Usuário"
          buttonOnClick={redirectToNew}
        />
      )
    }

    return <PageTitle title={`Detalhes de ${firstName}`} />
  }, [firstName, hasRole, redirectToNew])

  const products = useMemo(() => {
    if (requestStatus.loading || requestStatus.error) return ''
    return user?.products.map(product => product.name).join(', ')
  }, [requestStatus, user])

  if (requestStatus.loading) return <Loading />

  if (requestStatus.error) {
    return (
      <Route title="Usuário" access="auth" requireRoles={[]}>
        <LayoutProduct
          crumbs={[
            { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
            { title: 'Usuários', link: RoutingService.USERS },
            { title: 'Detalhes de Usuário' },
          ]}
          activeOption="users"
        >
          <Typography color="primary" variant="h6">
            Ocorreu um erro, tente novamente
          </Typography>
        </LayoutProduct>
      </Route>
    )
  }

  return (
    <Route title="Usuário" access="auth" requireRoles={['dev', 'admin']}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Usuários', link: RoutingService.USERS },
          { title: 'Detalhes de Usuário' },
        ]}
        activeOption="users"
      >
        {renderPageTitle}

        <Card>
          <Styled.Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Typography color="initial" variant="subtitle1">
                Nome
              </Typography>

              <Typography color="primary" variant="h6" capitalize="uppercase">
                {user?.name}
              </Typography>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Typography color="initial" variant="subtitle1">
                Email
              </Typography>

              <Typography color="primary" variant="h6">
                <Textfit>{user?.email}</Textfit>
              </Typography>
            </Grid>

            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Typography color="initial" variant="subtitle1">
                Telefone
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {phone}
              </Typography>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Status
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {status}
              </Typography>
            </Grid>
          </Styled.Grid>

          <Styled.Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Produtos
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {products}
              </Typography>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography color="initial" variant="subtitle1">
                Tipo de usuário
              </Typography>
              <Typography color="primary" variant="h6" capitalize="uppercase">
                {user?.role.translated_name}
              </Typography>
            </Grid>
          </Styled.Grid>

          <RenderWithRoles roles={['admin', 'dev']}>
            <Styled.ButtonContainer>
              <Link to={editUserLink}>
                <Button variant="outlined" color="primary" size="large">
                  Editar
                </Button>
              </Link>
            </Styled.ButtonContainer>
          </RenderWithRoles>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(UserDetails, UserDetailProvider)
