import React, { FC, memo, useEffect, useMemo } from 'react'

import Button from 'components/Button'
import RequestError from 'components/DetailsPages/RequestError'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import customDateReport from 'utils/formateDateReport'

import { PDFDownloadLink } from '@react-pdf/renderer'

import { useReport } from '../../context'
import DetailsComponent from './components/DetailsComponent'
import PrintReportOfPackage from './components/PrintReportOfPackage'
import { ReportOfPackageProps } from './props'
import * as Styled from './styles'

const ReportOfPackages: FC<ReportOfPackageProps> = ({ data }) => {
  const { type, date_gt, date_lt, company_type_eq } = data
  const {
    getReportOfPackages,
    reportOfPackages,
    requestOfPackagesStatus,
  } = useReport()

  const dategt = customDateReport({ date: data?.date_gt })

  const datelt = customDateReport({ date: data?.date_lt })

  useEffect(() => {
    getReportOfPackages({ type, date_gt, date_lt, company_type_eq })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_gt, date_lt, type, company_type_eq])

  const component = useMemo(() => {
    if (requestOfPackagesStatus.success)
      return <DetailsComponent data={reportOfPackages} />
    if (requestOfPackagesStatus.error) return <RequestError />
    return <Loading />
  }, [reportOfPackages, requestOfPackagesStatus])

  const buttonPrint = useMemo(() => {
    if (requestOfPackagesStatus.success) {
      return (
        <PDFDownloadLink
          document={
            <PrintReportOfPackage data={{ ...reportOfPackages, ...data }} />
          }
          fileName={`relatorio-pacotes-${dategt}-${datelt}.pdf`}
        >
          {({ loading }) => (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              disableElevation
            >
              {loading ? 'Carregando relatório' : 'Baixar relatório'}
            </Button>
          )}
        </PDFDownloadLink>
      )
    }
    return <></>
  }, [reportOfPackages, requestOfPackagesStatus, data, dategt, datelt])

  return (
    <>
      <Styled.Container>
        <PageTitle title="Relatório de Pacotes" />

        {buttonPrint}
      </Styled.Container>
      {component}
    </>
  )
}

export default memo(ReportOfPackages)
