import React, {
  FC,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react'

import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'

import { Notification, NotificationContextData } from './props'

const initialValue = {} as NotificationContextData

const NotificationContext = createContext(initialValue)

export const NotificationProvider: FC = props => {
  const { children } = props

  const [notificationsStatus, setNotificationsStatus] = useState<RequestStatus>(
    {
      loading: false,
      error: false,
      success: false,
    },
  )
  const [
    readNotificationsStatus,
    setReadNotificationsStatus,
  ] = useState<RequestStatus>({
    loading: false,
    error: false,
    success: false,
  })
  const [notifications, setNotifications] = useState<Notification[]>([])

  const readNotification = useCallback(async (notificationId: string) => {
    try {
      setReadNotificationsStatus({
        error: false,
        loading: true,
        success: false,
      })

      await api.patch(`/notifications/${notificationId}`)

      setReadNotificationsStatus({
        error: false,
        loading: false,
        success: true,
      })
    } catch (e) {
      setReadNotificationsStatus({
        error: true,
        loading: false,
        success: false,
      })
    }
  }, [])

  const getNotifications = useCallback(async () => {
    try {
      setNotificationsStatus({
        error: false,
        loading: true,
        success: false,
      })

      const response = await api.get<Notification[]>('/notifications')

      setNotifications(response.data)
      setNotificationsStatus({
        error: false,
        loading: false,
        success: true,
      })
    } catch (e) {
      setNotificationsStatus({
        error: true,
        loading: false,
        success: false,
      })
    }
  }, [])

  return (
    <NotificationContext.Provider
      value={{
        notificationsStatus,
        readNotificationsStatus,
        getNotifications,
        notifications,
        readNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context || context === initialValue) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    )
  }
  return context
}
