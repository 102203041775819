import React, { FC, memo } from 'react'

import Grid from '@material-ui/core/Grid'

import { ReportDetailsProps } from '../../props'
import CardTable from '../CardTable'
import TableChipsActivated from './components/TableChipsActivated'
import * as Styled from './styles'

const Tables: FC<ReportDetailsProps> = ({ data }) => {
  return (
    <Styled.Grid container spacing={2}>
      <Grid item>
        <CardTable title="Chips ativados" value={data?.chips_purchased} />
      </Grid>
      <Grid item>
        <CardTable
          title="Chips aguardando venda"
          value={data?.chips_awaiting_sale}
        />
      </Grid>
      <Grid item>
        <CardTable
          title="Chips ativados c/ recarga e pacote"
          value={data?.chips_with_plans}
        />
      </Grid>
      <Grid item>
        <CardTable
          title="Chips ativados sem recarga e pacote"
          value={data?.chips_without_plans}
        />
      </Grid>

      <Grid item>
        <CardTable
          title="Portabilidade/ migrações"
          value={data?.chips_with_portability}
        />
      </Grid>

      <Grid item>
        <CardTable title="Chips removidos" value={data?.chips_removed} />
      </Grid>

      <Grid item xs={12}>
        <TableChipsActivated data={data} />
      </Grid>
    </Styled.Grid>
  )
}

export default memo(Tables)
