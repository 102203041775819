import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { LinkCardProps } from './props'
import * as Styled from './styles'

const LinkCard: FC<LinkCardProps> = props => {
  const { label, className, ...rest } = props

  return (
    <Link {...rest}>
      <Styled.Card className={className}>
        <Styled.LabelText>{label}</Styled.LabelText>
        <ArrowForwardIcon color="primary" />
      </Styled.Card>
    </Link>
  )
}

export default memo(LinkCard)
