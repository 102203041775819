import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { PaginationParamsWithNameContains } from 'interfaces/common'
import { Company } from 'interfaces/company'
import { api } from 'services/api'
import { paginationReducer, PaginationActions } from 'services/pagination'

import { CompanyContextData } from './props'
import { initialState } from './reducer'

const initialValue = {} as CompanyContextData

export const CompanyContext = createContext(initialValue)

export const CompanyProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(
    paginationReducer<Company>(),
    initialState,
  )

  const listCompanies = useCallback(
    async (name_contains?: string) => {
      try {
        dispatch({ type: PaginationActions.Request })

        const paginationParams: PaginationParamsWithNameContains = {
          page: state.current_page,
          per_page: state.per_page,
          name_contains,
        }
        if (!name_contains) {
          paginationParams.name_contains = undefined
        }

        const response = await api.get('/companies', {
          params: paginationParams,
        })
        dispatch({
          type: PaginationActions.RequestSuccess,
          payload: response,
        })
      } catch (e) {
        dispatch({ type: PaginationActions.RequestError })
      }
    },
    [state],
  )

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback(
    (limit: number) =>
      dispatch({ type: PaginationActions.SetLimit, payload: limit }),
    [],
  )

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <CompanyContext.Provider
      value={{
        ...state,
        listCompanies,
        nextPage,
        previousPage,
        setLimit,
        setPage,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export function useCompany(): CompanyContextData {
  const context = useContext(CompanyContext)

  if (!context || context === initialValue) {
    throw new Error('useCompany must be used within a CompanyProvider')
  }

  return context
}
