import styled from 'styled-components'

import MUContainer from '@material-ui/core/Container'
import PureDrawer from '@material-ui/core/Drawer'

import { ButtonSidebarProps } from './components/ButtonSidebar/props'

export const Root = styled.div`
  flex: 1;
  overflow-x: hidden;
`
export const Drawer = styled(PureDrawer)`
  .MuiDrawer-paper {
    width: 202px;
    max-height: 100vh;
    overflow-y: auto;
  }
`

export const ContainerDrawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #f8f9fa;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  padding: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 18px;
`

export const Hr = styled.hr`
  border: none;
  display: initial;
  width: 80%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

export const Main = styled.main`
  padding-left: 250px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 960px) {
    padding-left: 0px;
  }
`

export const MainContent = styled(MUContainer)`
  overflow-x: hidden;
  overflow-y: hidden;

  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const Label = styled.h4<Pick<ButtonSidebarProps, 'colorLabel'>>`
  color: ${({ colorLabel, theme }) => colorLabel || theme.palette.primary.main};
  font-style: normal;
  font-weight: 500;
`
