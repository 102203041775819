import styled from 'styled-components'

import MUTableRow from '@material-ui/core/TableRow'

export const TableRow = styled(MUTableRow)`
  .MuiTableCell-root {
    padding-top: ${({ theme }) => theme.spacing(2)}px;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  button {
    margin-bottom: 0px;
  }
`
