import React, { FC, memo } from 'react'

import DetailsItem from 'components/DetailsPages/DetailsItem'
import Typography from 'components/Typography'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

const SectionBasicData: FC = () => {
  return (
    <Grid container>
      <Grid item xs={6} sm={6}>
        <Grid container spacing={2}>
          <DetailsItem title="Nome fantasia" capitalize="uppercase" sm={6}>
            mercadinho são bento
          </DetailsItem>
          <DetailsItem title="Cidade" capitalize="uppercase" sm={6}>
            Russas / CE
          </DetailsItem>
          <DetailsItem title="CNPJ" capitalize="uppercase" sm={12}>
            00.360.305/0001-04
          </DetailsItem>

          <Grid item xs={12} sm={12}>
            <Typography color="textPrimary" variant="subtitle1">
              Data de emissão: <Styled.TextDate>31/03/2020</Styled.TextDate>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} sm={6}>
        <Grid container spacing={2} justify="flex-end">
          <Styled.Grid item xs={12} sm={12}>
            <Typography color="textPrimary" variant="subtitle1">
              Valor do repasse
            </Typography>
            <Typography color="primary" variant="h3">
              R$ 500,00
            </Typography>
          </Styled.Grid>
          <Styled.Grid item xs={12} sm={12}>
            <Typography color="textPrimary" variant="subtitle1">
              Status de pagamento
            </Typography>
            <Typography color="primary" variant="h5" capitalize="uppercase">
              pendente
            </Typography>
          </Styled.Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(SectionBasicData)
