import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  DistributorState,
  DistributorReducerActionMap,
  DistributorDetailActions,
} from './props'

const initialState: DistributorState = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  distributor: null,
}

const DistributorDetailReducer: Reducer<
  DistributorState,
  DistributorReducerActionMap
> = (state, action) => {
  switch (action.type) {
    case DistributorDetailActions.Reset:
      return initialState

    case DistributorDetailActions.Request:
      state.requestStatus.loading = true
      state.requestStatus.success = false
      state.requestStatus.error = false
      return state

    case DistributorDetailActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.requestStatus.success = false
      state.distributor = null
      return state

    case DistributorDetailActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.requestStatus.success = true
      state.distributor = action.payload.data
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(DistributorDetailReducer)
