import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Card = styled.div`
  background-color: #eff1f7;
  width: 100%;
  max-width: 175px;
  min-width: 175px;
  height: 130px;
  padding-bottom: 20px;
  flex: 0 0 auto;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  margin-right: 9px;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TextValueItem = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`
