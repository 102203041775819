/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useMemo, memo } from 'react'

import Formik from 'components/Formik'

import { CircularProgress } from '@material-ui/core'

import { useGenerateCommission } from '../../context'
import { DataGenerateCommissionProps } from '../../context/props'
import schema from './schema'
import * as Styled from './styles'

const FormGenerateCommission: FC = () => {
  const { sendGenerateommission, status } = useGenerateCommission()

  const initialValues: DataGenerateCommissionProps = {
    month: '',
    year: '',
  }

  const titleButton = useMemo(
    () => (status.loading ? <CircularProgress size={20} /> : 'Gerar comissão'),
    [status.loading],
  )

  const handleSubmit = useCallback(
    async (values: DataGenerateCommissionProps) => {
      await sendGenerateommission(values)
    },
    [],
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Styled.GridContainer container spacing={2}>
        <Styled.GridItem item xs={12} sm={6} md={3}>
          <Styled.DatePicker
            label="Mês"
            inputVariant="outlined"
            name="month"
            views={['month']}
            format="MM"
          />
        </Styled.GridItem>
        <Styled.GridItem item xs={12} sm={6} md={3}>
          <Styled.DatePicker
            label="Ano"
            inputVariant="outlined"
            name="year"
            views={['year']}
            format="yyyy"
          />
        </Styled.GridItem>
        <Styled.GridItem item xs={12} sm={12} md={2}>
          <Styled.ButtonSubmit disabled={status.loading}>
            {titleButton}
          </Styled.ButtonSubmit>
        </Styled.GridItem>
      </Styled.GridContainer>
    </Formik>
  )
}

export default memo(FormGenerateCommission)
