export const valuesSaleTypes = [
  { name: 'Venda avulsa', labelName: 'single' },
  { name: 'Venda Ativação', labelName: 'activation' },
  { name: 'Portabilidade/Migração', labelName: 'portability' },
]

export const Packagevalues = [
  'BRISA + 2GB / SEMANAL /R$11.99',
  'BRISA + 3GB / MENSAL PROMO /R$19.99',
  'BRISA + 6GB / MENSAL /R$29.99 ',
  'BRISA + 7.5GB / MENSAL /R$49.99 ',
  'BRISA + 9.5GB / MENSAL /R$69.99 ',
  'BRISA + 2GB / QUINZENAL /R$11.99',
  'BRISA + 9GB / MENSAL REDES SOCIAIS /R$34.99',
  'BRISA + 12GB / MENSAL REDES SOCIAIS /R$54.99 ',
  'BRISA + 16GB / MENSAL REDES SOCIAIS /R$74.99 ',
  'PACOTE DIÁRIO',
]

export const valuesRecharge = [
  'R$10,00',
  'R$12,00',
  'R$15,00',
  'R$20,00',
  'R$25,00',
  'R$35,00',
  'R$40,00',
  'R$45,00',
  'R$50,00',
]

export const chipValues = ['R$5,00', 'R$10,00']
