import React, {
  FC,
  useCallback,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDebounce } from 'react-use'

import { useField, useFormikContext } from 'formik'
import { useCities, City } from 'hooks/location/cities'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { useFormCompany } from '../../context'
import { DataFormCompanyType } from '../../context/props'
import * as Styled from './styles'

const AutocompleteLocation: FC = () => {
  const { cities, getCities, status: requestStatusListCities } = useCities()
  const [field, meta, helpers] = useField<string>('city_id')
  const { values } = useFormikContext<DataFormCompanyType>()
  const { cityAndState, setCityAndState } = useFormCompany()
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(cityAndState.city)
    getCities(values.state_id || ' ', '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.state_id])

  useDebounce(
    () => {
      getCities(values.state_id || '', inputValue)
    },
    500,
    [inputValue],
  )

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }
    return {}
  }, [meta])

  const getCitiesOptionLabel = useCallback((option: City) => option.name, [])

  const onInputStateChange = useCallback((_, value: string) => {
    setInputValue(value)
  }, [])

  const onCityChange = useCallback(
    (event: React.ChangeEvent<{}>, value: City | null) => {
      if (value) {
        helpers.setValue(value.id, true)
        setCityAndState({ ...cityAndState, city: value.name })
      } else {
        field.onChange(event)
      }
    },
    [cityAndState, field, helpers, setCityAndState],
  )

  return (
    <Autocomplete
      id="combo-box-cities"
      options={cities}
      getOptionLabel={getCitiesOptionLabel}
      noOptionsText="nenhuma cidade encontrada"
      loading={requestStatusListCities.loading}
      onInputChange={onInputStateChange}
      onChange={onCityChange}
      inputValue={inputValue}
      renderInput={params => {
        // Remove autoFill do Browser
        const originalInputProps = params.inputProps as any
        originalInputProps.autoComplete = 'no'
        params.inputProps = originalInputProps
        return (
          <Styled.TextField
            label="Cidade"
            variant="outlined"
            name="city_id"
            {...errorProps}
            {...params}
            onBlur={field.onBlur}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )
      }}
    />
  )
}

export default memo(AutocompleteLocation)
