import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.palette.primary.main} 50%,
    transparent 50%
  );

  h1,
  h2 {
    color: ${({ theme }) => theme.palette.common.white};
  }

  h1 {
    margin-top: 34px;
  }

  h2 {
    margin-bottom: 42px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    align-items: center;
    justify-content: center;
    padding: 35px;
    background: ${({ theme }) => theme.palette.primary.main};
    flex-direction: row;
    h1,
    h2 {
      font-weight: normal;
    }

    h1 {
      font-size: 24px;
      line-height: 28px;
      color: ${({ theme }) => theme.palette.primary.main};
      margin-bottom: 12px;
    }

    h2 {
      font-size: 18px;
      line-height: 22px;
      max-width: 254px;
      color: ${({ theme }) => theme.palette.grey[500]};
      margin-bottom: 26px;
    }
  }
`

export const MainCard = styled.div`
  animation: none;
  max-height: 527px;
  width: 100%;
  align-items: flex-start;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.common.white};

  padding: 0 30px;

  hr {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: space-around;
    align-items: center;

    max-width: 918px;
    height: 528px;

    border-radius: 10px;

    hr {
      border: none;
      display: initial;
      width: 1px;
      height: 90%;
      background-color: ${({ theme }) => theme.palette.grey[300]};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0 ${({ theme }) => theme.spacing(2)}px
      ${({ theme }) => theme.spacing(2)}px;
  }
`

export const Presentation = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 412px;

    margin-right: ${({ theme }) => theme.spacing(3)}px;

    img {
      width: 372px;
      height: 300px;
    }
  }
`

export const PresentationMd = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: none;
  }
`

export const FormContainer = styled.div`
  margin-top: 84px;

  .MuiTypography-root {
    margin-bottom: 20px;
    text-align: left;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 506px;
    padding: 0 40px 0 70px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }

  > div {
    margin-top: 107px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    > div {
      margin-top: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`
