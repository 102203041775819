import React, { FC, memo, useMemo } from 'react'

import DetailsItem from 'components/DetailsPages/DetailsItem'
import Typography from 'components/Typography'
import { useCommissionDetail } from 'hooks/commissions/details'
import formatDocument from 'utils/document'
import formatDate, { DATE_FORMAT_DMY_HOUR_MINUTE } from 'utils/formatDate'
import formatPrice from 'utils/formatPrice'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

const SectionBasicData: FC = () => {
  const { commission, requestStatus } = useCommissionDetail()

  const FormattedDocument = useMemo(() => {
    if (requestStatus.loading || !commission) return 'Carregando'
    return formatDocument.format(commission?.company.document)
  }, [commission, requestStatus.loading])

  const city = useMemo(() => {
    return `${commission?.company.address.city.name} / ${commission?.company.address.city.state.initials}`
  }, [
    commission?.company.address.city.name,
    commission?.company.address.city.state.initials,
  ])

  const FormattedDate = useMemo(() => {
    if (requestStatus.loading || !commission) return ''

    return formatDate({
      date: commission?.created_at as string,
      format: DATE_FORMAT_DMY_HOUR_MINUTE,
    })
  }, [commission, requestStatus.loading])

  const totalValue = useMemo(() => {
    if (requestStatus.loading || !commission) return ''

    return formatPrice({ price: commission?.total_value })
  }, [commission, requestStatus.loading])

  const status = useMemo(() => {
    if (requestStatus.loading || !commission) return '#FFF'
    const { status } = commission

    switch (status) {
      case 'paid':
        return 'Pago'
      case 'unpaid':
        return 'Não pago'
      case 'open':
        return 'Aberto'
      default:
        return ''
    }
  }, [commission, requestStatus.loading])

  return (
    <Grid container>
      <Grid item xs={6} sm={8}>
        <Grid container spacing={2}>
          <DetailsItem title="Nome fantasia" capitalize="uppercase" sm={6}>
            {commission?.company.fantasy_name}
          </DetailsItem>
          <DetailsItem title="Cidade" capitalize="uppercase" sm={6}>
            {city}
          </DetailsItem>
          <DetailsItem title="CNPJ" capitalize="uppercase" sm={12}>
            {FormattedDocument}
          </DetailsItem>

          <Grid item xs={12} sm={12}>
            <Typography color="textPrimary" variant="subtitle1">
              Data de emissão:{' '}
              <Styled.TextDate>{FormattedDate}</Styled.TextDate>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} sm={4}>
        <Grid container spacing={2} justify="flex-end">
          <Styled.Grid item xs={12} sm={12}>
            <Typography color="textPrimary" variant="subtitle1">
              Valor da comissão
            </Typography>
            <Typography color="primary" variant="h3">
              {totalValue}
            </Typography>
          </Styled.Grid>
          <Styled.Grid item xs={12} sm={12}>
            <Typography color="textPrimary" variant="subtitle1">
              Status de pagamento
            </Typography>
            <Typography color="primary" variant="h5" capitalize="uppercase">
              {status}
            </Typography>
          </Styled.Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(SectionBasicData)
