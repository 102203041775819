import styled from 'styled-components'

export const Center = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
`
