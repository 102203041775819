import MUTypography from 'components/Typography'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import { ItemBreadcrumpProps } from './props'

export const Main = styled(Grid)`
  display: flex;
  flex-direction: row;
`

// eslint-disable-next-line prettier/prettier
export const Typography = styled(MUTypography) <ItemBreadcrumpProps>`
  font-weight: 500;
  opacity: ${({ actualState }) => (actualState ? '1' : '0.5')};
  color: ${({ theme, actualState }) =>
    actualState ? theme.palette.primary.main : theme.palette.grey['500']};
`
