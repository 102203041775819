import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  EditDistributor,
  EditDistributorReducerActionMap,
  EditDistributorActions,
  DataFormEditDistributor,
} from './props'

const initialState: EditDistributor = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  distributor: {} as DataFormEditDistributor,
}

const EditDistributorReducer: Reducer<
  EditDistributor,
  EditDistributorReducerActionMap
> = (state, action) => {
  switch (action.type) {
    case EditDistributorActions.Reset:
      return initialState

    case EditDistributorActions.RequestEditDistributor:
      state.requestStatus.loading = true
      state.requestStatus.success = false
      state.requestStatus.error = false
      return state

    case EditDistributorActions.RequestSuccessEditDistributor:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.requestStatus.success = true
      state.distributor = action.payload.data
      return state

    case EditDistributorActions.RequestErrorEditDistributor:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.requestStatus.success = false
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(EditDistributorReducer)
