import { AxiosResponse } from 'axios'
import { User } from 'hooks/auth/props'
import { ActionMapData } from 'hooks/props'
import { Stock } from 'hooks/stock/props'
import { TransferParticipantType } from 'hooks/transfers/props'
import { RequestStatus } from 'interfaces/common'
import { Company } from 'interfaces/company'
import { Distributor } from 'interfaces/distributor'

export type TransferStatusType = 'canceled' | 'pending' | 'denied' | 'finished'

export interface Wallet {
  id: string
  user_id: string
  created_at: Date
  updated_at: Date
  owner_type: TransferParticipantType
  stock?: Stock | null
  company?: Company | null
  distributor?: Distributor | null
}

export interface Stockist {
  id: string
  created_at: Date
  updated_at: Date
  user_id: string
  user: User
  stock_id: string
}

export type Transfer = {
  id: string
  created_at: Date
  updated_at: Date
  order_number: number
  status: TransferStatusType
  wallet_sender_id: string
  wallet_sender: Wallet
  wallet_recipient_id: string
  wallet_recipient: Wallet
  chips: any[]
}

export type TransferDetailResponse = Transfer

export enum TransferDetailActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type TransferState = {
  requestStatus: RequestStatus
  transfer: Transfer | null
}

export type TransferPayload = {
  [TransferDetailActions.Request]: undefined
  [TransferDetailActions.RequestSuccess]: AxiosResponse<TransferDetailResponse>
  [TransferDetailActions.RequestError]: undefined
  [TransferDetailActions.Reset]: undefined
}

export type TransferReducerActionMap = ActionMapData<TransferPayload>

export type TransferDetailContextData = TransferState & {
  getWalletUserId(wallet: Wallet): string | null
  /**
   * Recebe os dados da API
   */
  getTransfer(id: string): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}
