import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { SaleDetail } from 'interfaces/sale'
import { api } from 'services/api'

import { SaleDetailActions, SaleDetailContextData } from './props'
import saleReducer, { initialState } from './reducer'

const initialValue = {} as SaleDetailContextData

export const SaleDetailContext = createContext(initialValue)

export const SaleDetailProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(saleReducer, initialState)

  const getSale = useCallback(async (id: string) => {
    try {
      if (!id) {
        throw Error('Sale ID cannot be empty')
      }

      dispatch({ type: SaleDetailActions.Request })

      const response = await api.get<SaleDetail>(`/sales/${id}`)

      return dispatch({
        type: SaleDetailActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      return dispatch({ type: SaleDetailActions.RequestError })
    }
  }, [])

  const resetState = useCallback(
    () => dispatch({ type: SaleDetailActions.Reset }),
    [],
  )

  return (
    <SaleDetailContext.Provider
      value={{
        ...state,
        getSale,
        resetState,
      }}
    >
      {children}
    </SaleDetailContext.Provider>
  )
}

export function useSaleDetail(): SaleDetailContextData {
  const context = useContext(SaleDetailContext)

  if (!context || context === initialValue) {
    throw new Error('useSaleDetail must be used within a SaleDetailProvider')
  }

  return context
}
