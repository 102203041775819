import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
  useState,
} from 'react'

import { subDays, format } from 'date-fns'
import { useToast } from 'hooks/toast'
import { movelApi } from 'services/api'
import {
  paginationReducer,
  PaginationActions,
  PaginationRouteParams,
} from 'services/pagination'

import {
  RechargeContextData,
  ListRechargesResponse,
  RechargesStatusType,
  RechargesStatusParam,
  Recharge,
} from './props'
import { initialState } from './reducer'

const initialValue = {} as RechargeContextData
export const RechargeContext = createContext(initialValue)

export const RechargeProvider: FC = props => {
  const { children } = props
  const { addToast } = useToast()

  const [state, dispatch] = useReducer(
    paginationReducer<Recharge>(),
    initialState,
  )

  const [status, setStatus] = useState<RechargesStatusType>('success')
  const [startDate, setStartDate] = useState<Date | null>(() =>
    subDays(new Date(), 30),
  )
  const [endDate, setEndDate] = useState<Date | null>(() => new Date())

  const getRecharges = useCallback(async () => {
    try {
      dispatch({ type: PaginationActions.Request })

      const params: PaginationRouteParams & RechargesStatusParam = {
        page: state.current_page,
        per_page: state.per_page,
        status,
      }

      if (startDate) {
        params.date_gt = startDate
      }
      if (endDate) {
        params.date_lt = endDate
      }

      const response = await movelApi.get<ListRechargesResponse>(
        '/crm/find-recharge-by-range-date',
        {
          params,
          headers: { secret: process.env.REACT_APP_BRISAMOVEL_ROUTES_SECRET },
        },
      )
      dispatch({
        type: PaginationActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      dispatch({ type: PaginationActions.RequestError })
    }
  }, [endDate, startDate, state.current_page, state.per_page, status])

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  const generateReceipt = useCallback(
    async (nsu_operadora: string, data_operadora: string, source: string) => {
      try {
        const formatDateOperadora = format(new Date(data_operadora), 'yyyyMMdd')

        const response = await movelApi.get('/crm/recharge/receipt', {
          params: {
            nsu_operadora,
            data_operadora: formatDateOperadora,
            source,
          },
          headers: { secret: process.env.REACT_APP_BRISAMOVEL_ROUTES_SECRET },
          responseType: 'blob',
        })

        const file = new Blob([response.data], { type: 'application/pdf' })

        const fileUrl = URL.createObjectURL(file)

        window.open(fileUrl)
      } catch (error) {
        addToast({
          title: 'Erro ao gerar comprovante!',
          type: 'error',
        })
      }
    },
    [addToast],
  )

  return (
    <RechargeContext.Provider
      value={{
        ...state,
        getRecharges,
        nextPage,
        previousPage,
        status,
        setStatus,
        setLimit,
        endDate,
        setEndDate,
        startDate,
        setStartDate,
        setPage,
        generateReceipt,
      }}
    >
      {children}
    </RechargeContext.Provider>
  )
}

export function useRecharge(): RechargeContextData {
  const context = useContext(RechargeContext)

  if (!context || context === initialValue) {
    throw new Error('useRecharge must be used within a RechargeProvider')
  }

  return context
}
