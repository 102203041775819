import styled from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

export const Grid = styled(MUGrid)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const HeaderTable = styled(MUGrid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
