import React, { FC, useCallback, memo, useEffect, useMemo } from 'react'

import { useField } from 'formik'
import { useStates, State } from 'hooks/location/states'
import { debounce } from 'utils/debounce'

import Autocomplete from '@material-ui/lab/Autocomplete'

import * as Styled from './styles'

const AutocompleteLocation: FC = () => {
  const { states, getStates, status: requestStatusListStates } = useStates()
  const [field, meta, helpers] = useField<string>('state_id')

  useEffect(() => {
    getStates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }
    return {}
  }, [meta])

  const getStateOptionLabel = useCallback((option: State) => option.name, [])

  const onInputStateChange = useCallback(
    (_, value: string) => getStates(value),
    [getStates],
  )

  const onStateChange = useCallback(
    (event: React.ChangeEvent<{}>, value: State | null) => {
      if (value) {
        helpers.setValue(value.id, true)
      } else {
        field.onChange(event)
      }
    },
    [field, helpers],
  )

  return (
    <Autocomplete
      id="combo-box-states"
      options={states}
      getOptionLabel={getStateOptionLabel}
      noOptionsText="nenhum estado encontrado"
      // PaperComponent={PaperComponent}
      loading={requestStatusListStates.loading}
      onInputChange={debounce(onInputStateChange, 500)}
      autoComplete
      onChange={onStateChange}
      renderInput={params => (
        <Styled.TextField
          label="Estado"
          name="state_id"
          variant="outlined"
          onBlur={field.onBlur}
          {...params}
          {...errorProps}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  )
}

export default memo(AutocompleteLocation)
