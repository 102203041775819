import React, { FC, memo } from 'react'

import { CardProps } from './props'
import * as Styled from './styles'

const Card: FC<CardProps> = props => {
  return <Styled.Card {...props} />
}

export default memo(Card)
