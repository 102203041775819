import React, { FC } from 'react'

import { Dialog, FormControlLabel } from '@material-ui/core'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { ModalReportsProps } from '../props'
import {
  ExportButton,
  ContainerModal,
  Icon,
  ContainerIcon,
  Title,
  Subtitle,
  Text,
  CheckBox,
  Box,
  DowloadButton,
} from '../styles'
import { ExcellChange } from './ExcellChange'
import PdfChangeReport from './PdfChange'
const ChangeReport: FC<ModalReportsProps> = props => {
  const { firstParagraph, secondParagraph, data } = props

  const [open, setOpen] = React.useState(false)

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      checkedA: false,
      checkedB: false,
      [event.target.name]: event.target.checked,
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ExportButton
        variant="contained"
        color="primary"
        disabled={false}
        onClick={handleClickOpen}
      >
        Exportar
      </ExportButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ContainerModal>
          <ContainerIcon>
            <Icon
              src={require('assets/images/icons/close.svg').default}
              onClick={handleClose}
            />
          </ContainerIcon>
          <Title> {firstParagraph} </Title>
          <Text>{secondParagraph}</Text>
          <Subtitle>Escolha o formato para baixar</Subtitle>
          <Box>
            <FormControlLabel
              control={
                <CheckBox
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedA"
                  color="primary"
                />
              }
              label="PDF"
            />
            <FormControlLabel
              control={
                <CheckBox
                  checked={state.checkedB}
                  onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Excel"
            />
          </Box>

          {state.checkedA && (
            <PDFDownloadLink document={<PdfChangeReport data={data} />}>
              <DowloadButton
                variant="contained"
                color="primary"
                disabled={false}
                onClick={handleClickOpen}
              >
                Baixar
              </DowloadButton>
            </PDFDownloadLink>
          )}

          {state.checkedB && <ExcellChange data={data} />}
        </ContainerModal>
      </Dialog>
    </>
  )
}

export default ChangeReport
