/* eslint-disable no-underscore-dangle */
import React, { FC, useMemo, useCallback, memo } from 'react'

import { Obj } from 'interfaces/common'
import { ReportType } from 'pages/Product/context/props'
import {
  LineChart,
  Line,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
} from 'recharts'
import formatDateCommission from 'utils/formatDateCommission'

import { useTheme } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import { useProduct } from '../../../context'
import {
  LineRechargePackageDischargesProps,
  CustomLabelTooltipProps,
} from './props'
import * as Styled from './styles'

const LineRechargePackageDischarges: FC = () => {
  const { dataChart, statusChart, dataChartParams } = useProduct()

  const theme = useTheme()

  const colorByReportType = useMemo(() => {
    const colors: LineRechargePackageDischargesProps = {
      recharges: theme.palette.chart.primary,
      packages: theme.palette.chart.primary,
      discharges: theme.palette.chart.secondary,
    }

    if (!dataChartParams.report_type) return colors['recharges']

    return colors[dataChartParams.report_type]
  }, [
    dataChartParams.report_type,
    theme.palette.chart.primary,
    theme.palette.chart.secondary,
  ])

  const customTitleChart: Obj = useMemo(
    () => ({
      recharges: 'valor médio (R$)',
      packages: 'valor médio (R$)',
      discharges: 'evolução de parque',
    }),
    [],
  )

  const dataSet = useMemo(() => {
    if (!statusChart.loading) {
      return dataChart
    }

    return null
  }, [dataChart, statusChart.loading])

  const customLabelTooltip = useMemo(() => {
    const labels: CustomLabelTooltipProps = {
      recharges: { label: 'Valor médio (R$)' },
      packages: { label: 'Valor médio (R$)' },
      discharges: { label: 'Parque total' },
    }

    if (!dataChartParams.report_type) return labels['recharges']

    return labels[dataChartParams.report_type]
  }, [dataChartParams.report_type])

  const dataChartTypeKey = useMemo(() => {
    const typesKeys: Record<ReportType, string> = {
      recharges: 'average_period',
      packages: 'average_period',
      discharges: 'total_period',
    }

    return typesKeys[dataChartParams.report_type || 'recharges']
  }, [dataChartParams.report_type])

  const CustomTooltip = useCallback(
    ({ active, payload, label }) => {
      if (active && payload) {
        return (
          <Styled.ContainerTooltip>
            <Styled.Date>Data: {label}</Styled.Date>
            <Styled.AverageValue>
              {customLabelTooltip.label}: {payload[0]?.value.toFixed(2)}
            </Styled.AverageValue>
          </Styled.ContainerTooltip>
        )
      }

      return null
    },
    [customLabelTooltip.label],
  )

  const CustomizedLabel = useCallback(props => {
    const { x, y, stroke, value } = props

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={14} textAnchor="middle">
        {value?.toFixed(2)}
      </text>
    )
  }, [])

  if (statusChart.loading) {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Styled.Card>
          <CardContent>
            <Grid item>
              <Styled.Skeleton variant="rect" style={{ marginBottom: 20 }} />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" width="100%" height={121} />
            </Grid>
          </CardContent>
        </Styled.Card>
      </Grid>
    )
  }

  if (dataSet == null) return null

  return (
    <Styled.Container>
      <Styled.Content data-testid="container-commissions-chart">
        {dataChartParams.report_type && (
          <Styled.TitleGraph variant="h5" color="primary">
            Gráfico de {customTitleChart[dataChartParams.report_type]}
          </Styled.TitleGraph>
        )}
        <ResponsiveContainer width="100%" height={150}>
          <LineChart
            width={300}
            height={150}
            data={dataSet}
            margin={{
              top: 20,
              right: 20,
            }}
          >
            <Line
              type="linear"
              dataKey={dataChartTypeKey}
              stroke={colorByReportType.colorSecondary}
              label={<CustomizedLabel />}
              strokeWidth={2}
            />
            <XAxis
              dataKey={value =>
                `${value._id.day ? value._id.day : ''}-${value._id.month}-${
                  value._id.year
                }`
              }
              tickFormatter={value => {
                return formatDateCommission({ date: value })
              }}
            />

            <YAxis />
            <Tooltip content={CustomTooltip} />
            <Legend
              formatter={value => {
                const keyMap = {
                  average_period: 'Valor médio (R$)',
                  total_period: 'Parque total',
                }
                if (keyMap[value]) return keyMap[value]
                return value
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Styled.Content>
    </Styled.Container>
  )
}

export default memo(LineRechargePackageDischarges)
