import React, { FC, memo } from 'react'

import { SaleCashFlow } from 'interfaces/SalesCashFlow'
import { TranslateCategorySell, TranslateStatusSell } from 'utils/Translate'

import { Page, Document, View, Text } from '@react-pdf/renderer'

import { ModalReportsProps } from '../../props'
import styles from './styles'

const PdfValidationReport: FC<ModalReportsProps> = props => {
  const { data } = props

  return (
    <Document>
      <Page size="A4">
        <View style={styles.container}>
          <Text style={styles.title}>Relatorio de Validações</Text>
        </View>

        <View style={styles.containerTable}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader1}>
                <Text style={styles.tableCellHeader}>ICCID</Text>
                {data.map((item: SaleCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.chip.iccid}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Valor</Text>
                {data.map((item: SaleCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.price}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader3}>
                <Text style={styles.tableCellHeader}>Tipo de venda</Text>
                {data.map((item: SaleCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItemSell}>
                    {TranslateCategorySell(item.category)}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader4}>
                <Text style={styles.tableCellHeader}>Pacote venda</Text>
                {data.map((item: SaleCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.pack}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader4}>
                <Text style={styles.tableCellHeader}>Pacote Auditado</Text>
                {data.map((item: SaleCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.audited_pack || '      '}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader5}>
                <Text style={styles.tableCellHeader}>Status</Text>
                {data.map((item: SaleCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {TranslateStatusSell(item.status)}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader6}>
                <Text style={styles.tableCellHeader}>Nome</Text>
                {data.map((item: SaleCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.customer_name}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader7}>
                <Text style={styles.tableCellHeader}>CPF</Text>
                {data.map((item: SaleCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.customer_document}
                  </Text>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default memo(PdfValidationReport)
