import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import { NewStockState, StockReducerActionMap, NewStockActions } from './props'

const initialState: NewStockState = {
  requestStatus: {
    error: false,
    loading: false,
  },
}

const newStockReducer: Reducer<NewStockState, StockReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case NewStockActions.Reset:
      return initialState

    case NewStockActions.Request:
      state.requestStatus.loading = true
      return state

    case NewStockActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      return state

    case NewStockActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(newStockReducer)
