import React, { FC, memo, useState } from 'react'

import TableHead from 'components/TableHead'
import { useStock } from 'hooks/stock'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const StockListHeader: FC = () => {
  const { getStocks } = useStock()

  const [search, setSearch] = useState('')

  return (
    <TableHead
      title="Todos os estoques"
      filter={[
        {
          type: 'text',
          value: search,
          onChange: setSearch,
          label: 'Encontre um estoque',
        },
      ]}
      onClickFilter={() => getStocks(search)}
    >
      <TableRow>
        <TableCell>Nome</TableCell>

        <TableCell colSpan={2}>Status</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default memo(StockListHeader)
