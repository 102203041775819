import ButtonProps from 'components/ButtonSubmit'
import DatePickerProps from 'components/Inputs/DatePicker'
import SelectProps from 'components/Inputs/Select'
import TextFieldProps from 'components/Inputs/TextField'
import styled from 'styled-components'

import GridStyles from '@material-ui/core/Grid'

export const Select = styled(SelectProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const TextField = styled(TextFieldProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const Button = styled(ButtonProps)`
  width: 100%;
`

export const DatePicker = styled(DatePickerProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const Container = styled(GridStyles)``

export const Span = styled.span`
  color: '#495057';
  font-size: 12px;
`
