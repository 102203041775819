import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useTransferDetail } from 'hooks/transfers/details'
import { api } from 'services/api'

export const useDenyTransfer = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })
  const history = useHistory()
  const { transfer } = useTransferDetail()

  const denyTransfer = useCallback(async () => {
    try {
      if (!transfer) {
        throw new Error('ID cannot be empty')
      }

      setStatus({
        error: false,
        loading: true,
      })

      await api.post(`/transfers/${transfer.id}/deny`)

      setStatus({
        error: false,
        loading: false,
      })

      history.go(0)
    } catch (e) {
      setStatus({
        error: true,
        loading: false,
      })
    }
  }, [history, transfer])

  return {
    status,
    denyTransfer,
  }
}
