import React, { FC, memo, useMemo } from 'react'

import { useICCIDS } from 'hooks/iccids'

import { useCreateChips } from '../../context'
import * as Styled from './styles'

const ButtonSubmit: FC = () => {
  const { requestStatus } = useCreateChips()

  const { isValidQuantity } = useICCIDS()

  const isDisabled = useMemo(() => !isValidQuantity || requestStatus.loading, [
    isValidQuantity,
    requestStatus.loading,
  ])

  const renderText = useMemo(() => {
    if (requestStatus.loading) {
      return 'Carregando'
    }

    return 'Finalizar Cadastro de chips'
  }, [requestStatus])

  return (
    <Styled.Button
      variant="contained"
      color="secondary"
      size="large"
      type="submit"
      disabled={isDisabled}
    >
      {renderText}
    </Styled.Button>
  )
}

export default memo(ButtonSubmit)
