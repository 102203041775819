import React, { FC, useCallback, memo, useState } from 'react'
import { useDebounce } from 'react-use'

import { useFormCompany } from 'components/FormCompany/context'

import TextField from '@material-ui/core/TextField'

import { useProduct } from '../../../../context'
import * as Styled from './styles'
import { useCompanies } from './useCompanies'
import { useCompany } from './useCompany'

const AutoCompleteCompanies: FC = () => {
  const { getChart, setDataChartParams, dataChartParams } = useProduct()

  const [inputValue, setInputValue] = useState('')

  const { company: companyAdded } = useFormCompany()

  const {
    companies,
    getCompanies,
    status: requestStatusListCompanies,
  } = useCompanies()

  const { getCompany } = useCompany()

  useDebounce(
    () => {
      getCompanies(inputValue)
    },
    1000,
    [inputValue, companyAdded],
  )

  const handleChoosedOption = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      if (value !== null) {
        getCompany(value.id as string)

        dataChartParams.company_type_eq = undefined
        dataChartParams.company_id_eq = value.id

        setDataChartParams(dataChartParams)
        getChart()
      } else {
        // representativeField.setField('')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getOptionLabel = useCallback(
    (option: any) => option?.corporate_name ?? option.fantasy_name,
    [],
  )

  const getOptionSelected = useCallback((option: any) => option?.id, [])

  const onInputChange = useCallback((_, newInputValue) => {
    setInputValue(newInputValue)
  }, [])

  return (
    <Styled.Autocomplete
      debug
      id="combo-box-companies"
      options={companies}
      getOptionLabel={getOptionLabel}
      noOptionsText="Nenhum revendedor encontrado"
      onChange={handleChoosedOption}
      loading={requestStatusListCompanies.loading}
      onInputChange={onInputChange}
      inputValue={inputValue}
      clearOnEscape
      autoComplete
      forcePopupIcon={false}
      getOptionSelected={getOptionSelected}
      renderInput={params => {
        // Remove autoFill do Browser
        const originalInputProps = params.inputProps as any
        originalInputProps.autoComplete = 'no'
        params.inputProps = originalInputProps
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Pesquise pela empresa desejada"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Styled.Icon
                  src={require('assets/images/icons/search.svg').default}
                />
              ),
            }}
          />
        )
      }}
    />
  )
}

export default memo(AutoCompleteCompanies)
