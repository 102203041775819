import styled from 'styled-components'

import MUTableHead from '@material-ui/core/TableHead'

export const TableHead = styled(MUTableHead)`
  .MuiFormControl-root {
    margin-bottom: 0px;
  }
  .MuiTableCell-root {
    padding: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const ContainerExportReport = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f1f3f5;

  padding: ${({ theme }) => theme.spacing(3)}px
    ${({ theme }) => theme.spacing(2)}px;
`

export const TitleContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`
