import styled from 'styled-components'

export const Center = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Card = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(4, 4)};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`
