import React, { FC, useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import RequestError from 'components/DetailsPages/RequestError'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import ModalAskReplacement from 'components/Modals/AskReplacement'
import AskReplacementProvider from 'components/Modals/AskReplacement/context'
import ModalCreateChip from 'components/Modals/CreateChip'
import CreateChipsProvider from 'components/Modals/CreateChip/context'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import TablesTransfers from 'components/TablesTransfers'
import { useAuth } from 'hooks/auth'
import { useStockDetail, StockDetailProvider } from 'hooks/stock/details'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import DetailsComponent from './components/DetailsComponent'

const StockDetails: FC = () => {
  const { stockId } = useParams<any>()
  const { push } = useHistory()
  const { stock, getStock, requestStatus } = useStockDetail()
  const { hasRole } = useAuth()

  useEffectOnce(() => {
    getStock(stockId)
  })

  const redirectToNew = useCallback(() => push(RoutingService.NEW_STOCK), [
    push,
  ])

  const renderPageTitle = useMemo(() => {
    if (hasRole(['admin', 'dev'])) {
      return (
        <PageTitle
          title={`Detalhes de ${stock?.stock?.name}`}
          showButton
          buttonText="Novo Estoque"
          buttonOnClick={redirectToNew}
        />
      )
    }
    return <PageTitle title={`Detalhes de ${stock?.stock?.name}`} />
  }, [hasRole, redirectToNew, stock])

  const Content = useCallback(() => {
    if (requestStatus.success) return <DetailsComponent />
    if (requestStatus.error) return <RequestError />
    return <Loading />
  }, [requestStatus])

  return (
    <Route
      title="Estoque"
      access="auth"
      requireRoles={['admin', 'dev', 'analyst', 'distributor']}
      showLoader={requestStatus.loading}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Estoques', link: RoutingService.STOCKS },
          { title: 'Detalhes de Estoque' },
        ]}
        activeOption="stocks"
      >
        {renderPageTitle}

        <Content />

        <TablesTransfers
          dataTransfersSent={stock?.stock?.wallet?.transfers_sent}
          dataTransfersReceived={stock?.stock?.wallet?.transfers_received}
          loading={requestStatus.loading}
          page="stockDetail"
        />

        <ModalAskReplacement />
        <ModalCreateChip stockId={stockId} />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(
  StockDetails,
  StockDetailProvider,
  AskReplacementProvider,
  CreateChipsProvider,
)
