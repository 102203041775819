import Document from 'utils/document'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  document: Yup.string()
    .required('Documento é obrigatório')
    .test('valid size document', 'Documento inválido', value => {
      if (typeof value === 'string') {
        if (Document.type(value) === 'CPF') {
          return Document.removeMask(value).length === 11
        }
        if (Document.type(value) === 'CNPJ') {
          return Document.removeMask(value).length === 14
        }
      }
      return false
    }),
  fantasy_name: Yup.string().required('Nome Fantasia é obrigatório'),
  representative: Yup.object().shape({
    name: Yup.string()
      .required('Nome é obrigatório')
      .min(3, 'no mínimo 3 caracteres'),
    email: Yup.string()
      .required('E-mail é obrigatório')
      .email('Insira um e-mail válido'),
    phone: Yup.string().required('Telefone é obrigatório'),
  }),
})

export default schema
