import React, { FC, useMemo } from 'react'

import Card from 'components/Card'
import { FormCompanyProvider } from 'components/FormCompany/context'
import Formik from 'components/Formik'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Typography from 'components/Typography'
import { Field } from 'formik'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import AutocompleteCompanies from './components/AutocompleteCompanies'
import ButtonSubmit from './components/ButtonSubmit'
import Dialog from './components/Dialog'
import ModalProvider from './components/Dialog/context'
import InputRepresentative from './components/InputRepresentative'
import SectionDataSale from './components/SectionDataSale'
import { NewSaleProvider, useNewSale } from './context'
import schema from './schemas'
import * as Styled from './styles'

const NewSale: FC = () => {
  const { createSale } = useNewSale()

  const initialValues = useMemo(
    () => ({
      company_id: '',
      iccids_intervals: [{ first_iccid: '', last_iccid: '' }],
      comments: '',
      unit_chip_price: undefined,
      discount_quantity: 0,
    }),
    [],
  )

  return (
    <Route
      title="Nova venda"
      access="auth"
      requireRoles={['distributor', 'dev']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Vendas', link: RoutingService.SALES },
          { title: 'Nova venda', link: '#' },
        ]}
        activeOption="sales"
      >
        <PageTitle title="Nova venda" />

        <Dialog />
        <Card shadow>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={createSale}
          >
            <Typography color="primary" variant="h6" capitalize="uppercase">
              Dados do revendedor
            </Typography>
            <Styled.Grid container spacing={2}>
              <Grid lg={6} md={6} sm={12} xs={12} item>
                <Field component={AutocompleteCompanies} name="company_id" />
              </Grid>

              <Grid lg={6} md={6} sm={12} xs={12} item>
                <InputRepresentative />
              </Grid>
            </Styled.Grid>

            <SectionDataSale />

            <Styled.ButtonContainer>
              <ButtonSubmit />
            </Styled.ButtonContainer>
          </Formik>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(
  NewSale,
  NewSaleProvider,
  ModalProvider,
  FormCompanyProvider,
)
