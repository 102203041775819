import React, { FC, memo } from 'react'

import Typography from 'components/Typography'

import TableCell from '@material-ui/core/TableCell'

import { TableCellBodyProps } from './props'

const TableCellBody: FC<TableCellBodyProps> = props => {
  const { capitalizeText, children, ...rest } = props

  return (
    <TableCell {...rest}>
      <Typography
        color="textPrimary"
        variant="body1"
        capitalize={capitalizeText}
      >
        {children}
      </Typography>
    </TableCell>
  )
}

export default memo(TableCellBody)
