import React, { FC, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import Card from 'components/Card'
import Formik from 'components/Formik'
import CheckboxSimple from 'components/Inputs/CheckboxSimple'
import TextField from 'components/Inputs/TextField'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import { UserDetailProvider, useUserDetail } from 'hooks/users/details'
import { EditUserCredentials } from 'hooks/users/edit/props'
import { EditUserProvider, useEditUser } from 'hooks/users/edit/useEditUser'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import ButtonSubmit from './components/ButtonSubmit'
import CheckboxesProducts from './components/CheckboxesProducts'
import SelectRoles from './components/SelectRoles'
import schema from './schemas'
import * as Styled from './styles'

const EditUser: FC = () => {
  const [userType, setUserType] = useState('')
  const { userId } = useParams<any>()
  const { user, requestStatus, getUser } = useUserDetail()
  const { editUser, status } = useEditUser()

  useEffectOnce(() => {
    getUser(userId)
  })

  const handleSubmit = useCallback(
    (values: EditUserCredentials) => editUser(userId, values),
    [editUser, userId],
  )

  const initialValues = useMemo<EditUserCredentials>(
    () => ({
      active: user?.active,
      name: user?.name || '',
      email: user?.email || '',
      phone: user?.phone || '',
      role_name: user?.role.name || '',
      products_ids: user?.products.map(product => product.id) || [],
    }),
    [user],
  )

  if (requestStatus.loading) return <Loading />

  if (!user) return <h1>erro</h1> // Update here

  return (
    <Route title="Editar Usuário" access="auth" requireRoles={['dev', 'admin']}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Usuários', link: RoutingService.USERS },
          { title: 'Editar Usuário' },
        ]}
        activeOption="users"
      >
        <PageTitle title="Editar usuário" />

        <Card>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
            validateOnMount
            enableReinitialize
          >
            <Grid container spacing={3}>
              <Grid item xl={12} md={12} sm={12} xs={12}>
                <Styled.TitlePanel variant="body1">
                  dados do usuário
                </Styled.TitlePanel>
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <TextField name="name" label="Nome" />
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <TextField name="email" label="E-mail" type="email" />
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <SelectRoles value={userType} setValue={setUserType} />
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <TextField
                  name="phone"
                  label="Telefone *"
                  placeholder="DDD + Número"
                  mask="(99) 99999-9999"
                />
              </Grid>

              <Grid item xl={3} md={3} sm={12} xs={12}>
                <Styled.TitlePanel variant="body1">
                  Status do Usuário
                </Styled.TitlePanel>
                <CheckboxSimple name="active" label="Ativo" />
              </Grid>

              <Grid item xl={3} md={3} sm={12} xs={12}>
                <Styled.TitlePanel variant="body1">produtos</Styled.TitlePanel>
                <CheckboxesProducts />
              </Grid>

              <Grid item xl={12} md={12} sm={12} xs={12}>
                <Styled.ButtonContainer>
                  <ButtonSubmit loading={status.loading} />
                </Styled.ButtonContainer>
              </Grid>
            </Grid>
          </Formik>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(EditUser, UserDetailProvider, EditUserProvider)
