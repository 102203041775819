import React, { FC, useCallback, useMemo, useState, memo } from 'react'

import Formik from 'components/Formik'
import CheckboxCommissionType from 'components/Inputs/CheckboxCommissionType'
import Select from 'components/Inputs/Select'
import TextField from 'components/Inputs/TextField'
import { useStepper } from 'hooks/formStepper'
import Document from 'utils/document'

import Grid from '@material-ui/core/Grid'

import { useFormCompany } from '../../context'
import { Step1Props } from '../../props'
import CheckboxStatus from '../CheckboxStatus'
import ControlerSteps from '../ControllerSteps'
import SectionRepresentative from '../SectionRepresentative'
import SelectDocument from '../SelectDocument'
import { optionSelectType } from '../SelectDocument/props'
import Stepper from '../Stepper'
import schema from './schema'

const Step1: FC = () => {
  const {
    setActualStepForm,
    dataFormCompany,
    setDataFormCompany,
  } = useFormCompany()

  const { nextStep } = useStepper()

  const [typeCompany, setTypeCompany] = useState<string>(
    dataFormCompany.type || 'partner',
  )

  const [valueDocument, setValueDocument] = useState(
    dataFormCompany.document.length > 0
      ? Document.type(dataFormCompany.document)
      : 'CPF',
  )

  const typeDocument: optionSelectType[] = [
    {
      name: 'CPF',
      value: 'CPF',
    },
    {
      name: 'CNPJ',
      value: 'CNPJ',
    },
  ]

  const companyOptions: optionSelectType[] = [
    {
      name: 'PARCEIRA',
      value: 'partner',
    },
    {
      name: 'FRANQUEADA',
      value: 'franchisee',
    },
  ]

  const selecDocument: {
    [key: string]: { mask: string; fields: React.ReactNode }
  } = useMemo(
    () => ({
      CPF: {
        mask: '999.999.999-99',
        fields: (
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              name="fantasy_name"
              label="Nome *"
              defaultValue={dataFormCompany.fantasy_name}
            />
          </Grid>
        ),
      },
      CNPJ: {
        mask: '99.999.999/9999-99',
        fields: (
          <>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                name="fantasy_name"
                label="Nome Fantasia *"
                placeholder="Nome do negócio"
                defaultValue={dataFormCompany.fantasy_name}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                name="corporate_name"
                label="Razão Social"
                defaultValue={dataFormCompany.corporate_name}
              />
            </Grid>
          </>
        ),
      },
    }),
    [dataFormCompany.corporate_name, dataFormCompany.fantasy_name],
  )

  const handleSubmit = useCallback(
    async (data: Step1Props) => {
      try {
        setDataFormCompany({ ...dataFormCompany, ...data })

        setActualStepForm(1)
        nextStep()
      } catch (err) {
        alert(err)
      }
    },
    [dataFormCompany, nextStep, setActualStepForm, setDataFormCompany],
  )

  return (
    <Formik
      initialValues={dataFormCompany}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Stepper />

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <SelectDocument
            name="document"
            label="Documento *"
            options={typeDocument}
            value={valueDocument}
            setValue={setValueDocument}
            mask={selecDocument[valueDocument].mask}
            defaultValue={dataFormCompany.document}
          />
        </Grid>

        {selecDocument[valueDocument].fields}

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Select
            name="type"
            label="Tipo"
            options={companyOptions}
            value={typeCompany}
            setValue={setTypeCompany}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <CheckboxStatus />
        </Grid>
        <SectionRepresentative />
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <CheckboxCommissionType
            name="commission_model"
            label="Bonificação"
            typeCompany={typeCompany}
          />
        </Grid>
      </Grid>
      <ControlerSteps />
    </Formik>
  )
}

export default memo(Step1)
