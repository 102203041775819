import styled from 'styled-components'

import { Typography, Container as Button } from '@material-ui/core'
import PureDrawer from '@material-ui/core/Drawer'

const HeaderTitle = styled(Typography)``

export const Drawer = styled(PureDrawer)`
  .MuiDrawer-paper {
    width: 202px;
    max-height: 100vh;
    overflow-y: auto;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)}px 0;

  & ${HeaderTitle} {
    display: inline-block;
    flex: 1;
  }
`
export const ProductsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 767px) {
    justify-content: flex-start;
  }
`
export const SendButton = styled(Button)`
  width: 151px;
`
