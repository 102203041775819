import React, { FC, memo } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import RoutingService from 'services/routing'

import DetailsComponent from './components/DetailsComponent'

const ReportOfCommissions: FC = () => {
  return (
    <Route title="Relatórios de Comissões" access="auth" requireRoles={['dev']}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          { title: 'Relatórios' },
        ]}
        activeOption="companies"
      >
        <PageTitle
          title="Relatório de Comissões"
          showButton
          buttonText="Imprimir relatório"
        />
        <DetailsComponent />
      </LayoutProduct>
    </Route>
  )
}

export default memo(ReportOfCommissions)
