import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  NewTransferState,
  NewTransferReducerActionMap,
  NewTransferActions,
} from './props'

const initialState: NewTransferState = {
  requestStatus: {
    error: false,
    loading: false,
  },
  transfer: null,
}

const newTransferReducer: Reducer<
  NewTransferState,
  NewTransferReducerActionMap
> = (state, action) => {
  switch (action.type) {
    case NewTransferActions.Reset:
      return initialState

    case NewTransferActions.RequestNewTransfer:
      state.requestStatus.loading = true
      state.requestStatus.error = false
      return state

    case NewTransferActions.RequestSuccessNewTransfer:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.transfer = action.payload.data
      return state

    case NewTransferActions.RequestErrorNewTransfer:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.transfer = null
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(newTransferReducer)
