import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { api } from 'services/api'

import {
  TransferDetailActions,
  TransferDetailContextData,
  TransferDetailResponse,
  Wallet,
} from './props'
import transferDetailReducer, { initialState } from './reducer'

const initialValue = {} as TransferDetailContextData

export const TransferDetailContext = createContext(initialValue)

export const TransferDetailProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(transferDetailReducer, initialState)

  const getTransfer = useCallback(async (id: string) => {
    try {
      if (!id) {
        throw Error('Transfer ID cannot be empty')
      }

      dispatch({ type: TransferDetailActions.Request })

      const response = await api.get<TransferDetailResponse>(`/transfers/${id}`)

      return dispatch({
        type: TransferDetailActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      return dispatch({ type: TransferDetailActions.RequestError })
    }
  }, [])

  const resetState = useCallback(
    () => dispatch({ type: TransferDetailActions.Reset }),
    [],
  )

  const getWalletUserId = useCallback((wallet: Wallet) => {
    switch (true) {
      case !!wallet.stock:
        return wallet.stock!.stockist.user.id
      case !!wallet.company:
        return wallet.company!.representative!.user.id
      case !!wallet.distributor:
        return wallet.distributor!.user.id
      default:
        return null
    }
  }, [])

  return (
    <TransferDetailContext.Provider
      value={{
        ...state,
        getWalletUserId,
        getTransfer,
        resetState,
      }}
    >
      {children}
    </TransferDetailContext.Provider>
  )
}

export function useTransferDetail(): TransferDetailContextData {
  const context = useContext(TransferDetailContext)

  if (!context || context === initialValue) {
    throw new Error(
      'useTransferDetail must be used within a TransferDetailProvider',
    )
  }

  return context
}
