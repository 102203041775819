import * as Yup from 'yup'

const phoneRegex = /^\(\d{2}\)\s\d{4,5}-\d{4}/

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .required('E-mail é obrigatório')
    .email('Insira um e-mail válido'),
  role_name: Yup.string().required('Tipo de usuário é obrigatório'),
  phone: Yup.string()
    .matches(phoneRegex, 'Telefone inválido')
    .required('Telefone é obrigatório'),
  products_ids: Yup.array().when(['role_name'], {
    is: 'dev',
    then: Yup.array().of(Yup.string()),
    otherwise: Yup.array()
      .of(Yup.string())
      .required('Ao menos 1 deve ser selecionado'),
  }),
})

export default schema
