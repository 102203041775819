import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'
import { DistributorDetail } from 'interfaces/distributor'

export type DistributorDetailResponse = DistributorDetail

export enum DistributorDetailActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type DistributorState = {
  requestStatus: RequestStatus
  distributor: DistributorDetail | null
}

export type DistributorPayload = {
  [DistributorDetailActions.Request]: undefined
  [DistributorDetailActions.RequestSuccess]: AxiosResponse<DistributorDetailResponse>
  [DistributorDetailActions.RequestError]: undefined
  [DistributorDetailActions.Reset]: undefined
}

export type DistributorReducerActionMap = ActionMapData<DistributorPayload>

export type DistributorDetailContextData = DistributorState & {
  /**
   * Recebe os dados da API
   */
  getDistributor(id: string): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}
