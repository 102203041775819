import styled from 'styled-components'

import TableUnstyled from '@material-ui/core/Table'
import TableCellUnstyled from '@material-ui/core/TableCell'
import TableRowUnstyled from '@material-ui/core/TableRow'

export const TableCell = styled(TableCellUnstyled)`
  width: 160px;
`

export const Table = styled(TableUnstyled)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const TableRow = styled(TableRowUnstyled)`
  height: 40px;
`
