import ButtonProps from 'components/ButtonSubmit'
import DatePickerProps from 'components/Inputs/DatePicker'
import SelectProps from 'components/Inputs/Select'
import TextFieldProps from 'components/Inputs/TextField'
import styled from 'styled-components'

export const Button = styled(ButtonProps)`
  width: 100%;
`

export const TextField = styled(TextFieldProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const Select = styled(SelectProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const DatePicker = styled(DatePickerProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`
