import ButtonStyles from 'components/Button'
import ButtonPropsSubmit from 'components/ButtonSubmit'
import DatePickerProps from 'components/Inputs/DatePicker'
import TypographyProps from 'components/Typography'
import styled from 'styled-components'

import BoxStyles from '@material-ui/core/Box'

export const ButtonUpload = styled(ButtonStyles)`
  width: 100%;
  margin-bottom: 0;
`

export const Container = styled.div`
  margin-top: 10px;
`

export const Span = styled(TypographyProps)`
  width: auto;
  color: ${({ theme }) => theme.palette.secondary.light};
`

export const TextButton = styled(BoxStyles)``

export const ButtonSubmit = styled(ButtonPropsSubmit)`
  width: 100%;
`
export const InputUpload = styled.input`
  display: none;
`
export const Label = styled.label``

export const DatePicker = styled(DatePickerProps)`
  background-color: ${({ theme }) => theme.palette.common.white};
`
