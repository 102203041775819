import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { api } from 'services/api'

import {
  DistributorDetailActions,
  DistributorDetailContextData,
  DistributorDetailResponse,
} from './props'
import DistributorDetailReducer, { initialState } from './reducer'

const initialValue = {} as DistributorDetailContextData

export const DistributorDetailContext = createContext(initialValue)

export const DistributorDetailProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(DistributorDetailReducer, initialState)

  const getDistributor = useCallback(async (id: string) => {
    try {
      if (!id) {
        throw Error('Distribuidor ID cannot be empty')
      }

      dispatch({ type: DistributorDetailActions.Request })

      const response = await api.get<DistributorDetailResponse>(
        `/distributors/${id}`,
      )

      dispatch({
        type: DistributorDetailActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      dispatch({ type: DistributorDetailActions.RequestError })
    }
  }, [])

  const resetState = useCallback(
    () => dispatch({ type: DistributorDetailActions.Reset }),
    [],
  )

  return (
    <DistributorDetailContext.Provider
      value={{
        ...state,
        getDistributor,
        resetState,
      }}
    >
      {children}
    </DistributorDetailContext.Provider>
  )
}

export function useDistributorDetail(): DistributorDetailContextData {
  const context = useContext(DistributorDetailContext)

  if (!context || context === initialValue) {
    throw new Error(
      'useDistributorDetail must be used within a DistributorDetailProvider',
    )
  }

  return context
}
