import React, { memo, forwardRef } from 'react'

import { ButtonProps } from './props'
import { Container } from './styles'

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, ...rest } = props

  return (
    <Container ref={ref} type="button" {...rest} disableElevation>
      {children}
    </Container>
  )
})

Button.displayName = 'Button'

export default memo(Button)
