import React, { FC, useMemo } from 'react'

import loginImage from 'assets/images/login.png'
import Route from 'components/Route'
import { SetNewPasswordProvider } from 'hooks/auth/password/SetNewPassword'
import { useQuery } from 'services/useQuery'
import withContext from 'utils/withContext'

import Footer from '../components/Footer'
import EmptyToken from './components/EmptyToken'
import Form from './components/Form'
import Info from './components/Info'
import {
  Container,
  MainCard,
  Presentation,
  PresentationMd,
  FormContainer,
} from './styles'

const RecoverPassword: FC = () => {
  const query = useQuery()

  const token = useMemo(() => {
    const urlToken = query.get('token')
    if (!urlToken) return undefined
    return urlToken
  }, [query])

  const content = useMemo(() => {
    if (!token) return <EmptyToken />
    return <Form token={token} />
  }, [token])

  return (
    <Route title="Criar Senha" access="guest">
      <Container>
        <PresentationMd>
          <Info />
        </PresentationMd>

        <MainCard>
          <Presentation>
            <Info />

            <img src={loginImage} alt="Pessoa ao computador" />
          </Presentation>

          <hr />

          <FormContainer>
            {content}

            <Footer />
          </FormContainer>
        </MainCard>
      </Container>
    </Route>
  )
}

export default withContext(RecoverPassword, SetNewPasswordProvider)
