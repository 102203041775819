import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import { StockState, StockReducerActionMap, StockDetailActions } from './props'

const initialState: StockState = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  stock: null,
}

const StockDetailReducer: Reducer<StockState, StockReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case StockDetailActions.Reset:
      return initialState

    case StockDetailActions.Request:
      state.requestStatus.loading = true
      state.requestStatus.error = false
      state.requestStatus.success = false
      return state

    case StockDetailActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.requestStatus.success = false
      state.stock = null
      return state

    case StockDetailActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.requestStatus.success = true
      state.stock = action.payload.data
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(StockDetailReducer)
