import styled from 'styled-components'

import DividerUnstyled from '@material-ui/core/Divider'

export const Root = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    zoom: 0.5;
  }
`

export const Divider = styled(DividerUnstyled)`
  margin: ${({ theme }) => theme.spacing(2)}px 0px;
  background-color: ${({ theme }) => theme.palette.common.black};
`
