import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import { ICCIDSState, ICCIDSActions, ICCIDReducerActionMap } from './props'

const initialState: ICCIDSState = {
  status: {
    error: false,
    loading: false,
  },
  quantityChips: 0,
}

const ICCIDSReducer: Reducer<ICCIDSState, ICCIDReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case ICCIDSActions.Reset:
      return initialState

    case ICCIDSActions.RequestICCIDS:
      state.status.loading = true

      return state

    case ICCIDSActions.RequestErrorICCIDS:
      state.status.loading = false
      state.status.error = true
      state.quantityChips = 0

      return state

    case ICCIDSActions.RequestSuccessICCIDS:
      state.status.loading = false
      state.status.error = false
      state.quantityChips = action.payload.data.iccids.length

      return state

    default:
      return state
  }
}

export { initialState }
export default produce(ICCIDSReducer)
