import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { useTransferDetail } from 'hooks/transfers/details'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

export const useAcceptTransfer = () => {
  const { addToast } = useToast()

  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })
  const history = useHistory()
  const { transfer } = useTransferDetail()

  const acceptTransfer = useCallback(async () => {
    try {
      if (!transfer) {
        throw new Error('ID cannot be empty')
      }

      if (!(transfer.chips.length >= 99)) {
        setStatus({
          error: false,
          loading: true,
        })

        await api.post(`/transfers/${transfer.id}/accept`)

        setStatus({
          error: false,
          loading: false,
        })

        addToast({
          type: 'success',
          title: 'Transferência aceita!',
        })

        history.go(0)
      } else {
        addToast({
          type: 'info',
          title: '',
          description:
            'Essa transferência contém muitos chips, a confirmação pode demorar até 2 minutos',
        })
        setStatus({
          error: false,
          loading: true,
        })
        api.defaults.timeout = 200000
        await api.post(`/transfers/${transfer.id}/accept`)

        setStatus({
          error: false,
          loading: false,
        })

        addToast({
          type: 'success',
          title: 'Transferência aceita!',
        })

        history.go(0)
      }
    } catch (e) {
      setStatus({
        error: true,
        loading: false,
      })

      addToast({
        type: 'error',
        title: ErrorHandler.getMessage(e),
      })
    }
  }, [addToast, history, transfer])

  return {
    status,
    acceptTransfer,
  }
}
