import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { CommissionDetail } from 'interfaces/commission'
import { RequestStatus } from 'interfaces/common'

export type CommissionDetailResponse = CommissionDetail

export enum CommissionDetailActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type CommissionState = {
  requestStatus: RequestStatus
  commission: CommissionDetail | null
}

export type CommissionPayload = {
  [CommissionDetailActions.Request]: undefined
  [CommissionDetailActions.RequestSuccess]: AxiosResponse<CommissionDetailResponse>
  [CommissionDetailActions.RequestError]: undefined
  [CommissionDetailActions.Reset]: undefined
}

export type CommissionReducerActionMap = ActionMapData<CommissionPayload>

export type CommissionDetailContextData = CommissionState & {
  /**
   * Recebe os dados da API
   * @param company_id
   */
  getCommission(company_id: string, date: string): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}
