import React, { FC, useMemo, memo, useCallback } from 'react'

import TextField from 'components/Inputs/TextField'

import MenuItem from '@material-ui/core/MenuItem'

import { SelectDocumentProps, optionSelectType } from './props'
import * as Styled from './styles'

const SelectDocument: FC<SelectDocumentProps> = props => {
  const {
    name,
    mask = '',
    label,
    options,
    value,
    setValue,
    defaultValue,
    ...rest
  } = props

  const optionsMap = useMemo(
    () =>
      options.map((item: optionSelectType) => (
        <MenuItem value={item.value} key={item.value}>
          {item.value}
        </MenuItem>
      )),
    [options],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      setValue(event.target.value)
    },
    [setValue],
  )

  return (
    <Styled.Container>
      <Styled.Select
        select
        name="select"
        label={label}
        variant="outlined"
        value={value}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        autoFocus
        defaultValue={defaultValue}
        {...rest}
      >
        {optionsMap}
      </Styled.Select>
      <TextField
        name={name}
        mask={mask}
        label={value}
        defaultValue={defaultValue}
      />
    </Styled.Container>
  )
}

export default memo(SelectDocument)
