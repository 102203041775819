import React, { FC, useMemo, useEffect, memo } from 'react'

import TextField from 'components/Inputs/TextField'
import Typography from 'components/Typography'
import { useFormikContext } from 'formik'
import { useICCIDS } from 'hooks/iccids'
import validateICCID from 'utils/validateICCID'

import Grid from '@material-ui/core/Grid'

import FormProps from '../CreateChipForm/props'
import * as Styled from './styles'

const ICCIDSFields: FC = () => {
  const { values } = useFormikContext<FormProps>()

  const { quantityChips, isValidQuantity, countICCIDS } = useICCIDS()

  useEffect(() => {
    if (validateICCID([values.first_iccid, values.last_iccid])) {
      countICCIDS(values.first_iccid, values.last_iccid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.first_iccid, values.last_iccid])

  const renderErrorMultipleFive = useMemo(() => {
    if (!isValidQuantity) {
      return {
        helperText: 'Quantidade de chips deve ser múltiplo de cinco',
        error: true,
      }
    }
    return null
  }, [isValidQuantity])

  return (
    <Styled.Grid container spacing={2}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          label="ICCID Inicial"
          name="first_iccid"
          mask="99999999999999999999"
          {...renderErrorMultipleFive}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          label="ICCID Final"
          name="last_iccid"
          mask="99999999999999999999"
          {...renderErrorMultipleFive}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography color="initial" variant="subtitle1">
          Qtd. de chips
        </Typography>
        <Typography color="primary" variant="h6" capitalize="uppercase">
          {quantityChips}
        </Typography>
      </Grid>
    </Styled.Grid>
  )
}

export default memo(ICCIDSFields)
