import React, { FC, memo } from 'react'

import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import { CardInfoProps } from './props'
import * as Styled from './styles'

const CardInfo: FC<CardInfoProps> = porps => {
  const { loading, title, value } = porps

  if (loading) {
    return (
      <Grid item xs={12} sm={6} md={3}>
        <Styled.Card>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Skeleton variant="rect" width={90} height={20} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" width={200} height={40} />
              </Grid>
            </Grid>
          </CardContent>
        </Styled.Card>
      </Grid>
    )
  }

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Styled.Card>
        <CardContent>
          <Styled.CardHeader>
            <Styled.Title variant="subtitle1" color="textSecondary">
              {title}
            </Styled.Title>
          </Styled.CardHeader>

          <Styled.TextValueItem variant="h4" color="primary">
            {value}
          </Styled.TextValueItem>
        </CardContent>
      </Styled.Card>
    </Grid>
  )
}

export default memo(CardInfo)
