import React, { FC, memo, useEffect } from 'react'

import CheckboxSimple from 'components/Inputs/CheckboxSimple'
import TextField from 'components/Inputs/TextField'
import { useFormikContext } from 'formik'

import Grid from '@material-ui/core/Grid'

import { useFormCompany } from '../../context'
import { DataFormCompanyType } from '../../context/props'
import * as Styled from './styles'

const SectionRepresentative: FC = () => {
  const { typeForm } = useFormCompany()

  const { values, setFieldValue } = useFormikContext<DataFormCompanyType>()

  useEffect(() => {
    if (typeForm === 'update' && values.new_representative) {
      setFieldValue('representative.email', '')
      setFieldValue('representative.name', '')
      setFieldValue('representative.phone', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeForm, values.new_representative])

  const representativeFields = (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Styled.TitlePanel
            variant="subtitle1"
            color="primary"
            capitalize="uppercase"
          >
            Insira um representante
          </Styled.TitlePanel>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <TextField
            name="representative.name"
            label="Nome do representante *"
          />
        </Grid>

        <Grid item lg={5} md={5} sm={12} xs={12}>
          <TextField
            name="representative.email"
            label="E-mail do representante *"
            type="email"
          />
        </Grid>

        <Grid item lg={5} md={5} sm={12} xs={12}>
          <TextField
            name="representative.phone"
            label="Telefone do representante *"
            placeholder="DDD + Número"
            mask="(99) 99999-9999"
          />
        </Grid>
      </Grid>
    </>
  )

  if (typeForm === 'update') {
    return (
      <>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <Styled.TitlePanel
            variant="subtitle1"
            color="primary"
            capitalize="uppercase"
          >
            Representante
          </Styled.TitlePanel>

          <CheckboxSimple
            name="new_representative"
            label="Novo reprensentante"
          />
        </Grid>
        {values.new_representative && representativeFields}
      </>
    )
  }

  return representativeFields
}

export default memo(SectionRepresentative)
