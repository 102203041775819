import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  TransferState,
  TransferReducerActionMap,
  TransferDetailActions,
} from './props'

const initialState: TransferState = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  transfer: null,
}

const CompanyDetailReducer: Reducer<TransferState, TransferReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case TransferDetailActions.Reset:
      return initialState

    case TransferDetailActions.Request:
      state.requestStatus.loading = true
      state.requestStatus.error = false
      state.requestStatus.success = false
      return state

    case TransferDetailActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.requestStatus.success = false
      state.transfer = null
      return state

    case TransferDetailActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.requestStatus.success = true
      state.transfer = action.payload.data
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(CompanyDetailReducer)
