import styled from 'styled-components'
import { scaleInCenter } from 'styles/animations'

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    animation: ${scaleInCenter} 0.5s;
  }
`

export default Container
