import {
  COMPANIES,
  DISTRIBUTORS,
  MY_STOCK,
  SALES,
  STOCKS,
  TRANSFERS,
  USERS,
  RECHARGES,
  CONSOLIDATED_REPORTS,
  COMMISSIONS,
  COMPANY_REPRESENTATIVE_REPORTS,
  UPLOAD_FILE_VIVO,
  GENERATE_COMMISSION,
  CASHFLOW_LEAVES,
  CASHFLOW_REPORTS,
  CASHFLOW_SALES,
  CASHFLOW_WALLET,
  CASHFLOW_NEW_SALE,
} from './options'

const devRoles = [
  RECHARGES,
  DISTRIBUTORS,
  USERS,
  SALES,
  COMPANIES,
  CONSOLIDATED_REPORTS,
  STOCKS,
  TRANSFERS,
  UPLOAD_FILE_VIVO,
  GENERATE_COMMISSION,
]

const adminRoles = [
  RECHARGES,
  DISTRIBUTORS,
  USERS,
  SALES,
  COMPANIES,
  CONSOLIDATED_REPORTS,
  STOCKS,
  TRANSFERS,
  UPLOAD_FILE_VIVO,
  GENERATE_COMMISSION,
]

const analystRoles = [
  RECHARGES,
  DISTRIBUTORS,
  SALES,
  COMPANIES,
  CONSOLIDATED_REPORTS,
  STOCKS,
  TRANSFERS,
]

const company_representative_franchisee = [
  COMMISSIONS,
  COMPANY_REPRESENTATIVE_REPORTS,
]

const financial_collection = [SALES, RECHARGES]

const financial = [COMPANIES]

const stockist = [MY_STOCK, TRANSFERS]

const distributorRoles = [SALES, COMPANIES, STOCKS, TRANSFERS]

const cashflow = [
  CASHFLOW_NEW_SALE,
  CASHFLOW_SALES,
  CASHFLOW_WALLET,
  CASHFLOW_LEAVES,
  CASHFLOW_REPORTS,
]

export {
  devRoles,
  adminRoles,
  analystRoles,
  financial_collection,
  company_representative_franchisee,
  financial,
  stockist,
  distributorRoles,
  cashflow,
}
