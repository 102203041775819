import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useReducer,
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { CompanyWhoCanSellType } from 'interfaces/distributor'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'

import {
  EditDistributorContextProps,
  EditDistributorActions,
  DataFormEditDistributor,
  FormatPatternCompanyWhoCanSell,
} from './props'
import newUserReducer, { initialState } from './reducer'

const initialValue = {} as EditDistributorContextProps
const EditDistributorContext = createContext(initialValue)

const EditDistributorProvider: FC = props => {
  const { children } = props
  const [state, dispatch] = useReducer(newUserReducer, initialState)
  const { addToast } = useToast()
  const { push } = useHistory()

  /**
   *
   * @param value ['franchisee'] | ['partner'] | ['partner', 'franchise']
   * @return 'franchisee' | 'partner' | 'franchisee_and_partner'
   */
  const formatPatternCompanyWhoCanSell = useCallback<FormatPatternCompanyWhoCanSell>(
    values => {
      if (values.includes('partner') && values.includes('franchisee')) {
        return 'franchisee_and_partner'
      }
      return values.toString() as CompanyWhoCanSellType
    },
    [],
  )

  const editDistributor = useCallback(
    async (id: string, data: DataFormEditDistributor) => {
      try {
        if (!id) {
          throw Error('Distribuidor ID cannot be empty')
        }

        dispatch({ type: EditDistributorActions.RequestEditDistributor })

        const formData = {
          company_who_can_sell: formatPatternCompanyWhoCanSell(
            data.company_who_can_sell,
          ),
        }

        const response = await api.put(`/distributors/${id}`, formData)

        addToast({
          type: 'success',
          title: 'Distribuidor editado!',
        })
        dispatch({
          type: EditDistributorActions.RequestSuccessEditDistributor,
          payload: response,
        })

        push(
          RoutingService.getRouteString(
            RoutingService.DISTRIBUTOR_DETAILS,
            'distributorId',
            id,
          ),
        )
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        dispatch({
          type: EditDistributorActions.RequestErrorEditDistributor,
        })
      }
    },
    [addToast, formatPatternCompanyWhoCanSell, push],
  )

  const resetState = useCallback(
    () => dispatch({ type: EditDistributorActions.Reset }),
    [],
  )

  return (
    <EditDistributorContext.Provider
      value={{ ...state, editDistributor, resetState }}
    >
      {children}
    </EditDistributorContext.Provider>
  )
}

export * from './props'
export const useEditDistributor = () => {
  const context = useContext(EditDistributorContext)
  if (!context || context === initialValue) {
    throw new Error(
      'useEditDistributor must be used within a EditDistributorProvider',
    )
  }
  return context
}

export default EditDistributorProvider
