import styled from 'styled-components'

import { StyleProps } from './props'

const colorSelector = (color?: StyleProps['colorVariation']) => {
  const colors = {
    green: '#8CC83C',
    orange: '#F2994A',
    red: '#EB5757',
    yellow: '#F2C94C',
    grey: '#929292',
    default: '',
  }

  return colors[color || 'default']
}

const Container = styled.span<StyleProps>`
  background-color: ${props => colorSelector(props.colorVariation)};
  display: inline-block;
  border-radius: 30px;
  color: #fff;
  padding: 2px 16px;
  margin-left: 4px;

  :first-child {
    margin-left: 0px;
  }
`

export default Container
