import React, { FC, memo, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import Card from 'components/Card'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import Typography from 'components/Typography'
import { Purchase } from 'interfaces/company'
import RoutingService from 'services/routing'

import PurchasesListHeader from './components/PurchasesListHeader'
import PurchasesListItem from './components/PurchasesListItem'
import { TablePurchasesProps } from './props'
import * as Styled from './styles'

const TablePurchases: FC<TablePurchasesProps> = props => {
  const { data, status } = props

  const renderItem = useCallback<TableRenderItem<Purchase>>(
    item => <PurchasesListItem key={item.id} {...item} />,
    [],
  )

  const salesUrl = useMemo(() => RoutingService.SALES, [])

  if (!data) return null

  return (
    <Card shadow>
      <Styled.Grid container spacing={2}>
        <Styled.HeaderTable lg={12} md={12} sm={12} xs={12} item>
          <Typography color="primary" variant="h6" capitalize="uppercase">
            compras
          </Typography>
          <Link to={salesUrl}>
            <Button color="primary" variant="outlined" size="small">
              Ver todas
            </Button>
          </Link>
        </Styled.HeaderTable>
      </Styled.Grid>
      <Table
        data={data}
        loadingRequest={status.loading}
        renderItem={renderItem}
        HeaderComponent={<PurchasesListHeader />}
        FooterComponent={null}
        emptyMessage="Nenhuma compra encontrada"
        disableShadow
        size="small"
      />
    </Card>
  )
}

export default memo(TablePurchases)
