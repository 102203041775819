import React, { FC } from 'react'

import Typography from 'components/Typography'

import Grid from '@material-ui/core/Grid'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import * as Styled from './styles'

const TableCounting: FC = () => {
  return (
    <Grid container>
      <TableContainer>
        <Typography color="primary" variant="h5">
          Detalhamento apuração
        </Typography>

        <Styled.Table size="small" padding="none">
          <TableHead>
            <Styled.TableRow>
              <TableCell>
                <Typography color="primary" variant="subtitle1">
                  Produto
                </Typography>
              </TableCell>
              <Styled.TableCell align="right">
                <Typography color="primary" variant="subtitle1">
                  Quantidade
                </Typography>
              </Styled.TableCell>
              <Styled.TableCell align="right">
                <Typography color="primary" variant="subtitle1">
                  Valor líquido
                </Typography>
              </Styled.TableCell>
              <Styled.TableCell align="right">
                <Typography color="primary" variant="subtitle1">
                  Valor do repasse
                </Typography>
              </Styled.TableCell>
            </Styled.TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography
                  color="textPrimary"
                  variant="body1"
                  capitalize="uppercase"
                >
                  Brisanet fibra 400MB
                </Typography>
              </TableCell>
              <Styled.TableCell align="right">
                <Typography color="textPrimary" variant="body1">
                  1
                </Typography>
              </Styled.TableCell>
              <Styled.TableCell align="right">
                <Typography color="textPrimary" variant="body1">
                  R$ 100,00
                </Typography>
              </Styled.TableCell>
              <Styled.TableCell align="right">
                <Typography color="textPrimary" variant="body1">
                  R$ 100,00
                </Typography>
              </Styled.TableCell>
            </TableRow>
          </TableBody>
        </Styled.Table>
      </TableContainer>
    </Grid>
  )
}

export default TableCounting
