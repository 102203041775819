import React, { FC, memo, useCallback, useMemo } from 'react'

import Card from 'components/Card'
import DetailsPageItem from 'components/DetailsPages/DetailsItem'
import { useListIntervalIccids } from 'components/Modals/ListIntervalIccids/context'
import Typography from 'components/Typography'
import { useAuth } from 'hooks/auth'
import customFormatDate, { DATE_FORMAT_DMY_HOUR_MINUTE } from 'utils/formatDate'
import customFormatPrice from 'utils/formatPrice'

import Grid from '@material-ui/core/Grid'

import { useSaleDetail } from '../../context'
import { DetailsComponentProps } from './props'
import * as Styled from './styles'

const DetailsComponent: FC<DetailsComponentProps> = props => {
  const { id } = props

  const {
    openModal: openModalListIntervalIccids,
    getListIntervalIccids,
  } = useListIntervalIccids()

  const handleOpenModal = useCallback(async () => {
    getListIntervalIccids(id)
    openModalListIntervalIccids()
  }, [openModalListIntervalIccids, getListIntervalIccids, id])

  const { user } = useAuth()

  const saleDetail = useSaleDetail()

  const sale = useMemo(() => saleDetail.sale!, [saleDetail.sale])

  const price = useMemo(() => {
    if (!sale) return null
    return customFormatPrice({ price: sale.total_price })
  }, [sale])

  const unitChipPrice = useMemo(() => {
    if (!sale) return null
    return customFormatPrice({ price: sale.unit_chip_price })
  }, [sale])

  const formattedCreatedAt = useMemo(() => {
    if (!sale) return '-'
    return customFormatDate({
      date: sale.created_at.toString(),
      format: DATE_FORMAT_DMY_HOUR_MINUTE,
    })
  }, [sale])

  const formattedUpdatedAt = useMemo(() => {
    if (!sale) return '-'
    return customFormatDate({
      date: sale.updated_at.toString(),
      format: DATE_FORMAT_DMY_HOUR_MINUTE,
    })
  }, [sale])

  const comments = useMemo(() => {
    if (!sale?.comments || sale?.comments === '') return null

    return (
      <DetailsPageItem title="Observações" sm={12} md={6}>
        <Typography color="primary" variant="subtitle1">
          {sale.comments.split('↵').join('\n')}
        </Typography>
      </DetailsPageItem>
    )
  }, [sale])

  return (
    <Card shadow>
      <Styled.Grid container spacing={2}>
        <Grid sm={12} item>
          <Typography
            color="primary"
            variant="subtitle1"
            capitalize="uppercase"
          >
            Detalhes da{' '}
            {user?.role.name === 'company_representative' ? 'compra' : 'venda'}
          </Typography>
        </Grid>
      </Styled.Grid>

      <Styled.Grid container spacing={2}>
        <DetailsPageItem
          title={`Valor total da ${
            user?.role.name === 'company_representative' ? 'compra' : 'venda'
          }`}
          sm={3}
        >
          {price}
        </DetailsPageItem>

        <DetailsPageItem title="Valor por chip" sm={3}>
          {unitChipPrice}
        </DetailsPageItem>

        <DetailsPageItem title="Quantidade de chips" sm={3}>
          {sale?.chips?.length || '-'}
        </DetailsPageItem>

        <DetailsPageItem title="Bonificados" sm={3}>
          {sale?.discount_quantity}
        </DetailsPageItem>
      </Styled.Grid>

      <Styled.Grid container spacing={2}>
        <DetailsPageItem title="Empresa" sm={3}>
          {sale?.wallet_recipient.company.fantasy_name}
        </DetailsPageItem>
        <DetailsPageItem title="Representante" sm={3} capitalize="uppercase">
          {sale?.wallet_recipient.company.representative?.user.name || '-'}
        </DetailsPageItem>

        <DetailsPageItem title="Criado em" sm={3}>
          {formattedCreatedAt}
        </DetailsPageItem>

        <DetailsPageItem title="Atualizado em" sm={3}>
          {formattedUpdatedAt}
        </DetailsPageItem>
      </Styled.Grid>

      <Styled.Grid container spacing={2}>
        <DetailsPageItem title="Intervalo de iccids" sm={3}>
          <Styled.ViewList onClick={handleOpenModal}>
            Visualizar lista
          </Styled.ViewList>
        </DetailsPageItem>
      </Styled.Grid>

      <Styled.Grid container spacing={2}>
        {comments}
      </Styled.Grid>

      <Styled.Grid container spacing={2}>
        <Grid sm={12} item>
          <Typography
            color="primary"
            variant="subtitle1"
            capitalize="uppercase"
          >
            Detalhes do vendedor
          </Typography>
        </Grid>
      </Styled.Grid>

      <Styled.Grid container spacing={2}>
        <DetailsPageItem title="Nome" sm={3} capitalize="uppercase">
          {sale?.wallet_sender.distributor.user.name}
        </DetailsPageItem>

        <DetailsPageItem title="E-mail" sm={3}>
          {sale?.wallet_sender.distributor.user.email}
        </DetailsPageItem>
      </Styled.Grid>
    </Card>
  )
}

export default memo(DetailsComponent)
