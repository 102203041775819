import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useReducer,
  useState,
} from 'react'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import {
  CreateChipsContextProps,
  CreateChipsActions,
  DataFormAddChipsType,
} from './props'
import createChipsReducer, { initialState } from './reducer'

const CreateChipsContext = createContext({} as CreateChipsContextProps)

const CreateChipsProvider: FC = props => {
  const { children } = props
  const [state, dispatch] = useReducer(createChipsReducer, initialState)
  const { addToast } = useToast()

  const [modalActive, setModalActive] = useState(false)

  const openModal = useCallback(() => {
    setModalActive(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalActive(false)
  }, [])

  const addChips = useCallback(
    async (
      data: DataFormAddChipsType,
      id: string,
      biggerThanOneHundred?: boolean,
    ) => {
      try {
        dispatch({ type: CreateChipsActions.RequestCreateChips })
        if (!biggerThanOneHundred) {
          const response = await api
            .post(`/stocks/${id}/chips`, data)
            .catch(error => {
              throw error
            })

          addToast({
            type: 'success',
            title: 'Chips adicionados!',
          })
          dispatch({
            type: CreateChipsActions.RequestSuccessCreateChips,
            payload: response,
          })
          closeModal()
        } else {
          addToast({
            type: 'info',
            title: '',
            description:
              'Esse cadastro possui muitos chips, a confirmação pode demorar até 2 minutos',
          })
          api.defaults.timeout = 200000
          const response = await api
            .post(`/stocks/${id}/chips`, data)
            .catch(error => {
              throw error
            })
          addToast({
            type: 'success',
            title: 'Chips adicionados!',
          })
          dispatch({
            type: CreateChipsActions.RequestSuccessCreateChips,
            payload: response,
          })
          closeModal()
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        dispatch({ type: CreateChipsActions.RequestErrorCreateChips })
      }
    },
    [addToast, closeModal],
  )

  const resetState = useCallback(
    () => dispatch({ type: CreateChipsActions.Reset }),
    [],
  )

  return (
    <CreateChipsContext.Provider
      value={{
        ...state,
        addChips,
        resetState,
        modalActive,
        openModal,
        closeModal,
      }}
    >
      {children}
    </CreateChipsContext.Provider>
  )
}

export const useCreateChips = () => useContext(CreateChipsContext)

export default CreateChipsProvider
