import React, { FC, memo, useEffect } from 'react'

import FormAddCompany from 'components/FormCompany'
import { useFormCompany } from 'components/FormCompany/context'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { useModal } from './context'
import * as Styled from './styles'

const Dialog: FC = () => {
  const { modalActive, closeModal } = useModal()
  const { company: companyAdded, requestStatusAddCompany } = useFormCompany()

  useEffect(() => {
    if (requestStatusAddCompany.success && companyAdded) {
      closeModal()
    }
  }, [closeModal, companyAdded, requestStatusAddCompany])

  return (
    <Styled.Dialog
      open={modalActive}
      onClose={closeModal}
      aria-labelledby="form-dialog"
      fullWidth
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Styled.Title variant="h6" color="textSecondary">
              Cadastre um novo revendedor
            </Styled.Title>
          </Grid>
        </Grid>
        <FormAddCompany />
      </Container>
    </Styled.Dialog>
  )
}

export default memo(Dialog)
