import React, { FC, memo, useMemo } from 'react'

import Select from 'components/Inputs/Select'
import { OptionSelectType } from 'components/Inputs/Select/props'
import { useFormikContext } from 'formik'
import { EditUserCredentials } from 'hooks/users/edit/props'

import { SelectRolesProps } from './props'
import { useRoles } from './useRoles'

const SelectRoles: FC<SelectRolesProps> = props => {
  const { roles } = useRoles()
  const { values } = useFormikContext<EditUserCredentials>()

  const disabled = useMemo(
    () => values.role_name !== 'analyst' && values.role_name !== 'admin',
    [values.role_name],
  )

  const options = useMemo<OptionSelectType[]>(() => {
    if (!roles) return []

    return roles
      .filter(role => {
        if (disabled || role.name === 'analyst' || role.name === 'admin') {
          return true
        }
        return false
      })
      .map(role => ({ name: role.translated_name, value: role.name }))
  }, [disabled, roles])

  return (
    <Select
      name="role_name"
      label="Tipo de usuário"
      options={options}
      disabled={disabled}
      {...props}
    />
  )
}

export default memo(SelectRoles)
