import React, { memo, FC } from 'react'

import { TableRowItemProps } from './props'
import * as Styled from './styles'

const TableRowItem: FC<TableRowItemProps> = props => {
  const { children, ...rest } = props

  return <Styled.TableRow {...rest}>{children}</Styled.TableRow>
}

export default memo(TableRowItem)
