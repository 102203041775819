import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { useAuth } from 'hooks/auth'
import { useToast } from 'hooks/toast'
import { api } from 'services/api'

import {
  EditProfileActions,
  EditProfileBaseData,
  EditProfileContextData,
  UploadPhotoValidate,
} from './props'
import editProfileReducer, { initialState } from './reducer'

const initialValue = {} as EditProfileContextData

export const EditProfileContext = createContext(initialValue)

export const EditProfileProvider: FC = props => {
  const { children } = props

  const { getProfile } = useAuth()
  const { addToast } = useToast()
  const [state, dispatch] = useReducer(editProfileReducer, initialState)

  const editProfile = useCallback(
    async (newData: EditProfileBaseData) => {
      try {
        if (!newData) {
          throw Error('User Data cannot be empty')
        }

        dispatch({ type: EditProfileActions.Request })

        if (newData.photoAvatar) {
          await uploadPhoto({ avatar: newData.photoAvatar })
        }
        delete newData.photoAvatar

        await api.put(`/profile`, newData)

        dispatch({
          type: EditProfileActions.RequestSuccess,
        })

        addToast({
          title: 'Dados alterados',
          description: 'Seus dados foram alterados com sucesso',
          type: 'success',
        })

        await getProfile()
      } catch (e) {
        addToast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao alterar seus dados',
          type: 'error',
        })
        dispatch({ type: EditProfileActions.RequestError })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, getProfile],
  )

  const resetState = useCallback(
    () => dispatch({ type: EditProfileActions.Reset }),
    [],
  )

  const uploadPhoto = useCallback(
    async (data: UploadPhotoValidate) => {
      try {
        const formData = new FormData()
        formData.append('avatar', data.avatar as File)

        await api.patch('/users/avatar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        addToast({
          type: 'success',
          title: 'Sua foto foi enviada com sucesso!',
        })
      } catch (error) {
        addToast({ type: 'error', title: 'Houve um erro ao enviar sua foto!' })
      }
    },
    [addToast],
  )

  return (
    <EditProfileContext.Provider
      value={{
        ...state,
        editProfile,
        resetState,
        uploadPhoto,
      }}
    >
      {children}
    </EditProfileContext.Provider>
  )
}

export function useEditProfile(): EditProfileContextData {
  const context = useContext(EditProfileContext)

  if (!context || context === initialValue) {
    throw new Error('useEditProfile must be used within a EditProfileProvider')
  }

  return context
}
