import React, { FC, memo } from 'react'

import { DateFilterProps } from '../../props'
import * as Styled from './styles'

const DateFilter: FC<DateFilterProps> = props => {
  const { onChange, value, name, label, maxDate } = props

  const icon = <img src={require('assets/images/icons/calendar.svg').default} />

  return (
    <Styled.FormControl>
      <Styled.DatePicker
        label={label}
        inputVariant="outlined"
        name={name}
        value={value}
        onChange={event => onChange(event)}
        format="dd/MM/yyyy"
        maxDate={maxDate}
        variant="inline"
        disableToolbar
        disableFuture
        InputProps={{
          endAdornment: icon,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Styled.FormControl>
  )
}

export default memo(DateFilter)
