import React, { FC, useState } from 'react'

import Card from 'components/Card'
import Formik from 'components/Formik'
import TextField from 'components/Inputs/TextField'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import NewUserProvider, { useAddUser } from 'hooks/users/create'
import { DataFormAddUserType } from 'hooks/users/create/props'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import ButtonSubmit from './components/ButtonSubmit'
import SelectRoles from './components/SelectRoles'
import schema from './schemas'
import * as Styled from './styles'

const initialValues: DataFormAddUserType = {
  name: '',
  email: '',
  phone: '',
  role_name: '',
  products_ids: [],
}

const NewUser: FC = () => {
  const [userType, setUserType] = useState('')

  const { addUser, requestStatus } = useAddUser()

  return (
    <Route title="Usuários" access="auth" requireRoles={['dev', 'admin']}>
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Usuários',
            link: RoutingService.USERS_CASHFLOW,
          },
          {
            title: 'Novo usuário',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-users"
      >
        <PageTitle title="Novo usuário" />

        <Card>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={addUser}
            validateOnMount
          >
            <Grid container spacing={3}>
              <Grid item xl={12} md={12} sm={12} xs={12}>
                <Styled.TitlePanel variant="body1">
                  dados do usuário
                </Styled.TitlePanel>
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <TextField name="name" label="Nome" />
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <TextField name="email" label="E-mail" type="email" />
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <SelectRoles value={userType} setValue={setUserType} />
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <TextField
                  name="phone"
                  label="Telefone *"
                  placeholder="DDD + Número"
                  mask="(99) 99999-9999"
                />
              </Grid>
              <Grid item xl={12} md={12} sm={12} xs={12}></Grid>

              <Grid item xl={12} md={12} sm={12} xs={12}>
                <Styled.ButtonContainer>
                  <ButtonSubmit loading={requestStatus.loading} />
                </Styled.ButtonContainer>
              </Grid>
            </Grid>
          </Formik>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(NewUser, NewUserProvider)
