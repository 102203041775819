import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useReducer,
  useState,
} from 'react'

import { startOfMonth } from 'date-fns'
import { useAuth } from 'hooks/auth'
import { SaleCashFlow } from 'interfaces/SalesCashFlow'
import { api } from 'services/api'
import {
  PaginationActions,
  paginationReducer,
  PaginationRouteParams,
} from 'services/pagination'
import {
  initialCategory,
  initialSeller,
  initialStatus,
  initialStore,
} from 'utils/initialStateForSelects'
import { LeavesParams } from 'utils/paramsForRequest'
import { rolePermission } from 'utils/permissions'

import {
  ListSalesCashFlowResponse,
  OfficeData,
  SalesCashFlowContextData,
  SalesCaslFlowStatusParam,
  SalesStatusCashFlowType,
  SellCategory,
  SellersData,
} from './props'
import { initialState } from './reducer'
const initialValue = {} as SalesCashFlowContextData
export const SalesCashFlowcontext = createContext(initialValue)

export const SalesCashFlowProvider: FC = props => {
  const { children } = props
  const [state, dispatch] = useReducer(
    paginationReducer<SaleCashFlow>(),
    initialState,
  )
  const [status, setStatus] = useState<SalesStatusCashFlowType>(initialStatus)
  const [store, setStore] = useState<OfficeData>(initialStore)
  const [details, setDetails] = useState<SaleCashFlow>()
  const [sellCategory, setSellCategory] = useState<SellCategory>(
    initialCategory,
  )
  const [seller, setSeller] = useState<SellersData>(initialSeller)
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()))
  const [endDate, setEndDate] = useState<Date>(new Date())
  const { user } = useAuth()

  const getSalesCashFlow = useCallback(async () => {
    try {
      dispatch({ type: PaginationActions.Request })
      const paramsFiltred = LeavesParams({
        type: 'sales',
        params: {
          category_eq: sellCategory?.labelName,
          seller_user_id_eq: seller?.user_id,
          office_id_eq: store?.id,
          seller_role_name_eq: seller?.role_name,
          status_eq: status?.labelName,
        },
      })

      const params: PaginationRouteParams & SalesCaslFlowStatusParam = {
        page: state.current_page,
        per_page: state.per_page,
        ...paramsFiltred,
      }
      if (startDate && endDate) {
        params.date_gt = startDate
        params.date_lt = endDate
      }
      const permission = rolePermission(user?.role.name)
      if (permission == 'sellerPermission') {
        const response = await api.get<ListSalesCashFlowResponse>(
          `/sales-to-customers/list-sales-of-seller`,
          { params },
        )
        dispatch({
          type: PaginationActions.RequestSuccess,
          payload: response,
        })
      } else if (permission == 'midlePermission') {
        params.office_id_eq = undefined
        const response = await api.get<ListSalesCashFlowResponse>(
          `/sales-to-customers/list-sales-of-office`,
          { params },
        )
        dispatch({
          type: PaginationActions.RequestSuccess,
          payload: response,
        })
      } else if (permission == 'higherPermission') {
        const response = await api.get<ListSalesCashFlowResponse>(
          `/sales-to-customers/list-sales-to-customer`,
          { params },
        )
        dispatch({
          type: PaginationActions.RequestSuccess,
          payload: response,
        })
      }
    } catch (e) {
      dispatch({ type: PaginationActions.RequestError })
    }
  }, [endDate, sellCategory, seller, startDate, state, status, store, user])

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <SalesCashFlowcontext.Provider
      value={{
        ...state,
        store,
        sellCategory,
        startDate,
        status,
        endDate,
        seller,
        details,
        setDetails,
        setSellCategory,
        setSeller,
        setStore,
        getSalesCashFlow,
        nextPage,
        previousPage,
        setStartDate,
        setEndDate,
        setStatus,
        setLimit,
        setPage,
      }}
    >
      {children}
    </SalesCashFlowcontext.Provider>
  )
}

export function useSaleCashFlow(): SalesCashFlowContextData {
  const context = useContext(SalesCashFlowcontext)

  if (!context || context === initialValue) {
    throw new Error('useSale must be used within a SaleCashFlowProvider')
  }

  return context
}
