import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'

export interface ICCIDSState {
  status: RequestStatus
  quantityChips: number
}

export enum ICCIDSActions {
  RequestICCIDS = 'REQUEST_ICCIDS',
  RequestSuccessICCIDS = 'REQUEST_SUCCESS_ICCIDS',
  RequestErrorICCIDS = 'REQUEST_ERROR_ICCIDS',
  Reset = 'RESET',
}

export type ICCIDSPayload = {
  [ICCIDSActions.RequestICCIDS]: undefined
  [ICCIDSActions.RequestSuccessICCIDS]: AxiosResponse<any>
  [ICCIDSActions.RequestErrorICCIDS]: undefined

  [ICCIDSActions.Reset]: undefined
}

export type ICCIDContextData = ICCIDSState & {
  /**
   *
   * @param first_iccid primeiro ICCID
   * @param last_iccid segundo ICCID
   *
   * @return quantidade de ICCIDS entre o primeiro e o segundo
   */
  countICCIDS(first_iccid: string, last_iccid: string): void

  /**
   * Retorna se a quantidade de ICCIDS é valida, ou seja, multiplo de cinco
   */
  isValidQuantity: boolean
}

export type ICCIDReducerActionMap = ActionMapData<ICCIDSPayload>
