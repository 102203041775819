import styled from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

export const Grid = styled(MUGrid)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    button {
      margin-left: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`
