import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'

export interface State {
  id: string
  created_at: Date
  updated_at: Date
  name: string
  initials: string
  ibge_id: number
}

export interface City {
  id: string
  created_at: Date
  updated_at: Date
  name: string
  ibge_id: number
  state: State
}

export interface Address {
  id: string
  created_at: Date
  updated_at: Date
  active: boolean
  latitude?: any
  longitude?: any
  city: City
}

export interface User {
  id: string
  created_at: Date
  updated_at: Date
  active: boolean
  name: string
  email: string
  phone: string
  avatar?: any
  city_id?: any
  role_id: string
  avatar_url?: any
}

export interface Stockist {
  id: string
  created_at: Date
  updated_at: Date
  user_id: string
  stock_id: string
  user: User
}

export interface User2 {
  id: string
  created_at: Date
  updated_at: Date
  active: boolean
  name: string
  email: string
  phone: string
  avatar?: any
  city_id?: any
  role_id: string
  avatar_url?: any
}

export interface Distributor {
  id: string
  created_at: Date
  updated_at: Date
  company_who_can_sell: string
  user_id: string
  wallet_id: string
  user: User2
}

export interface WalletRecipient {
  id: string
  created_at: Date
  updated_at: Date
  owner_type: string
  stock?: any
  company?: any
  distributor: Distributor
}

export interface TransfersSent {
  id: string
  created_at: Date
  updated_at: Date
  order_number: number
  status: string
  wallet_sender_id: string
  wallet_recipient_id: string
  wallet_recipient: WalletRecipient
}

export interface Wallet {
  id: string
  created_at: Date
  updated_at: Date
  owner_type: string
  transfers_sent: TransfersSent[]
  transfers_received: any[]
}

export interface Stock {
  id: string
  created_at: Date
  updated_at: Date
  active: boolean
  name: string
  stockist_id: string
  wallet_id: string
  address: Address
  stockist: Stockist
  wallet: Wallet
}

export interface ResponseStock {
  stock: Stock
  total_pending_chips: number
  total_available_chips: number
}

export type StockDetailResponse = ResponseStock

export enum StockDetailActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type StockState = {
  requestStatus: RequestStatus
  stock: ResponseStock | null
}

export type StockPayload = {
  [StockDetailActions.Request]: undefined
  [StockDetailActions.RequestSuccess]: AxiosResponse<StockDetailResponse>
  [StockDetailActions.RequestError]: undefined
  [StockDetailActions.Reset]: undefined
}

export type StockReducerActionMap = ActionMapData<StockPayload>

export type StockDetailContextData = {
  stock: ResponseStock | null
  // chips: QuantityChips | null
  requestStatus: RequestStatus

  /**
   * Recebe os dados da API
   */
  getStock(id: string): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}
