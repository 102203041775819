/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import React, { FC, useCallback, useEffect } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import ComboBox from 'components/SelectStores'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { useAuth } from 'hooks/auth'
import { ChipsCashFlowProvider, useChipsCashFlow } from 'hooks/ChipsCashFlow'
import { ChipsCashFlow } from 'hooks/ChipsCashFlow/props'
import { OfficeData, SellersData } from 'hooks/salesCashFlow/props'
import RoutingService from 'services/routing'
import { initialSeller, initialStore } from 'utils/initialStateForSelects'
import { GetOffices, GetSellers } from 'utils/officesAndSellers'
import { rolePermission } from 'utils/permissions'
import withContext from 'utils/withContext'

import CashFlowListHeader from './components/WalletListHeader'
import CashFlowListItem from './components/WalletListItem'
import { InputsContainer } from './styles'
const Wallet: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    store,
    seller,
    getWallet,
    nextPage,
    previousPage,
    setStore,
    setSeller,
    requestStatus,
    setLimit,
    setPage,
  } = useChipsCashFlow()

  const renderItem = useCallback<TableRenderItem<ChipsCashFlow>>(
    item => <CashFlowListItem key={item.id} {...item} />,
    [],
  )
  const { user } = useAuth()
  const [offices, seOffices] = React.useState<OfficeData[]>([])
  const [sellers, setSellers] = React.useState<SellersData[]>([])
  const [permission, setPermission] = React.useState<string>('')
  useEffect(() => {
    const result = rolePermission(user?.role.name)
    setPermission(result)
    if (offices.length === 0) {
      ;(async () => {
        const dataOffices = await GetOffices()
        seOffices(dataOffices)
      })()
    }

    if (store && store.name !== '') {
      ;(async () => {
        const dataSellers = await GetSellers(store.id)
        setSellers(dataSellers)
      })()
    }

    if (!store) {
      setStore(initialStore)
      setSeller(initialSeller)
    }
    return () => {
      seOffices([])
      setSellers([])
      setPermission('')
    }
  }, [store, seller])

  useEffect(() => {
    if (!store?.name) {
      setSellers([])
      setSeller(initialSeller)
    }
    getWallet()
  }, [seller])

  useEffect(() => {
    if (
      store?.name == '' &&
      permission == 'midlePermission' &&
      offices.length > 0
    ) {
      setStore(offices[0])
    }
  }, [offices, permission])

  return (
    <Route
      title="Carteira"
      access="auth"
      requireRoles={[
        'admin',
        'attendant',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'office_stockist',
        'sale_leader',
        'pap_leader',
        'pap_seller',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Carteira',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-wallet"
      >
        <PageTitle title={'Carteira de Chips'} />
        <InputsContainer>
          {permission === 'higherPermission' && (
            <ComboBox
              width="300px"
              labelName="Lojas"
              options={offices}
              setOption={setStore}
              value={store}
            />
          )}
          {(permission === 'midlePermission' ||
            permission === 'higherPermission') && (
            <ComboBox
              width="300px"
              labelName="Atendentes"
              options={sellers}
              setOption={setSeller}
              value={seller}
            />
          )}
        </InputsContainer>
        <Table
          data={data}
          renderItem={renderItem}
          loadingRequest={requestStatus.loading}
          HeaderComponent={<CashFlowListHeader />}
          FooterComponent={
            <TablePagination
              getData={getWallet}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Wallet, ChipsCashFlowProvider)
