import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Card from 'components/Card'
import FormAddCompany from 'components/FormCompany'
import {
  FormCompanyProvider,
  useFormCompany,
} from 'components/FormCompany/context'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

const NewCompany: FC = () => {
  const { requestStatusAddCompany, company } = useFormCompany()

  const { push } = useHistory()

  useEffect(() => {
    if (requestStatusAddCompany.success && company) {
      push(
        RoutingService.getRouteString(
          RoutingService.COMPANY_DETAILS,
          'companyId',
          company?.id,
        ),
      )
    }
  }, [company, push, requestStatusAddCompany])

  return (
    <Route
      title="Revendedor"
      access="auth"
      requireRoles={['distributor', 'admin', 'dev', 'financial']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores', link: RoutingService.COMPANIES },
          { title: 'Adicionar Revendedor' },
        ]}
        activeOption="companies"
      >
        <PageTitle title="Adicionar Revendedor" />
        <Card shadow>
          <FormAddCompany />
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(NewCompany, FormCompanyProvider)
