import React, { FC, memo, useMemo } from 'react'

import { CircularProgress } from '@material-ui/core'

import { useNewSale } from '../../context'
import * as Styled from './styles'

const ButtonSubmit: FC = () => {
  const { isValidQuantityChips, requestStatus } = useNewSale()

  const disabled = useMemo(
    () => !isValidQuantityChips || requestStatus.loading,
    [isValidQuantityChips, requestStatus.loading],
  )

  const renderText = useMemo(() => {
    if (requestStatus.loading) {
      return <CircularProgress color="primary" />
    }

    return 'Finalizar venda'
  }, [requestStatus])

  return (
    <Styled.Button
      variant="contained"
      color="secondary"
      size="large"
      type="submit"
      disabled={disabled}
    >
      {renderText}
    </Styled.Button>
  )
}

export default memo(ButtonSubmit)
