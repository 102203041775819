import React, { FC, useMemo, useCallback, useEffect } from 'react'
import { useEffectOnce } from 'react-use'

import RequestError from 'components/DetailsPages/RequestError'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import ModalAskReplacement from 'components/Modals/AskReplacement'
import AskReplacementProvider from 'components/Modals/AskReplacement/context'
import ModalCreateChip from 'components/Modals/CreateChip'
import CreateChipsProvider from 'components/Modals/CreateChip/context'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import { useAuth } from 'hooks/auth'
import { useStockDetail, StockDetailProvider } from 'hooks/stock/details'
import ContextUser from 'services/contextUser'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import DetailsComponent from '../StockDetails/components/DetailsComponent'

const MyStock: FC = () => {
  const { stock, getStock, requestStatus } = useStockDetail()
  const { user, getProfile, requestProfileStatus } = useAuth()

  useEffectOnce(() => {
    getProfile()
  })

  const stockId = useMemo(() => ContextUser.extractId(user), [user])

  const loadingPage = useMemo(
    () => requestStatus.loading && requestProfileStatus.loading,
    [requestProfileStatus.loading, requestStatus.loading],
  )

  useEffect(() => {
    getStock(stockId)
  }, [getStock, stockId])

  const Content = useCallback(() => {
    if (requestStatus.success)
      return (
        <>
          <PageTitle title={`Detalhes de ${stock?.stock?.name}`} />
          <DetailsComponent />
        </>
      )
    if (requestStatus.error)
      return <RequestError data-cy="request-error__div" />
    return <Loading />
  }, [requestStatus, stock?.stock?.name])

  return (
    <Route
      title="Meu Estoque"
      access="auth"
      requireRoles={['stockist']}
      showLoader={loadingPage}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Meu Estoque' },
        ]}
        activeOption="my-stock"
      >
        <Content />
        <ModalAskReplacement />
        <ModalCreateChip stockId={stockId} />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(
  MyStock,
  StockDetailProvider,
  AskReplacementProvider,
  CreateChipsProvider,
)
