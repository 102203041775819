import React, { FC, memo, useCallback, useMemo } from 'react'

import DetailsPageItem from 'components/DetailsPages/DetailsItem'
import { useListIntervalIccids } from 'components/Modals/ListIntervalIccids/context'
import { useTransferDetail } from 'hooks/transfers/details'
import { TransferParticipantType } from 'hooks/transfers/props'
import customFormatDate, { DATE_FORMAT_DMY_HOUR_MINUTE } from 'utils/formatDate'

import TransferButtons from '../TransferButtons/TransferButtons'
import TransferHeader from '../TransferHeader/TransferHeader'
import { DetailsComponentProps } from './props'
import * as Styled from './styles'

const DetailsComponent: FC<DetailsComponentProps> = props => {
  const { id } = props

  const {
    openModal: openModalListIntervalIccids,
    getListIntervalIccids,
  } = useListIntervalIccids()

  const handleOpenModal = useCallback(() => {
    getListIntervalIccids(id)
    openModalListIntervalIccids()
  }, [getListIntervalIccids, openModalListIntervalIccids, id])

  const { transfer } = useTransferDetail()

  const getWalletName = useCallback(
    (
      key: 'wallet_sender' | 'wallet_recipient',
      type: TransferParticipantType | null | undefined,
    ) => {
      if (!transfer || !type) return null
      switch (type) {
        case 'company':
          return `Empresa: ${transfer[key].company?.fantasy_name}`
        case 'distributor':
          return `Distribuidor: ${transfer[key].distributor?.user.name}`
        case 'stock':
          return `Estoque: ${transfer[key].stock?.name}`
        default:
          return '-'
      }
    },
    [transfer],
  )

  const statusText = useMemo(() => {
    if (!transfer) return null
    const statuses = {
      canceled: 'Cancelado',
      denied: 'Negado',
      finished: 'Finalizado',
      pending: 'Pendente',
    }
    return statuses[transfer.status] || '-'
  }, [transfer])

  const from = useMemo(
    () => getWalletName('wallet_sender', transfer?.wallet_sender.owner_type),
    [getWalletName, transfer],
  )

  const recipient = useMemo(
    () =>
      getWalletName('wallet_recipient', transfer?.wallet_recipient.owner_type),
    [getWalletName, transfer],
  )

  const formattedCreatedAt = useMemo(() => {
    if (!transfer) return '-'
    return customFormatDate({
      date: transfer.created_at.toString(),
      format: DATE_FORMAT_DMY_HOUR_MINUTE,
    })
  }, [transfer])

  const formattedUpdatedAt = useMemo(() => {
    if (!transfer) return '-'
    return customFormatDate({
      date: transfer.updated_at.toString(),
      format: DATE_FORMAT_DMY_HOUR_MINUTE,
    })
  }, [transfer])

  return (
    <Styled.Grid container spacing={3}>
      <Styled.Grid sm={12} item>
        <Styled.Card>
          <TransferHeader />

          <Styled.Grid container spacing={2}>
            <DetailsPageItem title="Ordem" sm={2} md={3}>
              {transfer?.order_number}
            </DetailsPageItem>

            <DetailsPageItem title="Origem" sm={5} md={4}>
              {from}
            </DetailsPageItem>

            <DetailsPageItem title="Destino" sm={5} md={4}>
              {recipient}
            </DetailsPageItem>
          </Styled.Grid>

          <Styled.Grid container spacing={2}>
            <DetailsPageItem title="Quantidade de chips" sm={2} md={3}>
              {transfer?.chips?.length}
            </DetailsPageItem>

            <DetailsPageItem title="Status" sm={4}>
              {statusText}
            </DetailsPageItem>

            <DetailsPageItem title="Intervalo de iccids" sm={3}>
              <Styled.ViewList onClick={handleOpenModal}>
                Visualizar lista
              </Styled.ViewList>
            </DetailsPageItem>
          </Styled.Grid>

          <Styled.Grid container spacing={2}>
            <DetailsPageItem title="Criado em" sm={5} md={4}>
              {formattedCreatedAt}
            </DetailsPageItem>

            <DetailsPageItem title="Atualizado em" sm={5} md={4}>
              {formattedUpdatedAt}
            </DetailsPageItem>
          </Styled.Grid>
          <TransferButtons />
        </Styled.Card>
      </Styled.Grid>
    </Styled.Grid>
  )
}

export default memo(DetailsComponent)
