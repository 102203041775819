import Button from 'components/Button'
import styled from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

export const Container = styled(MUGrid)`
  margin: 15px 0px;
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  justify-content: space-between;
  align-items: center;
`

export const Main = styled(MUGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ContainerLastStep = styled(Container)`
  margin-top: 50px;
`
export const SendFormButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.success.light};
  color: ${({ theme }) => theme.palette.success.contrastText};
  :hover {
    background-color: ${({ theme }) => theme.palette.success.light};
  }
  @media (max-width: 1000px) {
    margin-top: 10px;
    width: 100%;
  }
`
export const ContainerRightButton = styled(MUGrid)`
  display: flex;
  justify-content: flex-end;
`
export const NextAndRevisionButton = styled(Button)`
  height: ${({ theme }) => theme.spacing(5)}px;
  @media (max-width: 1000px) {
    margin-top: 20px;
    width: 100%;
  }
`
