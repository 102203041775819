import { FC, useEffect, useState } from 'react'

import ComboBox from 'components/SelectStores'
import { Options } from 'components/SelectStores/props'
import { useAuth } from 'hooks/auth'
import { useChipsCashFlow } from 'hooks/ChipsCashFlow'

import { WalletComboProps } from './props'

export const AtualWallet: FC<WalletComboProps> = props => {
  const { iccid, setOption } = props
  const [IDS, setIds] = useState<Options[]>([])
  const { data, getWalletICCID } = useChipsCashFlow()
  const { user } = useAuth()
  useEffect(() => {
    if (user) {
      getWalletICCID({ status_eq: 'available' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (data) {
      const iccids = data.map(chip => {
        return { name: chip.iccid, id: chip.id, labelName: chip.id }
      })
      setIds(iccids)
    }

    return () => {
      setIds([])
    }
  }, [data])
  return (
    <ComboBox
      width={'300px'}
      options={IDS}
      labelName={'ICCID'}
      value={iccid}
      setOption={setOption}
    />
  )
}
