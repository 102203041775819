import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
  useMemo,
} from 'react'

import { api } from 'services/api'
import isMultiple from 'utils/isMultiple'

import { ICCIDContextData, ICCIDSActions } from './props'
import stocksReducer, { initialState } from './reducer'

const initialValue = {} as ICCIDContextData

export const ICCIDSContext = createContext(initialValue)

export const ICCIDSProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(stocksReducer, initialState)

  const isValidQuantity = useMemo(() => isMultiple.five(state.quantityChips), [
    state.quantityChips,
  ])

  const countICCIDS = useCallback(
    async (first_iccid: string, last_iccid: string) => {
      try {
        dispatch({ type: ICCIDSActions.RequestICCIDS })

        const response = await api
          .get('/chips/iccids', {
            params: {
              first_iccid,
              last_iccid,
            },
          })
          .catch(error => {
            throw error
          })

        dispatch({
          type: ICCIDSActions.RequestSuccessICCIDS,
          payload: response,
        })
      } catch (error) {
        dispatch({
          type: ICCIDSActions.RequestErrorICCIDS,
        })
      }
    },
    [],
  )

  return (
    <ICCIDSContext.Provider
      value={{
        ...state,
        countICCIDS,
        isValidQuantity,
      }}
    >
      {children}
    </ICCIDSContext.Provider>
  )
}

export function useICCIDS(): ICCIDContextData {
  const context = useContext(ICCIDSContext)

  if (!context || context === initialValue) {
    throw new Error('useICCIDS must be used within a ICCIDSProvider')
  }

  return context
}
