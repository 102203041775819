import React from 'react'

import { useChipsCashFlow } from 'hooks/ChipsCashFlow'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import { chipValues } from '../dataValues'
import { SelectValue } from './styles'
const Value = () => {
  const { chipValue, setChipValue } = useChipsCashFlow()
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChipValue(event.target.value as string)
  }
  return (
    <FormControl variant="outlined">
      <InputLabel>Valor do chip</InputLabel>
      <SelectValue value={chipValue} onChange={handleChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {chipValues.map(item => (
          <MenuItem value={item} key={`${item}`}>
            {item}
          </MenuItem>
        ))}
      </SelectValue>
    </FormControl>
  )
}

export default Value
