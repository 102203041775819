import React from 'react'

import { useChipsCashFlow } from 'hooks/ChipsCashFlow'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import { valuesSaleTypes } from '../dataValues'
import { SelectType } from './styles'

const SaleType = () => {
  const { saleType, setSaleType } = useChipsCashFlow()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSaleType(event.target.value as string)
  }
  return (
    <FormControl variant="outlined">
      <InputLabel>Tipo de venda</InputLabel>
      <SelectType value={saleType} onChange={handleChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {valuesSaleTypes.map(item => (
          <MenuItem value={item.labelName} key={`${item.labelName}`}>
            {item.name}
          </MenuItem>
        ))}
      </SelectType>
    </FormControl>
  )
}

export default SaleType
