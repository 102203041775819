import React, { FC, useMemo } from 'react'

import loginImage from 'assets/images/login.png'
import Route from 'components/Route'
import {
  RecoverPasswordProvider,
  useRecoverPassword,
} from 'hooks/auth/password/RecoverPassword'
import withContext from 'utils/withContext'

import Footer from '../components/Footer'
import Form from './components/Form'
import FormCompleted from './components/FormCompleted'
import Info from './components/Info'
import {
  Container,
  MainCard,
  Presentation,
  PresentationMd,
  FormContainer,
} from './styles'

const RecoverPassword: FC = () => {
  const { status } = useRecoverPassword()

  const content = useMemo(() => {
    if (status.success) return <FormCompleted />
    return <Form />
  }, [status.success])

  return (
    <Route title="Recuperar Senha" access="guest">
      <Container>
        <PresentationMd>
          <Info />
        </PresentationMd>

        <MainCard>
          <Presentation>
            <Info />

            <img src={loginImage} alt="Pessoa ao computador" />
          </Presentation>

          <hr />

          <FormContainer>
            {content}

            <Footer />
          </FormContainer>
        </MainCard>
      </Container>
    </Route>
  )
}

export default withContext(RecoverPassword, RecoverPasswordProvider)
