import React, { FC, memo } from 'react'

import Typography from 'components/Typography'

import Grid from '@material-ui/core/Grid'

import { DetailsPageItemProps } from './props'

const DetailsPageItem: FC<DetailsPageItemProps> = props => {
  const { title, children, capitalize, ...rest } = props

  return (
    <Grid xs={12} item {...rest}>
      <Typography color="initial" variant="subtitle1">
        {title}
      </Typography>
      <Typography color="primary" variant="h6" capitalize={capitalize}>
        {children}
      </Typography>
    </Grid>
  )
}

export default memo(DetailsPageItem)
