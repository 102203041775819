import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useState,
} from 'react'

import { format } from 'date-fns'
import { useToast } from 'hooks/toast'
import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import { GenerateCommissionProps, DataGenerateCommissionProps } from './props'

const initialValue = {} as GenerateCommissionProps

const GenerateCommissionContext = createContext(initialValue)

const GenerateCommissionProvider: FC = props => {
  const { children } = props
  const { addToast } = useToast()

  const [status, setStatus] = useState<RequestStatus>({
    error: false,
    loading: false,
    success: false,
  })

  const sendGenerateommission = useCallback(
    async (data: DataGenerateCommissionProps) => {
      try {
        setStatus({ error: false, loading: true, success: false })
        const fomatedMonth = format(new Date(data.month), 'MM')
        const fomatedYear = format(new Date(data.year), 'yyyy')

        const newData = {
          month: Number(fomatedMonth),
          year: Number(fomatedYear),
        }

        await api.post('/commissions', newData)

        setStatus({ error: false, loading: false, success: true })
        addToast({
          title: `Comissão do mês ${fomatedMonth}, gerada com sucesso!`,
          type: 'success',
        })
      } catch (error) {
        setStatus({ error: true, loading: false, success: false })
        addToast({
          title: ErrorHandler.getMessage(error),
          type: 'error',
        })
      }
    },
    [addToast],
  )

  return (
    <GenerateCommissionContext.Provider
      value={{ status, sendGenerateommission }}
    >
      {children}
    </GenerateCommissionContext.Provider>
  )
}

export function useGenerateCommission(): GenerateCommissionProps {
  const context = useContext(GenerateCommissionContext)

  if (!context || context === initialValue) {
    throw new Error(
      'useGenerateCommission must be used within a GenerateCommissionProvider',
    )
  }

  return context
}

export default GenerateCommissionProvider
