import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useReducer,
} from 'react'

import { CommissionRequest } from 'interfaces/commission'
import { api } from 'services/api'

import {
  CommissionDetailActions,
  CommissionDetailContextData,
  CommissionDetailResponse,
} from './props'
import CommissionDetailReducer, { initialState } from './reducer'

const initialValue = {} as CommissionDetailContextData

export const CommissionDetailContext = createContext(initialValue)

export const CommissionDetailProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(CommissionDetailReducer, initialState)

  const getCommission = useCallback(async (company_id: string, date) => {
    const [year, month] = date.split('-')

    try {
      dispatch({ type: CommissionDetailActions.Request })

      const params: CommissionRequest = {
        month,
        year,
      }

      const response = await api.get<CommissionDetailResponse>(
        `companies/${company_id}/commissions`,
        {
          params,
        },
      )

      dispatch({
        type: CommissionDetailActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      dispatch({ type: CommissionDetailActions.RequestError })
    }
  }, [])

  const resetState = useCallback(
    () => dispatch({ type: CommissionDetailActions.Reset }),
    [],
  )

  return (
    <CommissionDetailContext.Provider
      value={{ ...state, getCommission, resetState }}
    >
      {children}
    </CommissionDetailContext.Provider>
  )
}

export function useCommissionDetail() {
  const context = useContext(CommissionDetailContext)
  if (!context || context === initialValue) {
    throw new Error(
      'useCommission must be used within a CommissionDetailContext',
    )
  }

  return context
}
