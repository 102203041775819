import DatePickerStyles from 'components/Inputs/DatePicker'
import styled from 'styled-components'

import MUFormControl from '@material-ui/core/FormControl'

export const FormControl = styled(MUFormControl)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  .MuiOutlinedInput-notchedOutline {
    border-width: 1.5px !important;
  }
`

export const Container = styled.div`
  background-color: #f1f3f5;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: end;

  & > div {
    margin-right: 15px;
  }
`

export const DatePicker = styled(DatePickerStyles)``
