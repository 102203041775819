import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
} from 'react'

import { api } from 'services/api'

import {
  UserDetailActions,
  UserDetailContextData,
  UserDetailResponse,
} from './props'
import UsersReducer, { initialState } from './reducer'

const initialValue = {} as UserDetailContextData

export const DetailUserContext = createContext(initialValue)

export const UserDetailProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(UsersReducer, initialState)

  const getUser = useCallback(async (id: string) => {
    try {
      if (!id) {
        throw Error('User ID cannot be empty')
      }

      dispatch({ type: UserDetailActions.Request })

      const response = await api.get<UserDetailResponse>(`/users/${id}`)

      return dispatch({
        type: UserDetailActions.RequestSuccess,
        payload: response,
      })
    } catch (e) {
      return dispatch({ type: UserDetailActions.RequestError })
    }
  }, [])

  const resetState = useCallback(
    () => dispatch({ type: UserDetailActions.Reset }),
    [],
  )

  return (
    <DetailUserContext.Provider
      value={{
        ...state,
        getUser,
        resetState,
      }}
    >
      {children}
    </DetailUserContext.Provider>
  )
}

export function useUserDetail(): UserDetailContextData {
  const context = useContext(DetailUserContext)

  if (!context || context === initialValue) {
    throw new Error('useUserDetail must be used within a UserDetailProvider')
  }

  return context
}
