class Document {
  /**
   * Define o tipo do documento
   * @param document string contendo o valor puro do documento (sem formatação)
   *
   * @return retorna o tipo do documento CPF ou CNPJ
   */
  static type(document: string) {
    const documentDigits = this.removeMask(document)
    if (documentDigits.length === 11) {
      return 'CPF'
    }

    if (documentDigits.length === 14) {
      return 'CNPJ'
    }

    return ''
  }

  /**
   * Aplica mascara no documento
   * @param document string contendo o valor puro do documento (sem formatação)
   *
   * @return documento formatado
   */
  static format(document: string) {
    if (document.length === 11) {
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }

    if (document.length === 14) {
      return document.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5',
      )
    }

    return document
  }

  /**
   * Remove mascara no documento
   * @param document string o documento CPF ou CNPJ
   *
   * @return uma string contendo apenas os digitos do documento
   */
  static removeMask(document: string) {
    return document.replace(/\D/g, '')
  }
}

export default Document
