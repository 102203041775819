export const initialStore = {
  name: '',
  id: '_storeChips',
  labelName: '',
}
export const initialSeller = {
  name: '',
  user_id: '_sellerId',
  role_name: '_defaultRole',
}
export const initialCCID = { name: '', id: '', labelName: '_defaultIccdlabel' }
export const initialCCIDChange = {
  name: '',
  id: '',
  labelName: '_defaultChangeIccdlabel',
}
export const initialCategory = {
  name: '',
  id: '',
  labelName: '_defaultcategory',
}
export const initialStatus = {
  name: '',
  id: '',
  labelName: '_defaultStatus',
}
