import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export const GridTitleSectionVariant1 = styled(Grid)`
  margin-top: 20px;
`
export const GridTitleSectionVariant2 = styled(Grid)`
  margin-top: 10px;
`
export const TitleSection = styled(Typography)`
  font-weight: 500;
  line-height: 1px;
`
export const ItemSectionDescription = styled(Typography)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey['500']};
`
