import React, { FC, useState, useEffect, useMemo, memo } from 'react'

import RenderWithRoles from 'components/RenderWithRoles'
import { useAuth } from 'hooks/auth'

import { useProduct } from '../../../../context'
import AutocompleteCompanies from '../AutocompleteCompanies'
import {
  OPTIONS_SELECT_ALL,
  OPTIONS_SELECT_DISTRIBUTOR,
  TypesFilters,
} from './options'
import * as Styled from './styles'

const FormTypesUsers: FC = () => {
  const [typeFilter, setTypeFilter] = useState<TypesFilters>('')
  const { user } = useAuth()

  const OPTIONS = useMemo(() => {
    if (user?.role.name === 'distributor') {
      setTypeFilter('partner')
      return OPTIONS_SELECT_DISTRIBUTOR
    }

    setTypeFilter('all')
    return OPTIONS_SELECT_ALL
  }, [user?.role.name])

  const { getChart, setDataChartParams, dataChartParams } = useProduct()

  const autocompleteCompanies = useMemo(() => {
    if (typeFilter === 'name') {
      return (
        <Styled.Container item xs={12} sm={12} md={6}>
          <AutocompleteCompanies />
        </Styled.Container>
      )
    }
    return null
  }, [typeFilter])

  useEffect(() => {
    dataChartParams.company_id_eq = undefined
    dataChartParams.company_type_eq = typeFilter
    dataChartParams.report_type = 'recharges'

    setDataChartParams(dataChartParams)

    if (typeFilter !== 'name') {
      getChart()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilter])

  return (
    <RenderWithRoles roles={['admin', 'analyst', 'dev', 'distributor']}>
      <Styled.Span>Filtre por tipo de revendedor:</Styled.Span>
      <Styled.Container container spacing={2} style={{ marginTop: 10 }}>
        <Styled.Container item xs={12} sm={4} md={4}>
          <Styled.Select
            name="company_type_eq"
            value={typeFilter}
            onChange={event => {
              setTypeFilter(event.target.value as TypesFilters)
            }}
            options={OPTIONS}
          />
        </Styled.Container>
        {autocompleteCompanies}
      </Styled.Container>
    </RenderWithRoles>
  )
}

export default memo(FormTypesUsers)
