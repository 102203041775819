import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import { UserState, UserReducerActionMap, UserDetailActions } from './props'

const initialState: UserState = {
  requestStatus: {
    error: false,
    loading: false,
  },
  user: null,
}

const UserDetailReducer: Reducer<UserState, UserReducerActionMap> = (
  state,
  action,
) => {
  switch (action.type) {
    case UserDetailActions.Reset:
      return initialState

    case UserDetailActions.Request:
      state.requestStatus.loading = true
      return state

    case UserDetailActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.user = null
      return state

    case UserDetailActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.user = action.payload.data
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(UserDetailReducer)
