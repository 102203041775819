import React, { FC } from 'react'

import TableHead from 'components/TableHead'
import { useAuth } from 'hooks/auth'
import { useSale } from 'hooks/sales'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const SalesListHeader: FC = () => {
  const { user } = useAuth()

  const { getSales, startDate, endDate, setEndDate, setStartDate } = useSale()

  return (
    <TableHead
      title={
        user?.role.name === 'company_representative'
          ? 'Todas as compras'
          : 'Todas as vendas'
      }
      onClickFilter={getSales}
      filter={[
        {
          label: 'Data de inicio',
          type: 'date',
          // @ts-ignore
          onChange: setStartDate,
          value: startDate,
          maxDate: endDate,
        },
        {
          label: 'Data final',
          type: 'date',
          // @ts-ignore
          onChange: setEndDate,
          value: endDate,
        },
      ]}
    >
      <TableRow>
        <TableCell>
          Data da{' '}
          {user?.role.name === 'company_representative' ? 'Compra' : 'Venda'}
        </TableCell>
        <TableCell>Responsável</TableCell>
        <TableCell>Empresa</TableCell>
        <TableCell>Cidade</TableCell>
        <TableCell colSpan={2}>Valor total</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default SalesListHeader
