import styled from 'styled-components'

import CardMU from '@material-ui/core/Card'
import TypographyMU from '@material-ui/core/Typography'

export const Card = styled(CardMU)`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  margin-bottom: 48px;
`

export const Typography = styled(TypographyMU)`
  font-size: 32px;
  font-weight: 600;
`
export const TypographyTitle = styled(TypographyMU)`
  font-size: 18px;
  font-weight: 400;
`
