import React from 'react'

import TableHead from 'components/TableHead'
import { useBonus } from 'hooks/Bonus'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const BonusList: React.FC = () => {
  const { setStatus, status } = useBonus()

  return (
    <TableHead
      title="Todas as Bonificações"
      filter={[
        {
          type: 'select',
          options: [
            {
              label: 'Pago',
              value: 'outcome',
            },
            {
              label: 'Não pago',
              value: 'income',
            },
            {
              label: 'Todos',
              value: undefined,
            },
          ],
          label: 'Status',
          value: status,
          // @ts-ignore
          onChange: setStatus,
        },
      ]}
    >
      <TableRow>
        <TableCell>Data</TableCell>
        <TableCell align="center">Quantidade a receber</TableCell>

        <TableCell align="center">Status</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default BonusList
