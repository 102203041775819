import React, { FC } from 'react'

import Card from 'components/Card'
import TextField from 'components/Inputs/TextField'
import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import { Form, Formik } from 'formik'
import NewDistributorProvider, {
  useAddDistributor,
} from 'hooks/distributors/create'
import { DataFormAddDistributorType } from 'hooks/distributors/create/props'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import Grid from '@material-ui/core/Grid'

import Checkbox from '../components/Checkbox'
import ButtonSubmit from './components/ButtonSubmit'
import schema from './schemas'
import * as Styled from './styles'

const initialValues: DataFormAddDistributorType = {
  name: '',
  email: '',
  phone: '',
  company_who_can_sell: '',
}

const NewDistributor: FC = () => {
  const { addDistributor, requestStatus } = useAddDistributor()

  return (
    <Route
      title="Novo Distribuidor"
      access="auth"
      requireRoles={['admin', 'dev']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Distribuidores', link: RoutingService.DISTRIBUTORS },
          { title: 'Novo Distribuidor' },
        ]}
        activeOption="distributors"
      >
        <PageTitle title="Novo distribuidor" />
        <Card>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            validateOnMount
            onSubmit={addDistributor}
          >
            <Form>
              <Grid container spacing={3}>
                <Grid item xl={12} md={12} sm={12} xs={12}>
                  <Styled.TitlePanel variant="body1">
                    dados do distribuidor
                  </Styled.TitlePanel>
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <TextField name="name" label="Nome" autoFocus />
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <TextField name="email" label="E-mail" type="email" />
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <TextField
                    name="phone"
                    label="Telefone *"
                    placeholder="DDD + Número"
                    mask="(99) 99999-9999"
                  />
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <Styled.LabelTypeCompany variant="body2">
                    Pode vender para revendedores:
                  </Styled.LabelTypeCompany>
                  <Styled.ContainerOptions>
                    <Checkbox name="company_who_can_sell" value="franchisee">
                      franqueadas
                    </Checkbox>
                    <Checkbox name="company_who_can_sell" value="partner">
                      parceiras
                    </Checkbox>
                  </Styled.ContainerOptions>
                </Grid>

                <Grid item xl={12} md={12} sm={12} xs={12}>
                  <Styled.ButtonContainer>
                    <ButtonSubmit loading={requestStatus.loading} />
                  </Styled.ButtonContainer>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Card>
      </LayoutProduct>
    </Route>
  )
}

export default withContext(NewDistributor, NewDistributorProvider)
