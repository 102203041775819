import { AxiosResponse } from 'axios'
import { ActionMapData } from 'hooks/props'
import { RequestStatus } from 'interfaces/common'
import { SaleDetail } from 'interfaces/sale'

export enum SaleDetailActions {
  Request = 'REQUEST',
  RequestSuccess = 'REQUEST_SUCCESS',
  RequestError = 'REQUEST_ERROR',
  Reset = 'RESET',
}

export type SaleState = {
  requestStatus: RequestStatus
  sale: SaleDetail | null
}

export type SalePayload = {
  [SaleDetailActions.Request]: undefined
  [SaleDetailActions.RequestSuccess]: AxiosResponse<SaleDetail>
  [SaleDetailActions.RequestError]: undefined
  [SaleDetailActions.Reset]: undefined
}

export type SaleReducerActionMap = ActionMapData<SalePayload>

export type SaleDetailContextData = {
  sale: SaleDetail | null
  requestStatus: RequestStatus

  /**
   * Recebe os dados da API
   */
  getSale(id: string): Promise<void>

  /**
   * Retorna ao estado inicial
   */
  resetState(): void
}
