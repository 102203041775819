import { useCallback, useState } from 'react'

import { api } from 'services/api'

import { RecipientTypeOptions } from '../../context/props'

const ENDPOINTS = {
  stock: '/stocks',
  distributor: '/distributors',
  company: '/companies',
}

type Params = {
  name_contains?: string
}

export const useOptions = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })
  const [options, setOptions] = useState([])

  const getOptions = useCallback(
    async (type: RecipientTypeOptions, name_contains?: string) => {
      try {
        setStatus({
          error: false,
          loading: true,
        })

        const params: Params = {
          name_contains: undefined,
        }
        if (name_contains) {
          params.name_contains = name_contains
        }

        const response = await api.get(ENDPOINTS[type], {
          params,
        })

        setOptions(response.data.data)

        setStatus({
          error: false,
          loading: false,
        })
      } catch (e) {
        setOptions([])
        setStatus({
          error: true,
          loading: false,
        })
      }
    },
    [],
  )

  return { status, options, getOptions }
}
