import React, { FC, memo } from 'react'

import Card from 'components/Card'
import Typography from 'components/Typography'

import * as Styled from './styles'

const ErrorDetais: FC = () => {
  return (
    <Card>
      <Styled.Grid>
        <Typography>Ocorreu um erro ao carregar</Typography>
      </Styled.Grid>
    </Card>
  )
}

export default memo(ErrorDetais)
