import ButtonProps from 'components/Button'
import styled from 'styled-components'

import ButtonPure from '@material-ui/core/Button'

export const ButtonAnnex = styled(ButtonPure)`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  :hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  text-transform: none;
  width: 170px;
  height: 36px;
`

export const ButtonOpenAnnex = styled(ButtonPure)`
  text-transform: none;
  border-radius: 6px;
  min-width: 130px;
  height: 36px;
`

export const ButtonDetails = styled(ButtonProps)`
  width: 130px;
  height: 36px;
`
