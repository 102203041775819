import styled from 'styled-components'

import { Select, Button } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'

export const SelectType = styled(Select)`
  width: 410px;
  height: 42px;
  margin-top: 8px;
  margin-bottom: 15px;
  margin-right: 10px;
`

export const ActionButton = styled(Button)`
  width: 188px;
  height: 42px;
  margin-bottom: 8px;
  margin-left: 24px;
`
export const StepButton = styled(Button)`
  width: 188px;
  height: 42px;
  margin-top: 58px;
  margin-left: 24px;
`
export const Label = styled(InputLabel)`
  font-size: 20px;
`
