import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import RequestError from 'components/DetailsPages/RequestError'
import LayoutProduct from 'components/LayoutProduct'
import Loading from 'components/Loading'
import ModalListIntervalIccids from 'components/Modals/ListIntervalIccids'
import ListIntervalIccidsProvider from 'components/Modals/ListIntervalIccids/context'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import {
  TransferDetailProvider,
  useTransferDetail,
} from 'hooks/transfers/details'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import DetailsComponent from './components/DetailsComponent'

const CompanyDetails: FC = () => {
  const { transferId } = useParams<any>()
  const { getTransfer, requestStatus } = useTransferDetail()

  useEffectOnce(() => {
    getTransfer(transferId)
  })

  const Content = useMemo(() => {
    if (requestStatus.success) return <DetailsComponent id={transferId} />
    if (requestStatus.error) return <RequestError />
    return <Loading />
  }, [requestStatus, transferId])

  return (
    <Route
      title="Transferência"
      access="auth"
      requireRoles={[
        'admin',
        'analyst',
        'dev',
        'stockist',
        'company_representative',
        'distributor',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Transferências', link: RoutingService.TRANSFERS },
          { title: 'Detalhes de Transferência' },
        ]}
        activeOption="transactions"
      >
        <PageTitle title="Detalhes da transferência" showButton={false} />

        {Content}
        <ModalListIntervalIccids />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(
  CompanyDetails,
  TransferDetailProvider,
  ListIntervalIccidsProvider,
)
