import { animated } from 'react-spring'

import styled, { css } from 'styled-components'
import { smallShadow } from 'styles/mixins/shadows'

interface ContainerProps {
  type?: 'success' | 'error' | 'info'
}

const toastTypeVariations = {
  info: css`
    background: #0096d7;
    color: ${({ theme }) => theme.palette.common.white};
  `,

  success: css`
    background: #61bf33;
    color: ${({ theme }) => theme.palette.common.white};
  `,

  error: css`
    background: #f44444;
    color: ${({ theme }) => theme.palette.common.white};
  `,
}

export const Container = styled(animated.div)<ContainerProps>`
  ${smallShadow}
  width: 100%;

  border-radius: 6px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  & + div {
    margin-top: 5px;
  }

  ${({ type }) => toastTypeVariations[type || 'info']}

  > svg {
    margin: 4px 12px 0 0;
    margin-top: 0;
    align-self: flex-start;
  }

  div {
    flex: 1;

    strong {
      margin-left: ${({ theme }) => theme.spacing(2)}px;
    }

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
      margin-left: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  button {
    border: 0;
    background: transparent;
    color: inherit;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 350px;
  }
`

export const Icon = styled.img``
