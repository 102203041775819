import React, { FC, memo } from 'react'

import TableHead from 'components/TableHead'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { TransferListHeaderProps } from './props'

const TransferListHeader: FC<TransferListHeaderProps> = props => {
  const { title, type } = props

  const transferLocation = type === 'sent' ? 'Destino' : 'Origem'

  return (
    <TableHead title={title}>
      <TableRow>
        <TableCell>Ordem</TableCell>
        <TableCell>{transferLocation}</TableCell>
        <TableCell>Status</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

export default memo(TransferListHeader)
