import ButtonStyles from 'components/Button'
import styled from 'styled-components'

import ContainerStyles from '@material-ui/core/Container'

export const Container = styled(ContainerStyles)``

export const Main = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 20px 0;
  height: 76px;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 62px;

  h1 {
    color: ${({ theme }) => theme.palette.common.white};
    font-weight: 600;
    font-size: 24px;
  }
`

export const Welcome = styled.div`
  h2 {
    margin-top: 54px;
    font-size: 24px;
    font-weight: normal;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 600;
  }

  h3 {
    font-size: 18px;
    font-weight: normal;
    color: ${({ theme }) => theme.palette.grey[500]};
  }
`

export const ContainerTitle = styled.div`
  h3 {
    color: ${({ theme }) => theme.palette.primary.main};
    margin: 54px 0px ${({ theme }) => theme.spacing(2)}px 0px;
  }
`

export const ProductsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 767px) {
    justify-content: flex-start;
  }
`

export const Footer = styled.div`
  display: flex;
  height: 25px;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Button = styled(ButtonStyles)`
  height: 34px;
`
