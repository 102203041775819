import React, { FC, memo, useMemo } from 'react'

import { Obj } from 'interfaces/common'
import customDateReport from 'utils/formateDateReport'
import formatPrice from 'utils/formatPrice'

import { Page, Document, View, Text } from '@react-pdf/renderer'

import { Recharge } from '../../../../context/props'
import { PrintReportOfRechargesProps } from './props'
import styles from './styles'

const PrintReportOfRecharges: FC<PrintReportOfRechargesProps> = ({ data }) => {
  const customTypeRevendedor: Obj = useMemo(
    () => ({
      all: 'todos',
      partner: 'parceira',
      franchisee: 'franqueada',
    }),
    [],
  )

  const date_gt = customDateReport({ date: String(data?.date_gt) })

  const date_lt = customDateReport({ date: String(data?.date_lt) })

  return (
    <Document>
      <Page size="A4">
        <View style={styles.container}>
          <View style={styles.containerTitle}>
            <Text style={styles.textTitle}>Relatório de Recargas</Text>
          </View>

          <Text style={styles.textValueItem}>
            Tipo de revendedor:{' '}
            {customTypeRevendedor[String(data?.company_type_eq)]}
          </Text>
          <Text style={styles.textValueItem}>
            Período de {date_gt} á {date_lt}
          </Text>

          <Text style={styles.textValueItem}>
            Quantidade de recargas: {data?.total_recharges_quantity}
          </Text>
          <Text style={styles.textValueItem}>
            Valor apurado:{' '}
            {formatPrice({ price: Number(data?.total_recharges_value) })}
          </Text>
        </View>

        <View style={styles.containerTable}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1Header}>
                <Text style={styles.tableCellHeader}>Recargas</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Quantidade</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Valor liquído</Text>
              </View>
            </View>

            {data?.recharges?.map((recharge: Recharge) => (
              <View key={recharge.total_value} style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{recharge.value}</Text>
                </View>

                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{recharge.quantity}</Text>
                </View>

                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>
                    {formatPrice({ price: recharge.total_value })}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default memo(PrintReportOfRecharges)
