import React, { FC, memo, useMemo } from 'react'

import { useLayoutProduct } from 'components/LayoutProduct/context'

import Option from '../Option'
import { MenuOptionsProps } from './props'
import * as Styled from './styles'

const Options: FC<MenuOptionsProps> = props => {
  const { activeOption, menuTemplate } = props
  const { menuOptions, setMenuTemplate } = useLayoutProduct()

  const renderOptions = useMemo(() => {
    if (menuTemplate === 'cashflow') {
      setMenuTemplate(menuTemplate)
    }

    if (!menuOptions) return null

    return menuOptions.map((item, key) => {
      const active = item.optionKey === activeOption
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Option key={key} {...item} active={active}>
          {item.name}
        </Option>
      )
    })
  }, [activeOption, menuOptions, menuTemplate, setMenuTemplate])

  return (
    <Styled.Container className="sidebar__container">
      {renderOptions}
    </Styled.Container>
  )
}

export default memo(Options)
