import React, { FC, memo } from 'react'

import TableHead from 'components/TableHead'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const PurchaseListHeader: FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Data</TableCell>
        <TableCell>Valor</TableCell>
        <TableCell colSpan={2}>Status</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default memo(PurchaseListHeader)
