import { useCallback, useState } from 'react'

import { api } from 'services/api'

export type State = {
  id: string
  created_at: string | Date
  updated_at: string | Date
  name: string
  initials: string
  ibge_id: number
}

type Params = {
  name_contains?: string
}

export const useStates = () => {
  const [states, setStates] = useState<State[]>([])
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })

  const getStates = useCallback(async (name_contains?: string) => {
    try {
      setStatus({
        error: false,
        loading: true,
      })
      const params: Params = {
        name_contains: undefined,
      }
      if (name_contains) {
        params.name_contains = name_contains
      }

      const response = await api.get('/states', {
        params,
      })

      setStates(response.data.data)

      setStatus({
        error: false,
        loading: false,
      })
    } catch (e) {
      setStates([])
      setStatus({
        error: true,
        loading: false,
      })
    }
  }, [])

  return { states, getStates, status }
}
