import React, { FC, useCallback, useMemo, memo } from 'react'
import { useParams } from 'react-router-dom'

import Typography from 'components/Typography'
import { useStepper } from 'hooks/formStepper'

import Grid from '@material-ui/core/Grid'

import { useFormCompany } from '../../context'
import { ControllerStepsProps } from './props'
import * as Styled from './styles'

const ControlerSteps: FC<ControllerStepsProps> = props => {
  const { isLastStep } = props
  const { goToStep, step } = useStepper()
  const { companyId } = useParams<any>()

  const {
    addCompany,
    actualStepForm,
    typeForm,
    updateCompany,
    setActualStepForm,
  } = useFormCompany()

  const reviseData = useCallback(() => {
    setActualStepForm(0)
    goToStep(0)
  }, [setActualStepForm, goToStep])

  // Verifica se a etapa atual do form é de revisão.
  const renderLabelButton = useMemo(
    () => (step < actualStepForm && actualStepForm > 0 ? 'Revisar' : 'Próximo'),
    [actualStepForm, step],
  )

  const renderSubmitButtonLabel = useMemo(() => {
    if (typeForm === 'update') return 'Atualizar revendedor'

    return 'Adicionar revendedor'
  }, [typeForm])

  const submitMethod = useMemo(() => {
    if (typeForm === 'update') return () => updateCompany(companyId)

    return addCompany
  }, [addCompany, companyId, typeForm, updateCompany])

  if (isLastStep) {
    return (
      <Styled.Container item lg={12} md={12} sm={12} xs={12}>
        <Styled.Main container justify="space-between">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Styled.NextAndRevisionButton
              variant="outlined"
              color="primary"
              autoCapitalize=""
              onClick={reviseData}
            >
              Revisar
            </Styled.NextAndRevisionButton>
          </Grid>
          <Styled.ContainerRightButton item lg={6} md={6} sm={12} xs={12}>
            <Styled.SendFormButton
              type="submit"
              id="send-form"
              variant="contained"
              autoCapitalize=""
              onClick={submitMethod}
            >
              {renderSubmitButtonLabel}
            </Styled.SendFormButton>
          </Styled.ContainerRightButton>
        </Styled.Main>
      </Styled.Container>
    )
  }

  return (
    <Styled.Container item lg={12} md={12} sm={12} xs={12}>
      <Styled.Main container justify="space-between">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography variant="caption">* Campo obrigatório</Typography>
        </Grid>
        <Styled.ContainerRightButton item lg={6} md={6} sm={12} xs={12}>
          <Styled.NextAndRevisionButton
            variant="outlined"
            color="primary"
            autoCapitalize=""
            type="submit"
          >
            {renderLabelButton}
          </Styled.NextAndRevisionButton>
        </Styled.ContainerRightButton>
      </Styled.Main>
    </Styled.Container>
  )
}

export default memo(ControlerSteps)
