import React, { FC, memo } from 'react'

import Card from 'components/Card'

import { ReportDetailsProps } from '../../props'
import Table from '../Table'
import * as Styled from './styles'

const DetailsComponent: FC<ReportDetailsProps> = ({ data }) => {
  return (
    <Card shadow>
      <Styled.Root>
        <Table data={data} />
      </Styled.Root>
    </Card>
  )
}

export default memo(DetailsComponent)
