import React, { FC, useMemo } from 'react'

import { isAfter } from 'date-fns'

import { Typography } from '@material-ui/core'

import { DateFilterProps } from './props'
import * as Styled from './styles'

export const DateFilter: FC<DateFilterProps> = props => {
  const { onEndDateChange, onStartDateChange, endDate, startDate } = props

  const today = useMemo(() => new Date(), [])

  const maxStartDate = useMemo(() => {
    if (!endDate || isAfter(endDate, today)) return today
    return endDate
  }, [endDate, today])

  const minEndDate = useMemo(() => startDate || undefined, [startDate])

  return (
    <Styled.Container>
      <Typography color="primary">Filtrar por período</Typography>

      <Styled.DatePicker
        InputLabelProps={{
          shrink: true,
        }}
        value={startDate}
        onChange={onStartDateChange}
        format="dd/MM/yyyy"
        maxDate={maxStartDate}
      />

      <Styled.DatePicker
        InputLabelProps={{
          shrink: true,
        }}
        value={endDate}
        onChange={onEndDateChange}
        format="dd/MM/yyyy"
        minDate={minEndDate}
        maxDate={today}
      />
    </Styled.Container>
  )
}
