import React, { FC, useMemo } from 'react'

import Card from 'components/Card'
import Chart from 'components/Chart'
import { useRecharge } from 'hooks/recharges'
import { useTheme } from 'styled-components'

import { CircularProgress } from '@material-ui/core'

import * as Styled from './styles'

const RechargesLineChart: FC = () => {
  const { data, requestStatus } = useRecharge()
  const { palette } = useTheme()

  const labels = useMemo(() => {
    if (data.length == 0) return []
    return data.map(item => item.date)
  }, [data])

  const datasets = useMemo<Chart.ChartDataSets[]>(() => {
    if (data.length == 0) return []
    const chartDataSet: Chart.ChartDataSets[] = [
      {
        label: 'Total em Recargas',
        borderColor: palette.primary.main,
        backgroundColor: 'rgba(0,0,0,0)',
        data: data.map(item => item.total_of_day),
      },
    ]

    return chartDataSet
  }, [data, palette])

  if (requestStatus.loading) {
    return (
      <Styled.Container>
        <Card>
          <Styled.Center>
            <CircularProgress />
          </Styled.Center>
        </Card>
      </Styled.Container>
    )
  }

  return (
    <Styled.Container>
      <Card shadow>
        <Chart
          type="line"
          data={{
            labels,
            datasets,
          }}
          options={{
            responsive: true,
            scales: {
              xAxes: [
                {
                  stacked: false,
                },
              ],
              yAxes: [
                {
                  labels: ['Total'],
                  ticks: {
                    beginAtZero: true,
                    // Include a dollar sign in the ticks
                    callback(value) {
                      return `R$ ${value}`
                    },
                  },
                  stacked: false,
                },
              ],
            },
          }}
        />
      </Card>
    </Styled.Container>
  )
}

export default RechargesLineChart
