import React, { FC } from 'react'

import { useChipsCashFlow } from 'hooks/ChipsCashFlow'

import { MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import { Packagevalues } from '../dataValues'
import { SelectPackage } from './styles'
const Package: FC = () => {
  const { packageRecharge, setPackage } = useChipsCashFlow()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPackage(event.target.value as string)
  }
  return (
    <FormControl variant="outlined">
      <InputLabel>Pacote</InputLabel>
      <SelectPackage value={packageRecharge} onChange={handleChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Packagevalues.map(item => (
          <MenuItem value={item} key={`${item}`}>
            {item}
          </MenuItem>
        ))}
      </SelectPackage>
    </FormControl>
  )
}

export default Package
