import React, { FC, useMemo } from 'react'
import { useTransition } from 'react-spring'

import { Portal } from '@material-ui/core'

import ToastContainerProps from './props'
import { Container } from './styles'
import Toast from './Toast'

const ToastContainer: FC<ToastContainerProps> = ({ messages }) => {
  const messagesWithTransitions = useTransition(
    messages,
    message => message.id,
    {
      from: { right: '-120%', opacity: '0' },
      enter: { right: '0%', opacity: '1' },
      leave: { right: '-120%', opacity: '0' },
    },
  )

  const Toasts = useMemo(() => {
    return messagesWithTransitions.map(({ item, key, props }) => (
      <Toast key={key} style={props} message={item} />
    ))
  }, [messagesWithTransitions])

  return (
    <Portal>
      <Container>{Toasts}</Container>
    </Portal>
  )
}

export default ToastContainer
