import styled from 'styled-components'

import MUGrid from '@material-ui/core/Grid'

export const Container = styled.div``

export const Grid = styled(MUGrid)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`
