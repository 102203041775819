import React, { FC, useMemo, memo } from 'react'

import RenderWithRoles from 'components/RenderWithRoles'
import { useAuth } from 'hooks/auth'
import { useTransferDetail } from 'hooks/transfers/details'

import AcceptButton from '../AcceptButton'
import CancelButton from '../CancelButton'
import DenyButton from '../DenyButton'
import * as Styled from './styles'

const TransferButtons: FC = () => {
  const { user } = useAuth()
  const { transfer, getWalletUserId } = useTransferDetail()

  const isSender = useMemo(() => {
    if (!transfer || !user) return false
    return getWalletUserId(transfer.wallet_sender) === user.id
  }, [getWalletUserId, transfer, user])

  const isReceiver = useMemo(() => {
    if (!transfer || !user) return false
    return getWalletUserId(transfer.wallet_recipient) === user.id
  }, [getWalletUserId, transfer, user])

  const buttons = useMemo(() => {
    if (!transfer || !user) return null
    if (transfer.status !== 'pending') return null
    if (!isSender && !isReceiver) return null
    if (isSender) return <CancelButton />

    return (
      <>
        <AcceptButton />
        <DenyButton />
      </>
    )
  }, [isReceiver, isSender, transfer, user])

  return (
    <Styled.Container>
      <RenderWithRoles roles={['stockist', 'distributor']}>
        {buttons}
      </RenderWithRoles>
    </Styled.Container>
  )
}

export default memo(TransferButtons)
