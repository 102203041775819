import React, {
  FC,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react'

import { useToast } from 'hooks/toast'
import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import { FirstAccessCredentials, FirstAccessContextData } from './props'

const initialValue = {} as FirstAccessContextData

const FirstAccessContext = createContext(initialValue)

export const FirstAccessProvider: FC = props => {
  const { children } = props

  const [status, setStatus] = useState<RequestStatus>({
    loading: false,
    error: false,
    success: false,
  })
  const [
    backupCredentials,
    setBackupCredentials,
  ] = useState<FirstAccessCredentials | null>(null)
  const { addToast } = useToast()

  const resendEmail = useCallback(async () => {
    try {
      if (!backupCredentials) {
        throw new Error('No Credentials previously saved')
      }
      setStatus({
        error: false,
        loading: true,
        success: false,
      })

      await api.post('/password/forgot', backupCredentials)

      setStatus({
        error: false,
        loading: false,
        success: true,
      })
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro de autenticação',
        description: 'Verifique suas credenciais',
      })
      setStatus({
        error: true,
        loading: false,
        success: false,
      })
    }
  }, [addToast, backupCredentials])

  const firstAccess = useCallback(
    async (credentials: FirstAccessCredentials) => {
      try {
        setStatus({
          error: false,
          loading: true,
          success: false,
        })

        await api.post('/password/first-access', credentials)

        setStatus({
          error: false,
          loading: false,
          success: true,
        })
        setBackupCredentials(credentials)
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        setStatus({
          error: true,
          loading: false,
          success: false,
        })
      }
    },
    [addToast],
  )

  return (
    <FirstAccessContext.Provider value={{ status, firstAccess, resendEmail }}>
      {children}
    </FirstAccessContext.Provider>
  )
}

export const useFirstAccess = () => {
  const context = useContext(FirstAccessContext)
  if (!context || context === initialValue) {
    throw new Error('useFirstAccess must be used within a FirstAccessProvider')
  }
  return context
}
