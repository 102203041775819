import React, { memo, useMemo, forwardRef, useCallback } from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import InputMask from 'react-input-mask'

import { useField } from 'formik'

import { CustomTextFieldProps } from './props'
import * as Styled from './styles'

const TextField = forwardRef<HTMLInputElement, CustomTextFieldProps>(
  (props, ref) => {
    const {
      name,
      label,
      icon: Icon,
      'data-testid': dataTestId,
      onBlur,
      onFocus,
      onChange,
      value,
      type,
      mask = '',
      defaultValue,
      disabled,
      id,
      ...rest
    } = props

    const [field, meta, helpers] = useField<string>({
      name,
      type,
      defaultValue,
      id,
      value,
    })

    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        field.onBlur(event)
        if (onBlur) {
          onBlur(event)
        }
        // Quando o input tem máscara, ao sair do input ele não conta o change
        // isso resolve esse problema
        if (mask) {
          helpers.setValue(event.target.value, true)
        }
      },
      [field, helpers, mask, onBlur],
    )

    const errorProps = useMemo(() => {
      if (meta.initialError) {
        return {
          error: true,
          helperText: meta.initialError,
        }
      }
      if (meta.error && meta.touched) {
        return {
          error: true,
          helperText: meta.error,
        }
      }
      return {}
    }, [meta.error, meta.initialError, meta.touched])

    const EndAdornment = useMemo(() => {
      if (!meta.error || !meta.touched) return null

      return <FiAlertCircle color="red" size={20} />
    }, [meta])

    return (
      <Styled.FormControl variant="outlined" fullWidth>
        <InputMask
          mask={mask}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          maskChar={null}
          onBlur={handleBlur}
          onFocus={onFocus}
          defaultValue={defaultValue}
          disabled={disabled}
        >
          {() => (
            <Styled.TextField
              disabled={disabled}
              ref={ref}
              variant="outlined"
              defaultValue={defaultValue}
              inputRef={ref}
              id={name}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label={label}
              type={type}
              onFocus={onFocus}
              {...errorProps}
              {...rest}
              {...field}
              onBlur={handleBlur}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                'data-testid': dataTestId,
                startAdornment: Icon && <Icon size={20} />,
                endAdornment: EndAdornment,
                ...rest.InputProps,
              }}
            />
          )}
        </InputMask>
      </Styled.FormControl>
    )
  },
)

/**
 * Componente precisa ser child de `<Formik>`
 */
export default memo(TextField)
