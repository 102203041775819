import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
  useState,
} from 'react'

import { Options } from 'components/SelectStores/props'
import { useAuth } from 'hooks/auth'
import { OfficeData, SellersData } from 'hooks/salesCashFlow/props'
import { api } from 'services/api'
import {
  paginationReducer,
  PaginationActions,
  PaginationRouteParams,
} from 'services/pagination'
import {
  initialCCID,
  initialCCIDChange,
  initialSeller,
  initialStore,
} from 'utils/initialStateForSelects'
import { rolePermission } from 'utils/permissions'

import { ChipsCashFlow, ChipsContextData, ChipsStatusParams } from './props'
import { ListChipsResponse } from './props'
import { initialState } from './reducer'

const initialValue = {} as ChipsContextData

export const ChipsCashFlowContext = createContext(initialValue)

export const ChipsCashFlowProvider: FC = props => {
  const { children } = props
  const { user } = useAuth()
  const [store, setStore] = useState<OfficeData>(initialStore)
  const [seller, setSeller] = useState<SellersData>(initialSeller)
  const [chipValue, setChipValue] = useState<string>('')
  const [packageRecharge, setPackage] = useState<string>('')
  const [recharge, setRecharge] = useState<string>('')
  const [saleType, setSaleType] = useState<string>('')
  const [ICCID, setICCID] = useState<Options>(initialCCID)
  const [ICCIDChange, setICCIDChange] = useState<Options>(initialCCIDChange)
  const [state, dispatch] = useReducer(
    paginationReducer<ChipsCashFlow>(),
    initialState,
  )
  const permission = rolePermission(user?.role.name)
  const getWalletICCID = useCallback(
    async (props: ChipsStatusParams) => {
      try {
        dispatch({ type: PaginationActions.Request })

        const params: PaginationRouteParams & ChipsStatusParams = {
          page: state.current_page,
          per_page: state.per_page,
        }
        if (props) {
          params.status_eq = props.status_eq
        }
        if (permission == 'sellerPermission') {
          const response = await api.get<ListChipsResponse>(
            `/chips/list-chips`,
            {
              params,
            },
          )
          dispatch({
            type: PaginationActions.RequestSuccess,
            payload: response,
          })
        } else if (permission == 'midlePermission' && state.data.length === 0) {
          const response = await api.get<ListChipsResponse>(
            `/chips/list-chips`,
            {
              params,
            },
          )
          dispatch({
            type: PaginationActions.RequestSuccess,
            payload: response,
          })
        } else {
          const response = await api.get<ListChipsResponse>(
            `/chips/list-chips/${user?.id}/${user?.role.name}`,
            {
              params,
            },
          )
          dispatch({
            type: PaginationActions.RequestSuccess,
            payload: response,
          })
        }
      } catch (e) {
        dispatch({ type: PaginationActions.RequestError })
      }
    },
    [state, permission, user],
  )
  const getWallet = useCallback(
    async (props: ChipsStatusParams) => {
      try {
        dispatch({ type: PaginationActions.Request })

        const params: PaginationRouteParams & ChipsStatusParams = {
          page: state.current_page,
          per_page: state.per_page,
        }
        if (props) {
          params.status_eq = props.status_eq
        }
        if (permission == 'sellerPermission') {
          const response = await api.get<ListChipsResponse>(
            `/chips/list-chips`,
            {
              params,
            },
          )
          dispatch({
            type: PaginationActions.RequestSuccess,
            payload: response,
          })
        } else if (permission == 'midlePermission' && state.data.length === 0) {
          const response = await api.get<ListChipsResponse>(
            `/chips/list-chips`,
            {
              params,
            },
          )
          dispatch({
            type: PaginationActions.RequestSuccess,
            payload: response,
          })
        } else {
          const response = await api.get<ListChipsResponse>(
            `/chips/list-chips/${seller?.user_id}/${seller?.role_name}`,
            {
              params,
            },
          )
          dispatch({
            type: PaginationActions.RequestSuccess,
            payload: response,
          })
        }
      } catch (e) {
        dispatch({ type: PaginationActions.RequestError })
      }
    },
    [state, permission, seller],
  )

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])
  const clearAll = () => {
    setSaleType('')
    setChipValue('')
    setPackage('')
    setRecharge('')
  }

  return (
    <ChipsCashFlowContext.Provider
      value={{
        ...state,
        chipValue,
        packageRecharge,
        recharge,
        saleType,
        store,
        seller,
        ICCID,
        ICCIDChange,
        getWalletICCID,
        setICCIDChange,
        setICCID,
        setSeller,
        setStore,
        clearAll,
        setSaleType,
        setChipValue,
        setPackage,
        setRecharge,
        getWallet,
        nextPage,
        previousPage,
        setLimit,
        setPage,
      }}
    >
      {children}
    </ChipsCashFlowContext.Provider>
  )
}

export function useChipsCashFlow(): ChipsContextData {
  const context = useContext(ChipsCashFlowContext)

  if (!context || context === initialValue) {
    throw new Error(
      'useChipsCashFlow must be used within a ChipsCashFlowProvider',
    )
  }

  return context
}
