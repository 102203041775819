import React, { FC, memo, useCallback, useMemo } from 'react'

import { useField } from 'formik'

import { DatePickerProps } from './props'
import * as Styled from './styles'

const DatePicker: FC<DatePickerProps> = props => {
  const { name, ...rest } = props

  const icon = <img src={require('assets/images/icons/calendar.svg').default} />

  const [field, meta, helpers] = useField<Date | null>(name)

  const handleDateChange = useCallback(
    (date: Date | null) => {
      helpers.setValue(date)
    },
    [helpers],
  )

  const value = useMemo(() => field.value || null, [field.value])

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }

    return {}
  }, [meta.error, meta.initialError, meta.touched])

  return (
    <Styled.FormControl fullWidth>
      <Styled.DatePicker
        name={name}
        variant="inline"
        disableToolbar
        // autoOk
        value={value}
        onChange={handleDateChange}
        disableFuture
        format="dd/MM/yyyy"
        InputProps={{
          endAdornment: icon,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
        {...errorProps}
      />
    </Styled.FormControl>
  )
}

/**
 * Componente precisa ser child de `<Formik>`
 */
export default memo(DatePicker)
