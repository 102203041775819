import styled from 'styled-components'

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-bottom: 0;
  }

  .MuiFormControl-root {
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacing(1)}px;

    &:last-child {
      margin-right: 0%;
    }
  }
`
