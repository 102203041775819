import { useCallback, useState } from 'react'

import { CreatedAndUpdatedDateType } from 'interfaces/common'
import { api } from 'services/api'

export type Company = CreatedAndUpdatedDateType & {
  id: string
  fantasy_name: string
}

type Params = {
  name_contains?: string
}

export const useCompanies = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  })
  const [companies, setCompanies] = useState<Company[]>([])

  const getCompanies = useCallback(async (name_contains?: string) => {
    try {
      setStatus({
        error: false,
        loading: true,
      })

      const params: Params = {
        name_contains: undefined,
      }
      if (name_contains) {
        params.name_contains = name_contains
      }

      const response = await api.get('/companies', {
        params,
      })

      setCompanies(response.data.data)

      setStatus({
        error: false,
        loading: false,
      })
    } catch (e) {
      setCompanies([])

      setStatus({
        error: true,
        loading: false,
      })
    }
  }, [])

  return {
    status,
    companies,
    getCompanies,
  }
}
