import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutProduct from 'components/LayoutProduct'
import PageTitle from 'components/PageTitle'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import { useAuth } from 'hooks/auth'
import { useCompany, CompanyProvider } from 'hooks/company'
import { Company } from 'interfaces/company'
import RoutingService from 'services/routing'
import withContext from 'utils/withContext'

import CompanyListHeader from './components/CompanyListHeader'
import CompanyListItem from './components/CompanyListItem'

const Companies: FC = () => {
  const { push } = useHistory()
  const { hasRole } = useAuth()

  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    listCompanies,
    setLimit,
    nextPage,
    previousPage,
    requestStatus,
    setPage,
  } = useCompany()

  const renderItem = useCallback<TableRenderItem<Company>>(
    item => <CompanyListItem key={item.id} {...item} />,
    [],
  )

  const redirectToNew = useCallback(() => push(RoutingService.NEW_COMPANY), [
    push,
  ])

  const renderPageTitle = useMemo(() => {
    if (hasRole(['distributor', 'admin'])) {
      return (
        <PageTitle
          title="Revendedores"
          showButton
          buttonText="Novo Revendedor"
          buttonOnClick={redirectToNew}
        />
      )
    }

    return <PageTitle title="Revendedores" />
  }, [hasRole, redirectToNew])

  return (
    <Route
      title="Revendedores"
      access="auth"
      requireRoles={['analyst', 'admin', 'distributor', 'dev', 'financial']}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          { title: 'Revendedores' },
        ]}
        activeOption="companies"
      >
        {renderPageTitle}
        <Table
          data={data}
          loadingRequest={requestStatus.loading}
          renderItem={renderItem}
          HeaderComponent={<CompanyListHeader />}
          FooterComponent={
            <TablePagination
              getData={listCompanies}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(Companies, CompanyProvider)
