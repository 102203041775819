import React, { FunctionComponent } from 'react'

type WithContextType = (
  Component: FunctionComponent,
  ...Context: FunctionComponent[]
) => FunctionComponent

/**
 * HOC que combina Context Providers
 *
 * @param Component Componente filho do Provider
 * @param Contexts Lista de Componentes que contem Context.Provider
 * @returns Componente com mesclado com o Provider do Contexto usado
 *
 * @example
 * ```js
 * function TestComponent(props){
 *    return <div />
 * }
 * export default withContext(TestComponent, MyContextProvider)
 * export default withContext(TestComponent, MyContextProvider, MyContextProvider2)
 * ```
 */
const withContext: WithContextType = (Component, ...Contexts) => {
  if (Contexts.length === 0) return () => null

  return function WrappedComponent() {
    const ContextTree = Contexts.reduce((a, b) => args =>
      a({ children: b(args) }),
    )

    return (
      <ContextTree>
        <Component />
      </ContextTree>
    )
  }
}

export default withContext
