import React, { FC, memo } from 'react'

import TableRowItem from 'components/TableRowItem'
import format from 'date-fns/format'
import { ChangeChipsCashFlow } from 'hooks/ChangeChipsCashFlow/props'
import { TranslateCategoryReports } from 'utils/Translate'

import TableCell from '@material-ui/core/TableCell'

const ChangeListItem: FC<ChangeChipsCashFlow> = props => {
  const {
    created_at,
    category,
    old_chip: { iccid: old_iccid },
    new_chip: { iccid: new_iccid },
    user,
    name,
    document,
  } = props

  return (
    <TableRowItem>
      <TableCell>{user?.name}</TableCell>
      <TableCell>{format(new Date(created_at), 'dd-MM-yyyy')}</TableCell>
      <TableCell>{TranslateCategoryReports(category)}</TableCell>
      <TableCell>{old_iccid}</TableCell>
      <TableCell>{new_iccid}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{document}</TableCell>
    </TableRowItem>
  )
}

export default memo(ChangeListItem)
