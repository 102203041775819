import React, { FC, memo } from 'react'

import { format } from 'date-fns'
import { ChipsLeavesCashFlow } from 'hooks/ChipsLeavesCashFlow/props'
import { TranslateCategoryReports } from 'utils/Translate'

import { Page, Document, View, Text } from '@react-pdf/renderer'

import { ModalReportsProps } from '../../props'
import styles from './styles'

const PdfLeavesReport: FC<ModalReportsProps> = props => {
  const { data } = props

  return (
    <Document>
      <Page size="A4">
        <View style={styles.container}>
          <Text style={styles.title}>Relatorio de Saidas</Text>
        </View>

        <View style={styles.containerTable}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Data</Text>
                {data.map((item: ChipsLeavesCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {format(new Date(item.created_at), 'dd-MM-yyyy')}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Categoria</Text>
                {data.map((item: ChipsLeavesCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItemSell}>
                    {TranslateCategoryReports(item.category)}
                  </Text>
                ))}
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>ICCID</Text>
                {data.map((item: ChipsLeavesCashFlow) => (
                  <Text key={`${item.id}`} style={styles.textValueItem}>
                    {item.chip.iccid}
                  </Text>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default memo(PdfLeavesReport)
