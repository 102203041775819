import React, { FC, useMemo, useCallback } from 'react'

import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import customFormatPrice from 'utils/formatPrice'

import { useTheme } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import { useProduct } from '../../../context'
import FormCommissionPackage from '../../ReportCommissionPackage/components/FormCommissionPackage'
import * as Styled from './styles'

const CommissionPackage: FC = () => {
  const theme = useTheme()

  const { dataCommission, statusCommission } = useProduct()

  const dataSet = useMemo(() => {
    if (!statusCommission.loading) {
      return dataCommission
    }

    return null
  }, [dataCommission, statusCommission.loading])

  const CustomTooltip = useCallback(({ active, payload, label }) => {
    if (active && payload) {
      return (
        <Styled.ContainerTooltip>
          <Styled.Date>Data: {label}</Styled.Date>
          <Styled.Packages>
            Pacotes:{' '}
            {customFormatPrice({
              price: payload[1]?.value,
              showCurrencyPreffix: false,
            })}
          </Styled.Packages>
          <Styled.Commission>
            Comissão:{' '}
            {customFormatPrice({
              price: payload[0]?.value,
              showCurrencyPreffix: false,
            })}
          </Styled.Commission>
        </Styled.ContainerTooltip>
      )
    }

    return null
  }, [])

  if (statusCommission.loading) {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Styled.Card>
          <CardContent>
            <Grid item>
              <Styled.Skeleton variant="rect" style={{ marginBottom: 20 }} />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" width="100%" height={403} />
            </Grid>
          </CardContent>
        </Styled.Card>
      </Grid>
    )
  }

  if (dataSet == null) return null

  return (
    <Styled.Container>
      <Styled.Content data-testid="container-commissions-chart">
        <Styled.ContentTitle>
          <Styled.TitleGraph variant="h5" color="primary">
            Gráfico de comissão e pacote
          </Styled.TitleGraph>
          <FormCommissionPackage />
        </Styled.ContentTitle>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={dataSet}
            stackOffset="sign"
            margin={{ top: 20, right: 20 }}
          >
            <XAxis dataKey={value => `${value.month}-${value.year}`} />
            <YAxis />

            <Tooltip cursor={{ fill: '#E0E1E6' }} content={<CustomTooltip />} />
            <Legend
              formatter={value => {
                const keyMap = {
                  commissions: 'Comissão',
                  total_plans: 'Pacotes',
                }
                if (keyMap[value]) return keyMap[value]
                return value
              }}
            />

            <Bar
              dataKey="commissions"
              fill={theme.palette.chart.primary.colorPrimary}
              label={{
                position: 'top',
                formatter: value => {
                  return customFormatPrice({ price: Number(value) })
                },
              }}
            />
            <Bar
              dataKey="total_plans"
              fill={theme.palette.chart.primary.colorSecondary}
              label={{
                position: 'top',
                formatter: value => {
                  return customFormatPrice({ price: Number(value) })
                },
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Styled.Content>
    </Styled.Container>
  )
}

export default CommissionPackage
