import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  useReducer,
  useState,
} from 'react'

import { PaginationParamsWithNameContains } from 'interfaces/common'
import { api } from 'services/api'
import { paginationReducer, PaginationActions } from 'services/pagination'

import {
  StockContextData,
  Stock,
  ListStocksResponse,
  QuantityChips,
} from './props'
import { initialState } from './reducer'

const initialValue = {} as StockContextData

export const StockContext = createContext(initialValue)

export const StockProvider: FC = props => {
  const { children } = props

  const [state, dispatch] = useReducer(paginationReducer<Stock>(), initialState)

  const [quantityChips, setQuantityChips] = useState<QuantityChips>({
    total_chips: 0,
    total_pending_chips: 0,
  })

  const getStocks = useCallback(
    async (name_contains?: string) => {
      try {
        dispatch({ type: PaginationActions.Request })

        const params: PaginationParamsWithNameContains = {
          page: state.current_page,
          per_page: state.per_page,
        }

        if (name_contains) {
          params.name_contains = name_contains
        }

        const response = await api.get<ListStocksResponse>('/stocks', {
          params,
        })

        setQuantityChips({
          total_chips: response.data.total_chips,
          total_pending_chips: response.data.total_pending_chips,
        })

        dispatch({
          type: PaginationActions.RequestSuccess,
          payload: response,
        })
      } catch (e) {
        dispatch({ type: PaginationActions.RequestError })
      }
    },
    [state],
  )

  const nextPage = useCallback(() => {
    if (!state.next_page) return
    dispatch({ type: PaginationActions.NextPage })
  }, [state])

  const previousPage = useCallback(() => {
    if (!state.prev_page) return
    dispatch({ type: PaginationActions.PreviousPage })
  }, [state])

  const setLimit = useCallback((limit: number) => {
    dispatch({ type: PaginationActions.SetLimit, payload: limit })
  }, [])

  const setPage = useCallback((page: number) => {
    dispatch({ type: PaginationActions.SetPage, payload: page })
  }, [])

  return (
    <StockContext.Provider
      value={{
        ...state,
        getStocks,
        nextPage,
        previousPage,
        setLimit,
        quantityChips,
        setPage,
      }}
    >
      {children}
    </StockContext.Provider>
  )
}

export function useStock(): StockContextData {
  const context = useContext(StockContext)

  if (!context || context === initialValue) {
    throw new Error('useStock must be used within a StockProvider')
  }

  return context
}
