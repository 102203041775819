import React, { FC, memo, useEffect } from 'react'

import TextField from 'components/Inputs/TextField'
import Typography from 'components/Typography'
import { useFormikContext } from 'formik'
import { EditStockData } from 'hooks/stock/edit/props'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

const FieldsNewStockist: FC = () => {
  const { values, setFieldValue } = useFormikContext<EditStockData>()

  useEffect(() => {
    if (!values.new_stockist) {
      setFieldValue('stockist', undefined)
    }
  }, [setFieldValue, values.new_stockist])

  if (!values.new_stockist) return null

  return (
    <>
      <Typography color="primary" variant="h6" capitalize="uppercase">
        Criar usuário estoquista
      </Typography>

      <Styled.Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField label="Nome" name="stockist.name" />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField label="Email" name="stockist.email" type="email" />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            label="Telefone"
            name="stockist.phone"
            placeholder="DDD + Número"
            mask="(99) 99999-9999"
          />
        </Grid>
      </Styled.Grid>
    </>
  )
}

export default memo(FieldsNewStockist)
