import * as Yup from 'yup'

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .required('E-mail é obrigatório')
    .email('Insira um e-mail válido'),
  phone: Yup.string().required('Telefone é obrigatório'),
})

export default schema
