import React, {
  createContext,
  useCallback,
  useEffect,
  useContext,
  FC,
  useMemo,
  useReducer,
} from 'react'

import { useToast } from 'hooks/toast'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'

import {
  AuthContextData,
  LoginCredentials,
  AuthActions,
  AuthResponse,
  ProfileResponse,
  AuthContextProviderProps,
  RoleName,
} from './props'
import authReducer, { initialState } from './reducer'
import { persistToken } from './storage'

const defaultValue = {} as AuthContextData
const AuthContext = createContext(defaultValue)

export const AuthProvider: FC<AuthContextProviderProps> = props => {
  const { children, initialProps } = props

  const [state, dispatch] = useReducer(
    authReducer,
    initialProps || initialState,
  )

  const { addToast } = useToast()

  const isAuthenticated = useMemo(
    () => state.user != null && state.token != null,
    [state.user, state.token],
  )

  useEffect(() => {
    persistToken(state.token)
    api.defaults.headers.authorization = `Bearer ${state.token?.token}`
    if (state.token) {
      getProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token])

  const signOut = useCallback(() => {
    dispatch({ type: AuthActions.SignOut })
  }, [])

  const getProfile = useCallback(async () => {
    try {
      dispatch({ type: AuthActions.RequestProfile })
      const response = await api.get<ProfileResponse>('profile')
      dispatch({ type: AuthActions.RequestProfileSuccess, payload: response })
    } catch (e) {
      dispatch({ type: AuthActions.RequestProfileError })
      signOut()
    }
  }, [dispatch, signOut])

  const signIn = useCallback(
    async (credentials: LoginCredentials) => {
      try {
        dispatch({ type: AuthActions.RequestUser })

        const response = await api.post<AuthResponse>('sessions', credentials)
        const { token, expiresAt } = response.data
        persistToken({ token, expiresAt })
        dispatch({ type: AuthActions.RequestUserSuccess, payload: response })
      } catch (error) {
        dispatch({ type: AuthActions.RequestUserError })
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
      }
    },
    [addToast],
  )

  const hasRole = useCallback(
    (roleName: RoleName | RoleName[]) => {
      if (!state.user) return false
      const { user } = state
      if (user.role.name === 'dev') return true
      if (!Array.isArray(roleName)) return user.role.name === roleName

      return roleName.some(role => role === user.role.name)
    },
    [state],
  )

  return (
    <AuthContext.Provider
      value={{
        ...state,
        isAuthenticated,
        hasRole,
        signIn,
        signOut,
        getProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context || context == defaultValue) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}
