import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  CommissionState,
  CommissionReducerActionMap,
  CommissionDetailActions,
} from './props'

const initialState: CommissionState = {
  requestStatus: {
    error: false,
    loading: false,
    success: false,
  },
  commission: null,
}

const CommissionDetailReducer: Reducer<
  CommissionState,
  CommissionReducerActionMap
> = (state, action) => {
  switch (action.type) {
    case CommissionDetailActions.Reset:
      return initialState

    case CommissionDetailActions.Request:
      state.requestStatus.loading = true
      state.requestStatus.success = false
      state.requestStatus.error = false
      return state

    case CommissionDetailActions.RequestError:
      state.requestStatus.loading = false
      state.requestStatus.error = true
      state.requestStatus.success = false
      state.commission = null
      return state

    case CommissionDetailActions.RequestSuccess:
      state.requestStatus.loading = false
      state.requestStatus.error = false
      state.requestStatus.success = true
      state.commission = action.payload.data
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(CommissionDetailReducer)
