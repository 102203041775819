import React, { memo, useMemo, forwardRef } from 'react'
import { FiAlertCircle } from 'react-icons/fi'

import { useField } from 'formik'

import { TextareaProps } from './props'
import * as Styled from './styles'

const Textarea = forwardRef<HTMLInputElement, TextareaProps>((props, ref) => {
  const { name, 'data-testid': dataTestId, value, ...rest } = props

  const [field, meta] = useField<string>({
    name,
    value,
  })

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      }
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      }
    }
    return {}
  }, [meta.error, meta.initialError, meta.touched])

  const EndAdornment = useMemo(() => {
    if (!meta.error || !meta.touched) return null

    return <FiAlertCircle color="red" size={20} />
  }, [meta])

  return (
    <Styled.FormControl variant="outlined" fullWidth>
      <Styled.TextField
        ref={ref}
        variant="outlined"
        inputRef={ref}
        id={name}
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        {...errorProps}
        {...rest}
        {...field}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'data-testid': dataTestId,
          endAdornment: EndAdornment,
          ...rest.InputProps,
        }}
      />
    </Styled.FormControl>
  )
})

/**
 * Componente precisa ser child de `<Formik>`
 */
export default memo(Textarea)
