import * as Yup from 'yup'

const schema = Yup.object().shape({
  period: Yup.string(),
  date: Yup.string().when('period', period => {
    return period === 'monthly'
      ? Yup.string().required('Data obrigatória')
      : Yup.string()
  }),
})

export default schema
