import React, { memo, FC } from 'react'

import { TypographyProps } from './props'
import * as Styled from './styles'

const Typography: FC<TypographyProps> = props => {
  const { children, ...rest } = props

  return <Styled.Typography {...rest}>{children}</Styled.Typography>
}

export default memo(Typography)
