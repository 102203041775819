import React, { FC, useCallback } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import Report from 'components/Modals/Report/ReportLeaves'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import {
  ChipsLeavesCashFlowProvider,
  useChipsLeavesCashFlow,
} from 'hooks/ChipsLeavesCashFlow'
import { ChipsLeavesCashFlow } from 'hooks/ChipsLeavesCashFlow/props'
import RoutingService from 'services/routing'
import { FormatDate } from 'utils/formatDateReports'
import withContext from 'utils/withContext'

import ChipsLeavesHeader from './components/ChipsLeavesHeader'
import ChipsLeavesListItem from './components/ChipsLeavesListItem'
import HeaderTable from './components/HeaderTable'
import { TitleSection } from './styles'
const ChipLeaves: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    startDate,
    endDate,
    current_page,
    per_page,
    total,
    getChipsLeaves,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
  } = useChipsLeavesCashFlow()

  let initialDate = FormatDate(startDate)
  let ultimateDate = FormatDate(endDate)
  const renderItem = useCallback<TableRenderItem<ChipsLeavesCashFlow>>(
    item => <ChipsLeavesListItem key={item.id} {...item} />,
    [],
  )

  return (
    <Route
      title="Saídas de chip"
      access="auth"
      requireRoles={[
        'admin',
        'attendant',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'office_stockist',
        'sale_leader',
        'pap_leader',
        'pap_seller',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Relatórios',
            link: RoutingService.REPORTS_CASHFLOW,
          },
          {
            title: 'Saídas de chip',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-reports"
      >
        <TitleSection>Relatório de saída de chip</TitleSection>
        <HeaderTable />
        <Table
          data={data}
          renderItem={renderItem}
          loadingRequest={requestStatus.loading}
          HeaderComponent={<ChipsLeavesHeader />}
          FooterComponent={
            <TablePagination
              getData={getChipsLeaves}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
        <Report
          firstParagraph="Exportar relatório de Saídas"
          secondParagraph={`Período de tempo escolhido entre ${initialDate} e ${ultimateDate}`}
          type="leaves"
          data={data}
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(ChipLeaves, ChipsLeavesCashFlowProvider)
