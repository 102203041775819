import { Reducer } from 'hooks/props'
import { produce } from 'immer'

import {
  FormCompanyState,
  FormCompanyReducerActionMap,
  FormCompanyActions,
} from './props'

const initialState: FormCompanyState = {
  requestStatusAddCompany: {
    error: false,
    loading: false,
    success: false,
  },
  requestStatusUpdateCompany: {
    error: false,
    loading: false,
    success: false,
  },
  company: null,
}

const formCompanyReducer: Reducer<
  FormCompanyState,
  FormCompanyReducerActionMap
> = (state, action) => {
  switch (action.type) {
    case FormCompanyActions.Reset:
      return initialState

    /**
     * Request de cadastro de empresa
     */
    case FormCompanyActions.RequestAddCompany:
      state.requestStatusAddCompany.loading = true
      return state

    case FormCompanyActions.RequestErrorAddCompany:
      state.requestStatusAddCompany.loading = false
      state.requestStatusAddCompany.error = true
      state.requestStatusAddCompany.success = false
      state.company = null
      return state

    case FormCompanyActions.RequestSuccessAddCompany:
      state.requestStatusAddCompany.loading = false
      state.requestStatusAddCompany.error = false
      state.requestStatusAddCompany.success = true
      state.company = action.payload.data
      return state

    /**
     * Request de atualizaçao de empresa
     */
    case FormCompanyActions.RequestUpdateCompany:
      state.requestStatusUpdateCompany.loading = true
      return state

    case FormCompanyActions.RequestErrorUpdateCompany:
      state.requestStatusUpdateCompany.loading = false
      state.requestStatusUpdateCompany.error = true
      state.requestStatusUpdateCompany.success = false
      return state

    case FormCompanyActions.RequestSuccessUpdateCompany:
      state.requestStatusUpdateCompany.loading = false
      state.requestStatusUpdateCompany.error = false
      state.requestStatusUpdateCompany.success = true
      return state

    default:
      return state
  }
}

export { initialState }
export default produce(formCompanyReducer)
