import ButtonProps from 'components/ButtonSubmit'
import DatePickerProps from 'components/Inputs/DatePicker'
import styled from 'styled-components'

export const Button = styled(ButtonProps)`
  width: 100%;
`

export const DatePicker = styled(DatePickerProps)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`
