enum ERRORS {
  USER_NOT_FOUND = 'Usuário não encontrado',
  USER_HAS_EVER_LOGGED_IN = 'Usuário já realizou o primeiro login',
  INVALID_CREDENTIALS = 'Credenciais inválidas',
  DISABLED_USER = 'Usuário desativado',
  EMAIL_ALREADY_EXISTS = 'E-mail já cadastrado',
  ROLE_NOT_FOUND = 'Permissão não encontrada',
  PRODUCT_NOT_FOUND = 'Produto não encontrado',
  TOKEN_NOT_FOUND = 'Token não encontrado',
  EXPIRED_TOKEN = 'Sessão expirada',
  OLD_PASSWORD_REQUIRED = 'Senha antiga necessária',
  OLD_PASSWORD_NOT_MATCH = 'Senha antiga incorreta',
  UNAUTHORIZED = 'Não autorizado',
  NOTIFICATION_ALREADY_READ = 'Notificação já lida',
  RECIPIENT_NOT_FOUND = 'Destinatário não encontrado',
  NOTIFICATION_NOT_FOUND = 'Notificação não encontrada',
  INVALID_ICCID = 'ICCID Inválido',
  USER_NOT_STOCKIST = 'Usuário não é estoquista',
  STOCKIST_NOT_FOUND = 'Estoquista não encontrado',
  COMPANY_NOT_FOUND = 'Revendedor não encontrado',
  DISTRIBUTOR_NOT_FOUND = 'Distribuidor não encontrado',
  SALE_NOT_FOUND = 'Venda não encontrada',
  SALE_ALREADY_CONFIRMED = 'Venda já paga',
  COMPANY_NOT_ACTIVE = 'Revendedor desativado',
  COMPANY_BLOCKED = 'Revendedor bloqueado',
  DISTRIBUTOR_NOT_ALLOWED = 'Distribuidor sem permissão',
  DISCOUNT_GREATER_THAN_TOTAL_QUANTITY = 'Desconto maior que a quantidade total',
  SOME_CHIP_IS_NOT_REGISTERED = 'Algum chip não está registrado',
  SOME_CHIPS_DOES_NOT_ON_DISTRIBUTOR_STOCK = 'Algum chip não está no estoque do distribuidor',
  SOME_CHIPS_PENDING_ANOTHER_OPERATION = 'Algum chip está pendente em outra operação',
  STOCK_NOT_FOUND = 'Estoquista não encontrado',
  SOME_CHIPS_ALREADY_REGISTERED = 'Algum chip já foi cadastrado',
  WITHOUT_PERMISSION = 'Sem permissão',
  ONLY_PENDING_TRANSFER_CAN_CANCELED = 'Apenas transferências pendentes podem ser canceladas',
  ONLY_PENDING_TRANSFER_CAN_DENIED = 'Apenas transferências pendentes podem ser rejeitadas',
  ONLY_PENDING_TRANSFER_CAN_ACCEPT = 'Apenas transferências pendentes podem ser aceitas',
  SENDER_NOT_FOUND = 'Remetente não encontrado',
  SOME_CHIPS_DOES_NOT_ON_SENDER_STOCK = 'Algum chip não está no estoque do remetente',
  INVALID_TOKEN = 'Token inválido',
  TOO_MANY_REQUESTS = 'Muitas requisições',
  INTERNAL_SERVER_ERROR = 'Erro interno',
  CITY_NOT_FOUND = 'Cidade não encontrada',
  WALLET_NOT_FOUND = 'Carteira de chips não encontrada',
  SALE_STATUS_CANNOT_BE_CHANGED = 'Status atual da venda não pode ser modificado',
  INVALID_DISCOUNT = 'Desconto inválido',
  SOME_ICCID_IS_DUPLICATED = 'Alguns iccids estão duplicados',
  COMMISSION_ALREADY_CREATED = 'Commissão já criada',
  COMPANY_IS_NOT_FRANCHISED = 'A empresa náo é franqueada',
  COMMISSION_NOT_FOUND = 'Commissão não encontrada',
  CONSUMPTIONS_NOT_FOUND = 'Consumos não encontrados',
  FILE_ALREADY_EXISTING = 'Arquivo já existe',
  INVALID_FILE_FIELD = 'Campo de arquivo inválido',
  INTERVAL_NOT_FOUND = 'Intervalo não encontrado',
  ERROR_CHANGE_DATE = 'Erro de mudança de data',
  INTERVAL_ICCIDS_NOT_FOUND = 'Intervalo de iccids não existe',
  INVALID_PARAMS_GERENCIANET = 'Parâmetros inválidos (gerencianet)',
}

class ErrorHandler {
  /**
   * Retorna Erro traduzido
   *
   * @param error objeto de erro
   *
   * @return string contendo o erro traduzido ou generico
   */
  static getMessage(error: any) {
    const key = error.response?.data?.error

    if (typeof key === 'string' && ERRORS[key]) {
      return ERRORS[key]
    }
    return 'Ocorreu um problema'
  }
}

export default ErrorHandler
