import styled from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress'
import MUFormControl from '@material-ui/core/FormControl'
import MUTextField from '@material-ui/core/TextField'

export const TextField = styled(MUTextField)`
  border-radius: 6px;
  height: ${({ theme }) => theme.spacing(6)}px;
  .MuiInputBase-root.Mui-disabled {
    height: ${({ theme }) => theme.spacing(6)}px;
    border-radius: 6px;
    color: ${({ theme }) => theme.palette.common.black};
  }
`
export const FormControl = styled(MUFormControl)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  .MuiOutlinedInput-notchedOutline {
    border-width: 1.5px !important;
  }
`

export const LoadingIcon = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
`
