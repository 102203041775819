import React, { FC, memo, useMemo } from 'react'

import Typography from 'components/Typography'

import Grid from '@material-ui/core/Grid'

import { DetailsPageSectionProps } from './props'
import * as Styled from './styles'

const DetailsPageSection: FC<DetailsPageSectionProps> = props => {
  const { title, children, ...rest } = props

  const DetailsPageSectionHeader = useMemo(() => {
    if (!title) return null

    return (
      <Grid sm={12} item>
        <Typography color="primary" variant="h6" capitalize="uppercase">
          {title}
        </Typography>
      </Grid>
    )
  }, [title])

  return (
    <Styled.Grid container spacing={2} {...rest}>
      {DetailsPageSectionHeader}

      {children}
    </Styled.Grid>
  )
}

export default memo(DetailsPageSection)
