import React, {
  FC,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'hooks/toast'
import { RequestStatus } from 'interfaces/common'
import { api } from 'services/api'
import ErrorHandler from 'services/errorHandler'
import RoutingService from 'services/routing'

import { SetNewPasswordCredentials, SetNewPasswordContextData } from './props'

const initialValue = {} as SetNewPasswordContextData
const SetNewPasswordContext = createContext(initialValue)

export const SetNewPasswordProvider: FC = props => {
  const { children } = props

  const [status, setStatus] = useState<RequestStatus>({
    loading: false,
    error: false,
    success: false,
  })

  const { addToast } = useToast()
  const history = useHistory()

  const setNewPassword = useCallback(
    async (credentials: SetNewPasswordCredentials) => {
      try {
        setStatus({
          error: false,
          loading: true,
          success: false,
        })

        await api.post('/password/reset', credentials)

        setStatus({
          error: false,
          loading: false,
          success: true,
        })
        addToast({
          type: 'success',
          title: 'Senha alterada com sucesso',
        })
        history.push(RoutingService.LOGIN)
      } catch (error) {
        addToast({
          type: 'error',
          title: ErrorHandler.getMessage(error),
        })
        setStatus({
          error: true,
          loading: false,
          success: false,
        })
      }
    },
    [addToast, history],
  )

  return (
    <SetNewPasswordContext.Provider value={{ status, setNewPassword }}>
      {children}
    </SetNewPasswordContext.Provider>
  )
}

export const useSetNewPassword = () => {
  const context = useContext(SetNewPasswordContext)
  if (!context || context === initialValue) {
    throw new Error(
      'useSetNewPassword must be used within a SetNewPasswordProvider',
    )
  }
  return context
}
