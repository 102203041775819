import { User } from 'hooks/auth/props'

const TYPES = {
  distributor: 'distributor',
  stockist: 'stock',
  company_representative: 'company',
}

class ContextUser {
  /**
   * Retorna Id do contexto baseado no tipo de usuário
   *
   * @param User objeto contendo todo o usuário
   *
   * @return
   * - distributor: retorna Id do distributor
   * - stockist: retorna Id do estoquista
   * - company_representative: retorna Id da empresa
   */
  static extractId(user: User | null) {
    if (user) {
      switch (user.role.name) {
        case 'distributor':
          return user.distributor?.id as string

        case 'stockist':
          return user.stockist?.stock.id as string

        case 'company_representative':
          return user.company_representative?.company.id as string

        default:
          return ''
      }
    }
    return ''
  }

  /**
   * Retorna tipo de contexto do usuário
   *
   * @param User objeto contendo todo o usuário
   *
   * @return tipo do contexto
   */
  static extractType(user: User | null) {
    if (user) {
      return TYPES[user.role.name]
    }
    return ''
  }
}

export default ContextUser
