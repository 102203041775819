import React, { FC, useCallback } from 'react'

import LayoutProduct from 'components/LayoutProduct'
import Report from 'components/Modals/Report/ReportChange'
import Route from 'components/Route'
import Table from 'components/Table'
import { TableRenderItem } from 'components/Table/props'
import TablePagination from 'components/TablePagination'
import {
  ChangeChipsCashFlowProvider,
  useChangeChipsCashFlow,
} from 'hooks/ChangeChipsCashFlow'
import { ChangeChipsCashFlow } from 'hooks/ChangeChipsCashFlow/props'
import RoutingService from 'services/routing'
import { FormatDate } from 'utils/formatDateReports'
import withContext from 'utils/withContext'

import ChangeChipsHeader from './components/ChangeChipsHeader'
import ChangeListItem from './components/ChangeListItem'
import HeaderTable from './components/HeaderTable'
import { TitleSection } from './styles'
const ChangeChips: FC = () => {
  const {
    data,
    to,
    prev_page,
    next_page,
    from,
    current_page,
    per_page,
    total,
    startDate,
    endDate,
    getChipsChange,
    nextPage,
    previousPage,
    requestStatus,
    setLimit,
    setPage,
  } = useChangeChipsCashFlow()

  let initialDate = FormatDate(startDate)
  let ultimateDate = FormatDate(endDate)
  const renderItem = useCallback<TableRenderItem<ChangeChipsCashFlow>>(
    item => <ChangeListItem key={item.id} {...item} />,
    [],
  )
  return (
    <Route
      title="Trocas de chips"
      access="auth"
      requireRoles={[
        'admin',
        'attendant',
        'commercial_coordinator',
        'general_coordinator',
        'office_leader',
        'office_stockist',
        'sale_leader',
        'pap_leader',
        'pap_seller',
        'supervisor',
        'general_manager',
      ]}
    >
      <LayoutProduct
        crumbs={[
          { title: 'CRM Brisamóvel', link: RoutingService.BRISAMOVEL },
          {
            title: 'Relatórios',
            link: RoutingService.REPORTS_CHANGES_CASHFLOW,
          },
          {
            title: 'Trocas de chips',
            link: '#',
          },
        ]}
        customizable
        titleMenu="Fluxo de caixa"
        menuLayout="cashflow"
        activeOption="cashflow-reports"
      >
        <TitleSection>Relatório de trocas de chips</TitleSection>
        <HeaderTable />
        <Table
          data={data}
          renderItem={renderItem}
          loadingRequest={requestStatus.loading}
          HeaderComponent={<ChangeChipsHeader />}
          FooterComponent={
            <TablePagination
              getData={getChipsChange}
              to={to}
              prev_page={prev_page}
              next_page={next_page}
              from={from}
              current_page={current_page}
              per_page={per_page}
              total={total}
              onChangeRowsPerPage={setLimit}
              nextPage={nextPage}
              previousPage={previousPage}
              setPage={setPage}
            />
          }
        />
        <Report
          firstParagraph="Exportar relatório de Trocas"
          type="change"
          secondParagraph={`Período de tempo escolhido entre ${initialDate} e ${ultimateDate}`}
          data={data}
        />
      </LayoutProduct>
    </Route>
  )
}

export default withContext(ChangeChips, ChangeChipsCashFlowProvider)
