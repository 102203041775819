import React, { FC, memo, useState, useCallback, useMemo } from 'react'

import ButtonSubmit from 'components/ButtonSubmit'
import Formik from 'components/Formik'
import TextField from 'components/Inputs/TextField'
import Typography from 'components/Typography'
import { useAuth } from 'hooks/auth'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import schema from './schema'

const Form: FC = () => {
  const [showPassword, setShowPassword] = useState(false)

  const { signIn, isAuthenticating } = useAuth()

  const handleClickShowPassword = useCallback(
    () => setShowPassword(prev => !prev),
    [],
  )

  const eyeIcon = useMemo(
    () => (showPassword ? <Visibility /> : <VisibilityOff />),
    [showPassword],
  )

  const passwordInputType = useMemo(
    () => (showPassword ? 'text' : 'password'),
    [showPassword],
  )

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={signIn}
      validationSchema={schema}
    >
      <Typography color="primary" variant="h5">
        Entrar
      </Typography>

      <TextField
        data-testid="input-email"
        name="email"
        type="email"
        label="E-mail"
        placeholder="Digite seu e-mail"
      />

      <TextField
        data-testid="input-password"
        type={passwordInputType}
        name="password"
        label="Senha"
        placeholder="Digite sua senha"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {eyeIcon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <ButtonSubmit
        data-testid="button-enter"
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        disableElevation
        fullWidth
        disabled={isAuthenticating}
      >
        Entrar
      </ButtonSubmit>
    </Formik>
  )
}

export default memo(Form)
