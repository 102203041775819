import styled from 'styled-components'

import MUTable from '@material-ui/core/Table'

export const TableContainer = styled.div`
  overflow-x: auto;
`

export const Table = styled(MUTable)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 6px;
  overflow: hidden;

  thead {
    background-color: #f1f3f5;

    tr:last-child {
      background-color: ${({ theme }) => theme.palette.common.white};
    }
  }

  tfoot {
    border-top: ${({ theme }) => theme.spacing(1)}px solid
      ${({ theme }) => theme.palette.common.white};
    background-color: #f1f3f5;
  }

  th {
    background-color: transparent;
    align-items: center;
  }

  td,
  th {
    border-bottom: none;
  }

  tbody tr {
    border-bottom: none;

    :nth-of-type(odd) {
      background-color: #f1f3f5;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    display: table;
  }

  &.hide-shadow {
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;
  }
`
