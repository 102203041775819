import RoutingService from 'services/routing'

import { MenuOptionType } from '../components/OptionsList/props'

export const HOME: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/home.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/home.svg')
    .default,
  name: 'página inicial',
  page: RoutingService.BRISAMOVEL,
  optionKey: 'home',
}

export const DISTRIBUTORS: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/carrinho_de_compras.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/carrinho_de_compras.svg')
    .default,
  name: 'distribuidores',
  page: RoutingService.DISTRIBUTORS,
  optionKey: 'distributors',
}

export const USERS: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/user.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/user.svg')
    .default,
  name: 'usuários',
  page: RoutingService.USERS,
  optionKey: 'users',
}

export const SALES: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/money.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/money.svg')
    .default,
  name: 'vendas',
  page: RoutingService.SALES,
  optionKey: 'sales',
}

export const COMPANIES: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/trabalho.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/trabalho.svg')
    .default,
  name: 'revendedores',
  page: RoutingService.COMPANIES,
  optionKey: 'companies',
}

export const COMPANY_REPRESENTATIVE_REPORTS: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/document.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/document.svg')
    .default,
  name: 'Relátorios',
  page: RoutingService.COMPANY_REPRESENTATIVE_REPORTS,
  optionKey: 'reports-company',
}

export const CONSOLIDATED_REPORTS: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/document.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/document.svg')
    .default,
  name: 'Relátorios Consolidados',
  page: RoutingService.CONSOLIDATED_REPORTS,
  optionKey: 'reports-consolidated',
}

export const STOCKS: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/arquivo.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/arquivo.svg')
    .default,
  name: 'estoques',
  page: RoutingService.STOCKS,
  optionKey: 'stocks',
}

export const MY_STOCK: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/arquivo.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/arquivo.svg')
    .default,
  name: 'meu estoque',
  page: RoutingService.MY_STOCK,
  optionKey: 'my-stock',
}

export const TRANSFERS: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/arrow_link_left.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/arrow_link_left.svg')
    .default,
  name: 'transferências',
  page: RoutingService.TRANSFERS,
  optionKey: 'transactions',
}

export const RECHARGES: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/smartphone.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/smartphone.svg')
    .default,
  name: 'recargas app',
  page: RoutingService.APP_RECHARGES,
  optionKey: 'recharges',
}

export const COMMISSIONS: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/money.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/money.svg')
    .default,
  name: 'comissões',
  page: RoutingService.COMMISSIONS_COMPANY_REPRESENTATIVE,
  optionKey: 'commissions',
}

export const CASHFLOW_SALES: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/money.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/money.svg')
    .default,
  name: 'vendas',
  page: RoutingService.CASH_FLOW,
  optionKey: 'cashflow-sales',
}

export const CASHFLOW_WALLET: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/carteira.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/carteira.svg')
    .default,
  name: 'carteira',
  page: RoutingService.WALLET,
  optionKey: 'cashflow-wallet',
}

export const CASHFLOW_NEW_SALE: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/carrinho_de_compras.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/carrinho_de_compras.svg')
    .default,
  name: 'nova venda',
  page: RoutingService.NEW_SALE_CASHFLOW,
  optionKey: 'cashflow-new-sales',
}

export const CASHFLOW_LEAVES: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/chip.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/chip.svg')
    .default,
  name: 'saídas',
  page: RoutingService.LEAVES_CASHFLOW,
  optionKey: 'cashflow-leaves',
}

export const CASHFLOW_REPORTS: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/document.svg')
    .default,
  iconInactive: require('assets/images/icons-sidebar/inactive/document.svg')
    .default,
  name: 'relatorios',
  page: RoutingService.REPORTS_CASHFLOW,
  optionKey: 'cashflow-reports',
}

export const UPLOAD_FILE_VIVO: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/upload.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/upload.svg')
    .default,
  name: 'upload arquivo vivo',
  page: RoutingService.UPLOAD_FILE_VIVO,
  optionKey: 'upload-file',
}

export const GENERATE_COMMISSION: MenuOptionType = {
  iconActive: require('assets/images/icons-sidebar/active/money.svg').default,
  iconInactive: require('assets/images/icons-sidebar/inactive/money.svg')
    .default,
  name: 'gerar comissão',
  page: RoutingService.GENERATE_COMMISSION,
  optionKey: 'generate-commission',
}
