import * as Yup from 'yup'

const stockist = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
})

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  new_stockist: Yup.boolean(),
  stockist: Yup.mixed().when('new_stockist', {
    is: true,
    then: stockist,
  }),
  city_id: Yup.string().required('Campo obrigatório'),
  state_id: Yup.string().required('Campo obrigatório'),
})

export default schema
