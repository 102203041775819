import React, { FC, memo, useMemo } from 'react'

import { useICCIDS } from 'hooks/iccids'

import { useNewTransfer } from '../../context'
import * as Styled from './styles'

const ButtonSubmit: FC = () => {
  const { requestStatus } = useNewTransfer()
  const { isValidQuantity } = useICCIDS()

  const renderText = useMemo(() => {
    if (requestStatus.loading) {
      return 'Carregando...'
    }

    return 'Finalizar transferência'
  }, [requestStatus])

  return (
    <Styled.Button
      type="submit"
      color="secondary"
      variant="contained"
      size="large"
      disableElevation
      disabled={!isValidQuantity}
    >
      {renderText}
    </Styled.Button>
  )
}

export default memo(ButtonSubmit)
