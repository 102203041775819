import React, { memo, useCallback, useMemo } from 'react'

import { FieldArray, useField } from 'formik'

import MuiCheckbox from '@material-ui/core/Checkbox'

import { CheckboxGroupProps, CheckboxGroupItemProps } from './props'
import * as Styled from './styles'

function checkbox(props: CheckboxGroupItemProps) {
  const { label, ...rest } = props
  return (
    <Styled.FormControlLabel
      control={
        <MuiCheckbox
          icon={<Styled.FilledOutline />}
          disableRipple
          disableFocusRipple
          checkedIcon={
            <Styled.FilledOutline>
              <Styled.FilledOutlineChecked />
            </Styled.FilledOutline>
          }
          {...rest}
        />
      }
      label={label}
      labelPlacement="end"
    />
  )
}
const Checkbox = memo(checkbox)

function CheckboxGroup<ItemT extends {}>(props: CheckboxGroupProps<ItemT>) {
  const { name, data, checkboxKeys, orientation = 'row' } = props

  const [field, , helpers] = useField<string[]>(name)

  const isChecked = useCallback(
    (item: ItemT) =>
      field.value.includes(getValueOfCheckboxKey(item, 'checked')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkboxKeys.checked, field.value],
  )

  const getValueOfCheckboxKey = useCallback(
    (item: ItemT, key: keyof typeof checkboxKeys) =>
      (item[checkboxKeys[key]] as unknown) as string,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkboxKeys],
  )

  const handleItemChange = useCallback(
    (item: ItemT) => {
      const checked = isChecked(item)
      if (checked) {
        const idx = field.value.indexOf(getValueOfCheckboxKey(item, 'value'))

        const newValues = field.value
          .splice(0)
          .filter((_, index) => index != idx)
        helpers.setValue(newValues)
      } else {
        helpers.setValue([...field.value, getValueOfCheckboxKey(item, 'value')])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkboxKeys.value, field.value, helpers, isChecked],
  )

  const items = useMemo(() => {
    if (!data || data.length === 0) return null

    return data.map(item => {
      const checked = isChecked(item)
      const handleChange = () => handleItemChange(item)
      const key = getValueOfCheckboxKey(item, 'key')
      const label = getValueOfCheckboxKey(item, 'label')

      return (
        <Checkbox
          key={key}
          checked={checked}
          onChange={handleChange}
          label={label}
        />
      )
    })
  }, [data, getValueOfCheckboxKey, handleItemChange, isChecked])

  const fieldArrayRender = useCallback(
    () => <Styled.Content orientation={orientation}>{items}</Styled.Content>,
    [items, orientation],
  )

  return <FieldArray name={name} render={fieldArrayRender} />
}

export default CheckboxGroup
