import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
    }
    body {
      -webkit-font-smoothing: antialiased;
      #app{
        width: 100vw;
        height: 100vh;
      }
    }
    button,
    a {
      cursor: pointer;
    }
    a {
      text-decoration: none;
    }
    *::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }
    *::-webkit-scrollbar-track {
      box-shadow: none;
    }
    *::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,.2);
      outline: none;
      border-radius: 10px;
    }
`
export default GlobalStyle
